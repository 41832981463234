import React from 'react'
import * as PropTypes from 'prop-types'
import '../style.scss'

const RowCell = ({ className, content }) => (
  <td className="table__data">
    <div className={className}>{content}</div>
  </td>
)

RowCell.propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
}

export default RowCell
