import React from 'react'
import { takeLatest, call, put } from 'redux-saga/effects'
import { fetchMany, inventoryAdjustment } from 'api/warehouseCaseAndPallet'
import {
  fetchWarehouseCaseAndPalletListFail,
  fetchWarehouseCaseAndPalletListSuccess,
  types,
} from './actions'
import { resetModals, showModal } from '../modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'

function* fetchWarehouseCaseAndPalletList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Fetching location pallets...</div>,
      }),
    )
    yield put(
      fetchWarehouseCaseAndPalletListSuccess({
        items: response.data.data.items,
        metaData: response.data.meta,
      }),
    )
    yield put(resetModals())
  } catch (error) {
    yield put(fetchWarehouseCaseAndPalletListFail())
  }
}

function* warehouseCaseAndPalletInventoryAdjustment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(inventoryAdjustment, request)
    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

export default function* warehousePalletSaga() {
  yield takeLatest(
    types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_START,
    fetchWarehouseCaseAndPalletList,
  )
  yield takeLatest(
    types.WAREHOUSE_CASE_AND_PALLET_INVENTORY_ADJUSTMENT,
    warehouseCaseAndPalletInventoryAdjustment,
  )
}
