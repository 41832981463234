import { types } from './actions'

const INIT = {
  list: [],
  otherList: [],
}

const warehouseCaseReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_WAREHOUSE_CASES_LIST_START:
    case types.FETCH_OTHER_WAREHOUSE_CASES_LIST_START:
      return {
        ...state,
      }
    case types.FETCH_WAREHOUSE_CASES_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_OTHER_WAREHOUSE_CASES_LIST_SUCCESS:
      return {
        ...state,
        otherList: payload,
      }
    case types.FETCH_WAREHOUSE_CASES_LIST_SUCCESS_APPEND:
      return {
        ...state,
        list: [...state.list, ...payload],
      }
    case types.FETCH_WAREHOUSE_CASES_LIST_FAIL:
      return INIT
    default:
      return state
  }
}

export default warehouseCaseReducer
