import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import { closeModal, showModal, resetModals } from 'store/modal/actions'
import { fetchPrinterLibraryStart, clearPrinters } from 'store/print/actions'
import { MODAL_PENDING, MODAL_CONFIRM_DEFAULT } from 'components/common/Modal'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Button from 'components/common/Button'
import ExclamationSignYellow from 'assets/svg/exclamation-sign-yellow.svg'
import { SNACK_ERROR, SNACK_INFO } from 'constants/snackbar'
import { useSnackbar } from 'notistack'

const ClearPrintersPage = (props) => {
  const {
    allPrinters,

    closeModal,
    resetModals,
    showModal,
    fetchPrinterLibrary,
    clearPrinters,
  } = props

  const { enqueueSnackbar } = useSnackbar()

  const onMount = () => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Fetching printers...</div>,
    })
    fetchPrinterLibrary({
      afterAction: () => closeModal(MODAL_PENDING),
    })
  }

  useEffect(onMount, [])

  const onPrinterSelect = (printer_id, printer_location_name) => {
    showModal({
      name: MODAL_CONFIRM_DEFAULT,
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '50px',
          }}
        >
          <div>
            <ExclamationSignYellow style={{ marginBottom: '10px' }} />
          </div>
          <div className="text" style={{ textAlign: 'center' }}>
            Are you sure you want to clear the {printer_location_name} printer?
          </div>
        </div>
      ),
      events: {
        onAccept: () => clearPrinter(printer_id, printer_location_name),
        onDeny: resetModals,
      },
    })
  }

  const clearPrinter = (printer_id, printer_location_name) => {
    resetModals()

    clearPrinters({
      request: {
        printer_id: printer_id,
      },
      onSuccess: () => {
        enqueueSnackbar(
          `Clearing ${
            printer_id === 'all'
              ? 'all printers'
              : printer_location_name + ' printer'
          }...`,
          SNACK_INFO,
        )
      },
      onError: () => {
        enqueueSnackbar(
          `Error clearing ${
            printer_id === 'all'
              ? 'all printers'
              : printer_location_name + ' printer'
          }`,
          SNACK_ERROR,
        )
      },
    })
  }

  const onClearAll = () => {
    showModal({
      name: MODAL_CONFIRM_DEFAULT,
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '50px',
          }}
        >
          <div>
            <ExclamationSignYellow style={{ marginBottom: '10px' }} />
          </div>
          <div className="text" style={{ textAlign: 'center' }}>
            Are you sure you want to clear all printers?
          </div>
        </div>
      ),
      events: {
        onAccept: () => clearPrinter('all', ''),
        onDeny: resetModals,
      },
    })
  }

  return (
    <PageContent className="about-page">
      <ContentHeader title="Clear Printer Queues"></ContentHeader>
      <ContentSection>
        {allPrinters && allPrinters.length > 0 && (
          <div className="modal--printers modal--printers--no-bg">
            {allPrinters.map((printer) => {
              const onSelect = printer.is_online
                ? () => {
                    onPrinterSelect(printer.id, printer.printer_location_name)
                  }
                : null
              const buttonClass = printer.is_online ? 'online' : 'offline'

              return (
                <Card
                  className={`modal--printers__card ${buttonClass}`}
                  onClick={onSelect}
                >
                  <CardActionArea>
                    <CardContent className="modal--printers__card-content">
                      {printer.printer_location_name}
                      {printer.is_online ? '' : '  <OFFLINE>'}
                    </CardContent>
                  </CardActionArea>
                </Card>
              )
            })}
          </div>
        )}
        <Button text="Clear All" onClick={onClearAll} />
      </ContentSection>
    </PageContent>
  )
}

ClearPrintersPage.propTypes = {}

const mapStateToProps = (state) => ({
  allPrinters: state.print.allPrinters,
})

const mapDispatchToProps = {
  closeModal,
  clearPrinters,
  showModal,
  resetModals,
  fetchPrinterLibrary: fetchPrinterLibraryStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClearPrintersPage)
