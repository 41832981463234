import { connect } from 'react-redux'
import LocationGroupAddLocationForm from './component'
import { fetchAvailableBaysStart } from 'store/location/actions'
import { fetchFiltersStart } from 'store/filter/actions'
import { resetModals } from 'store/modal/actions'

const mapStateToProps = (state) => ({
  aisleList: state.filter.filterParams.aisleList,
  availableBayList: state.location.availableBays,
})

const mapDispatchToProps = {
  fetchLocationFilters: fetchFiltersStart,
  fetchAvailableBayList: fetchAvailableBaysStart,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationGroupAddLocationForm)
