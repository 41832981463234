import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalDefault from 'components/common/Modal/ModalDefault'
import { isEmptyObject } from 'helpers/index'
import TableList from 'components/common/TableList'
import Button from 'components/common/Button'
import { resetModals } from 'store/modal/actions'
import { MODAL_PRODUCTS_LIST } from 'components/common/Modal'

const modalColumns = [
  {
    id: 1,
    label: 'Model',
    value: 'model',
  },
  {
    id: 2,
    label: 'Model Manf',
    value: 'modelManf',
  },
  {
    id: 3,
    label: 'Product Name',
    value: 'productName',
  },
  {
    id: 4,
    label: 'Case',
    value: 'case',
  },
]

const ModalProductsList = ({ isOpen, modal, resetModals }) => {
  const getModalContent = () => {
    if (isEmptyObject(modal.options) || !isOpen) {
      return <></>
    }

    const getModalItems = () => {
      return modal.options.entity.orderProducts.map((item) => {
        const pickLocationsForProduct = modal.options.entity.pickLocations.filter(
          (pickLocation) =>
            pickLocation.product_id === item.products_id.products_id,
        )

        const pickedCaseCount = pickLocationsForProduct.reduce(
          (accumulator, currentValue) => {
            return currentValue.picked_quantity + accumulator
          },
          0,
        )

        const caseCount = pickLocationsForProduct.reduce(
          (accumulator, currentValue) => {
            return currentValue.location_pick_quantity + accumulator
          },
          0,
        )

        return {
          id: item.products_id.products_id,
          model: item.products_id.products_model,
          modelManf: item.products_id.products_manf_model,
          productName: item.product_description.products_name,
          case: `${pickedCaseCount}/${caseCount}`,
        }
      })
    }

    return (
      <div className="modal--products-list">
        <div className="modal--products-list__header">
          <div className="order-info">
            <span className="order-info--title">Order: </span>
            <span className="order-info--text">
              {modal.options.entity.order.orders_id}
            </span>
          </div>
          <div className="order-info">
            <span className="order-info--title">Customer: </span>
            <span className="order-info--text">
              {modal.options.entity.order.customers_name}
            </span>
          </div>
        </div>
        <TableList
          className="list"
          rows={getModalItems()}
          columns={modalColumns}
        />
        <div className="btn-wrapper">
          <Button text={'OK'} onClick={resetModals} />
        </div>
      </div>
    )
  }

  return isOpen ? (
    <ModalDefault isOpen={isOpen} children={getModalContent()} />
  ) : (
    ''
  )
}

ModalProductsList.propTypes = {
  isOpen: PropTypes.bool,

  modal: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_PRODUCTS_LIST,
  ),
  modal: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_PRODUCTS_LIST,
  ),
})

const mapDispatchToProps = {
  resetModals,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProductsList)
