import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.CONTAINER.FETCH_MANY(payload),
    method: 'GET',
  })

export const fetchOne = (payload) =>
  request({
    url: API_ROUTE.CONTAINER.FETCH_ONE(payload.id),
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.CONTAINER.UPDATE(payload.id),
    method: 'PUT',
    data: payload,
  })
