import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const Button = (props) => {
  const {
    className = '',
    text = '',
    type = 'button',
    disabled = false,
    onClick = () => false,
    tabIndex,
  } = props

  const [clicked, setClicked] = useState(false)

  const toggleClass = () => setClicked(!clicked)

  const onButtonClick = (e) => {
    e.stopPropagation()
    onClick(e)
  }

  return (
    <button
      tabIndex={tabIndex}
      type={type}
      disabled={disabled}
      onClick={onButtonClick}
      className={`btn ${clicked || 'btn--clicked'} ${className || ''}`}
      onMouseDown={toggleClass}
      onMouseUp={toggleClass}
    >
      {text}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.any,

  className: PropTypes.string,
  tabIndex: PropTypes.string,
  type: PropTypes.string,

  disabled: PropTypes.bool,

  onClick: PropTypes.func,
}

export default Button
