import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const createMany = (payload) =>
  request({
    url: API_ROUTE.TASK_ASSIGNMENT.CREATE_MANY,
    method: 'POST',
    data: payload,
  })

export const deleteMany = (payload) =>
  request({
    url: API_ROUTE.TASK_ASSIGNMENT.DELETE_MANY,
    method: 'DELETE',
    data: payload,
  })
