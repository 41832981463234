import React, { Component } from 'react'
import Switch from 'components/common/Switch'
import * as PropTypes from 'prop-types'
import query from 'querystringify'
import {
  ITEMS_LIST_DEFAULT_ITEM_ID,
  CONTAINER_LIST_PAGE_TYPE_RECEIVING,
  CONTAINER_LIST_PAGE_TYPE_UNLOADING,
} from 'constants/common'
import { cleanFilters, redirect } from 'helpers/index'
import { FILTERS } from 'constants/filters'

export const FILTER_PARAMETER = {
  IN_TRANSIT: 998,
  NOT_COMPLETED: 999,
}

export default class ContainersSwitchFilters extends Component {
  static propTypes = {
    containerStatusList: PropTypes.array,
    vendorList: PropTypes.array,
    filterList: PropTypes.array,

    pageType: PropTypes.string,

    fetchFilters: PropTypes.func,
    fetchListWithFilters: PropTypes.func,
  }

  static defaultProps = {
    containerStatusList: [],
    vendorList: [],
  }

  state = {
    filters: {
      [FILTERS.CONTAINER_STATUS_FILTER]:
        parseInt(
          query.parse(window.location.search)[FILTERS.CONTAINER_STATUS_FILTER],
        ) || ITEMS_LIST_DEFAULT_ITEM_ID,
      [FILTERS.VENDOR_FILTER]:
        parseInt(query.parse(window.location.search)[FILTERS.VENDOR_FILTER]) ||
        ITEMS_LIST_DEFAULT_ITEM_ID,
    },
    queryString: query.stringify(query.parse(window.location.search), true),
  }

  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const { filterList, fetchFilters } = this.props

    fetchFilters(filterList)
  }

  onChange = (name, value) => {
    const { filters } = this.state
    const { pageType, fetchListWithFilters } = this.props

    const params = query.parse(window.location.search)

    filters[name] = value

    const queryParams = cleanFilters({ ...params, ...filters })
    const queryString = query.stringify(queryParams, true)

    fetchListWithFilters(
      query.stringify(
        {
          ...queryParams,
          ...{ [FILTERS.CONTAINER_LIST_TYPE_FILTER]: pageType },
        },
        true,
      ),
    )

    this.setState({
      filters: filters,
      queryString: queryString,
    })

    redirect(`${window.location.pathname}${queryString}`)
  }

  render() {
    let { containerStatusList, vendorList } = this.props
    const { filters } = this.state
    if (this.props.pageType === CONTAINER_LIST_PAGE_TYPE_RECEIVING) {
      containerStatusList = [
        { value: FILTER_PARAMETER.IN_TRANSIT, label: 'In Transit' },
        ...containerStatusList,
      ]
    }
    if (this.props.pageType === CONTAINER_LIST_PAGE_TYPE_UNLOADING) {
      containerStatusList = [
        {
          value: FILTER_PARAMETER.NOT_COMPLETED,
          label: 'Not Completed',
        },
        ...containerStatusList,
      ]
    }

    return (
      <>
        <div className="filter">
          <p className="text">Status:</p>
          <Switch
            className="switch--multiple switch--secondary"
            name={FILTERS.CONTAINER_STATUS_FILTER}
            items={containerStatusList}
            defaultValue="All"
            selected={filters[FILTERS.CONTAINER_STATUS_FILTER]}
            scalable
            onChange={this.onChange}
          />
        </div>
        <div className="filter">
          <p className="text">Vendor:</p>
          <Switch
            className="switch--multiple switch--secondary"
            name={FILTERS.VENDOR_FILTER}
            items={vendorList}
            defaultValue="All"
            selected={filters[FILTERS.VENDOR_FILTER]}
            scalable
            onChange={this.onChange}
          />
        </div>
      </>
    )
  }
}
