import { types } from './actions'

const INIT = {
  list: [],
}

const qaHoldReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_QA_HOLD_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_QA_HOLD_LIST_FAIL:
      return INIT
    case types.FETCH_QA_HOLD_LIST_START:
      return { ...state }
    default:
      return state
  }
}

export default qaHoldReducer
