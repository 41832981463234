import * as PropTypes from 'prop-types'
import React from 'react'

const SubmitControl = ({ text, disabled, onClick, className }) => (
  <div
    onClick={() => (disabled ? false : onClick())}
    className={`${className}${disabled ? '--disabled' : ''}`}
  >
    {text}
  </div>
)

SubmitControl.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default SubmitControl
