import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const ContentHeader = ({ title, description, children }) => (
  <div className="content-header">
    <div className="content-header__section">
      <div className="content-header__title">{title}</div>
      <div className="content-header__subtitle">{description}</div>
    </div>
    {children}
  </div>
)

ContentHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default ContentHeader
