import React from 'react'
import * as PropTypes from 'prop-types'
import Avatar from 'components/features/StaffCard/Avatar'

const AssignToItem = ({ item, onRemove }) => {
  const onClick = () => onRemove(item)

  return (
    <div className="list__item" key={item.id}>
      <Avatar link={item.photo} />
      <span className="list__item--close" onClick={onClick} />
    </div>
  )
}

AssignToItem.propTypes = {
  item: PropTypes.object,

  onRemove: PropTypes.func,
}

export default AssignToItem
