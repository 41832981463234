export const types = {
  FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_START:
    'FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_START',
  FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS:
    'FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS',
  FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS_APPEND:
    'FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS_APPEND',
  FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_FAIL:
    'FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_FAIL',
  WAREHOUSE_CASE_AND_PALLET_INVENTORY_ADJUSTMENT:
    'WAREHOUSE_CASE_AND_PALLET_INVENTORY_ADJUSTMENT',
}

export const fetchWarehouseCaseAndPalletListStart = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_START,
  payload,
})
export const fetchWarehouseCaseAndPalletListSuccess = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS,
  payload,
})
export const fetchWarehouseCaseAndPalletListSuccessAppend = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS_APPEND,
  payload,
})
export const fetchWarehouseCaseAndPalletListFail = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_FAIL,
  payload,
})
export const warehouseCaseAndPalletInventoryAdjustment = (payload) => ({
  type: types.WAREHOUSE_CASE_AND_PALLET_INVENTORY_ADJUSTMENT,
  payload,
})
