import React, { useState, useEffect, useMemo } from 'react'
import ContentSection from 'components/common/PageContent/ContentSection'
import Button from 'components/common/Button'
import TextArea from 'components/common/TextArea'
import moment from 'moment'
import NumericInput from 'components/common/NumericInput'
import TextInput from 'components/common/TextInput'
import { CARRIERS } from 'constants/order'
import { connect } from 'react-redux'
import { resetModals, showModal } from 'store/modal/actions'
import { createShipmentStart } from 'store/shipment/actions'
import SelectInput from 'components/common/SelectInput'
import { redirect } from 'helpers/index'
import { DEFAULT_VALUES, FILTERS } from 'constants/filters'
import { fetchFiltersStart } from 'store/filter/actions'
import { ROUTES } from 'constants/routes'
import {
  DELIVERY_COMPANY_FIELD,
  DELIVERY_NAME_FIELD,
  DELIVERY_STREET_ADDRESS_FIELD,
  DELIVERY_SUBURB_FIELD,
  DELIVERY_CITY_FIELD,
  DELIVERY_STATE_FIELD,
  DELIVERY_ZIP_FIELD,
  CUSTOMERS_PHONE_FIELD,
  PICKUP_DATE_FIELD,
  PICKUP_TIME_START_FIELD,
  PICKUP_TIME_STOP_FIELD,
  WEIGHT_FIELD,
  PALLETS_FIELD,
  CASES_FIELD,
  LENGTH_FIELD,
  WIDTH_FIELD,
  HEIGHT_FIELD,
  DELIVERY_NOTES_FIELD,
  ORDERS_ID_FIELD,
  SEAL_NUMBER_FIELD,
  DOCK_CLOSE_TIME_FIELD,
  USER_FIELD,
} from 'constants/formFields'
import { MODAL_PENDING } from 'components/common/Modal'
import Joi from '@hapi/joi'
import { dateToFormat } from 'helpers'
import { clearShipmentOrders } from 'store/orderProcess/actions'

const ShipmentDetailsForm = (props) => {
  const {
    orders,
    user,
    isShipmentCreated,
    stateList,

    showModal,
    resetModals,
    submitForm,
    fetchFilters,
    fetchShipments,
    clearShipmentOrders,
  } = props

  const isInvalidField = (field) => {
    if (form.error.fields.indexOf(field) > -1) {
      return true // If the field is in the error fields, it's invalid
    }
    // Check if the field is required and if no value is selected
    if (
      form.rules[field] &&
      form.rules[field]._flags.presence === 'required' &&
      !form.fields[field]
    ) {
      return true // If required but no value selected, it's invalid
    }
    return false // Otherwise, it's valid
  }

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/[()-]/g, '')
      if (phoneNumber.length === 10) {
        phoneNumber = phoneNumber.replace(
          /^(\d{3})(\d{3})(\d{4})$/,
          '($1) $2-$3',
        )
      } else if (phoneNumber.length === 11) {
        phoneNumber = phoneNumber.replace(
          /^(\d{3})[ ](\d{3})(\d{4})$/,
          '($1) $2-$3',
        )
      } else {
        if (/^(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
          phoneNumber = phoneNumber.substr(0, 11)
        } else if (/^(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
          phoneNumber = phoneNumber.substr(0, 10)
        }
      }
    }

    return phoneNumber
  }

  const dockCloseTimesList = [
    { label: '8:00 AM', value: '0800' },
    { label: '8:30 AM', value: '0830' },
    { label: '9:00 AM', value: '0900' },
    { label: '9:30 AM', value: '0930' },
    { label: '10:00 AM', value: '1000' },
    { label: '10:30 AM', value: '1030' },
    { label: '11:00 AM', value: '1100' },
    { label: '11:30 AM', value: '1130' },
    { label: '12:00 PM', value: '1200' },
    { label: '12:30 PM', value: '1230' },
    { label: '1:00 PM', value: '1300' },
    { label: '1:30 PM', value: '1330' },
    { label: '2:00 PM', value: '1400' },
    { label: '2:30 PM', value: '1430' },
    { label: '3:00 PM', value: '1500' },
    { label: '3:30 PM', value: '1530' },
    { label: '4:00 PM', value: '1600' },
    { label: '4:30 PM', value: '1630' },
    { label: '5:00 PM', value: '1700' },
    { label: '5:30 PM', value: '1730' },
    { label: '6:00 PM', value: '1800' },
    { label: '6:30 PM', value: '1830' },
    { label: '7:00 PM', value: '1900' },
    { label: '7:30 PM', value: '1930' },
    { label: '8:00 PM', value: '2000' },
    { label: '8:30 PM', value: '2030' },
    { label: '9:00 PM', value: '2100' },
    { label: '9:30 PM', value: '2130' },
    { label: '10:00 PM', value: '2200' },
  ]

  const carriersHideCasesAndPallets = [
    CARRIERS.CARRIER_UPS_G,
    CARRIERS.CARRIER_UPS_THREE_DAY,
    CARRIERS.CARRIER_UPS_SECOND_DAY_AIR,
    CARRIERS.CARRIER_UPS_NEXT_DAY_AIR,
    CARRIERS.CARRIER_USPS_P,
    CARRIERS.CARRIER_USPS_P_EX,
    CARRIERS.CARRIER_USPS_PARCEL,
    CARRIERS.CARRIER_FEDEX_G,
  ]

  const carriersShowBoxDimensions = [
    CARRIERS.CARRIER_UPS_G,
    CARRIERS.CARRIER_UPS_THREE_DAY,
    CARRIERS.CARRIER_UPS_SECOND_DAY_AIR,
    CARRIERS.CARRIER_UPS_NEXT_DAY_AIR,
  ]

  const carriersShowSealNumber = [
    CARRIERS.CARRIER_FTL_OTHER,
    CARRIERS.CARRIER_FTL_LANDSTAR,
    CARRIERS.CARRIER_FTL_CFI,
  ]

  const getPickupStopTime = () => {
    let pickup_date_time_string =
      orders[0].entity.order.pickup_date +
      ' ' +
      orders[0].entity.order.pickup_time
    return moment(pickup_date_time_string).add(30, 'minutes').format('h:mm A')
  }

  const getPickupStartTime = () => {
    let pickup_date_time_string =
      orders[0].entity.order.pickup_date +
      ' ' +
      orders[0].entity.order.pickup_time
    return moment(pickup_date_time_string).format('h:mm A')
  }

  const getOrderWeight = (order) => {
    return order.entity.order.order_pallets
      .map((orderPallet) => orderPallet.pallet_weight)
      .reduce((acc, weight) => acc + weight, 0)
  }

  const getOrdersWeight = () => {
    const weights = orders.map((order) => getOrderWeight(order))
    return weights.reduce((acc, weight) => acc + weight, 0)
  }

  const getPallets = () => {
    const palletNums = orders.map(
      (order) => order.entity.order.order_pallets.length,
    )
    return palletNums.reduce((acc, palletsNum) => acc + palletsNum, 0)
  }

  const getCases = () => {
    const casesNums = orders.map(
      (order) => order.entity.order.total_products_quantity,
    )
    return casesNums.reduce((acc, casesNum) => acc + casesNum, 0)
  }

  const getDeliveryNotes = () => {
    const deliveryNotes = orders.map(
      (order) => order.entity.order.shipping_notes,
    )
    return deliveryNotes.length ? deliveryNotes.join(' ') : ''
  }

  const isFieldsFilled = () => {
    return !!form.fields[WEIGHT_FIELD] && !!form.fields[CASES_FIELD]
  }

  const getOrderIds = () => {
    const orderIds = orders.map((order) => order.entity.order.orders_id)
    return orderIds.join('_')
  }

  const DEFAULT_FORM_FIELDS = {
    [DELIVERY_COMPANY_FIELD]: orders[0].entity.order.delivery_company
      ? orders[0].entity.order.delivery_company
      : orders[0].entity.order.delivery_name,
    [DELIVERY_NAME_FIELD]: orders[0].entity.order.delivery_name,
    [DELIVERY_STREET_ADDRESS_FIELD]: orders[0].entity.order
      .delivery_street_address
      ? orders[0].entity.order.delivery_street_address
      : undefined,
    [DELIVERY_SUBURB_FIELD]: orders[0].entity.order.delivery_suburb
      ? orders[0].entity.order.delivery_suburb
      : undefined,
    [DELIVERY_CITY_FIELD]: orders[0].entity.order.delivery_city
      ? orders[0].entity.order.delivery_city
      : undefined,
    [DELIVERY_STATE_FIELD]: orders[0].entity.order.delivery_state
      ? orders[0].entity.order.delivery_state
      : undefined,
    [DELIVERY_ZIP_FIELD]: orders[0].entity.order.delivery_postcode
      ? orders[0].entity.order.delivery_postcode
      : undefined,
    [CUSTOMERS_PHONE_FIELD]: formatPhoneNumber(
      orders[0].entity.order.customers_telephone,
    ),
    [PICKUP_DATE_FIELD]: orders[0].entity.order.pickup_date,
    [PICKUP_TIME_START_FIELD]:
      orders[0].entity.carrier.id === CARRIERS.CARRIER_PICKUP
        ? getPickupStartTime()
        : undefined,
    [PICKUP_TIME_STOP_FIELD]:
      orders[0].entity.carrier.id === CARRIERS.CARRIER_PICKUP
        ? getPickupStopTime()
        : undefined,
    [WEIGHT_FIELD]: getOrdersWeight(),
    [PALLETS_FIELD]: getPallets(),
    [CASES_FIELD]: getCases(),
    [DELIVERY_NOTES_FIELD]: getDeliveryNotes(),
    [LENGTH_FIELD]: undefined,
    [WIDTH_FIELD]: undefined,
    [HEIGHT_FIELD]: undefined,
    [ORDERS_ID_FIELD]: getOrderIds(),
    [SEAL_NUMBER_FIELD]: undefined,
    [DOCK_CLOSE_TIME_FIELD]: '1630',
    [USER_FIELD]: user.user.staffLogin.username,
  }

  const FORM_RULES_PICKUP = {
    [DELIVERY_COMPANY_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_NAME_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_STREET_ADDRESS_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_SUBURB_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_CITY_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_STATE_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_ZIP_FIELD]: Joi.string().allow('').optional(),
    [CUSTOMERS_PHONE_FIELD]: Joi.string().required(),
    [PICKUP_DATE_FIELD]: Joi.string().allow(null).required(),
    [PICKUP_TIME_START_FIELD]: Joi.string().required(),
    [PICKUP_TIME_STOP_FIELD]: Joi.string().required(),
    [WEIGHT_FIELD]: Joi.number().integer().required(),
    [WIDTH_FIELD]: Joi.number().integer().optional(),
    [LENGTH_FIELD]: Joi.number().integer().optional(),
    [HEIGHT_FIELD]: Joi.number().integer().optional(),
    [PALLETS_FIELD]: Joi.number().integer().required(),
    [CASES_FIELD]: Joi.number().integer().required(),
    [DELIVERY_NOTES_FIELD]: Joi.string().allow('').optional(),
    [ORDERS_ID_FIELD]: Joi.string().required(),
    [SEAL_NUMBER_FIELD]: Joi.number().integer().optional(),
    [DOCK_CLOSE_TIME_FIELD]: Joi.number().integer().optional(),
    [USER_FIELD]: Joi.string().required(),
  }

  const FORM_RULES_NON_PICKUP = {
    [DELIVERY_COMPANY_FIELD]: Joi.string().required(),
    [DELIVERY_NAME_FIELD]: Joi.string().required(),
    [DELIVERY_STREET_ADDRESS_FIELD]: Joi.string().required(),
    [DELIVERY_SUBURB_FIELD]: Joi.string().allow('').optional(),
    [DELIVERY_CITY_FIELD]: Joi.string().required(),
    [DELIVERY_STATE_FIELD]: Joi.string().required(),
    [DELIVERY_ZIP_FIELD]: Joi.string().required(),
    [CUSTOMERS_PHONE_FIELD]: Joi.string().required(),
    [PICKUP_DATE_FIELD]: Joi.string().allow(null).optional(),
    [PICKUP_TIME_START_FIELD]: Joi.string().allow('').optional(),
    [PICKUP_TIME_STOP_FIELD]: Joi.string().allow('').optional(),
    [WEIGHT_FIELD]: Joi.number().integer().required(),
    [WIDTH_FIELD]: Joi.number().integer().optional(),
    [LENGTH_FIELD]: Joi.number().integer().optional(),
    [HEIGHT_FIELD]: Joi.number().integer().optional(),
    [PALLETS_FIELD]: Joi.number().integer().required(),
    [CASES_FIELD]: Joi.number().integer().required(),
    [DELIVERY_NOTES_FIELD]: Joi.string().allow('').optional(),
    [ORDERS_ID_FIELD]: Joi.string().required(),
    [SEAL_NUMBER_FIELD]: Joi.number().integer().optional(),
    [DOCK_CLOSE_TIME_FIELD]: Joi.number().integer().optional(),
    [USER_FIELD]: Joi.string().required(),
  }

  const defaultFormRules = (orders) => {
    return orders[0].entity.carrier.id === CARRIERS.CARRIER_PICKUP
      ? FORM_RULES_PICKUP
      : FORM_RULES_NON_PICKUP
  }

  const onChange = (name, value) => {
    const changedForm = { ...form }
    changedForm.fields[name] = value === null ? '' : value
    changedForm.error.fields = changedForm.error.fields.filter(
      (value) => value !== name,
    )
    Joi.validate(
      { [name]: value },
      { [name]: changedForm.rules[name] },
      (error) => {
        if (error !== null) {
          changedForm.error.fields = [...changedForm.error.fields, name]
        }
      },
    )
    setForm(changedForm)
  }

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      Joi.validate(form.fields, form.rules, (error) => {
        if (error === null) {
          resolve()
        } else {
          reject(error)
        }
      })
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      await validateForm()
      submitForm({ data: form.fields })
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Creating...</div>,
      })
    } catch (error) {
      // Handle validation errors
      console.error('Form validation error:', error)
    }
  }

  const [form, setForm] = useState({
    fields: { ...DEFAULT_FORM_FIELDS },
    rules: { ...defaultFormRules(orders) },
    error: { fields: [] },
  })

  const onCreateShipmentSuccess = () => {
    if (isShipmentCreated) {
      setTimeout(() => {
        resetModals()
        fetchShipments()
      }, 6000)
    }
  }

  useEffect(() => {
    fetchFilters([FILTERS.STATE_FILTER]) // technically only need to call this on shipping orders...

    return () => {
      clearShipmentOrders()
    }
  }, [])

  useEffect(onCreateShipmentSuccess, [isShipmentCreated])

  const onClose = () => {
    clearShipmentOrders()
    redirect(ROUTES.ORDERS_SHIPPING_PAGE)
  }

  const isSubmitButtonDisabled = useMemo(() => {
    // Check if any fields have errors
    const hasErrors = form.error.fields.length > 0
    // Check if any required fields are empty
    const hasEmptyRequiredFields = Object.keys(form.rules).some((field) => {
      return (
        form.rules[field]._flags.presence === 'required' && !form.fields[field]
      )
    })

    return hasErrors || hasEmptyRequiredFields
  }, [form.error.fields, form.fields, form.rules])

  return (
    <ContentSection>
      <div className="header">
        <div className="header__title">Shipment Details</div>
      </div>
      <hr />
      <form>
        <div className="form">
          {orders[0].entity.carrier.id !== CARRIERS.CARRIER_PICKUP && (
            <>
              <div className="form__main-column">
                <div className="info-section">
                  <div className="column-title">Shipping Address</div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_COMPANY_FIELD}
                      value={form.fields[DELIVERY_COMPANY_FIELD]}
                      placeholder="Ship to Company"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_COMPANY_FIELD)}
                      isWide
                    />
                  </div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_NAME_FIELD}
                      value={form.fields[DELIVERY_NAME_FIELD]}
                      placeholder="Ship to Name"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_NAME_FIELD)}
                      isWide
                    />
                  </div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_STREET_ADDRESS_FIELD}
                      value={form.fields[DELIVERY_STREET_ADDRESS_FIELD]}
                      placeholder="Address"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_STREET_ADDRESS_FIELD)}
                      isWide
                    />
                  </div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_SUBURB_FIELD}
                      value={form.fields[DELIVERY_SUBURB_FIELD]}
                      placeholder="Apt"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_SUBURB_FIELD)}
                    />
                  </div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_CITY_FIELD}
                      value={form.fields[DELIVERY_CITY_FIELD]}
                      placeholder="City"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_CITY_FIELD)}
                    />
                  </div>
                  <div className="form__row" style={{ marginBottom: '20px' }}>
                    <SelectInput
                      key={DELIVERY_STATE_FIELD}
                      name={DELIVERY_STATE_FIELD}
                      value={form.fields[DELIVERY_STATE_FIELD]}
                      defaultValue={DEFAULT_VALUES[FILTERS.STATE_FILTER]}
                      placeholder={DEFAULT_VALUES[FILTERS.STATE_FILTER]}
                      items={stateList}
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_STATE_FIELD)}
                    />
                  </div>
                  <div className="form__row">
                    <TextInput
                      tabIndex="0"
                      name={DELIVERY_ZIP_FIELD}
                      value={form.fields[DELIVERY_ZIP_FIELD]}
                      placeholder="Zipcode"
                      onChange={onChange}
                      isInvalid={isInvalidField(DELIVERY_ZIP_FIELD)}
                    />
                  </div>
                </div>
                <div className="column-title">Shipping Phone</div>
                <div className="form__row">
                  <TextInput
                    tabIndex="0"
                    name={CUSTOMERS_PHONE_FIELD}
                    value={form.fields[CUSTOMERS_PHONE_FIELD]}
                    placeholder="Shipping Phone"
                    onChange={onChange}
                    isInvalid={isInvalidField(CUSTOMERS_PHONE_FIELD)}
                  />
                </div>
                {!carriersHideCasesAndPallets.includes(
                  orders[0].entity.carrier.id,
                ) && (
                  <>
                    <div className="column-title">Pallets</div>

                    <div className="form__row">
                      <NumericInput
                        tabIndex="0"
                        name={PALLETS_FIELD}
                        value={form.fields[PALLETS_FIELD]}
                        placeholder="Pallets"
                        onChange={onChange}
                        isInvalid={isInvalidField(PALLETS_FIELD)}
                      />
                    </div>
                  </>
                )}
              </div>
              {orders[0].entity.order.shipping_options_line && (
                <div className="form__main-column">
                  <div className="info-section">
                    <div className="column-title">Shipping Options</div>
                    <div className="form__row">
                      {orders[0].entity.order.shipping_options_line}
                    </div>
                  </div>
                </div>
              )}
              <div className="form__main-column">
                <div className="info-section">
                  <div className="column-title">Pallet Locations</div>
                  {orders[0].entity.order.order_pallets &&
                    orders[0].entity.order.order_pallets.map(
                      (pallet, index) => (
                        <div key={index} className="p-2">
                          {pallet.pallet_location_shipping ||
                            pallet.pallet_location_pickup ||
                            'Unknown Location'}
                        </div>
                      ),
                    )}
                </div>
              </div>
            </>
          )}
          {orders[0].entity.carrier.id === CARRIERS.CARRIER_PICKUP && (
            <>
              <div className="form__main-column">
                <div className="info-section">
                  <div className="column-title">Pickup</div>
                  <div>
                    {dateToFormat(
                      form.fields[PICKUP_DATE_FIELD],
                      'ddd MM/DD/YYYY',
                    )}
                  </div>
                  <span>{`${form.fields[PICKUP_TIME_START_FIELD]}-${form.fields[PICKUP_TIME_STOP_FIELD]}`}</span>
                </div>
                <div className="column-title">Shipping Phone</div>
                <div className="form__row">
                  <TextInput
                    tabIndex="0"
                    name={CUSTOMERS_PHONE_FIELD}
                    value={form.fields[CUSTOMERS_PHONE_FIELD]}
                    placeholder="Shipping Phone"
                    onChange={onChange}
                    isInvalid={isInvalidField(CUSTOMERS_PHONE_FIELD)}
                  />
                </div>
                {!carriersHideCasesAndPallets.includes(
                  orders[0].entity.carrier.id,
                ) && (
                  <>
                    <div className="column-title">Pallets</div>

                    <div className="form__row">
                      <NumericInput
                        tabIndex="0"
                        name={PALLETS_FIELD}
                        value={form.fields[PALLETS_FIELD]}
                        placeholder="Pallets"
                        onChange={onChange}
                        isInvalid={isInvalidField(PALLETS_FIELD)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="form__main-column">
                <div className="info-section">
                  <div className="column-title">Pallet Locations</div>
                  {orders[0].entity.order.order_pallets &&
                    orders[0].entity.order.order_pallets.map(
                      (pallet, index) => (
                        <div key={index} className="p-2">
                          {pallet.pallet_location_pickup ||
                            pallet.pallet_location_shipping ||
                            'Unknown Location'}
                        </div>
                      ),
                    )}
                </div>
              </div>
            </>
          )}
          <div className="form__main-column">
            <div className="form__row form__row--weight">
              <div className="title">Weight</div>
              <NumericInput
                tabIndex="0"
                name={WEIGHT_FIELD}
                value={form.fields[WEIGHT_FIELD]}
                placeholder="Weight"
                onChange={onChange}
                isInvalid={isInvalidField(WEIGHT_FIELD)}
              />
            </div>
            {!carriersHideCasesAndPallets.includes(
              orders[0].entity.carrier.id,
            ) && (
              <div className="form__row">
                <div className="title">Cases</div>
                <NumericInput
                  tabIndex="0"
                  name={CASES_FIELD}
                  value={form.fields[CASES_FIELD]}
                  placeholder="Cases"
                  onChange={onChange}
                  isInvalid={isInvalidField(CASES_FIELD)}
                />
              </div>
            )}
            {carriersShowBoxDimensions.includes(
              orders[0].entity.carrier.id,
            ) && (
              <>
                <div className="form__row">
                  <div className="title">Length</div>
                  <div className="form__row--measure">
                    <NumericInput
                      tabIndex="0"
                      name={LENGTH_FIELD}
                      value={form.fields[LENGTH_FIELD]}
                      placeholder="Enter Length"
                      onChange={onChange}
                      isInvalid={isInvalidField(LENGTH_FIELD)}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="title">Width</div>
                  <div className="form__row--measure">
                    <NumericInput
                      tabIndex="0"
                      name={WIDTH_FIELD}
                      value={form.fields[WIDTH_FIELD]}
                      placeholder="Enter Width"
                      onChange={onChange}
                      isInvalid={isInvalidField(WIDTH_FIELD)}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="title">Height</div>
                  <div className="form__row--measure">
                    <NumericInput
                      tabIndex="0"
                      name={HEIGHT_FIELD}
                      value={form.fields[HEIGHT_FIELD]}
                      placeholder="Enter Height"
                      onChange={onChange}
                      isInvalid={isInvalidField(HEIGHT_FIELD)}
                    />
                  </div>
                </div>
              </>
            )}
            {carriersShowSealNumber.includes(orders[0].entity.carrier.id) && (
              <div className="form__row">
                <div className="title">Seal Number</div>
                <TextInput
                  tabIndex="0"
                  name={SEAL_NUMBER_FIELD}
                  value={form.fields[SEAL_NUMBER_FIELD]}
                  placeholder="Seal Number"
                  onChange={onChange}
                  isInvalid={isInvalidField(SEAL_NUMBER_FIELD)}
                />
              </div>
            )}
            {orders[0].entity.carrier.id === CARRIERS.CARRIER_XPO && (
              <div className="form__row">
                <div className="title">Dock Close Time</div>
                <SelectInput
                  tabIndex="0"
                  name={DOCK_CLOSE_TIME_FIELD}
                  value={form.fields[DOCK_CLOSE_TIME_FIELD]}
                  items={dockCloseTimesList}
                  onChange={onChange}
                />
              </div>
            )}
            <div className="form__row">
              <div className="title">Delivery Notes</div>
              <TextArea
                tabIndex="0"
                name={DELIVERY_NOTES_FIELD}
                value={form.fields[DELIVERY_NOTES_FIELD]}
                placeholder="Delivery Notes"
                onChange={onChange}
                isInvalid={isInvalidField(DELIVERY_NOTES_FIELD)}
                className={'text-area'}
                style={{ marginTop: '50px' }}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="footer">
          <Button
            tabIndex="11"
            disabled={isSubmitButtonDisabled}
            type="submit"
            onClick={onSubmit}
            text="Create Shipment"
          />
          <Button tabIndex="12" type="button" onClick={onClose} text="Close" />
        </div>
      </form>
    </ContentSection>
  )
}

const mapStateToProps = (state) => ({
  isShipmentCreated: state.shipment.isShipmentCreated,
  stateList: state.filter.filterParams.stateList,
  user: state.auth.user,
})

const mapDispatchToProps = {
  resetModals,
  fetchFilters: fetchFiltersStart,
  showModal,
  submitForm: createShipmentStart,
  clearShipmentOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailsForm)
