import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import './style.scss'
import { resetModals, showModal } from 'store/modal/actions'
import {
  createGroupLocationStart,
  resetLocationStatuses,
} from 'store/location/actions'
import { redirect } from 'helpers/index'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import PageContent from 'components/common/PageContent/index'
import Button from 'components/common/Button'
import CommonLocationDetails from 'components/features/Location/GroupLocationAddPage/CommonLocationDetails'
import ParticularLocations from 'components/features/Location/GroupLocationAddPage/ParticularLocations'
import * as query from 'querystringify'
import { ROUTES } from 'constants/routes'
import { FILTERS } from 'constants/filters'
import { MODAL_PENDING, MODAL_WARNING } from 'components/common/Modal'

class GroupLocationAddPage extends Component {
  static propTypes = {
    sendData: PropTypes.func,
    resetModals: PropTypes.func,
    resetLocationStatuses: PropTypes.func,
  }

  state = {
    data: {
      common: null,
      locations: [],
    },
  }

  constructor(props) {
    super(props)

    this.onSubmitCommonLocationDetails = this.onSubmitCommonLocationDetails.bind(
      this,
    )
    this.onSubmitParticularLocation = this.onSubmitParticularLocation.bind(this)
    this.isAllInfoFilled = this.isAllInfoFilled.bind(this)
    this.clearLocations = this.clearLocations.bind(this)
  }

  componentDidMount() {
    const { resetModals, resetLocationStatuses } = this.props

    resetModals()
    resetLocationStatuses()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isGroupLocationCreated,
      resetModals,
      resetLocationStatuses,
      isGroupLocationExists,
      showModal,
    } = this.props
    const { data } = prevState

    if (isGroupLocationCreated) {
      setTimeout(() => {
        resetModals()
      }, 3000)
    }

    if (isGroupLocationExists && data.locations.length > 0) {
      data.locations = data.locations.slice(0, -1)

      resetLocationStatuses()
      this.setState({ data })

      showModal({
        name: MODAL_WARNING,
        content: (
          <div className="modal--info__text">Location(s) Already Exists</div>
        ),
        events: {
          onClose: resetModals,
        },
      })
    }
  }

  returnToListPage = () => {
    const warehouseId = query.parse(window.location.search)[
      FILTERS.WAREHOUSE_FILTER
    ]

    redirect(
      `${ROUTES.LOCATION_LIST_PAGE}${query.stringify(
        { [FILTERS.WAREHOUSE_FILTER]: warehouseId },
        true,
      )}`,
    )
  }

  onSubmitCommonLocationDetails = (common) =>
    this.setState({ data: { ...this.state.data, common } })
  clearLocations = () =>
    this.setState({ data: { ...this.state.data, locations: [] } })

  onSubmitParticularLocation = (item) => {
    const { data } = this.state
    const { resetLocationStatuses, sendData, showModal } = this.props

    resetLocationStatuses()
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Adding...</div>,
    })
    sendData({
      data: {
        common: data.common,
        locationGroup: item,
      },
    })

    data.locations = [...data.locations, ...[item]]

    this.setState({ data })
  }

  isAllInfoFilled = () => {
    const { data } = this.state

    return !!(data.common && data.locations.length)
  }

  render() {
    const { data } = this.state

    return (
      <PageContent className="group-add-page">
        <ContentHeader
          title="Add group locations"
          description="Add Locations With Same Details"
        >
          <div className="content-header__menu">
            <Button
              disabled={!this.isAllInfoFilled()}
              type="submit"
              onClick={this.returnToListPage}
              text="Done"
            />
            <Button
              type="button"
              onClick={this.returnToListPage}
              text="Cancel"
            />
          </div>
        </ContentHeader>

        <CommonLocationDetails
          clearLocations={() => this.clearLocations()}
          onSubmit={this.onSubmitCommonLocationDetails}
        />

        <ParticularLocations
          isOpen={data.common}
          locations={data.locations}
          onSubmit={this.onSubmitParticularLocation}
        />
      </PageContent>
    )
  }
}

const mapStateToProps = (state) => ({
  isGroupLocationCreated: state.location.isGroupLocationCreated,
  isGroupLocationExists: state.location.isGroupLocationExists,
})

const mapDispatchToProps = {
  sendData: createGroupLocationStart,
  resetModals: resetModals,
  showModal: showModal,
  resetLocationStatuses: resetLocationStatuses,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupLocationAddPage)
