import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { ROUTES } from 'constants/routes'

const NotFoundPage = () => (
  <div className="page--error-wrapper">
    <div className="page--error">
      <h3 className="title">Page not found</h3>
      <Link
        style={{ textDecoration: 'none' }}
        to={ROUTES.HOME_PAGE}
        className="btn"
      >
        Back to Home
      </Link>
    </div>
  </div>
)

export default NotFoundPage
