import React from 'react'
import * as PropTypes from 'prop-types'
import { redirect } from 'helpers/index'

const Link = (props) => {
  const { to, ariaLabel, className, children } = props

  const defaultOnClick = (e) => {
    e.preventDefault()
    return redirect(to)
  }

  return (
    <a onClick={props.onClick ? props.onClick : defaultOnClick } href={to} aria-label={ariaLabel} className={className}>
      {children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,

  children: PropTypes.any,
}

export default Link
