import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import {
  ON_NAV_ITEM_CLICK,
  TOGGLE_SIDEBAR_ITEMS,
  TOGGLE_SIDEBAR_SUB_ITEMS,
} from 'constants/events'
import { redirect } from 'helpers'
import { getUrlStringWithMandatoryParams } from 'helpers/index'

const SideBarSubItem = ({ item }) => {
  const [isSelected, setIsSelected] = useState(false)

  const onReset = () => setIsSelected(false)

  const onMount = () => {
    window.addEventListener(TOGGLE_SIDEBAR_SUB_ITEMS, onReset)

    setIsSelected(item.link && window.location.href.indexOf(item.link) > -1)

    return () => {
      window.removeEventListener(TOGGLE_SIDEBAR_ITEMS, onReset)
    }
  }

  useEffect(onMount)

  const onToggle = () => {
    window.dispatchEvent(new Event(TOGGLE_SIDEBAR_ITEMS))
    window.dispatchEvent(new Event(TOGGLE_SIDEBAR_SUB_ITEMS))
    window.dispatchEvent(new Event(ON_NAV_ITEM_CLICK))

    setIsSelected(
      window.location.href.indexOf(item.link) > -1 ? true : !isSelected,
    )
  }

  const onSelect = () => {
    if (!item.link) {
      return
    }

    onToggle()

    if (!window.location.href.indexOf(item.link) > -1) {
      redirect(`${item.link}?${getUrlStringWithMandatoryParams()}`)
    }
  }

  const sidebarSubItemClassName = `list__item ${
    isSelected ? 'list__item--selected' : ''
  } ${item.isActive === false ? 'blurred' : ''}`

  return (
    <li className={sidebarSubItemClassName} onClick={onSelect}>
      <span className="list__text--short">{item.shortContent}</span>
      <span className="list__text">{item.content}</span>
      {item.badge ? <span className="badge--text">{item.badge}</span> : ''}
      <div className="label">{item.label}</div>
    </li>
  )
}

SideBarSubItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    badge: PropTypes.number,

    content: PropTypes.string,
    shortContent: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string,
  }),
}

export default SideBarSubItem
