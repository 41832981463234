import React from 'react'
import * as PropTypes from 'prop-types'
import StaffEngagementBlock from './StaffEngagementBlock'

const StaffEngagementTab = ({
  item,
  onAssign,
  onShowDetailsPopup,
  assignToList,
}) => {
  if (item.body.data.type === null) {
    return item.body.data.staff.map((block) =>
      block.body.data.staff.length > 0 ? (
        <StaffEngagementBlock
          assignToList={assignToList}
          onShowDetailsPopup={onShowDetailsPopup}
          key={block.body.data.type}
          item={{
            staff: block.body.data.staff,
            label: block.header.label,
          }}
          onAssign={onAssign}
        />
      ) : (
        ''
      ),
    )
  } else {
    return (
      <StaffEngagementBlock
        assignToList={assignToList}
        onShowDetailsPopup={onShowDetailsPopup}
        key={item.id}
        item={{
          ...item,
          staff: item.body.data.staff,
          label: item.header.label,
        }}
        onAssign={onAssign}
      />
    )
  }
}

StaffEngagementTab.propTypes = {
  item: PropTypes.object,

  onAssign: PropTypes.func,
}

export default StaffEngagementTab
