import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalPendingNew from 'components/common/Modal/ModalPendingNew'
import ModalConfirmDefaultNew from 'components/common/Modal/ModalConfirmDefaultNew'
import EnvironmentBanner from '../EnvironmentBanner'

const PageWrapper = ({ children }) => (
  <div className="page-wrapper">
    <EnvironmentBanner />
    <ModalPendingNew />
    <ModalConfirmDefaultNew />
    {children}
  </div>
)

PageWrapper.propTypes = {
  children: PropTypes.any,
}

export default PageWrapper
