import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'
import { FILTERS } from 'constants/filters'

export const sortObjectsList = (list, key, direction = DIRECTION_ASC) => {
  const properties = key.split('.')

  return list.sort((a, b) => {
    let valueA = a
    let valueB = b

    for (let prop of properties) {
      if (valueA[prop]) {
        valueA = valueA[prop]
      }

      if (valueB[prop]) {
        valueB = valueB[prop]
      }
    }

    const result = valueA >= valueB ? 1 : -1

    return direction === DIRECTION_DESC ? result * -1 : result
  })
}

function dateToNum(dateAsString) {
  const replaceNaNValue = 0
  const dateAsNumber = new Date(dateAsString).valueOf()
  return isNaN(dateAsNumber) ? replaceNaNValue : dateAsNumber
}

export const sortArrWithObjects = (
  list,
  mapper = [],
  key,
  direction = DIRECTION_ASC,
) =>
  list.sort((a, b) => {
    const prop = mapper[key]
    const valueA = a[prop]
    const valueB = b[prop]
    const result = valueA >= valueB ? 1 : -1

    if (
      key === FILTERS.CONTAINER_ARRIVE_DATE_FILTER ||
      key === FILTERS.CONTAINER_EXPECTED_ARRIVE_DATE_FILTER
      // must apply additional filters if other date-type columns are implemented
    ) {
      const resultForDate = dateToNum(valueA) >= dateToNum(valueB) ? 1 : -1
      return direction === DIRECTION_DESC ? resultForDate * -1 : resultForDate
    }

    return direction === DIRECTION_DESC ? result * -1 : result
  })
