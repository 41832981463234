export const types = {
  FETCH_FILTERS_START: 'FETCH_FILTERS_START',
  FETCH_FILTERS_SUCCESS: 'FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAIL: 'FETCH_FILTERS_FAIL',
  CLEAR_FILTER: 'CLEAR_FILTER',
}

export const fetchFiltersStart = (payload) => ({
  type: types.FETCH_FILTERS_START,
  payload,
})

export const fetchFiltersSuccess = (payload) => ({
  type: types.FETCH_FILTERS_SUCCESS,
  payload,
})

export const fetchFiltersFail = (payload) => ({
  type: types.FETCH_FILTERS_FAIL,
  payload,
})

export const clearFilter = (payload) => ({
  type: types.CLEAR_FILTER,
  payload,
})
