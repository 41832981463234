import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import './style.scss'
import { MODAL_INFO } from 'components/common/Modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '260px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '550px',
  },
}

const ModalInfo = ({ modal, isOpen }) =>
  isOpen ? (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal modal--info">
        <span
          className="modal--info__close-icon"
          onClick={modal.events.onClose}
        />
        {modal.content}
      </div>
    </Modal>
  ) : (
    ''
  )

ModalInfo.propTypes = {
  isOpen: PropTypes.bool,
  modal: PropTypes.shape({
    name: PropTypes.string,

    content: PropTypes.any,

    events: PropTypes.object,
  }),
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find((item) => item.name === MODAL_INFO),
  modal: state.modal.openModalsInfo.find((item) => item.name === MODAL_INFO),
})

export default connect(mapStateToProps, null)(ModalInfo)
