import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import ArrowDown from 'assets/svg/arrow-down-fill.svg'
import ArrowLeft from 'assets/svg/arrow-left-fill.svg'

class DragDropTableRow extends Component {
  constructor(props) {
    super(props)

    this.toggleExpandRow = this.toggleExpandRow.bind(this)
  }

  state = {
    isExpanded: false,
  }

  toggleExpandRow() {
    if (!this.props.row.options.expandable) {
      return
    }

    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

  render() {
    const { columns, row, index } = this.props
    const { isExpanded } = this.state

    return (
      <Draggable draggableId={row.id.toString()} index={index}>
        {(provided) => (
          <div
            className="drag-drop__row"
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            onClick={this.toggleExpandRow}
          >
            <div className="drag-drop__row-header">
              {columns.map((item) => (
                <div className="table-cell" key={item.id}>
                  {row[item.key]}
                </div>
              ))}
              {row.notes ? (
                <div className="table-cell">
                  {isExpanded ? <ArrowDown /> : <ArrowLeft />}
                </div>
              ) : (
                <div className="table-cell" />
              )}
            </div>
            {row.options.expandable && isExpanded ? (
              <div className="list">{row.expandedContent}</div>
            ) : null}
          </div>
        )}
      </Draggable>
    )
  }
}

export default DragDropTableRow
