import React from 'react'
import * as PropTypes from 'prop-types'
import TableListCell from 'components/common/TableList/TableListRow/TableListCell'

const TableListRow = ({ columns, row }) => {
  const isVoid = row.isVoid; 

  return (
    <div className={`list__row ${isVoid ? 'voided-row' : ''}`}>
      {columns.map((column) => (
        <TableListCell key={column.value}>{row[column.value]}</TableListCell>
      ))}
    </div>
  );
};

TableListRow.propTypes = {
  columns: PropTypes.array,

  row: PropTypes.object,
}

export default TableListRow
