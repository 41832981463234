import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../App'
import React, { useContext } from 'react'
import { ROUTES } from 'constants/routes'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isAuthenticated } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...{ ...props, user }} />
        ) : (
          <Redirect to={ROUTES.LOGIN_PAGE} />
        )
      }
    />
  )
}
