import React from 'react'
import * as PropTypes from 'prop-types'
import Card from '../Card'
import Icon from 'components/common/Icon'
import EditIconRounded from 'assets/svg/edit-rounded.svg'

const CardList = (props) => {
  const {
    items,
    className,
    options = {},
    events = {},
    assignToList = [],
  } = props

  return (
    <div className={`card-list ${className ? className : ''}`}>
      <div className="card-list__items">
        {items.map((item, index) => (
          <Card
            options={options}
            events={events}
            key={`${item.id}${index}`}
            item={item}
            isChecked={
              !!assignToList.find((assignToItem) => assignToItem.id === item.id)
            }
          />
        ))}
      </div>
      {options.editable && items.length > 0 && (
        <Icon onClick={events.onEdit}>
          <EditIconRounded />
        </Icon>
      )}
    </div>
  )
}

CardList.propTypes = {
  items: PropTypes.array.isRequired,

  className: PropTypes.string,

  onEdit: PropTypes.func,
}

export default CardList
