import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchOne = (payload) =>
  request({
    url: API_ROUTE.RESTOCK_PROCESS.FETCH_ONE(payload.id),
    method: 'GET',
  })

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.RESTOCK_PROCESS.FETCH_MANY,
    method: 'GET',
    params: payload,
  })

export const create = (payload) =>
  request({
    url: API_ROUTE.RESTOCK_PROCESS.CREATE,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.RESTOCK_PROCESS.UPDATE(payload.id),
    method: 'PUT',
    data: payload,
  })

export const createLocationRestock = (payload) =>
  request({
    url: API_ROUTE.RESTOCK_PROCESS.CREATE_LOCATION_RESTOCK,
    method: 'POST',
    data: payload,
  })
