import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import ContentSection from 'components/common/PageContent/ContentSection'
import LocationGroupForm from 'components/features/Location/GroupLocationAddPage/ParticularLocations/LocationGroupAddLocationForm'
import LocationGroupAddLocationItem from 'components/features/Location/GroupLocationAddPage/ParticularLocations/LocationGroupAddLocationItem'
import { AISLE_FIELD, LEVEL_FIELD, WAREHOUSE_FIELD } from 'constants/formFields'
import * as query from 'querystringify'
import { FILTERS } from 'constants/filters'
import { HORIZONTAL, LEVEL_1, VERTICAL } from "../../../../../constants/location";

export default class ParticularLocations extends Component {
  constructor(props) {
    super(props)

    this.addNewLocationGroup = this.addNewLocationGroup.bind(this)
    this.onSubmitHandle = this.onSubmitHandle.bind(this)
  }

  static propTypes = {
    locations: PropTypes.array,

    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    locations: [],
  }

  state = {
    locationsAmount: 0,
    copiedLocationGroup: null,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { locationsAmount } = prevState
    const { locations } = this.props

    if (!locations.length) {
      locationsAmount = 0
    }

    return {
      ...prevState,
      locationsAmount,
    }
  }

  addNewLocationGroup = () => {
    let { locationsAmount } = this.state

    locationsAmount++

    this.setState({ locationsAmount })
  }

  copyGroupAbove = () => {
    const copiedLocationGroup = Object.assign(
      {},
      this.props.locations[this.state.locationsAmount],
    )

    const warehouseId = parseInt(
      query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER],
    )
    const levelObject = copiedLocationGroup[LEVEL_FIELD]

    this.props.fetchAvailableBayList({
      ...{
        [WAREHOUSE_FIELD]: warehouseId,
        [AISLE_FIELD]: copiedLocationGroup[AISLE_FIELD],
      },
      ...levelObject,
    })

    this.setState({
      ...this.state,
      locationsAmount: this.state.locationsAmount + 1,
      copiedLocationGroup,
    })
  }

  onSubmitHandle = (data) => {
    const { onSubmit } = this.props
    if (data.level === LEVEL_1) {
      data.label_orientation = VERTICAL
    } else {
      data.label_orientation = HORIZONTAL
    }
    onSubmit(data)

    this.setState({
      ...this.state,
      copiedLocationGroup: null,
    })
  }

  render() {
    const { locationsAmount, copiedLocationGroup } = this.state
    const { isOpen, locations } = this.props
    if (!isOpen) return null

    return (
      <ContentSection className="content-section--location-group">
        <div className="header">
          <div className="header__title">
            <span className="header__title--bold">Step 2</span>&nbsp;
            <span>Location Group</span>
          </div>
        </div>
        <hr />
        <div className="form-list">
          {locations.map((item) => (
            <LocationGroupAddLocationItem
              key={`${item[AISLE_FIELD]}${item.pallets_capacity}`}
              item={item}
              className={locations.length === 1 && 'form--dashed'}
            />
          ))}

          {locations.length > locationsAmount ? (
            <div className="form-list__footer">
              <div className="form-list__footer__control">
                <div
                  className="form-list__footer__control__item"
                  onClick={() => this.addNewLocationGroup()}
                >
                  <i className="icon icon-add" />
                  Create New Group
                </div>
                <div
                  className="form-list__footer__control__item"
                  onClick={() => this.copyGroupAbove()}
                >
                  <i className="icon icon-copy" />
                  Copy Group Above
                </div>
              </div>
            </div>
          ) : (
            <LocationGroupForm
              data={copiedLocationGroup}
              onSubmit={(data) => this.onSubmitHandle(data)}
            />
          )}
        </div>
      </ContentSection>
    )
  }
}
