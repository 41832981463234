import React from 'react'
import * as PropTypes from 'prop-types'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'

const TableListCell = ({ children, onClick, sortOrder }) => {
  const sortedArrow = !(sortOrder === undefined) ? !sortOrder ? (
    <BsArrowDown className="ms-1" />
    ) : (
    <BsArrowUp className="ms-1" />
  ) : null

  return (
    <div className="cell" onClick={onClick}>
      <span className="default-cursor">
        {children}
      </span>
      {sortedArrow}
    </div>
  )
}

TableListCell.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  sortOrder: PropTypes.bool,
}

export default TableListCell
