import React from 'react'
import * as PropTypes from 'prop-types'
import TableRow from './TableRow'
import TableHeader from './TableHeader/index'
import './style.scss'

const Table = (props) => {
  const {
    rows = [],
    columns = [],
    selectedItems = [],

    className = '',

    links,
    events,
    sortOptions = {
      sortByKey: '',
      sortDirection: '',
    },
    options = {
      selectable: false,
      viewable: false,
      editable: false,
      deletable: false,
    },
  } = props

  return (
    <table className={`table ${className ? className : ''}`.trim()}>
      <TableHeader
        className="table__header"
        columns={columns}
        checked={rows.length === selectedItems.length}
        sortOptions={sortOptions}
        options={options}
        events={events}
      />
      <tbody>
        {rows.map((item) => (
          <TableRow
            key={item.id}
            item={item}
            columns={columns}
            options={options}
            events={events}
            links={links}
            checked={
              !!selectedItems.find(
                (selectedItem) => item.id === selectedItem.id,
              )
            }
          />
        ))}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array,
  selectedItems: PropTypes.array,

  className: PropTypes.string,

  links: PropTypes.object,
  events: PropTypes.object,
  options: PropTypes.object,
  sortOptions: PropTypes.object,
}

export default Table
