export const types = {
  CREATE_QA_HOLD: 'CREATE_QA_HOLD',
  UPDATE_MANY_QA_HOLD: 'UPDATE_MANY_QA_HOLD',
  FETCH_QA_HOLD_LIST_START: 'FETCH_QA_HOLD_LIST_START',
  FETCH_QA_HOLD_LIST_SUCCESS: 'FETCH_QA_HOLD_LIST_SUCCESS',
  FETCH_QA_HOLD_LIST_FAIL: 'FETCH_QA_HOLD_LIST_FAIL',
}

export const createQaHold = (payload) => ({
  type: types.CREATE_QA_HOLD,
  payload,
})

export const updateManyQaHold = (payload) => ({
  type: types.UPDATE_MANY_QA_HOLD,
  payload,
})

export const fetchQaHoldListSuccess = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_SUCCESS,
  payload,
})

export const fetchQaHoldListFail = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_FAIL,
  payload,
})

export const fetchQaHoldListStart = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_START,
  payload,
})
