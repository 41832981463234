export const ITEMS_LIST_DEFAULT_ITEM_ID = 0

export const DIRECTION_ASC = 'asc'
export const DIRECTION_DESC = 'desc'

export const DEFAULT_WAREHOUSE_ID = 3

export const CONTAINER_LIST_PAGE_TYPE_RECEIVING = 'receiving'
export const CONTAINER_LIST_PAGE_TYPE_UNLOADING = 'unloading'

export const PROJECT = {
  project_id: 4,
}

export const ENVIRONMENT_PROD = 'prod'
