import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Joi from '@hapi/joi'
import './style.scss'
import { fetchFiltersStart } from 'store/filter/actions'
import { resetModals, showModal } from 'store/modal/actions'
import { getUrlParams, redirect } from 'helpers/index'
import {
  createLocationStart,
  fetchAvailableBaysStart,
  resetLocationStatuses,
} from 'store/location/actions'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import PageContent from 'components/common/PageContent/index'
import TextInput from 'components/common/TextInput'
import SelectInput from 'components/common/SelectInput'
import Button from 'components/common/Button'
import Switch from 'components/common/Switch'
import * as query from 'querystringify'
import { REGEX } from 'constants/regex'
import { ROUTES } from 'constants/routes'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { YES_NO_SWITCH_ITEMS } from 'constants/switchItems'
import { DEFAULT_WAREHOUSE_ID } from 'constants/common'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'
import {
  AISLE_FIELD,
  BAY_FIELD,
  DOCK_FIELD,
  HEIGHT_FIELD,
  IS_PERMANENT_FIELD,
  LENGTH_FIELD,
  LEVEL_FIELD,
  LOCATION_TYPE_FIELD,
  STORAGE_TYPE_FIELD,
  WAREHOUSE_FIELD,
  WIDTH_FIELD,
  WRAP_REQUIRED_FIELD,
} from 'constants/formFields'
import { LEVEL_1, HORIZONTAL, VERTICAL } from 'constants/location'
import { FILTERS } from 'constants/filters'
import {
  MODAL_INFO,
  MODAL_PENDING,
  MODAL_WARNING,
} from 'components/common/Modal'

let label_orientation
const DEFAULT_FORM_FIELDS = {
  [WAREHOUSE_FIELD]: undefined,
  pallets_capacity: 1,
  [LENGTH_FIELD]: undefined,
  [LEVEL_FIELD]: undefined,
  [WIDTH_FIELD]: undefined,
  [HEIGHT_FIELD]: undefined,
  [AISLE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [LOCATION_TYPE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [BAY_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [STORAGE_TYPE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [IS_PERMANENT_FIELD]: 'Yes',
  [WRAP_REQUIRED_FIELD]: 'Yes',
  label_orientation: HORIZONTAL,
}

const FORM_RULES = {
  [WAREHOUSE_FIELD]: Joi.number().integer(),
  [AISLE_FIELD]: Joi.number().integer().optional(),
  pallets_capacity: Joi.number().integer(),
  [LEVEL_FIELD]: Joi.number().integer().optional(),
  [BAY_FIELD]: Joi.number().integer().optional(),
  [LOCATION_TYPE_FIELD]: Joi.number().integer().required(),
  [DOCK_FIELD]: Joi.number().optional(),
  [LENGTH_FIELD]: Joi.number().optional(),
  [WIDTH_FIELD]: Joi.number().optional(),
  [HEIGHT_FIELD]: Joi.number().optional(),
  label_orientation: Joi.string().valid(HORIZONTAL, VERTICAL).required(),
  [STORAGE_TYPE_FIELD]: Joi.number().required(),
  [IS_PERMANENT_FIELD]: Joi.string().valid('Yes', 'No').required(),
  [WRAP_REQUIRED_FIELD]: Joi.string().valid('Yes', 'No').required(),
}

const SingleLocationAddPage = (props) => {
  const {
    warehouseList = [],
    locationTypeList = [],
    aisleList = [],
    bayList = [],
    dockList = [],
    storageTypeList = [],
    availableBayList = [],

    isLocationCreated,
    isLocationCreationFailed,

    submitForm,
    showModal,
    fetchFilters,
    resetModals,
    resetLocationStatuses,
    fetchAvailableBayList,
  } = props

  const [form, setForm] = useState({
    fields: { ...DEFAULT_FORM_FIELDS },
    rules: { ...FORM_RULES },
    error: {
      fields: [],
    },
  })

  const onMount = () => {
    resetModals()
    resetLocationStatuses()
    fetchFilters([
      FILTERS.WAREHOUSE_FILTER,
      FILTERS.DOCK_FILTER,
      FILTERS.STORAGE_TYPE_FILTER,
      FILTERS.LOCATION_TYPE_FILTER,
      FILTERS.AISLE_FILTER,
      FILTERS.BAY_FILTER,
    ])
  }

  const onCreateLocationSuccess = () => {
    if (isLocationCreated) {
      setTimeout(() => {
        resetModals()
        resetLocationStatuses()
        returnToListPage()
      }, 3000)
    }
  }

  const onCreateLocationFail = () => {
    if (isLocationCreationFailed) {
      showModal({
        name: MODAL_WARNING,
        content: (
          <div className="modal--info__text">Location Already Exists</div>
        ),
        events: {
          onClose: onModalWarningClose,
        },
      })
    }
  }

  const onFetchWarehouseFilters = () => {
    if (warehouseList.length) {
      const warehouse = warehouseList.find(
        (item) =>
          item.value === parseInt(getUrlParams()[FILTERS.WAREHOUSE_FILTER]) ||
          DEFAULT_WAREHOUSE_ID,
      )

      if (warehouse) {
        const changedFields = { ...form.fields }

        changedFields[WAREHOUSE_FIELD] = warehouse.value

        setForm({ ...form, fields: changedFields })
      }
    }
  }

  useEffect(onMount, [])
  useEffect(onCreateLocationSuccess, [isLocationCreated])
  useEffect(onCreateLocationFail, [isLocationCreationFailed])
  useEffect(onFetchWarehouseFilters, [warehouseList])

  const returnToListPage = () =>
    redirect(
      `${ROUTES.LOCATION_LIST_PAGE}${query.stringify(
        {
          [FILTERS.WAREHOUSE_FILTER]: form.fields[WAREHOUSE_FIELD],
        },
        true,
      )}`,
    )

  const isCommonFieldsFilled = () =>
    !!form.fields[LOCATION_TYPE_FIELD] &&
    !!form.fields[STORAGE_TYPE_FIELD] &&
    !!form.fields.pallets_capacity &&
    !!form.fields[IS_PERMANENT_FIELD] &&
    !!form.fields[WRAP_REQUIRED_FIELD]

  const isGeneralFieldsFilled = () =>
    !!form.fields[DOCK_FIELD] ||
    (!!form.fields[AISLE_FIELD] &&
      !!form.fields[BAY_FIELD] &&
      !!form.fields[LEVEL_FIELD] &&
      !!form.fields[BAY_FIELD])

  const onSubmit = (e) => {
    e.preventDefault()

    if (
      isCommonFieldsFilled() &&
      isGeneralFieldsFilled() &&
      form.error.fields.length === 0
    ) {
      Joi.validate(form.fields, form.rules, (error) => {
        if (error === null) {
          if (form.fields.level === LEVEL_1) {
            form.fields.label_orientation = VERTICAL
          }

          submitForm({ data: form.fields })
          showModal({
            name: MODAL_PENDING,
            content: <div className="text">Adding...</div>,
          })
        }
      })
    }
  }

  const onChange = (name, value) => {
    const changedForm = { ...form }

    changedForm.fields[name] = value === null ? '' : value
    changedForm.error.fields = changedForm.error.fields.filter(
      (value) => value !== name,
    )

    Joi.validate(
      { [name]: value },
      { [name]: changedForm.rules[name] },
      (error) => {
        if (error !== null) {
          changedForm.error.fields = [...changedForm.error.fields, name]
        }
      },
    )

    // Addition func's when field is changed
    switch (name) {
      case LOCATION_TYPE_FIELD:
        resetFormFieldsExceptOf([LOCATION_TYPE_FIELD])
        break
      case AISLE_FIELD:
        if (changedForm.fields[LEVEL_FIELD]) {
          fetchAvailableBayList({
            [WAREHOUSE_FIELD]: changedForm.fields[WAREHOUSE_FIELD],
            [AISLE_FIELD]: changedForm.fields[AISLE_FIELD],
            [LEVEL_FIELD]: changedForm.fields[LEVEL_FIELD],
          })
        }
        break
      default:
        break
    }

    setForm(changedForm)
  }

  const onModalWarningClose = () => {
    resetModals()
    resetLocationStatuses()
    resetFormFieldsExceptOf([])
  }

  const onCheckAvailableBays = () =>
    showModal({
      name: MODAL_INFO,
      content: (
        <>
          <div className="modal--info__title">Available Bay Option(s)</div>
          <div className="modal--info__text">{availableBayList.join(', ')}</div>
        </>
      ),
      events: {
        onClose: resetModals,
      },
    })

  const resetFormFieldsExceptOf = (fields) => {
    const changedForm = { ...form }
    changedForm.error.fields = []

    Object.keys(DEFAULT_FORM_FIELDS).forEach((field) => {
      if (fields.indexOf(field) === -1) {
        changedForm.fields = {
          ...changedForm.fields,
          [field]: DEFAULT_FORM_FIELDS[field],
        }
      }
    })

    setForm(changedForm)
  }

  const isInvalidField = (field) => form.error.fields.indexOf(field) > -1

  const isDefault =
    form.fields[LOCATION_TYPE_FIELD] === ITEMS_LIST_DEFAULT_ITEM_ID
  const isDock = form.fields[LOCATION_TYPE_FIELD] === LOCATION_TYPE.DOCK_TYPE_ID
  const isDockOrDefault = isDock || isDefault
  const isNotDockOrDefault = !isDock || isDefault
  const selectedWarehouse = warehouseList.find(
    (item) => item.value === form.fields[WAREHOUSE_FIELD],
  )

  return (
    <PageContent className="single-add-page">
      <ContentHeader
        title="Add a location"
        description="Add Information For A New Location"
      />
      <ContentSection>
        <div className="header">
          <div className="header__title">Location Details</div>
        </div>
        <hr />
        <form>
          <div className="form">
            <div className="form__main-column">
              <div className="form__row">
                <div className="title">Warehouse</div>
                <div>{selectedWarehouse ? selectedWarehouse.label : ''}</div>
              </div>
              <div className="form__row">
                <div className="title title--required">Aisle</div>
                <div>
                  <SelectInput
                    tabIndex="3"
                    disabled={isDockOrDefault}
                    name={AISLE_FIELD}
                    value={form.fields[AISLE_FIELD]}
                    defaultValue="- Select -"
                    items={aisleList}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="title title--required">Level</div>
                <TextInput
                  tabIndex="5"
                  disabled={isDockOrDefault}
                  name={LEVEL_FIELD}
                  value={form.fields[LEVEL_FIELD]}
                  placeholder="Enter Level"
                  onChange={onChange}
                  isInvalid={isInvalidField(LEVEL_FIELD)}
                  pattern={REGEX.IS_LEVEL_FIELD}
                />
              </div>
              <div className="form__row">
                <div className="title title--required">Dock</div>
                <div>
                  <SelectInput
                    tabIndex="7"
                    disabled={isNotDockOrDefault}
                    name={DOCK_FIELD}
                    value={form.fields[DOCK_FIELD]}
                    defaultValue="- Select -"
                    items={dockList}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="title">Length</div>
                <div className="form__row--measure">
                  <TextInput
                    tabIndex="8"
                    name={LENGTH_FIELD}
                    value={form.fields[LENGTH_FIELD]}
                    placeholder="Enter Length"
                    onChange={onChange}
                    isInvalid={isInvalidField(LENGTH_FIELD)}
                    pattern={REGEX.IS_DIGIT}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="title title--required">Wrapped</div>
                <Switch
                  tabIndex="7"
                  name={WRAP_REQUIRED_FIELD}
                  items={YES_NO_SWITCH_ITEMS}
                  selected={form.fields[WRAP_REQUIRED_FIELD]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="form__main-column">
              <div className="form__row">
                <div className="title title--required">Location Type</div>
                <div>
                  <SelectInput
                    tabIndex="1"
                    name={LOCATION_TYPE_FIELD}
                    value={form.fields[LOCATION_TYPE_FIELD]}
                    defaultValue="- Select -"
                    items={locationTypeList}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form__row">
                <div className="title">Pallet Capacity</div>
                <TextInput
                  tabIndex="4"
                  name="pallets_capacity"
                  value={form.fields.pallets_capacity}
                  placeholder="Enter Capacity"
                  onChange={onChange}
                  isInvalid={isInvalidField('pallets_capacity')}
                  pattern={REGEX.IS_PALLETS_CAPACITY_FIELD}
                />
              </div>
              <div className="form__row form__row--auto-margin">
                <div className="title title--required">Bay</div>
                <div>
                  <SelectInput
                    tabIndex="6"
                    disabled={isDockOrDefault}
                    name={BAY_FIELD}
                    value={form.fields[BAY_FIELD]}
                    defaultValue="- Select -"
                    items={bayList}
                    onChange={onChange}
                  />
                  {availableBayList.length ? (
                    <span
                      className="modal__trigger"
                      onClick={onCheckAvailableBays}
                    >
                      Select Available Bay (s)
                    </span>
                  ) : (
                    <span className="modal__trigger">No available Bay(s)</span>
                  )}
                </div>
              </div>
              <div className="form__row form__row--auto-margin">
                <div className="title">Width</div>
                <div className="form__row--measure">
                  <TextInput
                    tabIndex="9"
                    name={WIDTH_FIELD}
                    value={form.fields[WIDTH_FIELD]}
                    placeholder="Enter Width"
                    onChange={onChange}
                    isInvalid={isInvalidField(WIDTH_FIELD)}
                    pattern={REGEX.IS_DIGIT}
                  />
                </div>
              </div>
            </div>

            <div className="form__main-column">
              <div className="form__row form__row--auto-margin">
                <div className="title title--required">Storage Type</div>
                <SelectInput
                  tabIndex="2"
                  name={STORAGE_TYPE_FIELD}
                  value={form.fields[STORAGE_TYPE_FIELD]}
                  defaultValue="- Select -"
                  items={storageTypeList}
                  onChange={onChange}
                />
              </div>
              <div className="form__row form__row--measure">
                <div className="title">Height</div>
                <TextInput
                  tabIndex="10"
                  name={HEIGHT_FIELD}
                  value={form.fields[HEIGHT_FIELD]}
                  placeholder="Enter Height"
                  onChange={onChange}
                  isInvalid={isInvalidField(HEIGHT_FIELD)}
                  pattern={REGEX.IS_DIGIT}
                />
              </div>
              <div className="form__row"></div>
            </div>
          </div>
          <hr />
          <div className="footer">
            <Button
              tabIndex="11"
              disabled={!isCommonFieldsFilled() || !isGeneralFieldsFilled()}
              type="submit"
              onClick={onSubmit}
              text="Done"
            />
            <Button
              tabIndex="12"
              type="button"
              onClick={returnToListPage}
              text="Cancel"
            />
          </div>
        </form>
      </ContentSection>
    </PageContent>
  )
}

SingleLocationAddPage.propTypes = {
  warehouseList: PropTypes.array,
  locationTypeList: PropTypes.array,
  aisleList: PropTypes.array,
  bayList: PropTypes.array,
  dockList: PropTypes.array,
  storageTypeList: PropTypes.array,
  availableBayList: PropTypes.array,

  isLocationCreationFailed: PropTypes.bool,

  submitForm: PropTypes.func,
  showModal: PropTypes.func,
  fetchFilters: PropTypes.func,
  resetModals: PropTypes.func,
  resetLocationStatuses: PropTypes.func,
  fetchAvailableBayList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  warehouseList: state.filter.filterParams.warehouseList,
  locationTypeList: state.filter.filterParams.locationTypeList,
  aisleList: state.filter.filterParams.aisleList,
  bayList: state.filter.filterParams.bayList,
  dockList: state.filter.filterParams.dockList,
  storageTypeList: state.filter.filterParams.storageTypeList,

  availableBayList: state.location.availableBays,
  isLocationCreated: state.location.isLocationCreated,
  isLocationCreationFailed: state.location.isLocationCreationFailed,
})

const mapDispatchToProps = {
  submitForm: createLocationStart,
  fetchFilters: fetchFiltersStart,
  resetModals: resetModals,
  showModal: showModal,
  resetLocationStatuses: resetLocationStatuses,
  fetchAvailableBayList: fetchAvailableBaysStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SingleLocationAddPage)
