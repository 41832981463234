import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import './style.scss'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import { MODAL_CHOOSE_PRINTER } from 'components/common/Modal'
import Button from '../../Button'

const ModalChoosePrinter = ({ modal, isOpen }) => {
  if (!isOpen) {
    return <></>
  }

  const onPrinterSelect = (printer_id, printer_location_name) => {
    modal.onPrinterSelect({
      ...modal.printData,
      printer_id,
      printer_location_name,
    })
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--printers">
        <div className="title">Choose Printer</div>
        {modal.printers.map((printer) => {
          const onSelect = printer.is_online
            ? () => {
                onPrinterSelect(printer.id, printer.printer_location_name)
              }
            : null
          const buttonClass = printer.is_online ? 'online' : 'offline'

          return (
            <Card
              className={`modal--printers__card ${buttonClass}`}
              onClick={onSelect}
            >
              <CardActionArea>
                <CardContent className="modal--printers__card-content">
                  {printer.printer_location_name}
                  {printer.is_online ? '' : '  <OFFLINE>'}
                </CardContent>
              </CardActionArea>
            </Card>
          )
        })}
        <div className="btn-menu">
          <Button onClick={modal.events.onDeny} text="Cancel" />
        </div>
      </div>
    </ModalDefault>
  )
}

ModalChoosePrinter.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CHOOSE_PRINTER,
  ),
  modal: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CHOOSE_PRINTER,
  ),
})

export default connect(mapStateToProps)(ModalChoosePrinter)
