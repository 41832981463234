import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import { connect } from 'react-redux'
import { fetchOrderProcessListStart } from 'store/orderProcess/actions'
import { ALL_ORDERS_STATUSES } from 'constants/order'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import PickOrderSection from 'components/features/Order/Shipping/OrderShippingPage/PickOrderSection'
import { selectPickOrderItems } from 'store/orderProcess/selectors'
import { showModal } from 'store/modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'

const OrderShippingPage = (props) => {
  const {
    pickOrderItems,

    showModal,
    fetchOrderProcessListStart,
  } = props

  const onMount = () => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Fetching shipping orders...</div>,
    })
    fetchOrderProcessListStart({
      params: {
        'filters[order_statuses]': ALL_ORDERS_STATUSES,
      },
    })
  }

  useEffect(onMount, [])

  return (
    <PageContent className="inventory-page orders-shipping-page">
      <ContentHeader
        title="Shipping"
        description="Create Shipments for Picked Orders."
      />
      <PickOrderSection items={pickOrderItems} />
    </PageContent>
  )
}

OrderShippingPage.propTypes = {
  pickOrderItems: PropTypes.array,

  fetchOrderProcessListStart: PropTypes.func,
}

const mapStateToProps = (state) => ({
  pickOrderItems: selectPickOrderItems(state),
})

const mapDispatchToProps = {
  fetchOrderProcessListStart,
  showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderShippingPage)
