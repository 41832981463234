import { types } from './actions'

const INIT = {
  list: [],
}

const reasonReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_REASON_LIST_START:
      return {
        ...state,
      }
    case types.FETCH_REASON_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_REASON_LIST_FAIL:
      return INIT
    default:
      return state
  }
}

export default reasonReducer
