import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

import Modal from 'react-modal'
import Button from '../../Button'
import { connect } from 'react-redux'
import { GrNotes } from 'react-icons/gr'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '650px',
  },
}

const ModalOrderNotes = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div
        className={`modal modal--order-notes ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div>
          <h1 className="title">Order Notes</h1>
          <GrNotes className="notes-icon" />
          {modalInfo.order.shipping_notes && (
            <>
              <h2 className="text--note-type">Shipping Notes</h2>
              <p className="text">{modalInfo.order.shipping_notes}</p>
            </>
          )}
          {modalInfo.order.warehouse_notes && (
            <>
              <h2 className="text--note-type">Warehouse Notes</h2>
              <p className="text">{modalInfo.order.warehouse_notes}</p>
            </>
          )}
        </div>
        <Button text="OK" onClick={modalInfo.onClose} />
      </div>
    </Modal>
  )
}

ModalOrderNotes.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalOrderNotes',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalOrderNotes',
  ),
})

export default connect(mapStateToProps)(ModalOrderNotes)
