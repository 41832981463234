import React from 'react'
import * as PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import DragDropTableHeader from './DragDropTableHeader'
import DragDropTableRow from './DragDropTableRow'
import './style.scss'

const DragDropTable = ({ columns, rows, droppableId }) => (
  <div className="drag-drop__table">
    <DragDropTableHeader columns={columns} />
    <Droppable droppableId={droppableId.toString()}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className="drag-drop__wrapper">
            {rows.map((row, i) => (
              <DragDropTableRow
                key={row.id}
                row={row}
                columns={columns}
                index={i}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </div>
)

DragDropTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,

  droppableId: PropTypes.number,
}

export default DragDropTable
