import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Modal from 'react-modal'
import './style.scss'
import Button from '../../Button'
import { MODAL_CONFIRM_DEFAULT } from 'components/common/Modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '650px',
  },
}

const ModalConfirmDefault = ({ isOpen, modal }) =>
  isOpen ? (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className={`modal modal--confirm`}>
        {modal.content}
        <div className="btn-menu">
          <Button onClick={modal.events.onAccept} text="OK" />
          <Button onClick={modal.events.onDeny} text="Cancel" />
        </div>
      </div>
    </Modal>
  ) : (
    ''
  )

ModalConfirmDefault.propTypes = {
  isOpen: PropTypes.bool,

  modal: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CONFIRM_DEFAULT,
  ),
  modal: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CONFIRM_DEFAULT,
  ),
})

export default connect(mapStateToProps, null)(ModalConfirmDefault)
