import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const createShipment = (payload) =>
  request({
    url: API_ROUTE.SHIPMENT.CREATE,
    method: 'POST',
    data: payload,
  })

export const markAsPrinted = (payload) =>
  request({
    url: API_ROUTE.SHIPMENT.MARK_PRINTED,
    method: 'POST',
    data: payload,
  })

export const voidShipment = (payload) =>
  request({
    url: API_ROUTE.SHIPMENT.VOID,
    method: 'POST',
    data: payload,
  })

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.SHIPMENT.FETCH_MANY,
    method: 'GET',
    params: payload,
  })

export const fetchError = (payload) =>
  request({
    url: API_ROUTE.SHIPMENT.FETCH_ERROR,
    method: 'GET',
    params: payload,
  })
