import React from 'react'
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchMany,
  fetchOne,
  fetchOther,
  fetchAvailableLocations,
  update,
} from 'api/warehousePallet'
import {
  fetchAvailableLocationListFail,
  fetchAvailableLocationListSuccess,
  fetchOneWarehousePalletFail,
  fetchOneWarehousePalletSuccess,
  fetchOtherWarehousePalletListFail,
  fetchOtherWarehousePalletListSuccess,
  fetchWarehousePalletListFail,
  fetchWarehousePalletListSuccess,
  fetchWarehousePalletListSuccessAppend,
  types,
} from './actions'
import { resetModals, showModal } from '../modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'

function* fetchWarehousePalletList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Fetching pallets...</div>,
      }),
    )
    yield put(
      response.data.meta.page === '1'
        ? fetchWarehousePalletListSuccess(response.data.data.items)
        : fetchWarehousePalletListSuccessAppend(response.data.data.items),
    )
    yield put(resetModals())
  } catch (error) {
    yield put(fetchWarehousePalletListFail())
  }
}

function* fetchOtherWarehousePalletList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOther, payload)

    yield put(fetchOtherWarehousePalletListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchOtherWarehousePalletListFail())
  }
}

function* fetchAvailableLocationList(action) {
  const { payload } = action

  try {
    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Fetching available locations...</div>,
      }),
    )
    const response = yield call(fetchAvailableLocations, payload)

    yield put(fetchAvailableLocationListSuccess(response.data.data.items))
    yield put(resetModals())
  } catch (error) {
    yield put(fetchAvailableLocationListFail())
  }
}

function* fetchOneWarehousePallet(action) {
  const { payload } = action

  try {
    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Fetching pallet...</div>,
      }),
    )
    const response = yield call(fetchOne, payload)

    yield put(fetchOneWarehousePalletSuccess(response.data.data.item))
    yield put(resetModals())
  } catch (error) {
    yield put(fetchOneWarehousePalletFail())
  }
}

function* updateWarehousePallet(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

export default function* warehousePalletSaga() {
  yield takeEvery(
    types.FETCH_ONE_WAREHOUSE_PALLET_START,
    fetchOneWarehousePallet,
  )
  yield takeEvery(
    types.FETCH_AVAILABLE_LOCATION_LIST_START,
    fetchAvailableLocationList,
  )
  yield takeLatest(
    types.FETCH_WAREHOUSE_PALLET_LIST_START,
    fetchWarehousePalletList,
  )
  yield takeLatest(
    types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_START,
    fetchOtherWarehousePalletList,
  )
  yield takeLatest(types.UPDATE_WAREHOUSE_PALLET, updateWarehousePallet)
}
