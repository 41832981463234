import { takeEvery, call, put } from 'redux-saga/effects'
import { fetchMany, fetchOne, update } from 'api/containers'
import {
  types,
  fetchContainerProcessListFail,
  fetchContainerProcessListSuccess,
  fetchOneContainerProcessFail,
  fetchOneContainerProcessSuccess,
  updateContainerProcessFail,
  updateContainerProcessSuccess,
} from './actions'
import { resetModals, showModal } from 'store/modal/actions'
import { wait } from '@hapi/hoek'
import { MODAL_SUCCESS } from 'components/common/Modal'

function* fetchContainers(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(resetModals())
    yield put(fetchContainerProcessListSuccess(response.data.items))
  } catch (error) {
    yield put(fetchContainerProcessListFail())
  }
}

function* fetchOneContainer(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneContainerProcessSuccess(response.data.data.item))
    yield put(resetModals())
  } catch (error) {
    yield put(fetchOneContainerProcessFail())
  }
}

function* updateContainer(action) {
  const { payload } = action

  try {
    const response = yield call(update, payload)
    yield put(updateContainerProcessSuccess())
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )

    const item = yield call(fetchOne, { id: payload.id })
    yield put(fetchOneContainerProcessSuccess(item.data.data.item))
    yield wait(2000)
    yield put(resetModals())
  } catch (error) {
    yield put(updateContainerProcessFail())
  }
}

export default function* containerProcessSaga() {
  yield takeEvery(types.FETCH_CONTAINER_PROCESS_LIST_START, fetchContainers)
  yield takeEvery(types.FETCH_ONE_CONTAINER_PROCESS_START, fetchOneContainer)
  yield takeEvery(types.UPDATE_CONTAINER_PROCESS_START, updateContainer)
}
