import { connect } from 'react-redux'
import { fetchFiltersStart } from 'store/filter/actions'
import CommonLocationDetails from './component'

const mapStateToProps = (state) => ({
  warehouseList: state.filter.filterParams.warehouseList,
  locationTypeList: state.filter.filterParams.locationTypeList,
  storageTypeList: state.filter.filterParams.storageTypeList,
})

const mapDispatchToProps = {
  fetchLocationFilters: fetchFiltersStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonLocationDetails)
