import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import './style.scss'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import Button from 'components/common/Button'
import GroupedOrderRemovedItems from 'components/features/Order/OrderRemoved/GroupedOrderRemovedList'
import { ORDER_PICK_STATUSES } from 'constants/order'
import {
  fetchOrderProcessListStart,
  updateOrderProcessStart,
} from 'store/orderProcess/actions'
import { resetModals, showModal } from 'store/modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'

const OrderRemovedPage = (props) => {
  const {
    removed,

    fetchOrders,
    updateOrders,
    showModal,
  } = props

  const [resolutions, setResolutions] = useState([])

  const onMount = () => {
    fetchOrders({
      params: {
        'filters[order_statuses]': [
          ORDER_PICK_STATUSES.STATUS_REMOVE,
          ORDER_PICK_STATUSES.STATUS_REMOVE_HOLD,
          ORDER_PICK_STATUSES.STATUS_REMOVE_BACK_TO_INV,
        ],
      },
    })
  }

  useEffect(onMount, [])

  const onSubmit = () => {
    // const isAllOrderPalletsPlacedOnLanes = removed
    //   .filter(removedItem => !!resolutions
    //     .find(resolution => resolution.id === removedItem.id))
    //   .filter(removedItem => removedItem.entity.orderPallets
    //     .find(orderPallet => orderPallet.warehouse_location_id === null))
    //   .length === 0;
    //
    // if (!isAllOrderPalletsPlacedOnLanes) {
    //   showModal({
    //     name: MODAL_WARNING,
    //     content: <div className="modal--info__text">Not all order pallets are placed in locations</div>,
    //     events: {
    //       onClose: resetModals
    //     }
    //   });
    //
    //   return;
    // }

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Updating...</div>,
    })
    updateOrders({
      items: resolutions,
    })
  }

  return (
    <PageContent className="list-page inventory-page orders-removed-page">
      <ContentHeader
        title="ORDERS REMOVED"
        description="View And Manage Removed Orders"
      >
        <Button
          type="button"
          disabled={!resolutions.length}
          onClick={onSubmit}
          text="SAVE"
        />
      </ContentHeader>
      <GroupedOrderRemovedItems
        items={removed}
        resolutions={resolutions}
        setResolutions={setResolutions}
      />
    </PageContent>
  )
}

OrderRemovedPage.propTypes = {
  removed: PropTypes.array,

  fetchOrders: PropTypes.func,
}

const mapStateToProps = (state) => ({
  removed: state.orderProcess.removed,
})

const mapDispatchToProps = {
  fetchOrders: fetchOrderProcessListStart,
  updateOrders: updateOrderProcessStart,
  showModal,
  resetModals,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRemovedPage)
