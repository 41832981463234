import React from 'react'
import Modal from 'react-modal'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../Button'
import ExclamationSignYellow from 'assets/svg/exclamation-sign-yellow.svg'
import { MODAL_WARNING } from 'components/common/Modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '388px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '662px',
  },
}

const ModalWarning = ({ modal, isOpen }) =>
  isOpen ? (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal modal--warning">
        <div className="modal--stale__exclamation">
          <ExclamationSignYellow />
        </div>
        {modal.content}
        <Button text="OK" onClick={modal.events.onClose} />
      </div>
    </Modal>
  ) : (
    ''
  )

ModalWarning.propTypes = {
  isOpen: PropTypes.bool,

  children: PropTypes.any,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_WARNING,
  ),
  modal: state.modal.openModalsInfo.find((item) => item.name === MODAL_WARNING),
})

export default connect(mapStateToProps, null)(ModalWarning)
