import { takeEvery, call, put } from 'redux-saga/effects'
import { types } from './actions'
import { fetchMany } from 'api/filters'
import { fetchFiltersSuccess, fetchFiltersFail } from './actions'

function* fetchFilters(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(fetchFiltersSuccess(response.data.items))
  } catch (error) {
    yield put(fetchFiltersFail())
  }
}

export default function* filtersSaga() {
  yield takeEvery(types.FETCH_FILTERS_START, fetchFilters)
}
