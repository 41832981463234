import { types } from './actions'

const INIT = {
  list: [],
  editedItem: {},
  availableBays: [],
  isLoading: false,
  isLocationCreated: false,
  isLocationCreationFailed: false,
  isLocationUpdated: false,
  isLocationUpdateFailed: false,
  isGroupLocationCreated: false,
  isGroupLocationExists: false,
}

const locationReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_LOCATION_LIST_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload.items,
        metaData: payload.metaData,
      }
    case types.FETCH_LOCATION_LIST_SUCCESS_APPEND:
      return {
        ...state,
        isLoading: false,
        list: [...state.list, ...payload],
      }
    case types.FETCH_ONE_LOCATION_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_ONE_LOCATION_SUCCESS:
      return {
        ...state,
        editedItem: payload,
        isLoading: false,
      }
    case types.FETCH_AVAILABLE_BAYS_SUCCESS:
      return {
        ...state,
        availableBays: payload,
      }
    case types.FETCH_LOCATIONS_FAIL:
      return INIT

    case types.CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationCreated: true,
        isLocationCreationFailed: false,
      }
    case types.CREATE_LOCATION_FAIL:
      return {
        ...state,
        isLocationCreated: false,
        isLocationCreationFailed: true,
      }
    case types.CREATE_GROUP_LOCATION_START:
      return {
        ...state,
        isGroupLocationCreated: false,
        isGroupLocationExists: false,
      }
    case types.CREATE_GROUP_LOCATION_SUCCESS:
      return {
        ...state,
        isGroupLocationCreated: true,
        isLocationCreationFailed: false,
      }
    case types.CREATE_GROUP_LOCATION_FAIL:
      return {
        ...state,
        isGroupLocationCreated: false,
        isGroupLocationExists: true,
        isLocationCreationFailed: true,
      }
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationUpdated: true,
      }
    case types.UPDATE_LOCATION_FAIL:
      return {
        ...state,
        isLocationUpdated: false,
        isLocationUpdateFailed: true,
      }
    case types.RESET_LOCATION_STATUSES:
      return {
        ...state,
        isLocationCreated: false,
        isLocationUpdated: false,
        isLoading: false,
        isGroupLocationCreated: false,
        isGroupLocationExists: false,
        isLocationCreationFailed: false,
        isLocationUpdateFailed: false,
      }
    case types.RESET_CURRENT_LOCATION:
      return {
        ...state,
        editedItem: {},
      }
    default:
      return state
  }
}

export default locationReducer
