import React, { PureComponent, useEffect, useState, useRef } from 'react'
import query from 'querystringify'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'components/common/Link'
import { FILTERS, SORT_FILTERS } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { ROUTES } from 'constants/routes'
import {
  DEFAULT_URL_PARAMS,
  DEFAULT_URL_PARAMS_VALUES,
} from 'constants/urlDefaultParams'
import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'
import { getUrlParams, haveDefaultUrlParams, redirect } from 'helpers'
import {
  deleteLocation,
  fetchLocationListStart,
  createLaneDeleteReason,
} from '../../../../store/location/actions'
import { fetchFiltersStart } from 'store/filter/actions'
import { resetModals, showModal, closeModal } from 'store/modal/actions'
import LocationListGridFilters from 'components/features/Location/LocationListPage/LocationListGridFilters'
import PageContent from 'components/common/PageContent'
import Table from 'components/common/Table'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import PaginationWrapper from 'components/common/PaginationWrapper'
import SelectInput from 'components/common/SelectInput'
import Button from 'components/common/Button'
import RecordCountMessage from 'components/common/RecordCountMessage'
import {
  MODAL_INFO,
  MODAL_PENDING,
  MODAL_LANE_PALLET,
} from 'components/common/Modal'
import PalletSm from '../../../../assets/svg/pallet-sm.svg'
import { deleteShippingLane } from '../../../../store/shippingLane/actions'
import { deletePickupLane } from '../../../../store/pickupLane/actions'
import { updateOrderLoadStatus } from '../../../../store/orderProcess/actions'
import { ORDER_LOAD_STATUSES } from '../../../../constants/order'

const LocationListPage = (props) => {
  let componentRef = useRef()

  const {
    items = [],
    metaData,
    filters,
    user,
    fetchItems,
    fetchFilters,
    deleteItem,
    showModal,
    closeModal,
    resetModals,
    updateOrderLoadStatus,
    deleteShippingLane,
    deletePickupLane,
    createLaneDeleteReason,
  } = props

  if (
    !haveDefaultUrlParams(getUrlParams()) ||
    !getUrlParams()[FILTERS.WAREHOUSE_FILTER]
  ) {
    redirect(
      `${window.location.pathname}?${query.stringify({
        ...getUrlParams(),
        ...{
          ...DEFAULT_URL_PARAMS_VALUES,
          [DEFAULT_URL_PARAMS.PER_PAGE_PARAM]: 50,
        },
        [FILTERS.WAREHOUSE_FILTER]: 3,
      })}`,
    )
  }

  const [sortDirection, setSortDirection] = useState(
    getUrlParams()[DEFAULT_URL_PARAMS.ORDER_BY],
  )
  const [selectedItems, setSelectedItems] = useState([])

  const onMount = () => {
    const params = getUrlParams()

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })
    fetchFilters([
      FILTERS.WAREHOUSE_FILTER,
      FILTERS.LOCATION_TYPE_FILTER,
      FILTERS.AISLE_FILTER,
      FILTERS.BAY_FILTER,
      FILTERS.LEVEL_FILTER,
    ])
    fetchItems(
      `?${query.stringify({
        ...params,
      })}`,
    )
  }

  useEffect(onMount, [])

  const onChangeDirection = () =>
    setSortDirection(
      sortDirection === DIRECTION_ASC ? DIRECTION_DESC : DIRECTION_ASC,
    )
  const onCloseModalToPrint = () => {
    resetModals()
    setSelectedItems([])
  }

  const getButton = () => <Button text="Print" />

  const onClickPrintConfirm = () => {
    console.log('onClickPrintConfirm.  Please code me.')
    alert('Location Labels Under Construction')
  }

  const onClickPrint = () => {
    if (selectedItems.length) {
      showModal({
        name: MODAL_INFO,
        events: {
          onClose: onCloseModalToPrint,
        },
        content: (
          <>
            {`Do you want to print ${selectedItems.length} location(s)?`}
            <Button onClick={onClickPrintConfirm}>Print</Button>
          </>
        ),
      })
    }
  }

  const onChangeFilterOptions = (params) => {
    redirect(`${window.location.pathname}?${query.stringify(params)}`)

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })

    fetchItems(
      `?${query.stringify({
        ...getUrlParams(),
      })}`,
    )
  }

  const hasFilterSelected = () =>
    !!getUrlParams()[FILTERS.LEVEL_FILTER] ||
    !!getUrlParams()[FILTERS.LOCATION_TYPE_FILTER] ||
    !!getUrlParams()[FILTERS.BAY_FILTER] ||
    !!getUrlParams()[FILTERS.AISLE_FILTER]

  const onChangeWarehouse = (name, value) => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })

    redirect(
      `${window.location.pathname}?${query.stringify({
        ...getUrlParams(),
        ...{ [FILTERS.WAREHOUSE_FILTER]: value },
      })}`,
    )

    fetchItems(
      `?${query.stringify({
        ...getUrlParams(),
      })}`,
    )
  }

  const onDelete = (id) =>
    showModal({
      name: 'confirm-default',
      content: <div className="text">Do you want to Delete this Location?</div>,
      events: {
        onDeny: resetModals,
        onAccept: () => {
          deleteItem(id)
          resetModals()
        },
      },
    })

  const onClickSelectAllLocations = () =>
    setSelectedItems(items.length === selectedItems.length ? [] : items)
  const onClickSelectLocation = (id) =>
    setSelectedItems(
      !selectedItems.find((item) => item.id === id)
        ? [...selectedItems, ...[items.find((item) => item.id === id)]]
        : selectedItems.filter((item) => item.id !== id),
    )

  const deleteLaneAfterAction = (orderLoadStatus) => {
    if (orderLoadStatus) {
      // after clearing lane we want to check and see if the order still has pallets in lanes
      // if it doesn't we want to mark its load status as cancelled
      updateOrderLoadStatus({
        data: {
          id: orderLoadStatus.id,
          orders_id: orderLoadStatus.orders_id,
          status_id: ORDER_LOAD_STATUSES.STATUS_CANCEL,
        }
      })
    }

    onMount() 
  }

  const deleteLane = (laneLocation, laneType, deletionNotes) => {
    const orderLoadStatus = laneLocation.order_pallet.order_load_status != null 
    ? laneLocation.order_pallet.order_load_status
    : null;
      
    closeModal(MODAL_LANE_PALLET)
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Deleting...</div>,
    })

    createLaneDeleteReason({
      data: {
        warehouse_location_id: laneLocation.warehouse_location_id,
        order_pallet_id: laneLocation.order_pallet.id,
        reason: deletionNotes,
        deleted_by_staff_login_id: user.user.staffLogin.staff_id,
      },
    })

    if (laneType === 'pickupLane') {
      deletePickupLane({
        request: { id: laneLocation.id },
        afterAction: () => deleteLaneAfterAction(orderLoadStatus),
      })
    } else if (laneType === 'shippingLane') {
      deleteShippingLane({
        request: { id: laneLocation.id },
        afterAction: () => deleteLaneAfterAction(orderLoadStatus),
      })
    }
  }

  const openPalletDialog = (item) => {
    const laneLocation = item.pickupLane || item.shippingLane;
    const laneType = item.pickupLane ? 'pickupLane' : 'shippingLane';
  
    showModal({
      laneLocation: laneLocation,
      name: MODAL_LANE_PALLET,
      events: {
        onCancel: () => closeModal(MODAL_LANE_PALLET),
        onAccept: (deletionNotes) => deleteLane(laneLocation, laneType, deletionNotes), 
      },
    });
  };
  
  const getNormalizedItems = () =>
    items.map((item) => ({
      ...item,
      checked: false,
      location_type: (
        <>
          <span
            className="icon--square"
            style={{ backgroundColor: item.location_type_color }}
          />
          <span>{item.location_type}</span>
        </>
      ),
      order_pallet:
        item.pickupLane || item.shippingLane ? (
          <div onClick={() => handleOpenPalletDialog(item)}>
            <PalletSm className="pallet-icon-svg" />
          </div>
        ) : null,
    }));
  
  const handleOpenPalletDialog = (item) => {
    openPalletDialog(item);
  };
  
  const isDockSelected =
    parseInt(getUrlParams()[FILTERS.LOCATION_TYPE_FILTER]) ===
    LOCATION_TYPE.DOCK_TYPE_ID
  const selectedWarehouseId = parseInt(getUrlParams()[FILTERS.WAREHOUSE_FILTER])

  const linkQueryString = query.stringify({
    [FILTERS.WAREHOUSE_FILTER]: selectedWarehouseId,
  })
  const linkToGroupAddPage = `${ROUTES.LOCATION_GROUP_ADD_PAGE}?${linkQueryString}`
  const linkToSingleAddPage = `${ROUTES.LOCATION_SINGLE_ADD_PAGE}?${linkQueryString}`
  const linkToEditDetailsPage = `${ROUTES.LOCATION_EDIT_DETAILS_PAGE_WITH_ID}?${linkQueryString}`

  const COLUMNS = [
    {
      id: 1,
      label: 'Aisle',
      value: 'aisle',
      name: FILTERS.AISLE_FILTER,
      isSorting: !isDockSelected,
    },
    {
      id: 2,
      label: 'Bay',
      value: 'bay',
      name: FILTERS.BAY_FILTER,
      isSorting: !isDockSelected,
    },
    {
      id: 3,
      label: 'Level',
      value: 'level',
      name: FILTERS.LEVEL_FILTER,
      isSorting: !isDockSelected,
    },
    {
      id: 4,
      label: 'Pallet Capacity',
      value: 'pallets_capacity',
      name: FILTERS.PALLET_CAPACITY_FILTER,
      isSorting: true,
    },
    {
      id: 5,
      label: 'Location Type',
      value: 'location_type',
      name: FILTERS.LOCATION_TYPE_FILTER,
      isSorting: true,
    },
    {
      id: 6,
      label: 'Storage Type',
      value: 'storage_type',
      name: FILTERS.STORAGE_TYPE_FILTER,
      isSorting: true,
    },
    {
      id: 7,
      label: 'Dock',
      value: 'dock_id',
      name: FILTERS.DOCK_FILTER,
      isSorting: true,
    },
    {
      id: 8,
      label: 'Order Pallet',
      value: 'order_pallet',
      name: 'order_pallet',
    },
    {
      id: 9,
      label: '',
      value: '',
      name: '',
      isSorting: false,
    },
  ]

  const paginationWrapperInstance = (
    <PaginationWrapper
      metaData={metaData}
      fetchFunction={fetchItems}
      countPerFetch={parseInt(
        getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM],
      )}
    />
  )

  return (
    <PageContent className="list-page">
      <ContentHeader title="Locations" description="Locations List View">
        <SelectInput
          name={FILTERS.WAREHOUSE_FILTER}
          value={selectedWarehouseId}
          defaultValue="- Select Warehouse -"
          items={filters[FILTERS.WAREHOUSE_FILTER] || []}
          onChange={onChangeWarehouse}
        />
      </ContentHeader>
      {!selectedWarehouseId ? (
        <ContentSection className="content-section--empty">
          <div className="header">
            <div className="header__title">0 Locations</div>
          </div>
        </ContentSection>
      ) : (
        <ContentSection>
          <div className="header">
            <div className="header__title">
              <RecordCountMessage
                metaData={metaData}
                unitsText={` Location${items.length !== 1 ? 's' : ''}`}
              />
            </div>
            <div className="header__menu">
              <Link
                aria-label="Header link to add group location"
                className="header__link"
                to={linkToGroupAddPage}
              >
                Add Group Locations
              </Link>
              <Link
                aria-label="Header link to add single location"
                className="header__link"
                to={linkToSingleAddPage}
              >
                Add Single Location
              </Link>
            </div>
          </div>
          <LocationListGridFilters
            filters={filters}
            onChangeFilters={onChangeFilterOptions}
            hasFilterSelected={hasFilterSelected}
          />
          <div className="icon-wrapper icon-wrapper--print">
            <i className="icon icon-print" />
            <span className="icon__text" onClick={onClickPrint}>
              Print UPC
            </span>
          </div>
          {parseInt(getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM]) > 10
            ? paginationWrapperInstance
            : ''}
          <Table
            rows={getNormalizedItems()}
            columns={COLUMNS}
            selectedItems={selectedItems}
            sortOptions={{
              sortByKey: getUrlParams()[SORT_FILTERS.SORT_BY_KEY],
              sortDirection: sortDirection,
            }}
            events={{
              onSort: onChangeDirection,
              onDelete: onDelete,
              onSelectAll: onClickSelectAllLocations,
              onSelect: onClickSelectLocation,
            }}
            options={{
              selectable: true,
              editable: true,
              deletable: true,
            }}
            links={{
              edit: linkToEditDetailsPage,
            }}
          />
          {paginationWrapperInstance}
        </ContentSection>
      )}
    </PageContent>
  )
}

LocationListPage.propTypes = {
  items: PropTypes.array,
  fetchItems: PropTypes.func,
  fetchFilters: PropTypes.func,
  showModal: PropTypes.func,
  resetModals: PropTypes.func,
}

const mapStateToProps = (state) => ({
  items: state.location.list,
  metaData: state.location.metaData,
  filters: {
    [FILTERS.WAREHOUSE_FILTER]: state.filter.filterParams.warehouseList,
    [FILTERS.LOCATION_TYPE_FILTER]: state.filter.filterParams.locationTypeList,
    [FILTERS.AISLE_FILTER]: state.filter.filterParams.aisleList,
    [FILTERS.BAY_FILTER]: state.filter.filterParams.bayList,
    [FILTERS.LEVEL_FILTER]: state.filter.filterParams.levelList,
  },
  user: state.auth.user,
})

const mapDispatchToProps = {
  fetchItems: fetchLocationListStart,
  fetchFilters: fetchFiltersStart,
  deleteItem: deleteLocation,
  resetModals: resetModals,
  showModal: showModal,
  closeModal: closeModal,
  deleteShippingLane,
  deletePickupLane,
  createLaneDeleteReason,
  updateOrderLoadStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationListPage)
