import { connect } from 'react-redux'
import ContainersSwitchFilters from './component'
import { fetchFiltersStart } from 'store/filter/actions'
import { fetchContainerProcessListStart } from 'store/containerProcess/actions'

const mapStateToProps = (state, ownProps) => ({
  containerStatusList: state.filter.filterParams.containerStatusList,
  vendorList: state.filter.filterParams.vendorList,

  filterList: ownProps.filters,
  pageType: ownProps.pageType,
})

const mapDispatchToProps = {
  fetchFilters: fetchFiltersStart,
  fetchListWithFilters: fetchContainerProcessListStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainersSwitchFilters)
