export const SORT_FILTERS = {
  SORT_BY_KEY: 'sortBy',
  SORT_DIRECTION: 'orderBy',
}

export const FILTERS = {
  AISLE_FILTER: 'a',
  BAY_FILTER: 'b',
  CONTAINER_FILTER: 'c',
  CONTAINER_ARRIVE_DATE_FILTER: 'cad',
  CARRIER_FILTER: 'car',
  CONTAINER_EXPECTED_ARRIVE_DATE_FILTER: 'cead',
  CUSTOMER_FILTER: 'cu',
  CONTAINER_LIST_TYPE_FILTER: 'clt',
  CONTAINER_STATUS_FILTER: 'cs',
  CONTAINER_STATUS_RECEIVING_FILTER: 'csr',
  CONTAINER_STATUS_UNLOADING_FILTER: 'csu',
  DOCK_FILTER: 'd',
  CONTAINER_CASES_QTY_FILTER: 'icq',
  CONTAINER_PALLETS_QTY_FILTER: 'ipq',
  LEVEL_FILTER: 'l',
  LOCATION_TYPE_FILTER: 'lt',
  ORDER_PROCESS_STATUS_FILTER: 'opsf',
  PRODUCT_MODEL_FILTER: 'pm',
  ORDER_PALLETS_ONLY_FILTER: 'opo',
  PRODUCT_MANF_MODEL_FILTER: 'pmm',
  PRODUCT_NAME_FILTER: 'pn',
  PALLET_CAPACITY_FILTER: 'pc',
  STORAGE_TYPE_FILTER: 'st',
  STATE_FILTER: 's',
  SHIPPING_OR_PICKUP_DATE_FILTER: 'sopd',
  VENDOR_FILTER: 'v',
  WAREHOUSE_FILTER: 'w',
  PAGE_TYPE_FILTER: 'pageType',
}

export const DEFAULT_VALUES = {
  [FILTERS.PRODUCT_MODEL_FILTER]: 'All Models',
  [FILTERS.PRODUCT_MANF_MODEL_FILTER]: 'All Manf Models',
  [FILTERS.PRODUCT_NAME_FILTER]: 'All Names',
  [FILTERS.PALLET_CAPACITY_FILTER]: 'All Pallet Capacities',
  [FILTERS.LOCATION_TYPE_FILTER]: 'All Location Types',
  [FILTERS.AISLE_FILTER]: 'All Aisles',
  [FILTERS.BAY_FILTER]: 'All Bays',
  [FILTERS.LEVEL_FILTER]: 'All Levels',
  [FILTERS.STATE_FILTER]: 'State',
}
