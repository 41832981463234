import React from 'react'
import * as PropTypes from 'prop-types'
import TextInput from 'components/common/TextInput'
import { REGEX } from 'constants/regex'

const NumericInput = (props) => {
  const {
    name,
    value,
    placeholder = '',
    className = '',
    tabIndex,
    pattern = REGEX.IS_INTEGER, //  put the REGEX here as default rather than rewrite
    onChange,
    isInvalid,
    autoComplete = 'off', // stop the dropdown suggestions from popping down
    type = 'tel', // using number or tel here is best. keyboard should go to numbers. tel doesn't have the up/down increment arrows, so it's a better choice
  } = props

  //no need for onChange here; can/will be defined in whatever class component is using a NumericInput

  return (
    <>
      <TextInput
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        pattern={pattern}
        className={`text-input ${isInvalid ? 'text-input--invalid' : ''} ${
          className ? className : ''
        }`}
      />
    </>
  )
}

NumericInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any, //could force the value to be .number, but bc starts as string with default value, i need to allow .any (or string and number)
  placeholder: PropTypes.string,

  tabIndex: PropTypes.any,

  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,

  className: PropTypes.string,
  pattern: PropTypes.number,

  onChange: PropTypes.func.isRequired,
  type: PropTypes.any,
}

export default NumericInput
