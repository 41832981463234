import React, { useState, useEffect } from 'react'
import './style.scss'
import * as query from 'querystringify'
import * as PropTypes from 'prop-types'
import Button from 'components/common/Button'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import ContainerProductInformation from './ContainerProductInformation'
import QAHoldDetailsPage from './QAHoldDetailsPage'
import ContainerDetailsForm from './ContainerDetailsForm'
import ContainerDetailsStatusSwitcher from './ContainerDetailsStatusSwitcher'
import { getUrlParams, isEmptyObject, redirect } from 'helpers'
import { ROUTES } from 'constants/routes'
import { FILTERS } from 'constants/filters'
import { CONTAINER_LIST_PAGE_TYPE_RECEIVING } from 'constants/common'
import { CONTAINER_STATUS } from 'constants/containerStatuses'
import CircleArrowIcon from 'assets/svg/circle-arrow.svg'
import { MODAL_CONFIRM_DEFAULT, MODAL_PENDING } from 'components/common/Modal'

const TOGGLE_LIST_BTN_TEXT_VIEW = 'View Product List'
const TOGGLE_LIST_BTN_TEXT_HIDE = 'Hide Product List'

const ContainerDetailsPage = (props) => {
  const [isListShown, setIsListShown] = useState(false)
  const [form, setForm] = useState({
    fields: {},
  })
  const [QAHoldItems, setQAHoldItems] = useState([])

  const {
    item,

    match: { params },
    page: { type, title, description },

    showModal,
    resetModals,
    fetchItem,
    changeItem,
    createUnloadProcess,
  } = props

  const onMount = () => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })

    fetchItem({ id: params.id })
  }

  const onChangeItem = () => {
    if (
      !isEmptyObject(item) &&
      item.container_status.id === CONTAINER_STATUS.PREPARE_TO_UNLOAD
    ) {
      redirect(
        `${ROUTES.CONTAINER_UNLOADING_LIST_PAGE}?${query.stringify({
          [FILTERS.WAREHOUSE_FILTER]: getUrlParams()[FILTERS.WAREHOUSE_FILTER],
        })}`,
      )
    }
  }

  useEffect(onMount, [])
  useEffect(onChangeItem, [item])

  const changeStatus = (status) => {
    changeItem({
      id: item.id,
      data: {
        ...{ container_status_id: status },
        ...{ id: item.id },
      },
    })
    resetModals()
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Updating...</div>,
    })
  }

  const returnToListPage = () => {
    const pageLink =
      type === CONTAINER_LIST_PAGE_TYPE_RECEIVING
        ? ROUTES.CONTAINER_RECEIVING_LIST_PAGE
        : ROUTES.CONTAINER_UNLOADING_LIST_PAGE

    redirect(
      `${pageLink}?${query.stringify({
        [FILTERS.WAREHOUSE_FILTER]: getUrlParams()[FILTERS.WAREHOUSE_FILTER],
      })}`,
    )
  }

  const onSubmitChangeStatus = () => {
    createUnloadProcess({ container_id: item.id })

    changeStatus(CONTAINER_STATUS.PREPARE_TO_UNLOAD)
  }

  const onClickSaveAndMove = () => {
    if (item.arrive_at && item.dock_id) {
      showModal({
        name: MODAL_CONFIRM_DEFAULT,
        content: (
          <div className="text">
            Move Container {item.name} to Unloading List?
          </div>
        ),
        events: {
          onAccept: onSubmitChangeStatus,
          onDeny: resetModals,
        },
      })
    }
  }

  const toggleList = () => setIsListShown(!isListShown)
  const onSubmitForm = () => {
    if (
      !!form.fields.arrive_at ||
      !!form.fields.dock_id ||
      !!form.fields.container_status_id
    ) {
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Updating...</div>,
      })
      changeItem({
        data: { ...form.fields, ...{ id: item.id } },
        id: item.id,
      })
    }
  }

  let isContainerStatusReceived = false
  let isFormFilled = false

  if (!isEmptyObject(item)) {
    isContainerStatusReceived =
      item.container_status.id === CONTAINER_STATUS.RECEIVING
  }
  if (!isEmptyObject(form)) {
    isFormFilled =
      !!form.fields.arrive_at ||
      !!form.fields.dock_id ||
      !!form.fields.container_status_id
  }

  let saveMoveClassName =
    isContainerStatusReceived && item.arrive_at && item.dock_id
      ? 'active'
      : 'disabled'

  const onChangeContainerStatus = (status) => {
    setForm({
      ...form,
      fields: {
        ...form.fields,
        container_status_id: status,
      },
    })
  }

  const toggleLists = () => {
    setIsListShown(!isListShown)
    fetchItem({ id: params.id })
  }

  return QAHoldItems.length > 0 ? (
    <QAHoldDetailsPage
      container={item}
      QAHoldItems={QAHoldItems}
      setQAHoldItems={setQAHoldItems}
      toggleLists={toggleLists}
    />
  ) : (
    <PageContent className="container-details-page">
      <ContentHeader title={title} description={description}>
        <div className="btn-group">
          <Button
            type="button"
            onClick={onSubmitForm}
            disabled={!isFormFilled}
            text="SAVE"
          />
          <Button type="button" onClick={returnToListPage} text="CANCEL" />
        </div>

        {!isEmptyObject(item) && (
          <ContainerDetailsStatusSwitcher
            item={item}
            value={
              form.fields && form.fields.container_status_id
                ? form.fields.container_status_id
                : item.container_status.id
            }
            onChange={onChangeContainerStatus}
          />
        )}
      </ContentHeader>
      <div className={`wrapper${isListShown ? ' wrapper--minimised' : ''}`}>
        <ContentSection>
          <div className="header">
            <div className="header__title">
              Container <span className="text--bold">{item.name}</span>
            </div>
          </div>
          <hr />
          <ContainerDetailsForm
            item={item}
            parentForm={form}
            onChange={setForm}
          />
        </ContentSection>

        <div className="footer">
          <Button
            className="btn--secondary"
            text={
              isListShown
                ? TOGGLE_LIST_BTN_TEXT_HIDE
                : TOGGLE_LIST_BTN_TEXT_VIEW
            }
            onClick={toggleList}
          />
          {isContainerStatusReceived && (
            <div
              onClick={onClickSaveAndMove}
              className={`text text--${saveMoveClassName}`}
            >
              Save & Move to Unloading Process
              <CircleArrowIcon />
            </div>
          )}
        </div>

        {isListShown && (
          <ContainerProductInformation
            onEditQAHoldDetails={setQAHoldItems}
            container={item}
            items={item.container_products}
          />
        )}
      </div>
    </PageContent>
  )
}

ContainerDetailsPage.propTypes = {
  item: PropTypes.object,

  page: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }),

  changeItem: PropTypes.func,
  fetchItem: PropTypes.func,
  resetModals: PropTypes.func,
  showModal: PropTypes.func,
  createUnloadProcess: PropTypes.func,
}

export default ContainerDetailsPage
