export const types = {
  FETCH_APP_INFO_START: 'FETCH_APP_INFO_START',
  FETCH_APP_INFO_SUCCESS: 'FETCH_APP_INFO_SUCCESS',
  FETCH_APP_INFO_FAIL: 'FETCH_APP_INFO_FAIL',
  TEST_EXCEPTION_START: 'TEST_EXCEPTION_START',
}

export const fetchAppInfoStart = (payload) => ({
  type: types.FETCH_APP_INFO_START,
  payload,
})
export const fetchAppInfoSuccess = (payload) => ({
  type: types.FETCH_APP_INFO_SUCCESS,
  payload,
})
export const fetchAppInfoFail = (payload) => ({
  type: types.FETCH_APP_INFO_FAIL,
  payload,
})
export const testExceptionStart = (payload) => ({
  type: types.TEST_EXCEPTION_START,
  payload,
})
