import { takeEvery, call, put } from 'redux-saga/effects'
import {
  types,
  fetchOrderProcessListFail,
  fetchOrderProcessListStart,
  fetchOrderProcessListSuccess,
  fetchOneOrderProcessFail,
  fetchOneOrderProcessSuccess,
  fetchShipmentOrdersSuccess,
} from './actions'
import {
  fetchMany,
  fetchOne,
  update,
  deleteOne,
  moveToNextDay,
  updateLoadStatus,
} from 'api/order'
import { resetModals, showModal } from '../modal/actions'
import { redirect } from 'helpers'
import { ALL_ORDERS_STATUSES } from 'constants/order'
import { ROUTES } from 'constants/routes'
import { MODAL_SUCCESS } from 'components/common/Modal'

function* fetchOrderProcessList(action) {
  const { payload } = action

  try {
    const responseWithOrderList = yield call(fetchMany, payload.params)
    yield put(
      fetchOrderProcessListSuccess(responseWithOrderList.data.data.items),
    )
    yield put(resetModals())
  } catch (error) {
    redirect(ROUTES.LOGIN_PAGE)

    yield put(fetchOrderProcessListFail())
  }
}

function* fetchShipmentOrders(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload.params)
    yield put(fetchShipmentOrdersSuccess(response.data.data.items))
    yield put(resetModals())
  } catch (error) {
    redirect(ROUTES.LOGIN_PAGE)
  }
}

function* updateOrderLoadStatus(action) {
  const { payload } = action

  try {
    const response = yield call(updateLoadStatus, payload)
  } catch (error) {
  }
}

function* updateOrder(action) {
  const { payload } = action

  try {
    yield call(update, payload)
    yield put(
      fetchOrderProcessListStart({
        params: {
          'filters[order_statuses]': ALL_ORDERS_STATUSES,
        },
      }),
    )
    yield put(
      showModal({
        name: MODAL_SUCCESS,
        content: 'Order Status Updated',
      }),
    )
  } catch (error) {
    yield put(fetchOrderProcessListFail())
  }
}

function* fetchOneOrderProcess(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneOrderProcessSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneOrderProcessFail())
  }
}

function* deleteOrderProcess(action) {
  const { payload } = action

  try {
    yield call(deleteOne, payload)
    yield put(
      fetchOrderProcessListStart({
        params: {
          'filters[order_statuses]': ALL_ORDERS_STATUSES,
        },
      }),
    )
    yield put(
      showModal({
        name: 'success',
        content: 'Order Removed',
      }),
    )
  } catch (error) {
    yield put(fetchOrderProcessListFail())
  }
}

function* moveOrderToNextDay(action) {
  const { payload } = action

  try {
    yield call(moveToNextDay, payload)
    yield put(
      fetchOrderProcessListStart({
        params: {
          'filters[order_statuses]': ALL_ORDERS_STATUSES,
        },
      }),
    )
    yield put(
      showModal({
        name: 'success',
        content: 'Order Moved To Tomorrow',
      }),
    )
  } catch (error) {
    yield put(fetchOrderProcessListFail())
  }
}

export default function* orderSaga() {
  yield takeEvery(types.FETCH_ORDER_PROCESS_LIST_START, fetchOrderProcessList)
  yield takeEvery(types.FETCH_SHIPMENT_ORDERS_START, fetchShipmentOrders)
  yield takeEvery(types.UPDATE_ORDER_PROCESS_START, updateOrder)
  yield takeEvery(types.FETCH_ONE_ORDER_PROCESS_START, fetchOneOrderProcess)
  yield takeEvery(types.DELETE_ORDER_PROCESS_START, deleteOrderProcess)
  yield takeEvery(types.MOVE_ORDER_TO_NEXT_DAY_START, moveOrderToNextDay)
  yield takeEvery(types.UPDATE_ORDER_LOAD_STATUS, updateOrderLoadStatus)
}
