import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.QA_HOLD.FETCH_MANY,
    method: 'GET',
    params: payload,
  })

export const create = (payload) =>
  request({
    url: API_ROUTE.QA_HOLD.CREATE,
    method: 'POST',
    data: payload,
  })

export const updateMany = (payload) =>
  request({
    url: API_ROUTE.QA_HOLD.UPDATE_MANY,
    method: 'PUT',
    data: payload,
  })
