import React, { Component } from 'react'
import query from 'querystringify'
import * as PropTypes from 'prop-types'
import ContentSection from 'components/common/PageContent/ContentSection'
import TextInput from 'components/common/TextInput'
import SelectInput from 'components/common/SelectInput'
import Switch from 'components/common/Switch'
import SubmitControl from 'components/common/FormElements/SubmitControl'
import { YES_NO_SWITCH_ITEMS } from 'constants/switchItems'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'
import { validate } from 'helpers/index'
import {
  HEIGHT_FIELD,
  LENGTH_FIELD,
  LOCATION_TYPE_FIELD,
  STORAGE_TYPE_FIELD,
  WAREHOUSE_FIELD,
  WIDTH_FIELD,
  WRAP_REQUIRED_FIELD,
} from 'constants/formFields'
import { FILTERS } from 'constants/filters'
import { DEFAULT_WAREHOUSE_ID } from 'constants/common'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { REGEX } from 'constants/regex'

const DEFAULT_FORM_FIELDS = {
  [WAREHOUSE_FIELD]: DEFAULT_WAREHOUSE_ID,
  [LOCATION_TYPE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [STORAGE_TYPE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  [LENGTH_FIELD]: '',
  [WIDTH_FIELD]: '',
  [HEIGHT_FIELD]: '',
  [WRAP_REQUIRED_FIELD]: 'Yes',
}

const FORM_RULES = {
  [WAREHOUSE_FIELD]: REGEX.IS_DIGIT,
  [LENGTH_FIELD]: REGEX.IS_DIGIT_WITH_DOTS,
  [LOCATION_TYPE_FIELD]: REGEX.IS_DIGIT,
  [STORAGE_TYPE_FIELD]: REGEX.IS_DIGIT,
  [WIDTH_FIELD]: REGEX.IS_DIGIT_WITH_DOTS,
  [HEIGHT_FIELD]: REGEX.IS_DIGIT_WITH_DOTS,
  [WRAP_REQUIRED_FIELD]: REGEX.IS_YES_NO,
}

export default class CommonLocationDetails extends Component {
  static propTypes = {
    locationTypeList: PropTypes.array,
    storageTypeList: PropTypes.array,
    warehouseList: PropTypes.array,

    onSubmit: PropTypes.func,
    fetchLocationFilters: PropTypes.func,
  }

  static defaultProps = {
    locationTypeList: [],
    storageTypeList: [],
    warehouseList: [],
  }

  state = {
    form: {
      fields: Object.assign({}, DEFAULT_FORM_FIELDS),
      rules: Object.assign({}, FORM_RULES),
    },
    isEditForm: true,
  }

  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { form } = prevState
    let { warehouseList } = nextProps
    let warehouseId =
      parseInt(query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER]) ||
      DEFAULT_WAREHOUSE_ID

    if (warehouseList.length) {
      const warehouse = warehouseList.find((item) => item.value === warehouseId)

      if (warehouse) {
        form.fields[WAREHOUSE_FIELD] = warehouse.value

        return { form }
      }
    }

    return prevState
  }

  componentDidMount() {
    const { fetchLocationFilters } = this.props
    const { form } = this.state
    const warehouseId = parseInt(
      query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER],
    )

    this.setState({
      form: {
        ...form,
        fields: {
          ...form.fields,
          [WAREHOUSE_FIELD]: warehouseId,
        },
      },
    })

    fetchLocationFilters([
      FILTERS.STORAGE_TYPE_FILTER,
      FILTERS.LOCATION_TYPE_FILTER,
      FILTERS.WAREHOUSE_FILTER,
    ])
  }

  isCommonFieldsFilled = () => {
    const { form } = this.state

    return (
      !!form.fields[LOCATION_TYPE_FIELD] &&
      !!form.fields[STORAGE_TYPE_FIELD] &&
      !!form.fields[WRAP_REQUIRED_FIELD]
    )
  }

  onChange = (name, value) => {
    let { form } = this.state

    if (value === '') {
      form.fields[name] = value
    } else {
      if (validate(value, form.rules[name])) {
        form.fields[name] = value
      }
    }

    this.setState({ form })
  }

  submitDetailsForm = () => {
    const {
      form: { fields },
    } = this.state

    if (this.isCommonFieldsFilled()) {
      this.props.onSubmit(fields)
    }

    this.setState({
      isEditForm: false,
    })
  }

  switchEdit = () => {
    this.props.clearLocations()

    this.setState({
      isEditForm: true,
    })
  }

  render() {
    const { storageTypeList, warehouseList, locationTypeList } = this.props
    const {
      form: { fields },
      isEditForm,
    } = this.state

    const locationTypeListWithoutDock = locationTypeList.filter(
      (item) => item.value !== LOCATION_TYPE.DOCK_TYPE_ID,
    )

    const selectedWarehouse = warehouseList.find(
      (item) => item.value === fields[WAREHOUSE_FIELD],
    )
    const selectedLocationType = locationTypeList.find(
      (item) => item.value === fields[LOCATION_TYPE_FIELD],
    )
    const selectedStorageType = storageTypeList.find(
      (item) => item.value === fields[STORAGE_TYPE_FIELD],
    )

    return (
      <ContentSection
        className={`content-section--details${!isEditForm ? '--filled' : ''}`}
      >
        <div className="header">
          <div className="header__title">
            <span className="header__title--bold">Step 1</span>
            <span>&nbsp;Location Details</span>
          </div>
          {!isEditForm && (
            <div onClick={() => this.switchEdit()}>
              <i className="icon icon-edit" />
            </div>
          )}
        </div>
        <hr />
        <form>
          <div className="form">
            <div className="form__main-column">
              <div className="form__row">
                <div className="title">Warehouse</div>
                <div>{selectedWarehouse ? selectedWarehouse.label : ''}</div>
              </div>
              <div className="form__row">
                <div className="title">Length</div>
                <div>
                  <div className="form__row--measure">
                    {isEditForm ? (
                      <TextInput
                        tabIndex="3"
                        name={LENGTH_FIELD}
                        value={fields[LENGTH_FIELD]}
                        placeholder="Enter Length"
                        onChange={this.onChange}
                        pattern={REGEX.IS_DIGIT}
                      />
                    ) : (
                      <>{fields[LENGTH_FIELD] ? fields[LENGTH_FIELD] : ''}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="form__row">
                <div className="title">Wrapped</div>
                {isEditForm ? (
                  <Switch
                    name={WRAP_REQUIRED_FIELD}
                    items={YES_NO_SWITCH_ITEMS}
                    selected={fields[WRAP_REQUIRED_FIELD]}
                    onChange={this.onChange}
                  />
                ) : (
                  <>
                    {fields[WRAP_REQUIRED_FIELD]
                      ? fields[WRAP_REQUIRED_FIELD]
                      : ''}
                  </>
                )}
              </div>
            </div>

            <div className="form__main-column">
              <div className="form__row">
                <div className="title">Location Type</div>
                {isEditForm ? (
                  <div>
                    <SelectInput
                      tabIndex="1"
                      name={LOCATION_TYPE_FIELD}
                      value={fields[LOCATION_TYPE_FIELD]}
                      defaultValue="- Select -"
                      items={locationTypeListWithoutDock}
                      onChange={this.onChange}
                    />
                  </div>
                ) : (
                  <>{selectedLocationType ? selectedLocationType.label : ''}</>
                )}
              </div>
              <div className="form__row">
                <div className="title">Width</div>
                <div>
                  <div className="form__row--measure">
                    {isEditForm ? (
                      <TextInput
                        tabIndex="4"
                        name={WIDTH_FIELD}
                        value={fields[WIDTH_FIELD]}
                        placeholder="Enter Width"
                        onChange={this.onChange}
                        pattern={REGEX.IS_DIGIT}
                      />
                    ) : (
                      <>{fields[WIDTH_FIELD] ? fields[WIDTH_FIELD] : ''}</>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="form__main-column">
              <div className="form__row">
                <div className="title">Storage Type</div>
                {isEditForm ? (
                  <div>
                    <SelectInput
                      tabIndex="2"
                      name={STORAGE_TYPE_FIELD}
                      value={fields[STORAGE_TYPE_FIELD]}
                      defaultValue="- Select -"
                      items={storageTypeList}
                      onChange={this.onChange}
                    />
                  </div>
                ) : (
                  <>{selectedStorageType ? selectedStorageType.label : ''}</>
                )}
              </div>
              <div className="form__row form__row--measure">
                <div className="title">Height</div>
                {isEditForm ? (
                  <div>
                    <TextInput
                      tabIndex="5"
                      name={HEIGHT_FIELD}
                      value={fields[HEIGHT_FIELD]}
                      placeholder="Enter Height"
                      onChange={this.onChange}
                      pattern={REGEX.IS_DIGIT}
                    />
                  </div>
                ) : (
                  <> {fields[HEIGHT_FIELD] ? fields[HEIGHT_FIELD] : ''}</>
                )}
              </div>
            </div>
          </div>
          {isEditForm && (
            <>
              <hr />
              <div className="form__footer">
                <SubmitControl
                  text="Continue"
                  className="form__footer__confirm"
                  onClick={() => this.submitDetailsForm()}
                  disabled={!this.isCommonFieldsFilled()}
                />
              </div>
            </>
          )}
        </form>
      </ContentSection>
    )
  }
}
