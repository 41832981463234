import React from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import StaffDetails from '../index'

const customStyles = {
  content: {
    padding: 0,
  },
}

const StaffDetailsModal = (props) => (
  <ModalDefault isOpen={props.isOpen} style={customStyles}>
    <StaffDetails />
  </ModalDefault>
)
StaffDetailsModal.propTypes = {
  isOpen: PropTypes.bool,

  staffItem: PropTypes.object,
  orderProcess: PropTypes.object,

  onDeny: PropTypes.func,
}

export default StaffDetailsModal
