export const types = {
  RESET_MODALS: 'RESET_MODALS',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_PENDING_MODAL: 'SHOW_PENDING_MODAL',
  CLOSE_PENDING_MODAL: 'CLOSE_PENDING_MODAL',
  SHOW_STAFF_DETAILS_MODAL: 'SHOW_STAFF_DETAILS_MODAL',
  CLOSE_STAFF_DETAILS_MODAL: 'CLOSE_STAFF_DETAILS_MODAL',
}

export const showModal = (payload) => ({
  type: types.SHOW_MODAL,
  payload,
})

export const closeModal = (payload) => ({
  type: types.CLOSE_MODAL,
  payload,
})

export const resetModals = (payload) => ({
  type: types.RESET_MODALS,
  payload,
})

export const showPendingModal = (payload) => ({
  type: types.SHOW_PENDING_MODAL,
  payload,
})

export const closePendingModal = (payload) => ({
  type: types.CLOSE_PENDING_MODAL,
  payload,
})

export const showModalStaffDetails = (payload) => ({
  type: types.SHOW_STAFF_DETAILS_MODAL,
  payload,
})

export const closeModalStaffDetails = (payload) => ({
  type: types.CLOSE_STAFF_DETAILS_MODAL,
  payload,
})
