import React from 'react'
import { takeEvery, call, put } from 'redux-saga/effects'
import {
  types,
  loginSuccess,
  loginFail,
  checkUserSuccess,
  checkUserFail,
  setNewPinCodeFail,
  resetUser,
} from './actions'
import { update, login, checkUser, getUserData, logout } from 'api/auth'
import { redirect } from 'helpers/index'
import { resetModals, showModal } from '../modal/actions'
import { ROUTES } from 'constants/routes'
import { store } from '../../index'
import {
  MODAL_PENDING,
  MODAL_SUCCESS,
  MODAL_WARNING,
} from 'components/common/Modal'
import {
  PRIMARY_IP_API_URL,
  PRIMARY_IP_API_KEY,
  SECONDARY_IP_API_URL,
  SECONDARY_IP_API_KEY,
} from 'constants/apiconfig.js'

function* userCheck(action) {
  const { payload } = action

  try {
    const response = yield call(checkUser, payload)
    yield put(checkUserSuccess(response.data.data.item))
  } catch (error) {
    yield put(checkUserFail(error.response.data.errors))
  }
}

function* loginUser(action) {
  let userIp
  let additionalMessage
  try {
    const res1 = yield call(fetch, PRIMARY_IP_API_URL + PRIMARY_IP_API_KEY)
    const json1 = yield res1.json()
    if (json1.ip) {
      userIp = json1.ip
    } else {
      throw new Error()
    }
  } catch (e) {
    try {
      const res2 = yield call(
        fetch,
        SECONDARY_IP_API_URL + SECONDARY_IP_API_KEY,
      )
      const json2 = yield res2.json()
      if (json2.ip) {
        userIp = json2.ip
      } else {
        throw new Error()
      }
    } catch (e2) {
      additionalMessage = 'Contact Administrator.'
    }
  }

  const ipObj = { userIp: userIp }
  let { payload } = action
  payload.data = { ...payload.data, ...ipObj }
  const onClose = () => store.dispatch(resetModals())

  try {
    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Logging in...</div>,
      }),
    )
    const responseWithToken = yield call(login, payload)
    const token = responseWithToken.data.data.access_token
    const refresh_token = responseWithToken.data.data.refresh_token
    const responseWithUserData = yield call(getUserData, token)

    yield put(
      loginSuccess({ ...responseWithUserData.data, token, refresh_token }),
    )
    yield put(resetModals())
    redirect(ROUTES.HOME_PAGE)
  } catch (error) {
    yield put(
      showModal({
        name: 'warning',
        content: additionalMessage ? (
          <div className="modal--info__text">
            <span>Login failed. {additionalMessage}</span>
          </div>
        ) : (
          <div className="modal--info__text">'Login failed'</div>
        ),
        events: {
          onClose,
        },
      }),
    )
    yield put(loginFail(error.response.data.error))
  }
}

function* logoutUser(action) {
  const { payload } = action

  try {
    yield put(resetUser())
    yield call(logout, payload)

    redirect(ROUTES.LOGIN_PAGE)
  } catch (error) {
    redirect(ROUTES.LOGIN_PAGE)
  }
}

function* userUpdate(action) {
  const { payload } = action

  try {
    yield call(update, payload)
    yield put(showModal({ name: MODAL_SUCCESS, content: 'PIN Reset' }))
  } catch (error) {
    yield put(setNewPinCodeFail(error.response.data.errors))
  }
}

export default function* authSaga() {
  yield takeEvery(types.CHECK_USER_START, userCheck)
  yield takeEvery(types.LOGIN_START, loginUser)
  yield takeEvery(types.SET_NEW_PIN_CODE_START, userUpdate)
  yield takeEvery(types.LOGOUT, logoutUser)
}
