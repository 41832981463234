import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const Checkbox = ({ checked = false, onClick = (e) => e }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const onChange = () => {
    setIsChecked(!checked)
    onClick()
  }

  return (
    <label className="checkbox">
      <input
        className="checkbox__input"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span className="checkbox__checkmark" />
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,

  onClick: PropTypes.func,
}

export default Checkbox
