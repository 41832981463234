import { types } from 'store/filter/actions'
import { FILTERS } from 'constants/filters'
import { sortObjectsList } from 'helpers/sorting'

const INIT = {
  filterParams: {
    warehouseList: [],
    stateList: [],
    locationTypeList: [],
    aisleList: [],
    bayList: [],
    levelList: [],
    dockList: [],
    storageTypeList: [],
    vendorList: [],
    containerStatusList: [],
    customerList: [],
    carrierList: [],
    palletCapacityList: [],
    productModelList: [],
    productManfModelList: [],
    productNameList: [],
  },
  isLoading: false,
}

const filterReducer = (state = INIT, action) => {
  const { type, payload } = action
  switch (type) {
    case types.FETCH_FILTERS_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_FILTERS_SUCCESS:
      const filterParams = {
        isLoading: false,
        palletCapacityList: !payload[FILTERS.PALLET_CAPACITY_FILTER]
          ? state.filterParams.palletCapacityList
          : sortObjectsList(payload[FILTERS.PALLET_CAPACITY_FILTER], 'value'),
        productModelList: !payload[FILTERS.PRODUCT_MODEL_FILTER]
          ? state.filterParams.productModelList
          : sortObjectsList(payload[FILTERS.PRODUCT_MODEL_FILTER], 'value'),

        productManfModelList: !payload[FILTERS.PRODUCT_MANF_MODEL_FILTER]
          ? state.filterParams.productManfModelList
          : sortObjectsList(
              payload[FILTERS.PRODUCT_MANF_MODEL_FILTER],
              'value',
            ),
        productNameList: !payload[FILTERS.PRODUCT_NAME_FILTER]
          ? state.filterParams.productNameList
          : sortObjectsList(payload[FILTERS.PRODUCT_NAME_FILTER], 'value'),

        warehouseList: !payload[FILTERS.WAREHOUSE_FILTER]
          ? state.filterParams.warehouseList
          : sortObjectsList(payload[FILTERS.WAREHOUSE_FILTER], 'label'),
        stateList: !payload[FILTERS.STATE_FILTER]
          ? state.filterParams.stateList
          : sortObjectsList(payload[FILTERS.STATE_FILTER], 'label'),
        locationTypeList: !payload[FILTERS.LOCATION_TYPE_FILTER]
          ? state.filterParams.locationTypeList
          : sortObjectsList(payload[FILTERS.LOCATION_TYPE_FILTER], 'label'),
        aisleList: !payload[FILTERS.AISLE_FILTER]
          ? state.filterParams.aisleList
          : sortObjectsList(payload[FILTERS.AISLE_FILTER], 'label'),
        levelList: !payload[FILTERS.LEVEL_FILTER]
          ? state.filterParams.levelList
          : sortObjectsList(payload[FILTERS.LEVEL_FILTER], 'value'),
        bayList: !payload[FILTERS.BAY_FILTER]
          ? state.filterParams.bayList
          : sortObjectsList(payload[FILTERS.BAY_FILTER], 'label'),
        storageTypeList: !payload[FILTERS.STORAGE_TYPE_FILTER]
          ? state.filterParams.storageTypeList
          : sortObjectsList(payload[FILTERS.STORAGE_TYPE_FILTER], 'label'),
        dockList: !payload[FILTERS.DOCK_FILTER]
          ? state.filterParams.dockList
          : sortObjectsList(payload[FILTERS.DOCK_FILTER], 'value'),
        vendorList: !payload[FILTERS.VENDOR_FILTER]
          ? state.filterParams.vendorList
          : sortObjectsList(payload[FILTERS.VENDOR_FILTER], 'value'),
        customerList: !payload[FILTERS.CUSTOMER_FILTER]
          ? state.filterParams.customerList
          : sortObjectsList(payload[FILTERS.CUSTOMER_FILTER], 'value'),
        carrierList: !payload[FILTERS.CARRIER_FILTER]
          ? state.filterParams.carrierList
          : sortObjectsList(payload[FILTERS.CARRIER_FILTER], 'label'),
        containerStatusList:
          !payload[FILTERS.CONTAINER_STATUS_UNLOADING_FILTER] &&
          !payload[FILTERS.CONTAINER_STATUS_RECEIVING_FILTER]
            ? state.filterParams.containerStatusList
            : payload[FILTERS.CONTAINER_STATUS_UNLOADING_FILTER]
            ? sortObjectsList(
                payload[FILTERS.CONTAINER_STATUS_UNLOADING_FILTER],
                'value',
              )
            : sortObjectsList(
                payload[FILTERS.CONTAINER_STATUS_RECEIVING_FILTER],
                'value',
              ),
      }
      return { ...state, filterParams }
    case types.FETCH_FILTERS_FAIL:
      return INIT
    case types.CLEAR_FILTER:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          [payload]: [],
        },
      }
    default:
      return state
  }
}

export default filterReducer
