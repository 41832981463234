import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { logout, resetUser } from 'store/auth/actions'
import Avatar from 'components/features/StaffCard/Avatar'
import { redirect } from 'helpers/index'
import { ROUTES } from 'constants/routes'

const HeaderNav = ({ user, logout, resetUser }) => {
  const onLogout = () => {
    resetUser()
    redirect(ROUTES.LOGIN_PAGE)
    logout(user.token)
  }

  return (
    <div className="main__nav">
      <div className="main__svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="47"
          viewBox="0 0 47 47"
        >
          <defs>
            <filter
              id="a"
              width="133.8%"
              height="133.8%"
              x="-16.9%"
              y="-16.9%"
              filterUnits="objectBoundingBox"
            >
              <feOffset
                dx="1"
                dy="1.5"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation="1"
              />
              <feColorMatrix
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g fill="none" fillRule="nonzero">
            <path fill="#D0CBBF" d="M3.5 26.167h17.333V4.5H3.5z" />
            <path fill="#EEE8D3" d="M3.5 43.5h17.333v-13H3.5z" />
            <path fill="#AA996D" d="M25.167 43.5H42.5V21.833H25.167z" />
            <path fill="#FFB601" d="M25.167 4.5v13H42.5v-13z" />
          </g>
        </svg>
      </div>
      <div className="main__user-text">
        <span>Hi, {user.name}</span>
        <span onClick={onLogout}>Logout</span>
      </div>
      <Avatar link={user.photo} />
    </div>
  )
}

HeaderNav.propTypes = {
  user: PropTypes.object,

  logout: PropTypes.func,
}

const mapDispatchToProps = {
  logout,
  resetUser,
}

export default connect(null, mapDispatchToProps)(HeaderNav)
