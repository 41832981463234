import React from 'react'
import * as PropTypes from 'prop-types'
import CardList from 'components/features/StaffCard/CardList'
import User from 'services/User'

const StaffEngagementBlock = ({
  item,
  assignToList,
  onAssign,
  onShowDetailsPopup,
}) => {
  return (
    <div className={`tab__content`}>
      <div className="title">{item.label}</div>
      <CardList
        assignToList={assignToList}
        items={item.staff.map((item) => new User(item))}
        options={{
          selectable: true,
        }}
        events={{
          onSelect: onAssign,
          onShowDetailsPopup: onShowDetailsPopup,
        }}
      />
    </div>
  )
}

StaffEngagementBlock.propTypes = {
  item: PropTypes.object.isRequired,

  onAssign: PropTypes.func,
}

export default StaffEngagementBlock
