import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import TableListHeader from './TableListHeader'
import TableListRow from 'components/common/TableList/TableListRow'
import './styles.scss'

const TableList = (props) => {
  const { className, columns, rows } = props
  const [sortField, setSortField] = useState(null)
  const [sortOrder, setSortOrder] = useState(true)

  const handleSort = (field) => {
    const newSortOrder = field === sortField ? !sortOrder : true
    setSortField(field)
    setSortOrder(newSortOrder)

    rows.sort((a, b) => {
      let aVal = a[field]
      let bVal = b[field]
      
      if (!isNaN(aVal) && !isNaN(bVal)) {
        aVal = parseFloat(aVal)
        bVal = parseFloat(bVal)
      } else {
        aVal = aVal ? aVal.toString().toLowerCase() : ''
        bVal = bVal ? bVal.toString().toLowerCase() : ''
      }

      return (
        (aVal > bVal ? 1 : aVal < bVal ? -1 : 0) *
        (newSortOrder ? 1 : -1)
      )
    })
  }

  return (
    <div className={className}>
      <TableListHeader 
        className="table-list__header" 
        columns={columns} 
        onSort={handleSort} 
        sortOrder={sortOrder}
        sortField={sortField}
      />
      {rows.map((row) => (
        <TableListRow
          className="table-list_row"
          key={row.id}
          row={row}
          columns={columns}
        />
      ))}
    </div>
  )
}

TableList.propTypes = {
  className: PropTypes.string,

  columns: PropTypes.array,
  rows: PropTypes.array,
}

export default TableList
