import React, { useState, useEffect } from 'react'
import Joi from '@hapi/joi'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FILTERS } from 'constants/filters'
import { MAPPER_EDIT_CONTAINER_DETAILS } from 'constants/mappers'
import { fetchFiltersStart } from 'store/filter/actions'
import {
  date,
  dateToFormat,
  isEmptyObject,
  isEqualObjects,
  mapItem,
} from 'helpers'
import Calendar from 'components/common/Calendar'
import HighPriorityIcon from 'assets/svg/high-priority-icon.svg'
import SelectInput from 'components/common/SelectInput'
import { CONTAINER_STATUS } from 'constants/containerStatuses'

const DEFAULT_FORM_FIELDS = {
  should_arrive_at: undefined,
  arrive_at: undefined,
  dock_id: '-',
  vendor: undefined,
  invoice_cases_quantity: undefined,
  container_priority: undefined,
}

const FORM_RULES = {
  should_arrive_at: Joi.string(),
  arrive_at: Joi.string(),
  dock_id: Joi.number(),
}

const ContainerDetailsForm = (props) => {
  const { item, parentForm, dockList, onChange, fetchFilters } = props

  const [form, setForm] = useState({
    fields: { ...DEFAULT_FORM_FIELDS },
    rules: { ...FORM_RULES },
    error: {
      fields: [],
    },
  })

  const onMount = () => {
    fetchFilters([FILTERS.DOCK_FILTER])
  }

  const onItemChange = () => {
    if (item.id && isEqualObjects(form.fields, DEFAULT_FORM_FIELDS)) {
      const updatedForm = {
        ...form,
        fields: mapItem(item, MAPPER_EDIT_CONTAINER_DETAILS),
      }

      setForm(updatedForm)
      onChange(updatedForm)
    }
  }

  useEffect(onMount, [])
  useEffect(onItemChange, [item])

  const onChangeForm = (name, value) => {
    let updatedForm = { ...form }

    updatedForm.fields[name] = value
    updatedForm.error.fields = updatedForm.error.fields.filter(
      (value) => value !== name,
    )

    Joi.validate({ [name]: value }, { [name]: form.rules[name] }, (error) => {
      if (error !== null) {
        updatedForm.error.fields = [...updatedForm.error.fields, name]
      }
    })

    setForm({
      ...parentForm,
      ...updatedForm,
      fields: {
        ...parentForm.fields,
        [name]: value,
      },
    })
    onChange({
      ...parentForm,
      ...updatedForm,
      fields: {
        ...parentForm.fields,
        [name]: value,
      },
    })
  }

  const setArrivalDate = () => {
    if (
      parentForm.fields &&
      parentForm.fields.container_status_id === CONTAINER_STATUS.RECEIVING
    ) {
      onChangeForm('arrive_at', dateToFormat(date(), 'YYYY-MM-DD'))
    }

    return '-'
  }

  return (
    <form>
      <div className="form">
        <div className="form__main-column">
          <div className="form__row">
            <div className="title">Expected Arrival</div>
            {form.fields.should_arrive_at
              ? dateToFormat(form.fields.should_arrive_at, 'MM-DD-YYYY')
              : '-'}
            <Calendar
              name={'should_arrive_at'}
              value={date(form.fields.should_arrive_at)}
              onChange={onChangeForm}
            />
          </div>
          <div className="form__row">
            <div className="title">Arrival</div>
            {form.fields.arrive_at
              ? dateToFormat(form.fields.arrive_at, 'MM-DD-YYYY')
              : setArrivalDate()}
            {!isEmptyObject(item) &&
              parentForm.fields.container_status_id ===
                CONTAINER_STATUS.RECEIVING && (
                <Calendar
                  name={'arrive_at'}
                  value={date(form.fields.arrive_at)}
                  onChange={onChangeForm}
                />
              )}
          </div>
          <div className="form__row">
            <div className="title title--required">Dock</div>
            {!isEmptyObject(item) &&
            parentForm.fields.container_status_id ===
              CONTAINER_STATUS.RECEIVING ? (
              <SelectInput
                name={'dock_id'}
                value={form.fields.dock_id}
                items={dockList}
                onChange={onChangeForm}
              />
            ) : (
              <div>{form.fields.dock_id ? form.fields.dock_id : '-'}</div>
            )}
          </div>
        </div>
        <div className="form__main-column">
          <div className="form__row">
            <div className="title">Vendor</div>
            {!isEmptyObject(item) && item.vendor.suppliers_name}
          </div>

          <div className="form__row">
            <div className="title">Case</div>
            {!isEmptyObject(item) && item.invoice_cases_quantity}
          </div>
          <div className="form__row">
            <div className="title">Priority</div>
            {!isEmptyObject(item) && item.container_priority ? (
              <HighPriorityIcon />
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

ContainerDetailsForm.propTypes = {
  item: PropTypes.object,
  parentForm: PropTypes.object,

  dockList: PropTypes.array,

  onChange: PropTypes.func,
  fetchFilters: PropTypes.func,
}

const mapStateToProps = (store) => ({
  item: store.containerProcess.item,
  dockList: store.filter.filterParams.dockList,
})

const mapDispatchToProps = {
  fetchFilters: fetchFiltersStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerDetailsForm)
