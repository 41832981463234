import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { fetchQaHoldListStart, updateManyQaHold } from 'store/qaHold/actions'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import GroupedQaHoldItems from 'components/features/Inventory/QaHoldResolutionPage/GroupedQaHoldItems'
import Button from 'components/common/Button'
import { redirect } from 'helpers/index'
import { ROUTES } from 'constants/routes'

const QaHoldResolutionPage = (props) => {
  const { qaHoldList, updateManyQaHold, fetchQaHoldList } = props

  const onMount = () => {
    fetchQaHoldList({
      'filters[resolution_id]': '',
    })
  }

  useEffect(onMount, [])

  const [isAbleToDone, setIsAbleToDone] = useState(false)
  const [itemsToDone, setItemsToDone] = useState([])

  const onChangeItems = (items) => {
    setIsAbleToDone(items.filter((item) => !!item.resolution_id).length > 0)
    setItemsToDone(items)
  }

  const onCancel = () => redirect(ROUTES.HOME_PAGE)
  const onDone = () =>
    updateManyQaHold({
      data: itemsToDone,
      options: ROUTES.HOME_PAGE,
    })

  return (
    <PageContent className="list-page inventory-page">
      <ContentHeader
        title="QA HOLD RESOLUTION"
        description="Manage QA Hold Product"
      >
        <Button
          type="button"
          onClick={onDone}
          disabled={!isAbleToDone}
          text="DONE"
        />
        <Button type="button" onClick={onCancel} text="CANCEL" />
      </ContentHeader>
      {qaHoldList.length > 0 && (
        <GroupedQaHoldItems onChange={onChangeItems} items={qaHoldList} />
      )}
    </PageContent>
  )
}

QaHoldResolutionPage.propTypes = {
  qaHoldList: PropTypes.array,
  fetchQaHoldList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  qaHoldList: state.qaHold.list,
})

const mapDispatchToProps = {
  fetchQaHoldList: fetchQaHoldListStart,
  updateManyQaHold: updateManyQaHold,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QaHoldResolutionPage)
