export const types = {
  CREATE_MANY_TASK_ASSIGNMENT: 'CREATE_MANY_TASK_ASSIGNMENT',
  DELETE_MANY_TASK_ASSIGNMENT: 'DELETE_MANY_TASK_ASSIGNMENT',
}

export const createManyTaskAssignment = (payload) => ({
  type: types.CREATE_MANY_TASK_ASSIGNMENT,
  payload,
})

export const deleteManyTaskAssignment = (payload) => ({
  type: types.DELETE_MANY_TASK_ASSIGNMENT,
  payload,
})
