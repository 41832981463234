import { takeEvery, call, put } from 'redux-saga/effects'
import { fetch, testException } from 'api/appInfo'
import { fetchAppInfoFail, fetchAppInfoSuccess, types } from './actions'

function* fetchAppInfo(action) {
  const { payload } = action

  try {
    const response = yield call(fetch, payload)

    yield put(fetchAppInfoSuccess(response.data))
  } catch (error) {
    yield put(fetchAppInfoFail())
  }
}

function* testExceptionStart(action) {
  const { payload } = action

  try {
    const response = yield call(testException)
    console.log(response)
  } catch (error) {}
}

export default function* appInfoSaga() {
  yield takeEvery(types.FETCH_APP_INFO_START, fetchAppInfo)
  yield takeEvery(types.TEST_EXCEPTION_START, testExceptionStart)
}
