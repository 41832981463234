import { takeEvery, call, put } from 'redux-saga/effects'
import { fetchMany } from 'api/reasons'
import { fetchReasonListFail, fetchReasonListSuccess, types } from './actions'

function* fetchReasonList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(fetchReasonListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchReasonListFail())
  }
}

export default function* reasonSaga() {
  yield takeEvery(types.FETCH_REASON_LIST_START, fetchReasonList)
}
