import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'

const MAX_TABS_PER_LINE = 6
const DEFAULT_ITEM_VALUE = 0

const EXPANDED_TEXT = 'Less'
const TWISTED_TEXT = 'More'

const Switch = (props) => {
  const {
    items = [],

    name,
    className,
    defaultValue,

    selected = DEFAULT_ITEM_VALUE,
    scalable,

    onChange,
  } = props

  const [numberOfShownItems, setNumberOfShownItems] = useState(
    MAX_TABS_PER_LINE,
  )
  const [buttonText, setButtonText] = useState(TWISTED_TEXT)

  const onChangeItems = () => {
    const indexOfSelected =
      getOptions().findIndex((item) => item.value === selected) + 1

    setButtonText(
      indexOfSelected === getOptions().length ? EXPANDED_TEXT : TWISTED_TEXT,
    )
    setNumberOfShownItems(
      indexOfSelected > numberOfShownItems
        ? indexOfSelected
        : numberOfShownItems,
    )
  }

  useEffect(onChangeItems, [items])

  const getOptions = () =>
    defaultValue
      ? [{ value: ITEMS_LIST_DEFAULT_ITEM_ID, label: defaultValue }, ...items]
      : items
  const getSelectedItem = () =>
    getOptions().find((item) => item.value === selected) || DEFAULT_ITEM_VALUE

  const onChangeNumberOfShownItems = () => {
    const total =
      numberOfShownItems >= getOptions().length
        ? MAX_TABS_PER_LINE
        : getOptions().length

    setNumberOfShownItems(total)
    setButtonText(getOptions().length ? EXPANDED_TEXT : TWISTED_TEXT)
  }

  return (
    <div className={`switch ${className}`}>
      {getOptions().map((item, index) => {
        const onChangeInput = () => onChange(name, item.value)

        return index < numberOfShownItems ? (
          <label
            key={item.label}
            className={`switch__tab ${
              getSelectedItem().value === item.value
                ? 'switch__tab--active'
                : ''
            }`}
          >
            <span className="switch__tab__text">{item.label}</span>
            <input
              type="radio"
              name="radio"
              className="switch__tab__radio"
              onClick={onChangeInput}
            />
          </label>
        ) : (
          ''
        )
      })}

      {scalable && getOptions().length > MAX_TABS_PER_LINE && (
        <button
          className="switch__expander"
          onClick={onChangeNumberOfShownItems}
        >
          {buttonText}
        </button>
      )}
    </div>
  )
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string,

  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  items: PropTypes.array.isRequired,

  scalable: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
}

export default Switch
