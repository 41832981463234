import React, { useState } from 'react'
import * as query from 'querystringify'
import * as PropTypes from 'prop-types'
import TableList from 'components/common/TableList'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import SelectInput from 'components/common/SelectInput'
import {
  dateToFormat,
  getUrlFilter,
  getUrlParams,
  redirect,
  removePropFromObject,
} from 'helpers/index'
import { ORDER_PRICE_LEVELS } from 'constants/order'
import { ROUTES } from 'constants/routes'
import { FILTERS } from 'constants/filters'
import { MAPPER_CARRIER_LOGO } from 'constants/mappers'
import Tooltip from 'components/common/Tooltip'
import { showModal, resetModals } from 'store/modal/actions'
import { MODAL_WARNING } from 'components/common/Modal'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const PickOrderSection = (props) => {
  const { items, showModal, resetModals, onCreateShipment } = props

  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(
    parseInt(getUrlFilter(FILTERS.CARRIER_FILTER)),
  )
  const [selectedDate, setSelectedDate] = useState(
    getUrlFilter([FILTERS.SHIPPING_OR_PICKUP_DATE_FILTER]),
  )

  const [selectedCustomer, setSelectedCustomer] = useState(
    getUrlFilter([FILTERS.CUSTOMER_FILTER]),
  )

  const isSelected = selectedItems.length > 0

  const history = useHistory()

  const redirectToCreateShipment = (selectedItems) => {
    const orderIds = selectedItems
      .map((item) => item.entity.order.orders_id)
      .join(',')
    history.push(
      ROUTES.ORDERS_CREATE_SHIPMENT_PAGE.replace(':orderIds', orderIds),
    )
  }

  const onSubmit = () => {
    let errorMessage = ''
    if (selectedItems.length > 1) {
      const sameCarrier =
        [
          ...new Set(
            selectedItems.map((item) => {
              return item.entity.carrier.id
            }),
          ),
        ].length === 1
      if (!sameCarrier) {
        errorMessage = 'Orders Must Have Same Carrier'
      }

      const sameShippingAddress =
        [
          ...new Set(
            selectedItems.map((item) => {
              return item.entity.order.delivery_street_address
            }),
          ),
        ].length === 1
      if (!sameShippingAddress) {
        errorMessage = 'Orders Must Have Same Shipping Address'
      }
    }

    if (errorMessage) {
      showModal({
        name: MODAL_WARNING,
        content: <div className="modal--info__text">{errorMessage}</div>,
        events: {
          onClose: resetModals,
        },
      })
    } else {
      redirectToCreateShipment(selectedItems)
    }
  }

  const onSelectAll = () =>
    setSelectedItems(
      !isSelected
        ? items.filter((item) => {
            const carrierValue = parseInt(
              getUrlParams()[FILTERS.CARRIER_FILTER],
            )
            const dateValue = getUrlParams()[
              FILTERS.SHIPPING_OR_PICKUP_DATE_FILTER
            ]
            const customerValue = parseInt(
              getUrlParams()[FILTERS.CUSTOMER_FILTER],
            )

            const isFoundByCarrier =
              carrierValue && item.entity.carrier.id !== carrierValue
            const isFoundByDate =
              dateValue &&
              dateValue !==
                (item.entity.order.shipping_date ||
                  item.entity.order.pickup_date)
            const isFoundByCustomer =
              customerValue && item.entity.order.customers_id !== customerValue

            return !(isFoundByCarrier || isFoundByDate || isFoundByCustomer)
          })
        : [],
    )
  const onToggleFilters = () => setIsFiltersExpanded(!isFiltersExpanded)

  const getColumns = () => [
    {
      id: 1,
      label: 'Order',
      value: 'orderId',
    },
    {
      id: 2,
      label: 'Carrier',
      value: 'carrier',
    },
    {
      id: 3,
      label: 'Cases',
      value: 'case',
    },
    {
      id: 4,
      label: 'Pallets',
      value: 'palletsQty',
    },
    {
      id: 5,
      label: 'Pallet Locations',
      value: 'palletLocations',
    },
    {
      id: 6,
      label: 'Weight',
      value: 'weight',
    },
    {
      id: 7,
      label: 'Customer',
      value: 'customer',
    },
    {
      id: 8,
      label: 'Status',
      value: 'orderStatus',
    },
    {
      id: 10,
      label: 'Special Attention',
      value: 'specialAttention',
    },
    {
      id: 11,
      label: 'Shipping Notes',
      value: 'shippingNotes',
    },
    {
      id: 12,
      label: 'Warehouse Notes',
      value: 'warehouseNotes',
    },
    {
      id: 13,
      label: 'Shipping Date',
      value: 'shippingDate',
    },
    {
      id: 14,
      label: <Checkbox onClick={onSelectAll} checked={isSelected} />,
      value: 'isChecked',
    },
    {
      id: 15,
      label: '',
      value: 'shipmentStatus',
    },
  ]

  const getFilters = () => {
    const uniqueCarriersIds = [
      ...new Set(items.map((item) => item.entity.carrier.id)),
    ]
    const carriers = uniqueCarriersIds.map((id) => ({
      value: id,
      label: items.find((item) => item.entity.carrier.id === id).entity.carrier
        .name,
    }))

    const uniqueDates = [
      ...new Set(
        items.map((item) =>
          item.entity.order.shipping_date
            ? item.entity.order.shipping_date
            : item.entity.order.pickup_date,
        ),
      ),
    ]
    const dates = uniqueDates.map((date) => ({
      value: date,
      label: date,
    }))

    const uniqueCustomers = [
      ...new Set(items.map((item) => item.entity.order.customers_id)),
    ]
    const customers = uniqueCustomers.map((id) => ({
      value: id,
      label: items.find((item) => item.entity.order.customers_id === id).entity
        .order.customers_name,
    }))

    const onSelect = (name, value) => {
      const searchParams = query.stringify(
        !value
          ? removePropFromObject(name, getUrlParams())
          : {
              ...getUrlParams(),
              [name]: value,
            },
      )

      redirect(`${ROUTES.ORDERS_SHIPPING_PAGE}?${searchParams}`)

      setSelectedCarrier(parseInt(getUrlFilter(FILTERS.CARRIER_FILTER)))
      setSelectedDate(getUrlFilter([FILTERS.SHIPPING_OR_PICKUP_DATE_FILTER]))
      setSelectedCustomer(getUrlFilter([FILTERS.CUSTOMER_FILTER]))
    }

    return (
      <div className="filters-list">
        <SelectInput
          name={FILTERS.CARRIER_FILTER}
          value={selectedCarrier}
          defaultValue="All Carriers"
          items={carriers}
          onChange={onSelect}
          className="row__column"
        />

        <SelectInput
          name={FILTERS.SHIPPING_OR_PICKUP_DATE_FILTER}
          value={selectedDate}
          defaultValue="All Shipping Dates"
          items={dates}
          onChange={onSelect}
          className="row__column"
        />

        <SelectInput
          name={FILTERS.CUSTOMER_FILTER}
          value={selectedCustomer}
          defaultValue="All Customers"
          items={customers}
          onChange={onSelect}
          className="row__column"
        />
      </div>
    )
  }

  const getNormalizedItems = () =>
    items
      .filter((item) => {
        const carrierValue = parseInt(getUrlParams()[FILTERS.CARRIER_FILTER])
        const dateValue = getUrlParams()[FILTERS.SHIPPING_OR_PICKUP_DATE_FILTER]
        const customerValue = parseInt(getUrlParams()[FILTERS.CUSTOMER_FILTER])

        const isFoundByCarrier =
          carrierValue && item.entity.carrier.id !== carrierValue
        const isFoundByDate =
          dateValue &&
          dateValue !==
            (item.entity.order.shipping_date || item.entity.order.pickup_date)
        const isFoundByCustomer =
          customerValue && item.entity.order.customers_id !== customerValue

        return !(isFoundByCarrier || isFoundByDate || isFoundByCustomer)
      })
      .map((item) => {
        let orderCasesQty = item.entity.order.total_products_quantity
        
        const palletLocations = item.entity.order.order_pallets
          .filter(
            (orderPallet) =>
              orderPallet.pallet_location_shipping !== null ||
              orderPallet.pallet_location_pickup !== null,
          )
          .map(
            (orderPallet) =>
              `${
                orderPallet.pallet_location_shipping !== null &&
                orderPallet.pallet_location_shipping !== undefined
                  ? orderPallet.pallet_location_shipping
                  : ''
              }${
                orderPallet.pallet_location_pickup !== null &&
                orderPallet.pallet_location_pickup !== undefined
                  ? orderPallet.pallet_location_pickup
                  : ''
              }`,
          )
          .join(', ')
        const weight = item.entity.order.order_pallets
          .map((orderPallet) => orderPallet.pallet_weight)
          .reduce((acc, weight) => acc + weight, 0)
        const shippingDate = item.entity.order.shipping_date
          ? dateToFormat(item.entity.order.shipping_date, 'MM/DD/YYYY')
          : dateToFormat(item.entity.order.pickup_date, 'MM/DD/YYYY')
        const isChecked = !!selectedItems.find(
          (selectedItem) => selectedItem.id === item.id,
        )

        const onClick = () =>
          isChecked
            ? setSelectedItems(
                selectedItems.filter(
                  (selectedItem) => selectedItem.id !== item.id,
                ),
              )
            : setSelectedItems([...selectedItems, item])

        return {
          id: item.id,
          orderId: item.entity.order.orders_id,
          carrier: (
            <Tooltip
              hoverTarget={MAPPER_CARRIER_LOGO[item.entity.carrier.id]}
              sideText={item.entity.carrier.name}
              extraTargetClassName="carrier-icon-target"
              extraBubbleClassName="for-carrier-logo"
            />
          ),
          palletsQty: item.entity.order.order_pallets.filter(
            (orderPallet) =>
              orderPallet.pallet_location_shipping !== null ||
              orderPallet.pallet_location_pickup !== null,
          ).length,
          case: orderCasesQty,
          weight: weight.toFixed(2),
          customer: item.entity.order.customers_name,
          palletLocations: palletLocations,
          shippingDate: shippingDate,
          orderStatus: 'Picked',
          shippingNotes: item.entity.order.shipping_notes,
          specialAttention:
            (item.entity.order.tag ? item.entity.order.tag : '') +
            (item.entity.order.tag &&
            item.entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
              ? ', '
              : '') +
            (item.entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
              ? 'Dealer Network Elite'
              : ''),
          warehouseNotes: item.entity.order.warehouse_notes,
          isChecked: <Checkbox onClick={onClick} checked={isChecked} />,
          shipmentStatus:
            item.entity.order.active_shipments_count > 0
              ? 'Shipment Created'
              : '',
        }
      })

  return (
    <>
      <div className="header">
        <div
          className={`filter-btn ${
            isFiltersExpanded ? 'filter-btn--active' : ''
          }`}
          onClick={onToggleFilters}
        >
          Filter
        </div>
        <Button
          text="CREATE SHIPMENT"
          disabled={selectedItems.length === 0}
          onClick={onSubmit}
        />
      </div>
      {isFiltersExpanded && getFilters()}
      <TableList
        className="list"
        rows={getNormalizedItems()}
        columns={getColumns()}
      />
    </>
  )
}

PickOrderSection.propTypes = {
  items: PropTypes.array,
}

const mapDispatchToProps = {
  resetModals,
  showModal,
}

export default connect(null, mapDispatchToProps)(PickOrderSection)
