import React from 'react'
import * as PropTypes from 'prop-types'
import query from 'querystringify'
import './style.scss'
import Checkbox from '../../Checkbox/index'
import { getUrlParams, redirect } from 'helpers/index'
import { cleanFilters } from 'helpers'
import { SORT_FILTERS } from 'constants/filters'
import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'

const TableHeader = (props) => {
  const {
    className = '',

    checked,

    columns,
    events,
    sortOptions,
    options,
  } = props

  return (
    <thead className={className}>
      <tr>
        {options.selectable && (
          <th>
            <div className="table__header__cell--checked">
              <Checkbox checked={checked} onClick={events.onSelectAll} />
            </div>
          </th>
        )}
        {columns.map((item) => {
          const makeSort = () => {
            const sortParams = {
              [SORT_FILTERS.SORT_BY_KEY]: item.name,
              [SORT_FILTERS.SORT_DIRECTION]:
                sortOptions.sortDirection === DIRECTION_ASC
                  ? DIRECTION_DESC
                  : DIRECTION_ASC,
            }

            redirect(
              query.stringify(
                cleanFilters({ ...getUrlParams(), ...sortParams }),
                true,
              ),
            )
            events.onSort(item.name)
          }

          return (
            <th key={item.name}>
              <div className="table__header__cell">
                {item.label}
                <br />
                {item.labelLine2}
                {item.isSorting && (
                  <i
                    onClick={makeSort}
                    className={`icon icon-sort-arrows ${
                      sortOptions.sortByKey === item.name
                        ? 'color__darker-grey'
                        : ''
                    }`}
                  />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  className: PropTypes.string,

  checked: PropTypes.bool,

  columns: PropTypes.array,

  events: PropTypes.object,
  options: PropTypes.object,
  sortOptions: PropTypes.object,
}

export default TableHeader
