export const CARRIERS = {
  CARRIER_FEDEX_E: 1,
  CARRIER_FEDEX_P: 2,
  CARRIER_TFORCE: 3,
  CARRIER_ESTES: 4,
  CARRIER_YRC: 5,
  CARRIER_FEDEX_G: 6,
  CARRIER_UPS_G: 7,
  CARRIER_UPS_NEXT_DAY_AIR: 8,
  CARRIER_UPS_SECOND_DAY_AIR: 9,
  CARRIER_UPS_THREE_DAY: 10,
  CARRIER_USPS_P: 11,
  CARRIER_USPS_P_EX: 12,
  CARRIER_USPS_PARCEL: 13,
  CARRIER_76DELIVERY: 14,
  CARRIER_PICKUP: 15,
  CARRIER_OTHER: 16,
  CARRIER_USPS_FLAT_RATE: 17,
  CARRIER_GROUP_BUY: 18,
  CARRIER_XPO: 19,
  CARRIER_FTL_OTHER: 20,
  CARRIER_FTL_LANDSTAR: 21,
  CARRIER_FTL_CFI: 22,
  CARRIER_FTL_FEDEX_VOLUME: 23,
  CARRIER_ABF: 25,
  CARRIER_RL_STANDARD: 27,
  CARRIER_RL_GUARANTEED: 28,
}

export const ORDER_PICK_STATUSES = {
  STATUS_ASSIGNED: 8,
  STATUS_PICKING_COMPLETE: 9,
  STATUS_LOADING: 10,
  STATUS_NEW: 11,
  STATUS_NEXT_DAY: 12,
  STATUS_PICKING: 13,
  STATUS_QUEUE: 14,
  STATUS_REMOVE: 15,
  STATUS_REMOVE_BACK_TO_INV: 16,
  STATUS_REMOVE_HOLD: 17,
  STATUS_SHIPPING: 18,
  STATUS_COMPLETE: 28,
  STATUS_PREPARE_TO_SHIP: 30,
}

export const ORDER_LOAD_STATUSES = {
  STATUS_ASSIGNED: 8,
  STATUS_LOADING: 10,
  STATUS_QUEUE: 14,
  STATUS_LOADED: 28,
  STATUS_CANCEL: 29,
}

export const ORDER_PRICE_LEVELS = {
  SILVER: 16,
  GOLD: 17,
  WHOLESALE: 18,
  PLATINUM: 19,
  DIAMOND: 20,
  ELITE: 21,
}

export const ORDER_PICK_STATUS_LABELS = {
  [ORDER_PICK_STATUSES.STATUS_ASSIGNED]: 'Assigned',
  [ORDER_PICK_STATUSES.STATUS_PICKING]: 'Picking',
}

export const TODAY_ORDER_STATUSES = [
  ORDER_PICK_STATUSES.STATUS_NEW,
  ORDER_PICK_STATUSES.STATUS_QUEUE,
  ORDER_PICK_STATUSES.STATUS_ASSIGNED,
  ORDER_PICK_STATUSES.STATUS_PICKING,
]

export const SHIPPING_PAGE_ORDER_STATUSES = [
  ORDER_PICK_STATUSES.STATUS_PICKING_COMPLETE,
]

export const DELETEABLE_ORDER_STATUSES = [
  ORDER_PICK_STATUSES.STATUS_NEW,
  ORDER_PICK_STATUSES.STATUS_QUEUE,
  ORDER_PICK_STATUSES.STATUS_ASSIGNED,
]

export const ALL_ORDERS_STATUSES = [
  ...TODAY_ORDER_STATUSES,
  ...SHIPPING_PAGE_ORDER_STATUSES,
  ORDER_PICK_STATUSES.STATUS_REMOVE,
  ORDER_PICK_STATUSES.STATUS_NEXT_DAY,
]

export const isDeleteable = (order_pick_status) => {
  return DELETEABLE_ORDER_STATUSES.includes(order_pick_status)
}
