import { connect } from 'react-redux'
import LocationEditDetailsPage from './component'
import {
  fetchOneLocationStart,
  updateLocationStart,
  resetCurrentLocation,
  resetLocationStatuses,
} from 'store/location/actions'
import { PRINT_LABEL_LIST } from '../../../../constants/print'
import { fetchFiltersStart } from 'store/filter/actions'
import { resetModals, showModal } from 'store/modal/actions'

const mapStateToProps = (state) => ({
  item: state.location.editedItem,
  isLocationUpdated: state.location.isLocationUpdated,
  locationTypeList: state.filter.filterParams.locationTypeList,
  printLabelList: PRINT_LABEL_LIST,
  warehouseList: state.filter.filterParams.warehouseList,
  aisleList: state.filter.filterParams.aisleList,
  bayList: state.filter.filterParams.bayList,
  storageTypeList: state.filter.filterParams.storageTypeList,
})

const mapDispatchToProps = {
  fetchLocation: fetchOneLocationStart,
  submitForm: updateLocationStart,
  resetModals: resetModals,
  showModal: showModal,
  resetCurrentLocation: resetCurrentLocation,
  resetLocationStatuses: resetLocationStatuses,
  fetchFilters: fetchFiltersStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationEditDetailsPage)
