import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import query from 'querystringify'
import SelectInput from 'components/common/SelectInput'
import {
  getUrlFilter,
  getUrlParams,
  redirect,
  removePropFiltersFromObject,
} from 'helpers'
import { FILTERS, DEFAULT_VALUES, SORT_FILTERS } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import Button from 'components/common/Button'
import Toggle from 'components/common/Toggle'
import './style.scss'

const LocationListGridFilters = ({
  filters,
  onChangeFilters,
  hasFilterSelected,
}) => {
  const onChange = (name, value) => {
    const oldParams = getUrlParams()
    const newParams = value
      ? { ...oldParams, [name]: value, [SORT_FILTERS.SORT_BY_KEY]: '' }
      : Object.keys(oldParams)
          .filter(
            (filterName) =>
              filterName !== name && filterName !== SORT_FILTERS.SORT_BY_KEY,
          )
          .reduce(
            (obj, filterName) => ({
              ...obj,
              [filterName]: oldParams[filterName],
            }),
            {},
          )

    redirect(`${window.location.pathname}?${query.stringify(newParams)}`)

    onChangeFilters(newParams)
  }

  const clearFiltersOnClick = () => {
    const baseParams = getUrlParams()
    const pureParams = removePropFiltersFromObject(filters, baseParams)
    onChangeFilters(pureParams)
  }

  const [checkedOrderPalletsOnly, setCheckedOrderPalletsOnly] = useState(
    getUrlParams()[FILTERS.ORDER_PALLETS_ONLY_FILTER] == 'true',
  )

  const selectOrderPalletsOnly = () => {
    const newCheckedOrderPalletsOnly = !checkedOrderPalletsOnly
    setCheckedOrderPalletsOnly(newCheckedOrderPalletsOnly)

    const paramName = FILTERS.ORDER_PALLETS_ONLY_FILTER
    const paramValue = newCheckedOrderPalletsOnly ? 'true' : 'false'

    const oldParams = getUrlParams()
    const newParams = {
      ...oldParams,
      [paramName]: paramValue,
      [SORT_FILTERS.SORT_BY_KEY]: '',
    }

    redirect(`${window.location.pathname}?${query.stringify(newParams)}`)

    onChangeFilters(newParams)
  }

  return (
    <>
      <div className="loc-filter-section">
        <Button
          className="select"
          disabled={!hasFilterSelected()}
          onClick={() => clearFiltersOnClick()}
          text="Clear Filters"
        />
        <Toggle
          text="Order Pallets Only"
          checked={checkedOrderPalletsOnly}
          onChange={selectOrderPalletsOnly}
          name={FILTERS.ORDER_PALLETS_ONLY_FILTER}
          value={getUrlFilter(FILTERS.ORDER_PALLETS_ONLY_FILTER)}
        />
      </div>

      <div className="filter-section">
        <SelectInput
          key={FILTERS.LOCATION_TYPE_FILTER}
          name={FILTERS.LOCATION_TYPE_FILTER}
          value={parseInt(getUrlFilter(FILTERS.LOCATION_TYPE_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.LOCATION_TYPE_FILTER]}
          items={filters[FILTERS.LOCATION_TYPE_FILTER]}
          onChange={onChange}
        />
        <SelectInput
          key={FILTERS.AISLE_FILTER}
          name={FILTERS.AISLE_FILTER}
          disabled={
            parseInt(getUrlParams()[FILTERS.LOCATION_TYPE_FILTER]) ===
            LOCATION_TYPE.DOCK_TYPE_ID
          }
          value={parseInt(getUrlFilter(FILTERS.AISLE_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.AISLE_FILTER]}
          items={filters[FILTERS.AISLE_FILTER]}
          onChange={onChange}
        />
        <SelectInput
          key={FILTERS.BAY_FILTER}
          name={FILTERS.BAY_FILTER}
          disabled={
            parseInt(getUrlParams()[FILTERS.LOCATION_TYPE_FILTER]) ===
            LOCATION_TYPE.DOCK_TYPE_ID
          }
          value={parseInt(getUrlFilter(FILTERS.BAY_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.BAY_FILTER]}
          items={filters[FILTERS.BAY_FILTER]}
          onChange={onChange}
        />
        <SelectInput
          key={FILTERS.LEVEL_FILTER}
          name={FILTERS.LEVEL_FILTER}
          disabled={
            parseInt(getUrlParams()[FILTERS.LOCATION_TYPE_FILTER]) ===
            LOCATION_TYPE.DOCK_TYPE_ID
          }
          value={parseInt(getUrlFilter(FILTERS.LEVEL_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.LEVEL_FILTER]}
          items={filters[FILTERS.LEVEL_FILTER]}
          onChange={onChange}
        />
      </div>
    </>
  )
}

LocationListGridFilters.propTypes = {
  filters: PropTypes.object,
  hasFilterSelected: PropTypes.func,

  onChangeFilters: PropTypes.func,
}

export default LocationListGridFilters
