import { connect } from 'react-redux'
import ContainerDetailsPage from '../component'
import {
  fetchOneContainerProcessStart,
  updateContainerProcessStart,
} from 'store/containerProcess/actions'
import { showModal, resetModals } from 'store/modal/actions'
import { createUnloadProcess } from 'store/unloadProcess/actions'
import { CONTAINER_STATUS } from 'constants/containerStatuses'
import {
  CONTAINER_LIST_PAGE_TYPE_RECEIVING,
  CONTAINER_LIST_PAGE_TYPE_UNLOADING,
} from 'constants/common'

const pageSelector = (store) => {
  if (!store.containerProcess) {
    return ''
  }

  const { item } = store.containerProcess
  const isNotPrepareToUnload =
    item.container_status &&
    item.container_status.id >= CONTAINER_STATUS.PREPARE_TO_UNLOAD
  const text = isNotPrepareToUnload ? 'Unloading' : 'Receiving'

  return {
    title: `${text} Details`,
    description: `View/Edit ${text} Details`,
    type: isNotPrepareToUnload
      ? CONTAINER_LIST_PAGE_TYPE_UNLOADING
      : CONTAINER_LIST_PAGE_TYPE_RECEIVING,
  }
}

const mapStateToProps = (store) => ({
  item: store.containerProcess.item,
  page: pageSelector(store),
})

const mapDispatchToProps = {
  changeItem: updateContainerProcessStart,
  fetchItem: fetchOneContainerProcessStart,
  showModal: showModal,
  resetModals: resetModals,
  createUnloadProcess: createUnloadProcess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerDetailsPage)
