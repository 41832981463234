export const types = {
  CREATE_SHIPMENT_START: 'CREATE_SHIPMENT_START',
  CREATE_SHIPMENT_SUCCESS: 'CREATE_SHIPMENT_SUCCESS',

  MARK_AS_PRINTED_START: 'MARK_AS_PRINTED_START',
  MARK_AS_PRINTED_SUCCESS: 'MARK_AS_PRINTED_SUCCESS',

  FETCH_SHIPMENT_LIST_START: 'FETCH_SHIPMENT_LIST_START',
  FETCH_SHIPMENT_LIST_SUCCESS: 'FETCH_SHIPMENT_LIST_SUCCESS',

  FETCH_SHIPMENT_ERROR_START: 'FETCH_SHIPMENT_ERROR_START',
  FETCH_SHIPMENT_ERROR_SUCCESS: 'FETCH_SHIPMENT_ERROR_SUCCESS',
  
  VOID_SHIPMENT_START: 'VOID_SHIPMENT_START',
  VOID_SHIPMENT_SUCCESS: 'VOID_SHIPMENT_SUCCESS',
}

export const voidShipmentStart = (payload) => ({
  type: types.VOID_SHIPMENT_START,
  payload,
})

export const voidShipmentSuccess = (payload) => ({
  type: types.VOID_SHIPMENT_SUCCESS,
  payload,
})

export const fetchShipmentListStart = (payload) => ({
  type: types.FETCH_SHIPMENT_LIST_START,
  payload,
})

export const fetchShipmentListSuccess = (payload) => ({
  type: types.FETCH_SHIPMENT_LIST_SUCCESS,
  payload,
})

export const fetchShipmentErrorStart = (payload) => ({
  type: types.FETCH_SHIPMENT_ERROR_START,
  payload,
})

export const fetchShipmentErrorSuccess = (payload) => ({
  type: types.FETCH_SHIPMENT_ERROR_SUCCESS,
  payload,
})

export const createShipmentStart = (payload) => ({
  type: types.CREATE_SHIPMENT_START,
  payload,
})

export const createShipmentSuccess = (payload) => ({
  type: types.CREATE_SHIPMENT_SUCCESS,
  payload,
})

export const markAsPrintedStart = (payload) => ({
  type: types.MARK_AS_PRINTED_START,
  payload,
})

export const markAsPrintedSuccess = (payload) => ({
  type: types.MARK_AS_PRINTED_SUCCESS,
  payload,
})
