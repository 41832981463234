import { types } from './actions'

const INIT = {
  item: {},
  list: [],
}

const restockProcessReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_RESTOCK_PROCESS_LIST_START:
      return {
        ...state,
        list: [],
      }
    case types.FETCH_RESTOCK_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_RESTOCK_PROCESS_LIST_FAIL:
      return INIT
    case types.FETCH_ONE_RESTOCK_PROCESS_START:
      return { ...state }
    case types.FETCH_ONE_RESTOCK_PROCESS_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case types.FETCH_ONE_RESTOCK_PROCESS_FAIL:
      return {
        ...state,
        item: {},
      }
    default:
      return state
  }
}

export default restockProcessReducer
