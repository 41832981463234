import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import PalletSm from '../../../assets/svg/pallet-sm.svg'
import './pallet_icon_style.scss'

export function PalletWithCount(props) {
  const pallet = props
  return (
    <Link
      style={{ width: '33%', padding: '0px' }}
      key={pallet.id}
      to={ROUTES.RESTOCK_TASK_DETAIL_PAGE_WITH_ID.replace(':id', pallet.id)}
    >
      <div className="pallet-icon-image-wrapper">
        <span className="pallet-text-span">
          <p className="pallet-icon-p">{pallet.qty_cases_on_pallet}</p>
        </span>

        <PalletSm className="pallet-icon-svg"></PalletSm>
        <p className="pallet-icon-p">{pallet.pallet_upc_short}</p>
      </div>
    </Link>
  )
}

export default PalletWithCount
