import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetch = (payload) =>
  request({
    url: API_ROUTE.APP_INFO.FETCH(payload.project_id),
    method: 'GET',
  })

export const testException = () =>
  request({
    url: API_ROUTE.APP_INFO.TEST_EXCEPTION(),
    method: 'GET',
  })
