import React from 'react'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ENVIRONMENT_PROD } from '../../../constants/common'

const EnvironmentBanner = ({ appInfo }) => {
  const { environment_key, environment_name, color, font_color } = appInfo
  const bannerDisplay = environment_name ? 'block' : 'none'
  const bannerStyle = {
    backgroundColor: color || 'blue',
    display: bannerDisplay,
  }
  const nameStyle = {
    color: font_color || 'white',
  }

  return environment_key === ENVIRONMENT_PROD ? (
    ''
  ) : (
    <div className="environment-banner" style={bannerStyle}>
      <span className="environment-name" style={nameStyle}>
        {environment_name}
      </span>
    </div>
  )
}

EnvironmentBanner.propTypes = {
  environment_name: PropTypes.string,
}
const mapStateToProps = (state) => ({
  appInfo: state.appInfo.item,
})

export default connect(mapStateToProps)(EnvironmentBanner)
