import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import SelectInput from 'components/common/SelectInput'
import TextInput from 'components/common/TextInput'
import Switch from 'components/common/Switch'
import SubmitControl from 'components/common/FormElements/SubmitControl'
import query from 'querystringify'
import { ODD_EVEN_SWITCH_ITEMS } from 'constants/switchItems'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'
import { FILTERS } from 'constants/filters'
import {
  AISLE_FIELD,
  BAY_RANGE_SWITCH_FIELD,
  BAY_RANGE_TYPE_FIELD,
  LEVEL_FIELD,
  WAREHOUSE_FIELD,
} from 'constants/formFields'
import { HORIZONTAL, VERTICAL, LEVEL_1 } from 'constants/location'
import RangePicker from 'components/common/RangePicker'
import Joi from '@hapi/joi'
import { splitArrayByRanges } from 'helpers'
import { REGEX } from 'constants/regex'

const DEFAULT_FORM_FIELDS = {
  [AISLE_FIELD]: ITEMS_LIST_DEFAULT_ITEM_ID,
  pallets_capacity: 1,
  [LEVEL_FIELD]: '',
  label_orientation: HORIZONTAL,
  [BAY_RANGE_TYPE_FIELD]: [],
  [BAY_RANGE_SWITCH_FIELD]: 'Both',
}

const FORM_RULES = {
  [AISLE_FIELD]: Joi.number().integer(),
  pallets_capacity: Joi.number().integer(),
  label_orientation: Joi.string().valid(HORIZONTAL, VERTICAL).required(),
  [LEVEL_FIELD]: Joi.number().integer(),
  [BAY_RANGE_SWITCH_FIELD]: Joi.string(),
  [BAY_RANGE_TYPE_FIELD]: Joi.array(),
}

const DROPDOWN_MAX_HEIGHT = 160

export default class LocationGroupAddLocationForm extends Component {
  static propTypes = {
    aisleList: PropTypes.array,
    availableBayList: PropTypes.array,

    onSubmit: PropTypes.func,
    fetchLocationFilters: PropTypes.func,
    fetchAvailableBayList: PropTypes.func,
  }

  state = {
    form: {
      fields: Object.assign({}, DEFAULT_FORM_FIELDS),
      rules: Object.assign({}, FORM_RULES),
      error: {
        fields: [],
      },
    },
    isRangePickerShown: false,
  }

  constructor(props) {
    super(props)

    this.closeRangePicker = this.closeRangePicker.bind(this)
    this.openRangePicker = this.openRangePicker.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps
    const { form } = prevState

    if (data) {
      form.fields = data

      return {
        ...prevState,
        form,
      }
    }

    return prevState
  }

  componentDidMount() {
    const { fetchLocationFilters } = this.props

    fetchLocationFilters([FILTERS.AISLE_FILTER])
  }

  isFieldsFilled = () => {
    const { form } = this.state

    return (
      !!form.fields[AISLE_FIELD] &&
      !!form.fields.pallets_capacity &&
      !!form.fields[LEVEL_FIELD] &&
      !!form.fields[BAY_RANGE_TYPE_FIELD].length
    )
  }

  onChange = (name, value) => {
    let { fetchAvailableBayList } = this.props
    let { form } = this.state

    form.fields[name] = value === null ? '' : value
    form.error.fields = form.error.fields.filter((value) => value !== name)

    Joi.validate({ [name]: value }, { [name]: form.rules[name] }, (error) => {
      if (error !== null) {
        form.error.fields.push(name)
      }
    })

    // Addition func's when field is changed
    switch (name) {
      case LEVEL_FIELD:
      case AISLE_FIELD:
        const warehouseId = parseInt(
          query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER],
        )
        const levelObject = form.fields[LEVEL_FIELD]
          ? { [LEVEL_FIELD]: form.fields[LEVEL_FIELD] }
          : {}

        fetchAvailableBayList({
          ...{
            [WAREHOUSE_FIELD]: warehouseId,
            [AISLE_FIELD]: form.fields[AISLE_FIELD],
          },
          ...levelObject,
        })
        form.fields[BAY_RANGE_TYPE_FIELD] = []
        break
      case BAY_RANGE_SWITCH_FIELD:
        form.fields[BAY_RANGE_TYPE_FIELD] = []
        break
      default:
        break
    }

    if (form.fields.level === LEVEL_1) {
      form.fields.label_orientation = VERTICAL
    }

    this.setState({ form })
  }

  onSubmit = () => this.props.onSubmit(this.state.form.fields)

  getBayRange = (type) => {
    const { availableBayList } = this.props

    if (type === 'Both') {
      return availableBayList
    }

    let oddBays = []
    let evenBays = []

    availableBayList.forEach((item) => {
      if (item % 2) {
        oddBays.push(item)
      } else {
        evenBays.push(item)
      }
    })

    return type === 'Even' ? evenBays : oddBays
  }

  closeRangePicker = () => this.setState({ isRangePickerShown: false })
  openRangePicker = () => this.setState({ isRangePickerShown: true })
  isInvalidField = (field) => this.state.form.error.fields.indexOf(field) > -1

  render() {
    const { aisleList } = this.props
    const {
      form: { fields },
      isRangePickerShown,
    } = this.state

    const bayList = this.getBayRange(fields[BAY_RANGE_SWITCH_FIELD])

    return (
      <form>
        <div className="form">
          <div className="form__row">
            <div className="title">Aisle</div>
            <SelectInput
              tabIndex="1"
              name={AISLE_FIELD}
              value={fields[AISLE_FIELD]}
              defaultValue="- Select -"
              items={aisleList}
              onChange={this.onChange}
              styleOptions={{
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: DROPDOWN_MAX_HEIGHT,
                }),
              }}
            />
          </div>
          <div className="form__main-column">
            <div className="form__row">
              <div className="title">Level</div>
              <div>
                <TextInput
                  tabIndex="2"
                  name={LEVEL_FIELD}
                  value={fields[LEVEL_FIELD]}
                  placeholder="Enter Level"
                  onChange={this.onChange}
                  pattern={REGEX.IS_LEVEL_FIELD}
                />
              </div>
            </div>
            <div className="form__row">
              <div>
                <Switch
                  tabIndex="4"
                  name={BAY_RANGE_SWITCH_FIELD}
                  items={ODD_EVEN_SWITCH_ITEMS}
                  selected={fields[BAY_RANGE_SWITCH_FIELD]}
                  onChange={this.onChange}
                  className="switch--multiple"
                />
              </div>
            </div>
          </div>

          <div className="form__main-column">
            <div className="form__row">
              <div className="title">Pallet Capacity</div>
              <div>
                <TextInput
                  tabIndex="3"
                  name="pallets_capacity"
                  value={fields.pallets_capacity}
                  placeholder="Enter Capacity"
                  isInvalid={this.isInvalidField('pallets_capacity')}
                  onChange={this.onChange}
                  pattern={REGEX.IS_PALLETS_CAPACITY_FIELD}
                />
              </div>
            </div>
            <div className="form__row form__row--auto-margin">
              <div className="title">Bay Range</div>
              <div className="text">
                {fields[BAY_RANGE_TYPE_FIELD].length
                  ? splitArrayByRanges(fields[BAY_RANGE_TYPE_FIELD])
                  : ''}
              </div>
              <div>
                {bayList.length === 0 ? (
                  <span>No Available Bays</span>
                ) : (
                  <>
                    <span
                      onClick={this.openRangePicker}
                      className="modal__trigger"
                    >
                      {fields[BAY_RANGE_TYPE_FIELD].length > 0
                        ? 'Edit'
                        : 'Select Available Bay (s)'}
                    </span>
                    <RangePicker
                      title="Available Bays"
                      name={BAY_RANGE_TYPE_FIELD}
                      items={this.getBayRange(fields[BAY_RANGE_SWITCH_FIELD])}
                      selectedItems={fields[BAY_RANGE_TYPE_FIELD]}
                      isOpen={isRangePickerShown}
                      onSubmit={this.onChange}
                      onClose={this.closeRangePicker}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="form__row form__footer">
            <div className="form__footer__confirm">
              <SubmitControl
                tabIndex="6"
                text="Add locations"
                className="form__footer__confirm"
                onClick={this.onSubmit}
                disabled={!this.isFieldsFilled()}
              />
            </div>
          </div>
        </div>
      </form>
    )
  }
}
