import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { fetchFiltersStart } from 'store/filter/actions'
import { fetchContainerProcessListStart } from 'store/containerProcess/actions'
import { showModal } from 'store/modal/actions'
import { FILTERS } from 'constants/filters'
import query from 'querystringify'
import { getUrlParams, redirect } from 'helpers/index'
import SelectInput from 'components/common/SelectInput'
import { MODAL_PENDING } from 'components/common/Modal'

const ContainersListTopFilters = (props) => {
  const {
    warehouseList = [],
    customerList = [],

    pageType = '',

    showModal,
    fetchFilters,
    fetchListWithFilters,
  } = props

  const [radio, setRadio] = useState({
    warehouse: true,
    customer: false,
  })

  const onMount = () => {
    fetchFilters([FILTERS.WAREHOUSE_FILTER])
  }

  useEffect(onMount, [])

  const onChange = (name, value) => {
    const params = {
      ...getUrlParams(),
      [name]: value,
    }

    const queryString = query.stringify(
      {
        ...params,
        [FILTERS.CONTAINER_LIST_TYPE_FILTER]: pageType,
      },
      true,
    )

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })
    fetchListWithFilters(queryString)

    redirect(`${window.location.pathname}?${query.stringify(params)}`)
  }

  const onSelectRadioWarehouse = () =>
    setRadio({
      warehouse: true,
      customer: false,
    })
  const onSelectRadioCustomer = () =>
    setRadio({
      warehouse: false,
      customer: true,
    })

  const warehouseId = parseInt(getUrlParams()[FILTERS.WAREHOUSE_FILTER])

  return (
    <div className="content-header__menu">
      <div className="content-header__select">
        <input
          className="radio"
          id="warehouse-input"
          type="radio"
          name="filter"
          value="warehouse"
          checked={radio.warehouse}
          onChange={(e) => ''}
          onClick={onSelectRadioWarehouse}
        />
        <label className="radio__label" htmlFor="warehouse-input">
          &nbsp;
        </label>
        <SelectInput
          disabled={radio.customer}
          name={FILTERS.WAREHOUSE_FILTER}
          value={warehouseId}
          defaultValue="- Select Warehouse -"
          items={warehouseList}
          onChange={onChange}
        />
      </div>
      <div className="content-header__select">
        <input
          className="radio"
          id="customer-input"
          type="radio"
          name="filter"
          value=""
          checked={radio.customer}
          onChange={(e) => ''}
          onClick={onSelectRadioCustomer}
        />
        <label className="radio__label" htmlFor="customer-input">
          &nbsp;
        </label>
        <SelectInput
          disabled={radio.warehouse}
          name={FILTERS.CUSTOMER_FILTER}
          value={getUrlParams()[FILTERS.CUSTOMER_FILTER]}
          defaultValue="- Select Customer -"
          items={customerList}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

ContainersListTopFilters.propTypes = {
  warehouseList: PropTypes.array,
  customerList: PropTypes.array,

  pageType: PropTypes.string,

  showModal: PropTypes.func,
  fetchFilters: PropTypes.func,
  fetchListWithFilters: PropTypes.func,
}

const mapStateToProps = (state) => ({
  warehouseList: state.filter.filterParams.warehouseList,
})

const mapDispatchToProps = {
  fetchFilters: fetchFiltersStart,
  fetchListWithFilters: fetchContainerProcessListStart,
  showModal: showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainersListTopFilters)
