import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.FILTER.FETCH_MANY,
    method: 'GET',
    params: {
      filters: payload,
    },
  })
