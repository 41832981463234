import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Checkbox from '../Checkbox'
import Button from '../Button'
import Modal from 'react-modal'
import './style.scss'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
    minHeight: '600px',
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '351px',
  },
}

const RangePicker = (props) => {
  const {
    items = [],
    selectedItems = [],

    name = '',
    title = '',

    onClose,
    onSubmit,

    isOpen,
  } = props

  const [selectedList, setSelectedList] = useState([])

  const [hoveredItem, setHoveredItem] = useState(null)
  const [firstSelectedItem, setFirstSelectedItem] = useState(null)

  const [isKeyPressed, setIsKeyPressed] = useState(false)
  const [wasFilledEarlier, setWasFilledEarlier] = useState(false)

  useEffect(() => {
    if (wasFilledEarlier && selectedItems.length === 0) {
      setSelectedList([])
      setWasFilledEarlier(!wasFilledEarlier)
    }
  }, [wasFilledEarlier, selectedItems])

  const onSelect = (e, selectedItem) => {
    let tempSelectedList = [...selectedList]

    tempSelectedList =
      selectedList.indexOf(selectedItem) === -1
        ? [...tempSelectedList, selectedItem]
        : tempSelectedList.filter((item) => item !== selectedItem)

    if (e.shiftKey) {
      let indexFirst = items.indexOf(
        items.find((item) => item === firstSelectedItem),
      )
      let indexLast = items.indexOf(items.find((item) => item === selectedItem))

      if (indexFirst > indexLast) {
        ;[indexFirst, indexLast] = [indexLast, indexFirst]
      }

      for (let i = indexFirst + 1; i < indexLast; i++) {
        if (tempSelectedList.indexOf(items[i]) === -1) {
          tempSelectedList = [...tempSelectedList, items[i]]
        }
      }
    }

    setSelectedList(tempSelectedList)
    setFirstSelectedItem(selectedItem)
  }

  const onHover = (e, item) => {
    setHoveredItem(item)
    setIsKeyPressed(!!e.shiftKey)
  }

  const onSubmitRange = () => {
    onClose()
    onSubmit(name, selectedList)
    setWasFilledEarlier(true)
  }

  const selectAll = () =>
    setSelectedList(selectedList.length !== items.length ? [...items] : [])

  const getRangeList = () =>
    items.map((item, index) => {
      const isPrevSelected =
        !!items[index - 1] && selectedList.indexOf(items[index - 1]) > -1
      const isNextSelected =
        !!items[index + 1] && selectedList.indexOf(items[index + 1]) > -1
      const isCurrentSelected = selectedList.indexOf(item) > -1

      const isItemBetween =
        isPrevSelected && isNextSelected && isCurrentSelected
      const isRangeBorderItem =
        isCurrentSelected && (isNextSelected || isPrevSelected)
      const isLeftRangeBorderItem =
        isCurrentSelected && isNextSelected && !isPrevSelected
      const isRightRangeBorderItem =
        isCurrentSelected && isPrevSelected && !isNextSelected
      const isItemsRange =
        firstSelectedItem < hoveredItem
          ? !!firstSelectedItem &&
            item <= hoveredItem &&
            item >= firstSelectedItem &&
            isKeyPressed
          : !!firstSelectedItem &&
            item >= hoveredItem &&
            item <= firstSelectedItem &&
            isKeyPressed

      const itemClassName = `range__item ${
        isCurrentSelected && !isItemBetween ? 'range__item--selected' : ''
      } ${
        isItemsRange || isItemBetween || isRangeBorderItem
          ? 'range__item--intermediate'
          : ''
      } ${
        isLeftRangeBorderItem
          ? 'range__item--first'
          : isRightRangeBorderItem
          ? 'range__item--last'
          : ''
      }`.trim()

      const handleHover = (e) => onHover(e, item)
      const handleSelect = (e) => onSelect(e, item)

      return (
        <div
          key={item}
          className={itemClassName}
          onMouseEnter={handleHover}
          onClick={handleSelect}
        >
          <div className="text">{item}</div>
        </div>
      )
    })

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="range">
        <div className="header">
          <span className="text text--bold">{title}</span>
          <div onClick={onClose} className="icon--close" />
          <div className="count">{selectedList.length} Selected</div>
        </div>
        <div className="range__field">{getRangeList()}</div>
        <div className="footer">
          <div className="select-all">
            <Checkbox
              checked={selectedList.length === items.length}
              onClick={selectAll}
            />
            <span className="text">Select All</span>
          </div>
          <Button
            onClick={onSubmitRange}
            disabled={selectedList.length === 0}
            text="OK"
          />
        </div>
      </div>
    </Modal>
  )
}

RangePicker.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,

  name: PropTypes.string,
  title: PropTypes.string,

  onClose: PropTypes.func,
  onSubmit: PropTypes.func,

  isOpen: PropTypes.bool,
}

export default RangePicker
