import { types } from './actions'

const INIT = {
  printDialogOpen: false,
  printData: null,
  printerLibrary: null,
  allPrinters: [],
}

const printReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.OPEN_PRINT_DIALOG:
      return {
        ...state,
        printDialogOpen: true,
        printData: payload,
      }
    case types.CLOSE_PRINT_DIALOG:
      return {
        ...state,
        printDialogOpen: false,
        printData: null,
      }
    case types.FETCH_PRINTER_LIBRARY_SUCCESS:
      return {
        ...state,
        printerLibrary: payload.printer_library,
        allPrinters: payload.all_printers,
      }
    default:
      return state
  }
}

export default printReducer
