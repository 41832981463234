export const types = {
  FETCH_STAFF_ASSIGNMENT_START: 'FETCH_STAFF_ASSIGNMENT_START',
  FETCH_STAFF_ASSIGNMENT_SUCCESS: 'FETCH_STAFF_ASSIGNMENT_SUCCESS',
  FETCH_STAFF_ASSIGNMENT_FAIL: 'FETCH_STAFF_ASSIGNMENT_FAIL',
}

export const fetchStaffAssignmentStart = (payload) => ({
  type: types.FETCH_STAFF_ASSIGNMENT_START,
  payload,
})

export const fetchStaffAssignmentSuccess = (payload) => ({
  type: types.FETCH_STAFF_ASSIGNMENT_SUCCESS,
  payload,
})

export const fetchStaffAssignmentFail = (payload) => ({
  type: types.FETCH_STAFF_ASSIGNMENT_FAIL,
  payload,
})
