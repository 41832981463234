import React, { useState, useEffect } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import ArrowRoundedIcon from 'assets/svg/arrow-rounded.svg'
import CloseCross from 'assets/svg/big-cross.svg'
import LoginForm from 'components/features/Auth/LoginPage/LoginForm'
import CheckUserForm from 'components/features/Auth/LoginPage/CheckUserForm'
import NewPinForm from 'components/features/Auth/LoginPage/NewPinForm'
import CheckUserFormWithoutUsername from 'components/features/Auth/LoginPage/CheckUserFormWithoutUsername'
import { resetModals, showModal } from 'store/modal/actions'
import { isEmptyObject } from 'helpers/index'
import { resetUser } from 'store/auth/actions'
import Button from 'components/common/Button'
import PageContent from 'components/common/PageContent'

const LoginPage = ({ user, showModal, modal, resetModals, resetUser }) => {
  const [currentFormType, setCurrentFormType] = useState('login')

  useEffect(() => {
    if (
      (currentFormType === 'checkUserWithoutUsername' ||
        currentFormType === 'checkUser') &&
      user.staff_id
    ) {
      setCurrentFormType('newPinForm')
    }

    if (modal.name === 'success') {
      setTimeout(() => {
        resetModals()
        resetUser()
        setCurrentFormType('login')
      }, 2000)
    }
  }, [currentFormType, user.staff_id, modal, resetModals, resetUser])

  useEffect(() => {
    resetModals()
  }, [])

  const onClick = () =>
    showModal({
      name: 'confirm-default',
      content: <div className="text">Quit Editing?</div>,
      events: {
        onDeny: resetModals,
        onAccept: () => {
          switchForm('login')
          resetModals()
        },
      },
      options: {
        className: 'modal--login',
      },
    })

  const switchForm = (formName) => setCurrentFormType(formName)

  const getFormType = () => {
    switch (currentFormType) {
      case 'checkUser':
        return (
          <>
            <div className="close" onClick={onClick}>
              <CloseCross />
            </div>
            <CheckUserForm />
          </>
        )
      case 'checkUserWithoutUsername':
        return (
          <>
            <div className="close" onClick={onClick}>
              <CloseCross />
            </div>
            <CheckUserFormWithoutUsername />
          </>
        )
      case 'newPinForm':
        return (
          <>
            <div className="close" onClick={onClick}>
              <CloseCross />
            </div>
            <NewPinForm />
          </>
        )
      case 'login':
        const onBtnClick = () =>
          switchForm(
            isEmptyObject(user) ? 'checkUser' : 'checkUserWithoutUsername',
          )

        return (
          <>
            <LoginForm />
            {/* <div className="page-login__footer">
              {currentFormType === 'login' ? (
                <Button
                  onClick={onBtnClick}
                  tabIndex="4"
                  text={
                    <>
                      Reset PIN
                      <ArrowRoundedIcon className="svg" />
                    </>
                  }
                />
              ) : (
                ''
              )}
            </div> */}
          </>
        )
      default:
        return ''
    }
  }

  return <PageContent className="page page-login">{getFormType()}</PageContent>
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  modal: state.modal,
})

const mapDispatchToProps = {
  resetModals,
  resetUser,
  showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
