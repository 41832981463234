import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import './style.scss'
import { MODAL_SUCCESS } from 'components/common/Modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '315px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '550px',
  },
}

const ModalSuccess = ({ isOpen, modal }) =>
  isOpen ? (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="modal modal--success">
        <div className="check-mark draw" />
        <div className="modal--info__text">{modal.content}</div>
      </div>
    </Modal>
  ) : (
    ''
  )

ModalSuccess.propTypes = {
  modal: PropTypes.object,

  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_SUCCESS,
  ),
  modal: state.modal.openModalsInfo.find((item) => item.name === MODAL_SUCCESS),
})

export default connect(mapStateToProps, null)(ModalSuccess)
