import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import SideBarSubItem from 'components/common/SideBar/SideBarItem/SideBarSubItem'
import { TOGGLE_SIDEBAR_ITEMS } from 'constants/events'
import { getUrlStringWithMandatoryParams, redirect } from 'helpers'

const SideBarItem = ({ item }) => {
  const [isSelected, setIsSelected] = useState(false)

  const onHideSubItems = () => setIsSelected(false)

  const onMount = () => {
    window.addEventListener(TOGGLE_SIDEBAR_ITEMS, onHideSubItems)

    if (item.link && window.location.href.indexOf(item.link) > -1) {
      setIsSelected(true)
    } else if (item.subItems.length > 0) {
      item.subItems.forEach((subItem) => {
        if (subItem.link && window.location.href.indexOf(subItem.link) > -1) {
          setIsSelected(true)
        }
      })
    } else {
      setIsSelected(false)
    }

    return () => {
      window.removeEventListener(TOGGLE_SIDEBAR_ITEMS, onHideSubItems)
    }
  }

  useEffect(onMount)

  const onToggle = () => {
    window.dispatchEvent(new Event(TOGGLE_SIDEBAR_ITEMS))

    setIsSelected(!isSelected)
  }

  const onSelectItem = () => {
    if (!item.link && !window.location.href.indexOf(item.link) > -1) {
      return
    }

    redirect(`${item.link}?${getUrlStringWithMandatoryParams()}`)
  }

  const sideBarItemClassName = `${isSelected ? 'nav__item--selected' : ''} ${
    !item.isActive ? 'blurred' : ''
  }`

  return (
    <div onClick={onSelectItem} className={sideBarItemClassName}>
      <div className={`nav__item ${isSelected ? 'nav__item--active' : ''}`}>
        <div className="title" onClick={onToggle}>
          <div className="icon__wrapper">
            <div className={!item.iconItem ? item.iconClass : ''}>
              {item.iconItem && item.iconItem}
            </div>
          </div>
          <p className="text">
            {item.content}
            {item.isBadged && <span className="badge--point" />}
          </p>
          <div className="label">{item.label}</div>
        </div>
        {item.subItems.length > 0 && isSelected && (
          <ul className="list">
            {item.subItems.map((subItem) => (
              <SideBarSubItem key={subItem.id} item={subItem} />
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

SideBarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,

    iconClass: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,

    onClick: PropTypes.func,

    isBadged: PropTypes.bool,
    isActive: PropTypes.bool,

    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        shortContent: PropTypes.string,
        link: PropTypes.string,
        label: PropTypes.string,

        badge: PropTypes.number,
      }),
    ),
  }),
}

export default SideBarItem
