import React from 'react'
import Auth from 'components/features/Auth'
import query from 'querystringify'
import history from '../history'
import axios from 'axios'
import moment from 'moment'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'
import { DEFAULT_URL_PARAMS_LIST } from 'constants/urlDefaultParams'
import { ROUTES } from 'constants/routes'
import { HTTP_CODE } from 'constants/httpCodes'
import { FILTERS } from 'constants/filters'
import { resetUser } from 'store/auth/actions'
import { store } from '../index'

const getToken = () => {
  const state = localStorage.getItem('state')
  const serializedState = JSON.parse(state)

  if (serializedState.auth) {
    return serializedState.auth.user.token
  } else {
    return ''
  }
}

const request = (requestOptions = {}) => {
  requestOptions.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getToken()}`,
    ...requestOptions.headers,
  }

  // For backend debug
  // const session = 'XDEBUG_SESSION_START=18044';
  // requestOptions.url = requestOptions.url.indexOf('?') > -1
  //   ? `${requestOptions.url}&${session}`
  //   : `${requestOptions.url}?${session}`;

  return axios(requestOptions)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (!error.response) {
        store.dispatch(resetUser())
        redirect(ROUTES.LOGIN_PAGE)
      }

      if (error.response.status === HTTP_CODE.UNAUTHORIZED) {
        redirect(ROUTES.LOGIN_PAGE)
      }

      throw error
    })
}

export default request

export const saveState = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state))
  } catch (error) {
    console.error("Can't save state to localStorage!")
  }
}

// Selector of reducers, which we want to store in LocalStorage
export const stateToStorageSelector = (state) => ({
  auth: state.auth,
  version: state.version,
})

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')

    if (serializedState === null) {
      return {}
    }

    const state = JSON.parse(serializedState)

    saveState(state)

    return state
  } catch (error) {
    return {}
  }
}

export const isAuthorized = (Component) => {
  return (props) => <Auth {...props} Component={Component} />
}

export const cleanFilters = (items) => {
  let cleanedItems = {}

  for (let name in items) {
    if (items[name] !== ITEMS_LIST_DEFAULT_ITEM_ID) {
      Object.assign(cleanedItems, { [name]: items[name] })
    }
  }

  return cleanedItems
}

export const validate = (value, rule) => rule.test(value)

export const redirect = (route) => history.push(route)

export const mapItem = (item, mapper) => {
  if (!item) {
    return
  }

  const normalizedItem = {}

  for (let key in mapper) {
    if (!mapper[key].match(/\./)) {
      if (item[mapper[key]]) {
        normalizedItem[key] = item[mapper[key]]
      }
    } else {
      let mapKeyArr = mapper[key].split('.')
      let tmpValue = ''
      let depth = 0

      for (let i = 0; i < mapKeyArr.length; i++) {
        if (tmpValue[mapKeyArr[i]]) {
          tmpValue = tmpValue[mapKeyArr[i]]
        } else if (item[mapKeyArr[i]] && !depth) {
          tmpValue = item[mapKeyArr[i]]
        }

        depth++
      }

      normalizedItem[key] = tmpValue
    }
  }

  return normalizedItem
}

export const date = (value) => new Date(moment(value))
export const dateAddDays = (value, numberOfDays) =>
  new Date(moment(value).add(numberOfDays, 'd'))
export const dateToFormat = (value, format) => moment(value).format(format)

export const isEmptyObject = (object) =>
  Object.entries(object).length === 0 && object.constructor === Object
export const isEqualObjects = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2)
export const isArrayContainsObject = (object, array) => {
  for (let i = 0; i < array.length; i++) {
    if (isEqualObjects(array[i], object)) {
      return true
    }
  }

  return false
}
export const removePropFromObject = (prop, obj) =>
  Object.keys(obj).reduce((object, key) => {
    if (key !== prop) {
      object[key] = obj[key]
    }
    return object
  }, {})

export const removePropFiltersFromObject = (filtersObject, obj) => {
  const refinedFilterKeys = () => {
    let filterKeys = [...Object.keys(filtersObject), 'sortBy']
    if (filterKeys.includes('w')) {
      const indexOfW = filterKeys.indexOf('w')
      filterKeys.splice(indexOfW, 1)
    }
    return filterKeys
  }
  let arrayOfFilters = refinedFilterKeys()
  let trimmedObj = obj
  for (let i = 0; i < arrayOfFilters.length; i++) {
    trimmedObj = removePropFromObject(arrayOfFilters[i], trimmedObj)
  }
  return trimmedObj
}

export const splitArrayByRanges = (array) => {
  const sortedArray = array.sort((a, b) => a - b)

  let resultArray = []
  let first = null

  for (let i = 0; i < sortedArray.length; i++) {
    first = first ? first : sortedArray[i]

    if (sortedArray[i] + 1 !== sortedArray[i + 1]) {
      if (!sortedArray[i + 1] && !first) {
        resultArray.push(sortedArray[i])
      } else {
        resultArray.push(
          first === sortedArray[i] ? first : `${first}-${sortedArray[i]}`,
        )
      }

      first = null
    }
  }

  return resultArray.join('; ')
}

export const haveDefaultUrlParams = (params) =>
  params.length !== 0
    ? Object.keys(params).map(
        (param) => DEFAULT_URL_PARAMS_LIST.indexOf(param) > -1,
      ).length >= DEFAULT_URL_PARAMS_LIST.length
    : false

export const getUrlParams = () => query.parse(window.location.search)
export const getUrlFilter = (name) =>
  getUrlParams()[name] || ITEMS_LIST_DEFAULT_ITEM_ID

export const getUrlStringWithMandatoryParams = () => {
  if (!Object.entries || !Object.fromEntries) {
    Object.entries = function (obj) {
      let ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i)
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

      return resArray
    }
    Object.fromEntries = function (iterable) {
      return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})
    }
  }

  return query.stringify(
    Object.fromEntries(
      Object.entries(getUrlParams()).filter(
        ([key]) =>
          key === FILTERS.WAREHOUSE_FILTER || key === FILTERS.CUSTOMER_FILTER,
      ),
    ),
  )
}

export const getUniqueId = () => Date.now() + Math.random()

export const getOneOf = (...params) => params.find((item) => !!item === true)
