import { isEmptyObject } from 'helpers/index'
import User from 'services/User'
import { MANAGER_ROLES, ROLES } from 'constants/roles'

export const authorize = (auth) => {
  const isAuthenticated =
    !isEmptyObject(auth.user) && !!auth.isAuthorized && !!auth.user.token
  const user = isAuthenticated
    ? new User({
        ...auth.user.user,
        isAuthorized: auth.isAuthorized,
        token: auth.user.token,
      })
    : {}

  return [user, isAuthenticated]
}

export const hasManagerRole = (auth) => {
  let hasRole = false

  if (auth.user.user.roles) {
    auth.user.user.roles.forEach((role) => {
      if (MANAGER_ROLES.includes(role.id)) {
        hasRole = true
      }
    })
  }

  return hasRole
}

export const isWarehouseManager = (auth) => {
  let hasRole = false

  if (auth.user.user.roles) {
    auth.user.user.roles.forEach((role) => {
      if (
        ROLES.WAREHOUSE_MANAGER === role.id ||
        ROLES.SUPPLY_CHAIN_MANAGER === role.id
      ) {
        hasRole = true
      }
    })
  }

  return hasRole
}
