import React from 'react'
import * as PropTypes from 'prop-types'
import TableListCell from '../TableListRow/TableListCell'

const TableListHeader = ({ columns, onSort, sortOrder, sortField }) => (
  <div className="list__header">
    {columns.map((column) => (
      <TableListCell
        onClick={() => onSort(column.value)}
        sortOrder={column.value === sortField ? sortOrder : undefined}
        key={column.id}
      >
          {column.label}
      </TableListCell>
    ))}
  </div>
)

TableListHeader.propTypes = {
  columns: PropTypes.array,
  onSort: PropTypes.func,
  sortOrder: PropTypes.bool,
}

export default TableListHeader
