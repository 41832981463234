import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { Router, Route, Switch } from 'react-router-dom'
import history from '../../../history'
import ErrorBoundary from 'components/common/ErrorBoundary'
import PageWrapper from 'components/common/PageWrapper'
import { connect } from 'react-redux'
import Header from 'components/common/Header'
import SideBar from 'components/common/SideBar'
import { PrivateRoute } from 'components/common/PrivateRoute'
import { ROUTES } from 'constants/routes'
import { fetchAppInfoStart } from 'store/appInfo/actions'
import { resetModals, showModal } from 'store/modal/actions'
import NotFoundPage from 'components/common/NotFoundPage'
import MainPage from 'components/features/Main/MainPage'
import LocationListPage from 'components/features/Location/LocationListPage'
import SingleLocationAddPage from 'components/features/Location/SingleLocationAddPage'
import GroupLocationAddPage from 'components/features/Location/GroupLocationAddPage'
import LocationEditDetailsPage from 'components/features/Location/LocationEditDetailsPage'
import ContainerReceivingListPage from 'components/features/Container/ContainerList/ContainerReceivingListPage'
import ContainerUnloadingListPage from 'components/features/Container/ContainerList/ContainerUnloadingListPage'
import ContainerReceivingDetailsPage from 'components/features/Container/ContainerDetails/ContainerReceivingDetailsPage'
import OrdersPage from 'components/features/Order/OrdersPage'
import OrdersStaffAssignmentPage from 'components/features/Order/OrdersStaffAssignmentPage'
import InventoryManagementPage from 'components/features/Inventory/InventoryManagementPage'
import RestockTaskDetailPage from 'components/features/Inventory/RestockTaskDetailPage'
import QaHoldResolutionPage from 'components/features/Inventory/QaHoldResolutionPage'
import OrderRemovedPage from 'components/features/Order/OrderRemoved'
import OrderShippingPage from 'components/features/Order/Shipping/OrderShippingPage'
import OrdersCreateShipmentPage from 'components/features/Order/Shipping/OrdersCreateShipmentPage'
import { authorize } from 'helpers/authorize'
import LoginPage from 'components/features/Auth/LoginPage'
import AboutPage from 'components/features/Admin/About'
import ClearPrintersPage from 'components/features/Admin/ClearPrinters'
import { PROJECT } from 'constants/common'
import './style.scss'
import { GiFishbone } from 'react-icons/gi'
import { buildVersionText } from 'helpers/version'
import { SnackbarProvider } from 'notistack'

// delete me
const routeList = [
  {
    Component: MainPage,
    path: ROUTES.HOME_PAGE,
  },
  {
    Component: MainPage,
    path: ROUTES.MAIN_PAGE,
  },
  {
    Component: AboutPage,
    path: ROUTES.ABOUT_PAGE,
  },
  {
    Component: ClearPrintersPage,
    path: ROUTES.CLEAR_PRINTERS_PAGE,
  },
  {
    Component: LocationListPage,
    path: ROUTES.LOCATION_LIST_PAGE,
  },
  {
    Component: SingleLocationAddPage,
    path: ROUTES.LOCATION_SINGLE_ADD_PAGE,
  },
  {
    Component: GroupLocationAddPage,
    path: ROUTES.LOCATION_GROUP_ADD_PAGE,
  },
  {
    Component: LocationEditDetailsPage,
    path: ROUTES.LOCATION_EDIT_DETAILS_PAGE_WITH_ID,
  },
  {
    Component: ContainerReceivingListPage,
    path: ROUTES.CONTAINER_RECEIVING_LIST_PAGE,
  },
  {
    Component: ContainerUnloadingListPage,
    path: ROUTES.CONTAINER_UNLOADING_LIST_PAGE,
  },
  {
    Component: ContainerReceivingDetailsPage,
    path: ROUTES.CONTAINER_RECEIVING_DETAILS_PAGE_WITH_ID,
  },
  {
    Component: ContainerReceivingDetailsPage,
    path: ROUTES.CONTAINER_UNLOADING_DETAILS_PAGE_WITH_ID,
  },
  {
    Component: OrdersCreateShipmentPage,
    path: ROUTES.ORDERS_CREATE_SHIPMENT_PAGE,
  },
  {
    Component: OrdersPage,
    path: ROUTES.ORDERS_ACTIVE_PAGE,
  },
  {
    Component: OrdersStaffAssignmentPage,
    path: ROUTES.ORDERS_STAFF_ASSIGNMENT_PAGE_WITH_ID,
  },
  {
    Component: InventoryManagementPage,
    path: ROUTES.INVENTORY_INVENTORY_MANAGEMENT_PAGE,
  },
  {
    Component: RestockTaskDetailPage,
    path: ROUTES.RESTOCK_TASK_DETAIL_PAGE_WITH_ID,
  },
  {
    Component: QaHoldResolutionPage,
    path: ROUTES.INVENTORY_QA_HOLD_RESOLUTION_PAGE,
  },
  {
    Component: OrderRemovedPage,
    path: ROUTES.ORDERS_REMOVED_PAGE,
  },
  {
    Component: OrderShippingPage,
    path: ROUTES.ORDERS_SHIPPING_PAGE,
  },
]

export const AuthContext = React.createContext({
  user: {},
  isAuthenticated: false,
})

const App = ({
  auth,
  appInfo,
  isStale,
  fetchAppInfo,
  showModal,
  resetModals,
}) => {
  const [user, isAuthenticated] = authorize(auth)

  const onMount = () => {}

  const getAppInfo = () => {
    fetchAppInfo(PROJECT)
    setTimeout(() => {
      getAppInfo()
    }, 300000)
  }

  const refreshApp = () => {
    window.location.reload()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getAppInfo()
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  useEffect(onMount, [])

  if (appInfo && isStale) {
    const versionText = buildVersionText(appInfo)

    showModal({
      name: 'confirm-default',
      content: (
        <div className="modal--stale">
          <div className="modal--stale__exclamation">
            <GiFishbone />
          </div>
          <div className="modal--stale__text">
            <div className="modal--stale__title">Your app is out of date.</div>
            There is a newer version of this app.
            <div className="modal--stale__version">{versionText}</div>
            <br />
            <br />
            Click 'OK' to refresh and upgrade.
          </div>
        </div>
      ),
      events: {
        onAccept: refreshApp,
        onDeny: resetModals,
      },
    })
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user }}>
      <Router history={history}>
        <ErrorBoundary>
          <PageWrapper>
            <SnackbarProvider maxSnack={3}>
              {isAuthenticated && (
                <>
                  <Header user={user} />
                  <SideBar user={user} />
                </>
              )}
              <Switch>
                {routeList.map(({ Component, path, exact = true }, idx) => (
                  <PrivateRoute
                    key={idx}
                    exact={exact}
                    path={path}
                    component={Component}
                  />
                ))}
                <Route exact path={ROUTES.LOGIN_PAGE} component={LoginPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </SnackbarProvider>
          </PageWrapper>
        </ErrorBoundary>
      </Router>
    </AuthContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  appInfo: state.appInfo.newItem,
  isStale: state.appInfo.isStale,
})

const mapDispatchToProps = {
  fetchAppInfo: fetchAppInfoStart,
  showModal: showModal,
  resetModals: resetModals,
}

App.propTypes = {
  auth: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
