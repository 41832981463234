import { createSelector } from 'reselect'

const getStaffAssignments = (state) => state.staff.item

export const getNormalizedStaffAssignments = createSelector(
  [getStaffAssignments],
  (item) => {
    if (!item.staff) {
      return {}
    }

    const typesWithList = Object.entries(item.staff)
    const allAssignments = item.taskAssignments

    const prepareStaff = (list) =>
      list.map((listItem) => ({
        ...listItem,
        taskAssignments: allAssignments.filter(
          (i) => listItem.taskAssignments.indexOf(i.id) > -1,
        ),
      }))

    return Object.fromEntries(
      typesWithList.map(([typeId, list]) => {
        return [
          parseInt(typeId),
          parseInt(typeId) === 0 ? list : prepareStaff(list),
        ]
      }),
    )
  },
)
