import React, { useState } from 'react'
import { connect } from 'react-redux'
import Form from 'components/common/FormElements/Form'
import { inputs, stateTypes } from 'constants/form'
import { NEW_PIN_FIELD, REPEAT_NEW_PIN_FIELD } from 'constants/fields'
import { REGEX } from 'constants/regex'
import Button from 'components/common/Button'
import { setNewPinCodeStart } from 'store/auth/actions'
import Logo from 'assets/svg/logo.svg'

const INITIAL_FIELDS = {
  [NEW_PIN_FIELD]: '',
  [REPEAT_NEW_PIN_FIELD]: '',
}

const NewPinForm = (props) => {
  const { setNewPinCode, user } = props

  const [data, setData] = useState(INITIAL_FIELDS)
  const [inputErrors, setInputErrors] = useState({})

  const onChange = (name, value) => {
    setData({ ...data, [name]: value })
    setInputErrors({ ...inputErrors, [name]: null })
  }

  const isAllFieldFilled = !!data[NEW_PIN_FIELD] && !!data[REPEAT_NEW_PIN_FIELD]
  const fieldsMatch = data[NEW_PIN_FIELD] === data[REPEAT_NEW_PIN_FIELD]

  const onSubmit = () => {
    if (!isAllFieldFilled) {
      return setInputErrors({
        [NEW_PIN_FIELD]: data[NEW_PIN_FIELD] ? '' : { text: 'required' },
        [REPEAT_NEW_PIN_FIELD]: data[REPEAT_NEW_PIN_FIELD]
          ? ''
          : { text: 'required' },
      })
    }

    if (data[NEW_PIN_FIELD].length < 6) {
      return setInputErrors({
        ...inputErrors,
        [NEW_PIN_FIELD]: { text: 'At least 6 numbers' },
      })
    }

    if (!fieldsMatch) {
      return setInputErrors({
        [NEW_PIN_FIELD]: { text: 'not match' },
        [REPEAT_NEW_PIN_FIELD]: { text: 'not match' },
      })
    }

    setNewPinCode({
      data: {
        staff_id: user.staff_id,
        pincode: data[NEW_PIN_FIELD],
      },
    })
  }

  return (
    <div className="login">
      <Logo className="login__logo" />
      <h2 className="login__title">Reset PIN</h2>
      <p className="login__text">Reset Your New PIN Here</p>
      <Form
        fields={[
          {
            type: inputs.PASSWORD,
            name: NEW_PIN_FIELD,
            events: {
              onChange,
            },
            inputProps: {
              pattern: REGEX.IS_PIN,
              placeholder: 'NEW PIN',
              tabIndex: 10,
            },
            value: data[NEW_PIN_FIELD],
            state: {
              type: inputErrors[NEW_PIN_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[NEW_PIN_FIELD]
                ? inputErrors[NEW_PIN_FIELD].text
                : '',
            },
          },
          {
            type: inputs.PASSWORD,
            name: REPEAT_NEW_PIN_FIELD,
            events: {
              onChange,
            },
            inputProps: {
              pattern: REGEX.IS_PIN,
              placeholder: 'REPEAT NEW PIN',
              tabIndex: 11,
            },
            value: data[REPEAT_NEW_PIN_FIELD],
            state: {
              type: inputErrors[REPEAT_NEW_PIN_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[REPEAT_NEW_PIN_FIELD]
                ? inputErrors[REPEAT_NEW_PIN_FIELD].text
                : '',
            },
          },
        ]}
        className="login__form"
        onSubmit={onSubmit}
        submitBtn={<Button tabIndex="12" text="Confirm" onClick={onSubmit} />}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

const mapDispatchToProps = {
  setNewPinCode: setNewPinCodeStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPinForm)
