import React from 'react'
import * as PropTypes from 'prop-types'

const ListCell = ({ children }) => <div className="cell">{children}</div>

ListCell.propTypes = {
  children: PropTypes.any,
}

export default ListCell
