import React from 'react'
import * as PropTypes from 'prop-types'
import TextInput from 'components/common/TextInput'
import AddLotIcon from 'assets/svg/add-lot-number.svg'
import RemoveLotIcon from 'assets/svg/remove-lot-number.svg'

const LotNumber = (props) => {
  const {
    item,
    index,

    onChange,
    onClickAdd,
    onClickRemove,
  } = props

  const onRemove = () => onClickRemove(item)
  const onChangeInput = (name, value) => onChange({ ...item, [name]: value })

  return (
    <div className="row qa-hold__lot">
      <span className="text--bold">Lot Number</span>
      <TextInput
        name={'ex_number'}
        onChange={onChangeInput}
        value={item.ex_number}
      />
      {index > 0 && <RemoveLotIcon className="svg" onClick={onRemove} />}
      <AddLotIcon className="svg" onClick={onClickAdd} />
    </div>
  )
}

LotNumber.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,

  onChange: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickRemove: PropTypes.func,
}

export default LotNumber
