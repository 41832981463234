import React from 'react'
import * as PropTypes from 'prop-types'
import LotNumber from './LotNumber'
import RemoveFromQAIcon from 'assets/svg/remove-from-qa.svg'
import { getUniqueId } from 'helpers'

const ProductLotNumber = (props) => {
  const {
    item,

    onChange,
    onDelete,
  } = props

  const onRemoveProductLotNumber = () => onDelete(item)
  const onAddLotNumber = () =>
    onChange({
      ...item,
      lotNumbers: [
        ...item.lotNumbers,
        { id: getUniqueId(), ex_number: undefined },
      ],
    })
  const onRemoveLotNumber = (lotNumber) =>
    onChange({
      ...item,
      lotNumbers: item.lotNumbers.filter(
        (lotNumberItem) => lotNumberItem.id !== lotNumber.id,
      ),
    })
  const onChangeLotNumber = (lotNumber) =>
    onChange({
      ...item,
      lotNumbers: item.lotNumbers.map((lotNumberItem) =>
        lotNumber.id === lotNumberItem.id ? lotNumber : lotNumberItem,
      ),
    })

  return (
    <div className="row">
      <RemoveFromQAIcon
        className="svg--remove"
        onClick={onRemoveProductLotNumber}
      />
      <div className="column">
        <p className="row">
          <span className="row text--bold">Model&nbsp;</span>
          {item.product.products_model}
        </p>
      </div>
      <div className="column">
        <p className="row">
          <span className="row text--bold">Manf Model&nbsp;</span>
          {item.product.products_manf_model}
        </p>
      </div>
      <div className="column">
        <p className="row">
          <span className="row text--bold">Product Name&nbsp;</span>
          {item.description.products_name}
        </p>
      </div>
      <div className="column">
        {item.lotNumbers.map((lotNumber, index) => (
          <LotNumber
            key={lotNumber.id}
            item={lotNumber}
            index={index}
            onChange={onChangeLotNumber}
            onClickAdd={onAddLotNumber}
            onClickRemove={onRemoveLotNumber}
          />
        ))}
      </div>
    </div>
  )
}

ProductLotNumber.propTypes = {
  item: PropTypes.object,

  onChange: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ProductLotNumber
