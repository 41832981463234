export const types = {
  FETCH_REASON_LIST_START: 'FETCH_REASON_LIST_START',
  FETCH_REASON_LIST_SUCCESS: 'FETCH_REASON_LIST_SUCCESS',
  FETCH_REASON_LIST_FAIL: 'FETCH_REASON_LIST_FAIL',
}

export const fetchReasonListStart = (payload) => ({
  type: types.FETCH_REASON_LIST_START,
  payload,
})
export const fetchReasonListSuccess = (payload) => ({
  type: types.FETCH_REASON_LIST_SUCCESS,
  payload,
})
export const fetchReasonListFail = (payload) => ({
  type: types.FETCH_REASON_LIST_FAIL,
  payload,
})
