import { types } from './actions'

const INIT = {
  list: [],
  otherList: [],
  availableLocationList: [],
  item: {},
}

const warehousePalletReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_AVAILABLE_LOCATION_LIST_START:
    case types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_START:
    case types.FETCH_WAREHOUSE_PALLET_LIST_START:
      return {
        ...state,
      }
    case types.FETCH_WAREHOUSE_PALLET_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_SUCCESS:
      return {
        ...state,
        otherList: payload,
      }
    case types.FETCH_ONE_WAREHOUSE_PALLET_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case types.FETCH_WAREHOUSE_PALLET_LIST_SUCCESS_APPEND:
      return {
        ...state,
        list: [...state.list, ...payload],
      }
    case types.FETCH_AVAILABLE_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        availableLocationList: payload,
      }
    case types.FETCH_ONE_WAREHOUSE_PALLET_START:
    case types.FETCH_ONE_WAREHOUSE_PALLET_FAIL:
    case types.FETCH_WAREHOUSE_PALLET_LIST_FAIL:
      return INIT
    default:
      return state
  }
}

export default warehousePalletReducer
