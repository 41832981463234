import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const Tooltip = (props) => {
  const {
    hoverTarget,
    hoverMainText = '',
    sideText = '',
    extraTargetClassName = '',
    extraBubbleClassName = '',
  } = props

  const combinedTargetClassName = 'tooltip ' + extraTargetClassName
  const combinedBubbleClassNames = 'tooltip-text ' + extraBubbleClassName

  return (
    <>
      <div className={combinedTargetClassName}>
        <span>{hoverTarget}</span>
        <span className={combinedBubbleClassNames}>
          {hoverMainText} <span className="side-text">{sideText}</span>
        </span>
      </div>
    </>
  )
}

Tooltip.propTypes = {
  hoverTarget: PropTypes.any,
  hoverMainText: PropTypes.any,
  sideText: PropTypes.string,
  extraTargetClassName: PropTypes.string,
  extraBubbleClassName: PropTypes.string,
}

export default Tooltip
