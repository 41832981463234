import { connect } from 'react-redux'
import LocationGroupAddLocationItem from './component'

const mapStateToProps = (state) => ({
  aisleList: state.filter.filterParams.aisleList,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationGroupAddLocationItem)
