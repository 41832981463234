import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { isEmptyObject } from 'helpers'
import { FILTERS } from 'constants/filters'
import { CONTAINER_STATUS } from 'constants/containerStatuses'
import {
  OnWaterIcon,
  OnRailIcon,
  OnTruckIcon,
  OnReceivedIcon,
  PrepareToUnloadIcon,
  StartUnloadIcon,
  CompleteUnloadIcon,
  OnRailIconDisabled,
  OnTruckIconDisabled,
  OnReceivedIconDisabled,
  PrepareToUnloadIconDisabled,
  StartUnloadIconDisabled,
  CompleteUnloadIconDisabled,
  CheckMarkSuccessIcon,
} from './icons'

export default class ContainerDetailsStatusSwitcher extends Component {
  static propTypes = {
    containerStatusList: PropTypes.array,
    filterList: PropTypes.array,

    item: PropTypes.object,

    value: PropTypes.number,

    changeItem: PropTypes.func,
    onChange: PropTypes.func,
    fetchFilters: PropTypes.func,
    fetchListStatusesStart: PropTypes.func,
    clearFilter: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.onChangeStatus = this.onChangeStatus.bind(this)
  }

  componentDidMount() {
    const { fetchFilters, item, clearFilter } = this.props

    clearFilter('containerStatusList')

    let filterType = ''

    item.container_status.id >= CONTAINER_STATUS.PREPARE_TO_UNLOAD
      ? (filterType = FILTERS.CONTAINER_STATUS_UNLOADING_FILTER)
      : (filterType = FILTERS.CONTAINER_STATUS_RECEIVING_FILTER)

    fetchFilters([filterType])
  }

  onChangeStatus = (statusId) => {
    this.props.onChange(statusId)
  }

  chooseStatusIcon = (currentStatus, isSelOrPrevStatus) => {
    switch (currentStatus.toLowerCase()) {
      case 'on water':
        return <OnWaterIcon />
      case 'on rail':
        return isSelOrPrevStatus ? <OnRailIcon /> : <OnRailIconDisabled />
      case 'on truck':
        return isSelOrPrevStatus ? <OnTruckIcon /> : <OnTruckIconDisabled />
      case 'received':
        return isSelOrPrevStatus ? (
          <OnReceivedIcon />
        ) : (
          <OnReceivedIconDisabled />
        )
      case 'prepare to unload':
        return isSelOrPrevStatus ? (
          <PrepareToUnloadIcon />
        ) : (
          <PrepareToUnloadIconDisabled />
        )
      case 'start unload':
        return isSelOrPrevStatus ? (
          <StartUnloadIcon />
        ) : (
          <StartUnloadIconDisabled />
        )
      case 'complete unload':
        return isSelOrPrevStatus ? (
          <CompleteUnloadIcon />
        ) : (
          <CompleteUnloadIconDisabled />
        )
      default:
        break
    }
  }

  render() {
    const { containerStatusList = [], item, value } = this.props

    return (
      <div className="status-bar--wrapper">
        <div className="status-bar">
          {!isEmptyObject(item) &&
            containerStatusList.map((status) => {
              const isPreviousStatus = status.value < value
              const isSelectedOrPreviousStatus = status.value <= value

              return (
                <div
                  key={status.value}
                  style={{ width: `${87 / containerStatusList.length}%` }}
                  className={
                    isSelectedOrPreviousStatus ? 'icon' : 'icon icon--disabled'
                  }
                  onClick={() => this.onChangeStatus(status.value)}
                >
                  <div className="svg--wrapper">
                    {this.chooseStatusIcon(
                      status.label,
                      isSelectedOrPreviousStatus,
                    )}
                  </div>
                  <div className="status-bar--text">
                    <p className="text">{status.label}</p>
                    {isPreviousStatus ? <CheckMarkSuccessIcon /> : null}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}
