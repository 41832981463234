import React from 'react'
import * as PropTypes from 'prop-types'
import Link from 'components/common/Link'
import RowCell from 'components/common/Table/TableRow/RowCell'
import Checkbox from 'components/common/Checkbox'
import IconRoundedBrown from 'assets/svg/arrow-rounded-brown.svg'

const TableRow = (props) => {
  const {
    item,
    links,

    checked,

    columns,
    options,
    events,
  } = props

  const onSelectClick = () => events.onSelect(item.id)
  const onDeleteClick = () => events.onDelete(item.id)

  const putControlLinks = () => {
    let controlLinks = []

    if (options.editable && links.edit) {
      controlLinks = [
        ...controlLinks,
        <Link
          key={`edit-btn-${item.id}`}
          ariaLabel="Edit"
          to={`${links.edit.replace(':id', item.id)}`}
          className="icon"
        >
          <i className="icon icon-edit" />
        </Link>,
      ]
    }

    if (options.viewable && links.view) {
      controlLinks = [
        ...controlLinks,
        <Link
          key={`view-btn-${item.id}`}
          aria-label="View"
          to={`${links.view.replace(':id', item.id)}`}
        >
          <IconRoundedBrown className="svg-hover" />
        </Link>,
      ]
    }

    if (options.deletable) {
      controlLinks = [
        ...controlLinks,
        <div
          key={`delete-btn-${item.id}`}
          className="icon"
          onClick={onDeleteClick}
        >
          <i className="icon icon-delete" />
        </div>,
      ]
    }

    return controlLinks.length ? controlLinks.map((item) => item) : <></>
  }

  const getOptionalColumn = () =>
    options.editable || options.viewable || options.deletable ? (
      <RowCell
        key="actionButtons"
        className="table__row__cell table__row__cell--control"
        content={<>{putControlLinks()}</>}
      />
    ) : null

  const getSelectableColumn = () =>
    options.selectable ? (
      <RowCell
        key="checkbox"
        className="table__row__cell table__row__cell--checkbox"
        content={<Checkbox checked={checked} onClick={onSelectClick} />}
      />
    ) : null

  const getColumns = () =>
    columns
      .filter((column) => !!column.value)
      .map((column) => (
        <RowCell
          className="table__row__cell"
          key={column.value}
          content={item[column.value]}
        />
      ))

  const result = (
    <>
      {getSelectableColumn()}
      {getColumns()}
      {getOptionalColumn()}
    </>
  )

  return <tr className="table__row">{result}</tr>
}

TableRow.propTypes = {
  columns: PropTypes.array,

  item: PropTypes.object,
  links: PropTypes.object,
  options: PropTypes.object,

  checked: PropTypes.bool,
}

export default TableRow
