import React from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import User from 'services/User'
import { Redirect } from 'react-router'
import { isEmptyObject } from 'helpers'
import { ROUTES } from 'constants/routes'

const Auth = (props) => {
  const { Component, user } = props

  if (isEmptyObject(user.user) || !user.isAuthorized || !user.user.token) {
    return <Redirect to={ROUTES.LOGIN_PAGE} />
  }

  const newProps = {
    ...props,
    user: new User({
      ...user.user.user,
      isAuthorized: user.isAuthorized,
      token: user.user.token,
    }),
  }

  if (!user.isAuthorized || !user.user.token) {
    return <Redirect to={ROUTES.LOGIN_PAGE} />
  }

  return <Component {...newProps} />
}

Auth.propTypes = {
  user: PropTypes.object.isRequired,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
}

const mapStateToProps = (state) => ({
  user: state.auth,
})

export default connect(mapStateToProps, null)(Auth)
