import React from 'react'
import { store } from '../../index'
import { takeEvery, takeLatest, call, put, delay } from 'redux-saga/effects'
import {
  fetchMany,
  create,
  remove,
  update,
  fetchAvailableBaysByAisleAndWarehouse,
  fetchOne,
  createMany,
  createLocationLaneDeleteReason,
} from 'api/locations'
import {
  types,
  createGroupLocationFail,
  createGroupLocationSuccess,
  createLocationFail,
  createLocationSuccess,
  fetchAvailableBaysFail,
  fetchAvailableBaysSuccess,
  fetchLocationListFail,
  fetchLocationListStart,
  fetchLocationListSuccess,
  fetchOneLocationSuccess,
  updateLocationFail,
  updateLocationSuccess,
} from './actions'
import { showModal } from 'store/modal/actions'
import { resetModals } from '../modal/actions'
import * as query from 'querystringify'
import { getUrlParams } from 'helpers/index'
import {
  MODAL_PENDING,
  MODAL_SUCCESS,
  MODAL_WARNING,
} from 'components/common/Modal'

function* createLaneDeleteReason(action) {
  const { payload } = action

  try {
    const response = yield call(createLocationLaneDeleteReason, payload)
  } catch (error) {
  }
}

function* fetchLocations(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(
      fetchLocationListSuccess({
        items: response.data.data.items,
        metaData: response.data.meta,
      }),
    )
    yield put(resetModals())
  } catch (error) {
    yield put(fetchLocationListFail())
  }
}

function* fetchOneLocation(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneLocationSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchLocationListFail())
  }
}

function* createLocation(action) {
  const { payload } = action

  try {
    const response = yield call(create, payload)
    yield put(createLocationSuccess(response))
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
  } catch (error) {
    yield put(createLocationFail())
  }
}

function* createGroupLocation(action) {
  const { payload } = action

  try {
    const response = yield call(createMany, payload)
    yield put(createGroupLocationSuccess(response))
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
  } catch (error) {
    yield put(createGroupLocationFail())
  }
}

function* updateLocation(action) {
  const { payload } = action
  const onClose = () => store.dispatch(resetModals())
  try {
    const response = yield call(update, payload)
    yield put(updateLocationSuccess())
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
  } catch (error) {
    yield put(updateLocationFail())
    yield put(
      showModal({
        name: MODAL_WARNING,
        content: (
          <div className="modal--info__text">
            An error occurred while updating. {error.response.data.data.message}
          </div>
        ),
        events: {
          onClose,
        },
      }),
    )
  }
}

function* deleteLocation(action) {
  const { payload } = action
  const onClose = () => store.dispatch(resetModals())

  try {
    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Deleting...</div>,
      }),
    )
    const response = yield call(remove, payload)
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
    yield delay(3000)
    yield put(resetModals())
    yield put(fetchLocationListStart(`?${query.stringify(getUrlParams())}`))
  } catch (error) {
    yield put(
      showModal({
        name: MODAL_WARNING,
        content: (
          <div className="modal--info__text">
            An error occurred while deleting, the location is not empty
          </div>
        ),
        events: {
          onClose,
        },
      }),
    )
    yield put(fetchLocationListFail())
  }
}

function* fetchAvailableBays(action) {
  const { payload } = action

  try {
    const response = yield call(fetchAvailableBaysByAisleAndWarehouse, payload)
    yield put(fetchAvailableBaysSuccess(response.data.items))
  } catch (error) {
    yield put(fetchAvailableBaysFail())
  }
}

export default function* locationSaga() {
  yield takeLatest(types.FETCH_LOCATION_LIST_START, fetchLocations)
  yield takeEvery(types.CREATE_LOCATION_START, createLocation)
  yield takeEvery(types.DELETE_LOCATION, deleteLocation)
  yield takeEvery(types.UPDATE_LOCATION_START, updateLocation)
  yield takeEvery(types.FETCH_AVAILABLE_BAYS_START, fetchAvailableBays)
  yield takeEvery(types.FETCH_ONE_LOCATION_START, fetchOneLocation)
  yield takeEvery(types.CREATE_GROUP_LOCATION_START, createGroupLocation)
  yield takeEvery(types.CREATE_LANE_DELETE_REASON, createLaneDeleteReason)
}
