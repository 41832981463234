export const types = {
  FETCH_WAREHOUSE_CASES_LIST_START: 'FETCH_WAREHOUSE_CASES_LIST_START',
  FETCH_WAREHOUSE_CASES_LIST_SUCCESS: 'FETCH_WAREHOUSE_CASES_LIST_SUCCESS',
  FETCH_WAREHOUSE_CASES_LIST_SUCCESS_APPEND:
    'FETCH_WAREHOUSE_CASES_LIST_SUCCESS_APPEND',
  FETCH_WAREHOUSE_CASES_LIST_FAIL: 'FETCH_WAREHOUSE_CASES_LIST_FAIL',

  FETCH_OTHER_WAREHOUSE_CASES_LIST_START:
    'FETCH_OTHER_WAREHOUSE_CASES_LIST_START',
  FETCH_OTHER_WAREHOUSE_CASES_LIST_SUCCESS:
    'FETCH_OTHER_WAREHOUSE_CASES_LIST_SUCCESS',
  FETCH_OTHER_WAREHOUSE_CASES_LIST_FAIL:
    'FETCH_OTHER_WAREHOUSE_CASES_LIST_FAIL',

  UPDATE_WAREHOUSE_CASE: 'UPDATE_WAREHOUSE_CASE',
}

export const updateWarehouseCase = (payload) => ({
  type: types.UPDATE_WAREHOUSE_CASE,
  payload,
})

export const fetchWarehouseCasesListStart = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASES_LIST_START,
  payload,
})

export const fetchWarehouseCasesListSuccess = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASES_LIST_SUCCESS,
  payload,
})

export const fetchWarehouseCasesListSuccessAppend = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASES_LIST_SUCCESS_APPEND,
  payload,
})

export const fetchOtherWarehouseCasesListStart = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_CASES_LIST_START,
  payload,
})

export const fetchOtherWarehouseCasesListSuccess = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_CASES_LIST_SUCCESS,
  payload,
})

export const fetchWarehouseCasesListFail = (payload) => ({
  type: types.FETCH_WAREHOUSE_CASES_LIST_FAIL,
  payload,
})

export const fetchOtherWarehouseCasesListFail = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_CASES_LIST_FAIL,
  payload,
})
