import React from 'react'
import Switch from 'react-switch'
import * as PropTypes from 'prop-types'
import './style.scss'
import styleVariables from '../../../assets/styleVariables.module.scss'

const DEFAULT_STYLES = {
  boxShadow: '0 1px 5px 0 rgba(168, 162, 150, 0.68)',
  activeBoxShadow: '0 1px 5px 0 rgba(168, 162, 150, 0.68)',
  offColor: '#e6e6e6',
  onColor: styleVariables.primaryColor,
  offCircleColor: styleVariables.white,
  onCircleColor: styleVariables.white,
  circleDiameter: 34,
  height: 28,
  width: 52,
}

const Toggle = (props) => {
  const { text = '', checked, className = '', onChange } = props

  return (
    <label className="toggle">
      <span className="toggle__text">{text}</span>
      <Switch
        checked={checked}
        className={className}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={DEFAULT_STYLES.boxShadow}
        activeBoxShadow={DEFAULT_STYLES.activeBoxShadow}
        onColor={DEFAULT_STYLES.onColor}
        offColor={DEFAULT_STYLES.offColor}
        onHandleColor={DEFAULT_STYLES.onCircleColor}
        offHandleColor={DEFAULT_STYLES.offCircleColor}
        handleDiameter={DEFAULT_STYLES.circleDiameter}
        height={DEFAULT_STYLES.height}
        width={DEFAULT_STYLES.width}
        onChange={onChange}
      />
    </label>
  )
}

Toggle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Toggle
