import { types } from './actions'

const INIT = {
  list: [],
}

const warehouseCaseAndPalletReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_START:
      return {
        ...state,
      }
    case types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS:
      return {
        ...state,
        list: payload.items,
        metaData: payload.metaData,
      }
    case types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_SUCCESS_APPEND:
      return {
        ...state,
        list: [...state.list, ...payload],
      }
    case types.FETCH_WAREHOUSE_CASE_AND_PALLET_LIST_FAIL:
      return INIT
    default:
      return state
  }
}

export default warehouseCaseAndPalletReducer
