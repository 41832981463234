import { FILTERS } from 'constants/filters'
import {
  DOCK_FIELD,
  HEIGHT_FIELD,
  LENGTH_FIELD,
  LOCATION_TYPE_FIELD,
  PALLETS_CAPACITY_FIELD,
  WIDTH_FIELD,
  WRAP_REQUIRED_FIELD,
} from 'constants/formFields'
import { TASK_TYPES } from './taskAssignment'
import { CARRIERS } from './order'

import FedexE from '../assets/svg/carrier-fedex-e.svg'
import FedexP from '../assets/svg/carrier-fedex-p.svg'
import Estes from '../assets/svg/carrier-estes.svg'
import Yrc from '../assets/svg/carrier-yrc.svg'
import FedexG from '../assets/svg/carrier-fedex-g.svg'
import UpsG from '../assets/svg/carrier-ups-g.svg'
import UpsNextDayAir from '../assets/svg/carrier-ups-next-day-air.svg'
import UpsSecondDayAir from '../assets/svg/carrier-ups-second-day-air.svg'
import UpsThreeDay from '../assets/svg/carrier-ups-three-day.svg'
import UspsP from '../assets/svg/carrier-usps-p.svg'
import UspsPEx from '../assets/svg/carrier-usps-p-ex.svg'
import UspsParcel from '../assets/svg/carrier-usps-parcel.svg'
import Ltl from '../assets/svg/carrier-ltl.svg'
import Ftl from '../assets/svg/carrier-ftl.svg'
import Delivery from '../assets/svg/carrier-delivery.svg'
import Pickup from '../assets/svg/carrier-pickup.svg'
import UspsFlatRate from '../assets/svg/carrier-usps-flat-rate.svg'
//Group Buy; no image available
import Xpo from '../assets/svg/carrier-xpo.svg'
import Tforce from '../assets/svg/carrier-tforce.svg'
import Abf from '../assets/svg/carrier-abf.svg'
import Landstar from '../assets/svg/carrier-landstar.svg'  
import Cfi from '../assets/svg/carrier-cfi.svg'  
import Rls from '../assets/svg/carrier-rl-s.svg'  
import Rlg from '../assets/svg/carrier-rl-g.svg'  

import React from 'react'

export const MAPPER_CONTAINERS_LIST_SORTING = {
  [FILTERS.CONTAINER_FILTER]: 'id',
  [FILTERS.CONTAINER_STATUS_FILTER]: 'container_status_id',
  [FILTERS.VENDOR_FILTER]: 'supplier_id',
  [FILTERS.CONTAINER_EXPECTED_ARRIVE_DATE_FILTER]: 'should_arrive_at',
  [FILTERS.CONTAINER_ARRIVE_DATE_FILTER]: 'arrive_at',
  [FILTERS.CONTAINER_PALLETS_QTY_FILTER]: 'invoice_pallets_quantity',
  [FILTERS.CONTAINER_CASES_QTY_FILTER]: 'invoice_cases_quantity',
}

// OTHER

export const MAPPER_EDIT_LOCATION_FIELDS = {
  [LOCATION_TYPE_FIELD]: LOCATION_TYPE_FIELD,
  [PALLETS_CAPACITY_FIELD]: PALLETS_CAPACITY_FIELD,
  [LENGTH_FIELD]: LENGTH_FIELD,
  [WIDTH_FIELD]: WIDTH_FIELD,
  [HEIGHT_FIELD]: HEIGHT_FIELD,
  [WRAP_REQUIRED_FIELD]: WRAP_REQUIRED_FIELD,
}

export const MAPPER_LOCATION_INFO = {
  warehouse_id: 'warehouse.id',
  location_type_id: 'location_type.id',
  storage_type_id: 'storage_type.id',
  aisle_id: 'regular_location.aisle_id.id',
  level: 'regular_location.level',
  bay_id: 'regular_location.bay_id.id',
  pallets_capacity: 'pallets_capacity',
  dock_id: 'dock_location.dock_id.id',
}

export const MAPPER_EDIT_CONTAINER_DETAILS = {
  container_status_id: 'container_status.id',
  should_arrive_at: 'should_arrive_at',
  arrive_at: 'arrive_at',
  [DOCK_FIELD]: DOCK_FIELD,
}

export const MAPPER_CARRIER_LOGO = {
  [CARRIERS.CARRIER_FEDEX_E]: <FedexE />,
  [CARRIERS.CARRIER_FEDEX_P]: <FedexP />,
  [CARRIERS.CARRIER_TFORCE]: <Tforce />,
  [CARRIERS.CARRIER_ESTES]: <Estes />,
  [CARRIERS.CARRIER_YRC]: <Yrc />,
  [CARRIERS.CARRIER_FEDEX_G]: <FedexG />,
  [CARRIERS.CARRIER_UPS_G]: <UpsG />,
  [CARRIERS.CARRIER_UPS_NEXT_DAY_AIR]: <UpsNextDayAir />,
  [CARRIERS.CARRIER_UPS_SECOND_DAY_AIR]: <UpsSecondDayAir />,
  [CARRIERS.CARRIER_UPS_THREE_DAY]: <UpsThreeDay />,
  [CARRIERS.CARRIER_USPS_P]: <UspsP />,
  [CARRIERS.CARRIER_USPS_P_EX]: <UspsPEx />,
  [CARRIERS.CARRIER_USPS_PARCEL]: <UspsParcel />,
  [CARRIERS.CARRIER_PICKUP]: <Pickup />,
  [CARRIERS.CARRIER_76DELIVERY]: <Delivery />,
  [CARRIERS.CARRIER_OTHER]: <Ltl />,
  [CARRIERS.CARRIER_USPS_FLAT_RATE]: <UspsFlatRate />,
  [CARRIERS.CARRIER_GROUP_BUY]: <p>Group Buy</p>, // no svg yet (01/2021)
  [CARRIERS.CARRIER_XPO]: <Xpo />,
  [CARRIERS.CARRIER_FTL_OTHER]: <Ftl />,
  [CARRIERS.CARRIER_ABF]: <Abf />,
  [CARRIERS.CARRIER_FTL_LANDSTAR]: <Landstar />,
  [CARRIERS.CARRIER_FTL_CFI]: <Cfi />,
  [CARRIERS.CARRIER_FTL_FEDEX_VOLUME]: <Ftl />,
  [CARRIERS.CARRIER_RL_STANDARD]: <Rls />,
  [CARRIERS.CARRIER_RL_GUARANTEED]: <Rlg />,
}

export const TASK_PROGRESS_NAME_MAPPER = {
  [TASK_TYPES.AVAILABLE_TYPE]: '',
  [TASK_TYPES.CONTAINER_UNLOAD_TYPE]: 'Unloading',
  [TASK_TYPES.PUTAWAY_TYPE]: 'Putaway',
  [TASK_TYPES.PICKING_TYPE]: 'Picking',
  [TASK_TYPES.RESTOCKING_TYPE]: 'Restocking',
  [TASK_TYPES.RELOCATE_TYPE]: 'Relocate',
  [TASK_TYPES.LOADING_TYPE]: 'Loading',
}
