export const WAREHOUSE_FIELD = 'warehouse_id'
export const LOCATION_TYPE_FIELD = 'location_type_id'
export const STORAGE_TYPE_FIELD = 'storage_type_id'
export const PRINT_LABEL_FIELD = 'label_orientation'
export const AISLE_FIELD = 'aisle_id'
export const BAY_FIELD = 'bay_id'
export const LEVEL_FIELD = 'level'
export const DOCK_FIELD = 'dock_id'
export const PALLETS_CAPACITY_FIELD = 'pallets_capacity'
export const LENGTH_FIELD = 'length'
export const WIDTH_FIELD = 'width'
export const HEIGHT_FIELD = 'height'
export const IS_PERMANENT_FIELD = 'is_permanent'
export const WRAP_REQUIRED_FIELD = 'wrap_required'
export const BAY_RANGE_TYPE_FIELD = 'bay_range_type'
export const BAY_RANGE_SWITCH_FIELD = 'bay_range_switch'
export const DELIVERY_COMPANY_FIELD = 'delivery_company'
export const DELIVERY_NAME_FIELD = 'delivery_name'
export const DELIVERY_STREET_ADDRESS_FIELD = 'delivery_street_address'
export const DELIVERY_SUBURB_FIELD = 'delivery_suburb'
export const DELIVERY_CITY_FIELD = 'delivery_city'
export const DELIVERY_STATE_FIELD = 'delivery_state'
export const DELIVERY_ZIP_FIELD = 'delivery_zip'
export const CUSTOMERS_PHONE_FIELD = 'customers_phone'
export const PICKUP_DATE_FIELD = 'pickup_date'
export const PICKUP_TIME_START_FIELD = 'pickup_time_start'
export const PICKUP_TIME_STOP_FIELD = 'pickup_time_stop'
export const WEIGHT_FIELD = 'weight'
export const PALLETS_FIELD = 'pallets'
export const CASES_FIELD = 'cases'
export const DELIVERY_NOTES_FIELD = 'delivery_notes'
export const ORDERS_ID_FIELD = 'orders_id'
export const SEAL_NUMBER_FIELD = 'seal_number'
export const DOCK_CLOSE_TIME_FIELD = 'dock_close_time'
export const USER_FIELD = 'user'