import { API_URL } from 'constants/apiconfig'

export const API_ROUTE = {
  APP_INFO: {
    FETCH: (project_id) => `${API_URL}app-info/${project_id}`,
    TEST_EXCEPTION: () => `${API_URL}test-exception`,
  },
  AUTH: {
    LOGIN: `${API_URL}login`,
    LOGOUT: `${API_URL}logout`,
    CHECK_USER: `${API_URL}login/check-user`,
    GET_USER_DATA: `${API_URL}current-user`,
    UPDATE: `${API_URL}user`,
  },
  CONTAINER: {
    FETCH_ONE: (id) => `${API_URL}containers/${id}`,
    FETCH_MANY: (queryString) => `${API_URL}containers${queryString}`,
    UPDATE: (id) => `${API_URL}containers/${id}`,
  },
  FILTER: {
    FETCH_MANY: `${API_URL}get-filters`,
  },
  LOCATION: {
    FETCH_ONE: (id) => `${API_URL}locations/${id}`,
    FETCH_MANY: (queryString) => `${API_URL}locations${queryString}`,
    FETCH_AVAILABLE_BAYS: `${API_URL}locations/available-bays`,
    CREATE_LANE_DELETE_REASON: `${API_URL}locations/create-lane-delete-reason`,
    CREATE: `${API_URL}locations`,
    CREATE_MANY: `${API_URL}locations/group`,
    UPDATE: (id) => `${API_URL}locations/${id}`,
    REMOVE: (id) => `${API_URL}locations/${id}`,
  },
  LOG: {
    CREATE_INV_ADJUSTMENT: `${API_URL}logs/inv-adjustment`,
  },
  ORDER: {
    FETCH_MANY: `${API_URL}processes/orders`,
    FETCH_ONE: (id) => `${API_URL}processes/orders/${id}`,
    UPDATE: `${API_URL}processes/orders`,
    DELETE: (id) => `${API_URL}processes/orders/${id}`,
    MOVE_TO_NEXT_DAY: (id) => `${API_URL}processes/orders-move-next-day/${id}`,
    UPDATE_LOAD_STATUS: `${API_URL}order-load-status`,
  },
  QA_HOLD: {
    FETCH_MANY: `${API_URL}qa-holds`,
    CREATE: `${API_URL}qa-holds`,
    UPDATE_MANY: `${API_URL}qa-holds/many`,
  },
  PICKUP_LANE: {
    DELETE: `${API_URL}pickup-lane`,
  },
  REASON: {
    FETCH_MANY: `${API_URL}reasons`,
  },
  RESTOCK_PROCESS: {
    FETCH_ONE: (id) => `${API_URL}processes/restock/${id}`,
    FETCH_MANY: `${API_URL}processes/restock`,
    CREATE: `${API_URL}processes/restock`,
    CREATE_LOCATION_RESTOCK: `${API_URL}restock-locations`,
    UPDATE: (id) => `${API_URL}processes/restock/${id}`,
  },
  UNLOAD_PROCESS: {
    CREATE: `${API_URL}processes/container-unload`,
  },
  SHIPMENT: {
    CREATE: `${API_URL}shipment`,
    FETCH_MANY: `${API_URL}shipment`,
    MARK_PRINTED: `${API_URL}shipment/mark-printed`,
    FETCH_ERROR: `${API_URL}shipment/error`,
    VOID: `${API_URL}shipment/void`,
  },
  SHIPPING_LANE: {
    DELETE: `${API_URL}shipping-lane`,
  },
  STAFF: {
    FETCH_MANY: `${API_URL}staff/assignments`,
  },
  TASK_ASSIGNMENT: {
    CREATE_MANY: `${API_URL}task-assignments/many`,
    DELETE_MANY: `${API_URL}task-assignments/many`,
  },
  WAREHOUSE_CASE: {
    FETCH_ONE: (queryString) => `${API_URL}warehouse-cases/${queryString}`,
    FETCH_MANY: (queryString) => `${API_URL}warehouse-cases${queryString}`,
    FETCH_OTHER: (queryString) =>
      `${API_URL}warehouse-cases/other${queryString}`,
    UPDATE: (id) => `${API_URL}warehouse-cases/${id}`,
  },
  WAREHOUSE_CASE_AND_PALLET: {
    FETCH_MANY: (payload) => `${API_URL}warehouse-cases-and-pallets${payload}`,
    INVENTORY_ADJUSTMENT: `${API_URL}warehouse-cases-and-pallets/inventory-adjustment`,
  },
  WAREHOUSE_PALLET: {
    FETCH_ONE: (queryString) => `${API_URL}warehouse-pallets/${queryString}`,
    FETCH_MANY: (queryString) => `${API_URL}warehouse-pallets${queryString}`,
    FETCH_OTHER: (queryString) =>
      `${API_URL}warehouse-pallets/other${queryString}`,
    FETCH_AVAILABLE_LOCATION_LIST: (queryString) =>
      `${API_URL}warehouse-pallets/available${queryString}`,
    UPDATE: (id) => `${API_URL}warehouse-pallets/${id}`,
  },
}
