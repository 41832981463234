export const buildVersionText = (appInfo) => {
  return appInfo.major_version
    ? appInfo.major_version +
        '.' +
        appInfo.minor_version +
        '.' +
        appInfo.patch_version +
        ' (B' +
        appInfo.build_id +
        ')'
    : ''
}
