import React from 'react'
import HeaderNav from './HeaderNav/index'
import HeaderLogo from './HeaderLogo/index'
import './style.scss'

const Header = (props) => (
  <div className="main__header">
    <HeaderLogo />
    <HeaderNav {...props} />
  </div>
)

export default Header
