export const types = {
  FETCH_CONTAINER_PROCESS_LIST_START: 'FETCH_CONTAINER_PROCESS_LIST_START',
  FETCH_CONTAINER_PROCESS_LIST_SUCCESS: 'FETCH_CONTAINER_PROCESS_LIST_SUCCESS',
  FETCH_CONTAINER_PROCESS_LIST_FAIL: 'FETCH_CONTAINER_PROCESS_LIST_FAIL',

  FETCH_ONE_CONTAINER_PROCESS_SUCCESS: 'FETCH_ONE_CONTAINER_PROCESS_SUCCESS',
  FETCH_ONE_CONTAINER_PROCESS_FAIL: 'FETCH_ONE_CONTAINER_PROCESS_FAIL',
  FETCH_ONE_CONTAINER_PROCESS_START: 'FETCH_ONE_CONTAINER_PROCESS_START',

  UPDATE_CONTAINER_PROCESS_START: 'UPDATE_CONTAINER_PROCESS_START',
  UPDATE_CONTAINER_PROCESS_SUCCESS: 'UPDATE_CONTAINER_PROCESS_SUCCESS',
  UPDATE_CONTAINER_PROCESS_FAIL: 'UPDATE_CONTAINER_PROCESS_FAIL',

  SORT_CONTAINER_PROCESS_LIST: 'SORT_CONTAINER_PROCESS_LIST',
}

export const fetchContainerProcessListStart = (payload) => ({
  type: types.FETCH_CONTAINER_PROCESS_LIST_START,
  payload,
})

export const fetchContainerProcessListSuccess = (payload) => ({
  type: types.FETCH_CONTAINER_PROCESS_LIST_SUCCESS,
  payload,
})

export const fetchContainerProcessListFail = (payload) => ({
  type: types.FETCH_CONTAINER_PROCESS_LIST_FAIL,
  payload,
})

export const fetchOneContainerProcessStart = (payload) => ({
  type: types.FETCH_ONE_CONTAINER_PROCESS_START,
  payload,
})

export const fetchOneContainerProcessSuccess = (payload) => ({
  type: types.FETCH_ONE_CONTAINER_PROCESS_SUCCESS,
  payload,
})

export const fetchOneContainerProcessFail = (payload) => ({
  type: types.FETCH_ONE_CONTAINER_PROCESS_FAIL,
  payload,
})

export const updateContainerProcessStart = (payload) => ({
  type: types.UPDATE_CONTAINER_PROCESS_START,
  payload,
})

export const updateContainerProcessSuccess = (payload) => ({
  type: types.UPDATE_CONTAINER_PROCESS_SUCCESS,
  payload,
})

export const updateContainerProcessFail = (payload) => ({
  type: types.UPDATE_CONTAINER_PROCESS_FAIL,
  payload,
})

export const sortContainerProcessList = (payload) => ({
  type: types.SORT_CONTAINER_PROCESS_LIST,
  payload,
})
