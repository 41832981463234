import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const TextArea = (props) => {
  const {
    tabIndex,
    name,
    type,
    className,
    placeholder,

    value,

    disabled,
    isInvalid,

    onChange,
    onBlur = () => false,

    pattern = undefined,
    autoComplete,
    style,
  } = props

  const onChangeInput = ({ target: { value } }) => {
    if (pattern !== undefined) {
      if (!pattern.test(value)) {
        onChange(name, null)
        return null
      }
    }
    onChange(name, value)
  }

  return (
    <textarea
      tabIndex={tabIndex}
      className={`text-area ${isInvalid ? 'text-area--invalid' : ''} ${
        className ? className : ''
      }`}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChangeInput}
      onBlur={onBlur}
      autoComplete={autoComplete}
      style={style || {}}
    />
  )
}

TextArea.propTypes = {
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,

  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  pattern: PropTypes.any,
  autoComplete: PropTypes.any,
}

export default TextArea
