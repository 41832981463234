import { takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchOneRestockProcessFail,
  fetchOneRestockProcessSuccess,
  fetchRestockProcessListFail,
  fetchRestockProcessListSuccess,
  types,
} from './actions'
import {
  create,
  update,
  fetchMany,
  fetchOne,
  createLocationRestock,
} from 'api/restockProcess'

function* createProcess(action) {
  const { payload } = action

  try {
    yield call(create, payload)
  } catch (error) {}
}

function* updateRestockProcess(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

function* fetchRestockProcessList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchRestockProcessListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchRestockProcessListFail())
  }
}

function* fetchOneRestockProcess(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneRestockProcessSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneRestockProcessFail())
  }
}

function* createRestockLocation(action) {
  const { payload } = action

  try {
    const response = yield call(createLocationRestock, payload)
    yield call(create, {
      restock_location_id: response.data.data.item.id,
    })
  } catch (error) {}
}

function* createRestockCombineLocation(action) {
  const { payload } = action

  let restockLocationIds = []

  try {
    for (let i = 0; i < payload.data.length; i++) {
      const response = yield call(createLocationRestock, {
        data: payload.data[i].restock_location,
      })

      yield (restockLocationIds = [
        ...restockLocationIds,
        response.data.data.item.id,
      ])
    }

    yield call(create, {
      restock_location_ids: restockLocationIds,
    })
  } catch (error) {}
}

export default function* restockProcessSaga() {
  yield takeEvery(types.CREATE_RESTOCK_PROCESS, createProcess)
  yield takeEvery(
    types.FETCH_RESTOCK_PROCESS_LIST_START,
    fetchRestockProcessList,
  )
  yield takeEvery(types.FETCH_ONE_RESTOCK_PROCESS_START, fetchOneRestockProcess)
  yield takeEvery(types.UPDATE_RESTOCK_PROCESS, updateRestockProcess)
  yield takeEvery(types.CREATE_RESTOCK_LOCATION, createRestockLocation)
  yield takeEvery(
    types.CREATE_RESTOCK_COMBINE_LOCATION,
    createRestockCombineLocation,
  )
}
