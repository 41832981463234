export const TASK_TYPES = {
  AVAILABLE_TYPE: 0,
  CONTAINER_UNLOAD_TYPE: 1,
  UNLOAD_WRAP_TYPE: 2,
  UNLOAD_PUTAWAY_TYPE: 3,
  ORDER_TYPE: 4,
  LOCATION_PICKING: 5,
  PUTAWAY_TYPE: 7,
  PICKING_TYPE: 8,
  RESTOCKING_TYPE: 9,
  RELOCATE_TYPE: 10,
  PALLET_LOADING_TYPE: 11,
  LOADING_TYPE: 12,
}

export const TASK_ASSIGNMENT_TYPES = [
  {
    id: TASK_TYPES.AVAILABLE_TYPE,
    name: 'available',
    label: 'Available',
    shortName: 'A',
  },
  {
    id: TASK_TYPES.CONTAINER_UNLOAD_TYPE,
    name: 'unload',
    label: 'Unload',
    shortName: 'UL',
  },
  {
    id: TASK_TYPES.PUTAWAY_TYPE,
    name: 'putaway',
    label: 'Putaway',
    shortName: 'Pa',
  },
  {
    id: TASK_TYPES.PICKING_TYPE,
    name: 'picking',
    label: 'Picking',
    shortName: 'P',
  },
  {
    id: TASK_TYPES.RESTOCKING_TYPE,
    name: 'restocking',
    label: 'Restocking',
    shortName: 'Res',
  },
  {
    id: TASK_TYPES.ORDER_TYPE,
    name: 'order picking',
    label: 'Order',
    shortName: 'O',
  },
  {
    id: TASK_TYPES.RELOCATE_TYPE,
    name: 'relocate',
    label: 'Relocate',
    shortName: 'Rel',
  },
  {
    id: TASK_TYPES.LOADING_TYPE,
    name: 'loading',
    label: 'Loading',
    shortName: 'L',
  },
]
