import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './style.scss'
import { ROUTES } from 'constants/routes'
import { ON_NAV_ITEM_CLICK } from 'constants/events'
import SideBarItem from 'components/common/SideBar/SideBarItem'
import OrderIcon from 'assets/svg/orders.svg'
import InventoryIcon from 'assets/svg/inventory.svg'
import LocationIcon from 'assets/svg/location.svg'
import ContainerIcon from 'assets/svg/container.svg'
import GearIcon from 'assets/svg/gear.svg'
import DoubleArrowRight from 'assets/svg/circle-double-arrow-right.svg'
import DoubleArrowLeft from 'assets/svg/circle-double-arrow-left.svg'
import { buildVersionText } from 'helpers/version'
import { selectPickOrderItems } from 'store/orderProcess/selectors'

const SideBar = ({ orders, shipping, appInfo, isStale }) => {
  const [isCompact, setIsCompact] = useState(true)

  const aboutBadge = isStale ? '!' : null

  const SIDEBAR_TOP_ITEMS = [
    {
      id: 1,
      iconItem: <LocationIcon className="svg" />,
      content: 'Location',
      label: 'Location',
      link: ROUTES.LOCATION_LIST_PAGE,
      subItems: [],
      isActive: true,
    },
    {
      id: 2,
      iconItem: <ContainerIcon className="svg svg--container" />,
      content: 'Container',
      label: 'Container',
      subItems: [
        {
          id: 21,
          content: 'Receiving',
          shortContent: 'R',
          label: 'Receiving',
          link: ROUTES.CONTAINER_RECEIVING_LIST_PAGE,
        },
        {
          id: 22,
          content: 'Unloading',
          shortContent: 'U',
          label: 'Unloading',
          link: ROUTES.CONTAINER_UNLOADING_LIST_PAGE,
        },
      ],
      isActive: true,
    },
    {
      id: 3,
      iconItem: <OrderIcon className="svg svg--order" />,
      content: 'Order',
      label: 'Order',
      isBadged: !!orders.length  || !!shipping.length,
      subItems: [
        {
          id: 31,
          content: 'Orders',
          shortContent: 'O',
          label: 'Orders',
          link: ROUTES.ORDERS_ACTIVE_PAGE,
          badge: orders.length,
        },
        {
          id: 32,
          content: 'Order Removed',
          shortContent: 'Re',
          label: 'Order Removed',
          link: ROUTES.ORDERS_REMOVED_PAGE,
          isActive: false,
        },
        {
          id: 33,
          content: 'Shipping',
          shortContent: 'S',
          label: 'Shipping',
          link: ROUTES.ORDERS_SHIPPING_PAGE,
          badge: shipping.length,
        },
      ],
      isActive: true,
    },
    {
      id: 4,
      iconItem: <InventoryIcon className="svg" />,
      content: 'Inventory',
      label: 'Inventory',
      link: '',
      subItems: [
        {
          id: 41,
          content: 'Inventory Management',
          shortContent: 'M',
          label: 'Inventory Management',
          link: ROUTES.INVENTORY_INVENTORY_MANAGEMENT_PAGE,
        },
        {
          id: 42,
          content: 'Damage Resolution',
          shortContent: 'D',
          label: 'Damage Resolution',
          link: '',
          isActive: false,
        },
        {
          id: 43,
          content: 'QA Hold Resolution',
          shortContent: 'Q',
          label: 'QA Hold Resolution',
          link: ROUTES.INVENTORY_QA_HOLD_RESOLUTION_PAGE,
        },
        {
          id: 44,
          content: 'Return Resolution',
          shortContent: 'R',
          label: 'Return Resolution',
          link: '',
          isActive: false,
        },
      ],
      isActive: true,
    },
  ]

  const SIDEBAR_BOTTOM_ITEMS = [
    {
      id: 1,
      iconItem: <GearIcon className="svg svg--gear" />,
      content: 'Admin',
      label: 'Admin',
      link: '',
      isBadged: isStale,
      subItems: [
        {
          id: 11,
          content: 'About',
          shortContent: 'A',
          label: 'About',
          link: ROUTES.ABOUT_PAGE,
          badge: aboutBadge,
        },
        {
          id: 12,
          content: 'Clear Printers',
          shortContent: 'C',
          label: 'Clear Printers',
          link: ROUTES.CLEAR_PRINTERS_PAGE,
        },
      ],
      isActive: true,
    },
  ]

  const onToggle = () => setIsCompact(!isCompact)
  const onNavItemSelect = () =>
    window.dispatchEvent(new Event(ON_NAV_ITEM_CLICK))

  const sideBarClassName = `sidebar${isCompact ? ' sidebar--open' : ''}`
  const version = buildVersionText(appInfo)

  return (
    <div className={sideBarClassName}>
      <div className="toggle" onClick={onToggle}>
        {isCompact ? (
          <DoubleArrowLeft className="svg-hover" />
        ) : (
          <DoubleArrowRight className="svg-hover" />
        )}
      </div>
      <div className="nav">
        {SIDEBAR_TOP_ITEMS.map((item) => (
          <SideBarItem key={item.id} item={item} onClick={onNavItemSelect} />
        ))}
        <span className="divider" />
        {SIDEBAR_BOTTOM_ITEMS.map((item) => (
          <SideBarItem key={item.id} item={item} onClick={onNavItemSelect} />
        ))}
      </div>
      <div className="version-info">{version}</div>
    </div>
  )
}

SideBar.propTypes = {
  orders: PropTypes.array,
}

const mapStateToProps = (state) => ({
  shipping: selectPickOrderItems(state),
  orders: [...state.orderProcess.today, ...state.orderProcess.nextDay],
  appInfo: state.appInfo.item,
  isStale: state.appInfo.isStale,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
