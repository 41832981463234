import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ContentSection from 'components/common/PageContent/ContentSection'
import BlackCheckIcon from 'assets/svg/black-check.svg'
import List from 'components/common/List'
import Checkbox from 'components/common/Checkbox'
import { CONTAINER_STATUS } from 'constants/containerStatuses'

const ContainerProductInformation = ({
  items,
  container,
  onEditQAHoldDetails,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    items.filter((item) => item.isQAHold),
  )

  const isNeedQaHold =
    [
      CONTAINER_STATUS.ON_WATER,
      CONTAINER_STATUS.ON_RAIL,
      CONTAINER_STATUS.ON_TRUCK,
      CONTAINER_STATUS.RECEIVING,
      CONTAINER_STATUS.PREPARE_TO_UNLOAD,
    ].indexOf(container.container_status.status_id) > -1

  let columns = [
    {
      id: 1,
      label: 'Model',
      value: 'products_model',
    },
    {
      id: 2,
      label: 'Manf Model',
      value: 'products_manf_model',
    },
    {
      id: 3,
      label: 'Product Name',
      value: 'products_name',
    },
    {
      id: 4,
      label: 'Case',
      value: 'quantity',
    },
  ]

  if (isNeedQaHold) {
    columns = [
      ...columns,
      ...[
        {
          id: 5,
          label: 'QA Hold',
          value: 'isQAHold',
        },
      ],
    ]
  }

  const onEditQAHold = () => {
    if (!selectedItems.length) {
      return
    }

    onEditQAHoldDetails(selectedItems.filter((item) => item.isNew))
  }

  const getNormalizedItems = (items) =>
    items.map((item) => {
      const onCheck = () =>
        setSelectedItems(
          !!selectedItems.find((selectedItem) => selectedItem.id === item.id)
            ? selectedItems.filter(
                (selectedItem) => selectedItem.id !== item.id,
              )
            : [...selectedItems, { ...item, isNew: true }],
        )
      const foundItem = selectedItems.find(
        (selectedItem) => item.id === selectedItem.id,
      )

      const mappedItem = {
        id: item.id,
        products_model: item.product.products_model,
        products_manf_model: item.product.products_manf_model,
        products_name: item.description.products_name,
        quantity: item.quantity,
      }

      return isNeedQaHold
        ? {
            ...mappedItem,
            isQAHold:
              foundItem && !foundItem.isNew ? (
                <BlackCheckIcon className="svg--checkmark" />
              ) : (
                <Checkbox onClick={onCheck} checked={!!foundItem} />
              ),
          }
        : mappedItem
    })

  return (
    <ContentSection
      className={`product-list${
        items.length === 0 ? ' product-list--empty' : ''
      }`}
    >
      <div className="header">
        <div className="header__title">
          <span>Product Information</span>
          {selectedItems.filter((item) => item.isNew).length > 0 && (
            <span
              onClick={onEditQAHold}
              className={`header__edit header__edit--active`}
            >
              Edit QA Hold Detail
            </span>
          )}
        </div>
      </div>
      <hr />
      <List
        placeholder="Product List will be Coming Soon"
        items={getNormalizedItems(items)}
        columns={columns}
      />
    </ContentSection>
  )
}

ContainerProductInformation.propTypes = {
  items: PropTypes.array.isRequired,

  container: PropTypes.object,
}

export default ContainerProductInformation
