import React from 'react'
import * as PropTypes from 'prop-types'
import { getUrlParams } from 'helpers'
import { DEFAULT_URL_PARAMS } from 'constants/urlDefaultParams'

const RecordCountMessage = (props) => {
  const { metaData, unitsText } = props

  let currentPage
  let perPage
  let totalPages
  let remainder
  let lastPreviousPageListItemNumber
  let displayedListRangeStart
  let displayedListRangeEnd

  if (metaData) {
    currentPage = parseInt(metaData.page)
    perPage = parseInt(metaData.perPage)
    totalPages = Math.ceil(
      metaData.numberFound /
        parseInt(getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM]),
    )
    remainder = metaData.numberFound % perPage
    lastPreviousPageListItemNumber = currentPage * perPage - perPage
    displayedListRangeStart = lastPreviousPageListItemNumber + 1
    if (currentPage === totalPages) {
      displayedListRangeEnd = lastPreviousPageListItemNumber + remainder
    } else {
      displayedListRangeEnd = displayedListRangeStart + perPage - 1
    }
  }

  return (
    <>
      {metaData ? displayedListRangeStart : ''}
      <small style={{ textTransform: 'lowercase' }}>
        {metaData ? ' to ' : ''}
      </small>
      {metaData ? displayedListRangeEnd : ''}
      <small style={{ textTransform: 'lowercase' }}>
        {metaData ? ', of ' : ''}
      </small>
      {metaData ? metaData.numberFound : ''} {unitsText}
    </>
  )
}

RecordCountMessage.propTypes = {
  metaData: PropTypes.any,
  unitsText: PropTypes.any,
}

export default RecordCountMessage
