export const types = {
  FETCH_WAREHOUSE_PALLET_LIST_START: 'FETCH_WAREHOUSE_PALLET_LIST_START',
  FETCH_WAREHOUSE_PALLET_LIST_SUCCESS: 'FETCH_WAREHOUSE_PALLET_LIST_SUCCESS',
  FETCH_WAREHOUSE_PALLET_LIST_SUCCESS_APPEND:
    'FETCH_WAREHOUSE_PALLET_LIST_SUCCESS_APPEND',
  FETCH_WAREHOUSE_PALLET_LIST_FAIL: 'FETCH_WAREHOUSE_PALLET_LIST_FAIL',

  FETCH_ONE_WAREHOUSE_PALLET_START: 'FETCH_ONE_WAREHOUSE_PALLET_START',
  FETCH_ONE_WAREHOUSE_PALLET_SUCCESS: 'FETCH_ONE_WAREHOUSE_PALLET_SUCCESS',
  FETCH_ONE_WAREHOUSE_PALLET_FAIL: 'FETCH_ONE_WAREHOUSE_PALLET_FAIL',

  FETCH_OTHER_WAREHOUSE_PALLET_LIST_START:
    'FETCH_OTHER_WAREHOUSE_PALLET_LIST_START',
  FETCH_OTHER_WAREHOUSE_PALLET_LIST_SUCCESS:
    'FETCH_OTHER_WAREHOUSE_PALLET_LIST_SUCCESS',
  FETCH_OTHER_WAREHOUSE_PALLET_LIST_FAIL:
    'FETCH_OTHER_WAREHOUSE_PALLET_LIST_FAIL',

  FETCH_AVAILABLE_LOCATION_LIST_START: 'FETCH_AVAILABLE_LOCATION_LIST_START',
  FETCH_AVAILABLE_LOCATION_LIST_SUCCESS:
    'FETCH_AVAILABLE_LOCATION_LIST_SUCCESS',
  FETCH_AVAILABLE_LOCATION_LIST_FAIL: 'FETCH_AVAILABLE_LOCATION_LIST_FAIL',

  UPDATE_WAREHOUSE_PALLET: 'UPDATE_WAREHOUSE_PALLET',
}

export const updateWarehousePallet = (payload) => ({
  type: types.UPDATE_WAREHOUSE_PALLET,
  payload,
})

export const fetchWarehousePalletListStart = (payload) => ({
  type: types.FETCH_WAREHOUSE_PALLET_LIST_START,
  payload,
})
export const fetchWarehousePalletListSuccess = (payload) => ({
  type: types.FETCH_WAREHOUSE_PALLET_LIST_SUCCESS,
  payload,
})
export const fetchWarehousePalletListSuccessAppend = (payload) => ({
  type: types.FETCH_WAREHOUSE_PALLET_LIST_SUCCESS_APPEND,
  payload,
})
export const fetchWarehousePalletListFail = (payload) => ({
  type: types.FETCH_WAREHOUSE_PALLET_LIST_FAIL,
  payload,
})

export const fetchOneWarehousePalletStart = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_PALLET_START,
  payload,
})
export const fetchOneWarehousePalletSuccess = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_PALLET_SUCCESS,
  payload,
})
export const fetchOneWarehousePalletFail = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_PALLET_FAIL,
  payload,
})

export const fetchOtherWarehousePalletListStart = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_START,
  payload,
})
export const fetchOtherWarehousePalletListSuccess = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_SUCCESS,
  payload,
})
export const fetchOtherWarehousePalletListFail = (payload) => ({
  type: types.FETCH_OTHER_WAREHOUSE_PALLET_LIST_FAIL,
  payload,
})

export const fetchAvailableLocationListStart = (payload) => ({
  type: types.FETCH_AVAILABLE_LOCATION_LIST_START,
  payload,
})
export const fetchAvailableLocationListSuccess = (payload) => ({
  type: types.FETCH_AVAILABLE_LOCATION_LIST_SUCCESS,
  payload,
})
export const fetchAvailableLocationListFail = (payload) => ({
  type: types.FETCH_AVAILABLE_LOCATION_LIST_FAIL,
  payload,
})
