import { takeLatest, call, put } from 'redux-saga/effects'
import { types } from 'store/taskAssignment/actions'
import { createMany, deleteMany } from 'api/taskAssignment'
import { fetchStaffAssignmentStart } from 'store/staff/actions'

function* createManyTaskAssignment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(createMany, request)
    yield put(fetchStaffAssignmentStart())

    if (afterAction) {
      yield put(afterAction())
    }
  } catch (error) {}
}

function* deleteManyTaskAssignment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(deleteMany, request)
    yield put(fetchStaffAssignmentStart())

    if (afterAction) {
      yield put(afterAction())
    }
  } catch (error) {}
}

export default function* taskAssignmentSaga() {
  yield takeLatest(types.CREATE_MANY_TASK_ASSIGNMENT, createManyTaskAssignment)
  yield takeLatest(types.DELETE_MANY_TASK_ASSIGNMENT, deleteManyTaskAssignment)
}
