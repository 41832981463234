import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const ContentSection = ({ className = '', children }) => (
  <div className={`content-section ${className}`}>{children}</div>
)

ContentSection.propTypes = {
  className: PropTypes.string,
}

export default ContentSection
