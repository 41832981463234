export const MODAL_CHOOSE_PRINTER = 'MODAL_CHOOSE_PRINTER'
export const MODAL_INVENTORY_ADJUSTMENT = 'MODAL_INVENTORY_ADJUSTMENT'
export const MODAL_PENDING = 'modalPending'
export const MODAL_CONFIRM_DEFAULT = 'confirm-default'
export const MODAL_INFO = 'info'
export const MODAL_PRODUCTS_LIST = 'products-list'
export const MODAL_SUCCESS = 'success'
export const MODAL_CHOOSE_COPIES = 'modalChooseCopies'
export const MODAL_WARNING = 'warning'
export const MODAL_LANE_PALLET = 'modalLanePallet'
