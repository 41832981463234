import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import '../style.scss'
import ExpandableBlock from 'components/common/ExpandableBlock'
import TableList from 'components/common/TableList'
import { dateToFormat } from 'helpers/index'
import Checkbox from 'components/common/Checkbox'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { connect } from 'react-redux'
import { showModal, resetModals } from 'store/modal/actions'
import BurgerIcon from '../../../../../assets/svg/active.svg'
import { MODAL_PRODUCTS_LIST } from 'components/common/Modal'

const GroupedOrderRemovedList = (props) => {
  const {
    items,
    resolutions,

    showModal,
    setResolutions,
  } = props

  const [isOpen, setIsOpen] = useState(true)

  const columns = [
    {
      id: 1,
      label: 'Order',
      value: 'order',
    },
    {
      id: 2,
      label: 'Case',
      value: 'case',
    },
    {
      id: 3,
      label: 'Customer',
      value: 'customer',
    },
    {
      id: 4,
      label: 'Status',
      value: 'status',
    },
    {
      id: 5,
      label: 'Removed Date',
      value: 'removedDate',
    },
    {
      id: 6,
      label: 'Shipping/Pickup Date',
      value: 'shippingPickupDate',
    },
    {
      id: 7,
      label: items.length > 0 ? 'Hold' : '',
      value: 'isHold',
    },
    {
      id: 8,
      label: items.length > 0 ? 'Back To Inventory' : '',
      value: 'isBackToInventory',
    },
  ]

  const getItems = () => {
    return items.map((item) => {
      const isHoldClicked = !!resolutions.find(
        (resolution) =>
          resolution.id === item.id &&
          resolution.order_pick_status ===
            ORDER_PICK_STATUSES.STATUS_REMOVE_HOLD,
      )

      const isBackToInventoryClicked = !!resolutions.find(
        (resolution) =>
          resolution.id === item.id &&
          resolution.order_pick_status ===
            ORDER_PICK_STATUSES.STATUS_REMOVE_BACK_TO_INV,
      )

      const onClickHold = () => {
        if (isHoldClicked) {
          setResolutions(
            resolutions.filter((resolution) => resolution.id !== item.id),
          )
        } else {
          setResolutions([
            ...resolutions.filter((resolution) => resolution.id !== item.id),
            {
              id: item.id,
              order_pick_status: ORDER_PICK_STATUSES.STATUS_REMOVE_HOLD,
            },
          ])
        }
      }

      const onClickBackToInventory = () => {
        if (isBackToInventoryClicked) {
          setResolutions(
            resolutions.filter((resolution) => resolution.id !== item.id),
          )
        } else {
          setResolutions([
            ...resolutions.filter((resolution) => resolution.id !== item.id),
            {
              id: item.id,
              order_pick_status: ORDER_PICK_STATUSES.STATUS_REMOVE_BACK_TO_INV,
            },
          ])
        }
      }

      const openModal = () => {
        showModal({
          name: MODAL_PRODUCTS_LIST,
          options: item,
        })
      }

      const pickedCaseCount = item.entity.pickLocations.reduce(
        (sum, currentValue) => {
          return currentValue.picked_quantity + sum
        },
        0,
      )

      const caseCount = item.entity.pickLocations.reduce(
        (sum, currentValue) => {
          return currentValue.location_pick_quantity + sum
        },
        0,
      )

      const date = item.entity.order.shipping_date
        ? dateToFormat(item.entity.order.shipping_date, 'MM/DD/YYYY')
        : dateToFormat(item.entity.order.pickup_date, 'MM/DD/YYYY')

      return {
        id: item.id,
        order: item.entity.order.orders_id,
        case: (
          <>
            <span className="case-count-span">
              {pickedCaseCount}/{caseCount}
            </span>
            <BurgerIcon onClick={openModal} />
          </>
        ),
        customer: item.entity.order.customers_name,
        status: item.entity.orderPickStatus.name,
        removedDate: dateToFormat(item.end_date, 'MM/DD/YYYY'),
        shippingPickupDate: date,
        isHold:
          item.entity.orderPickStatus.id ===
          ORDER_PICK_STATUSES.STATUS_REMOVE_HOLD ? (
            <></>
          ) : (
            <Checkbox onClick={onClickHold} checked={isHoldClicked} />
          ),
        isBackToInventory:
          item.entity.orderPickStatus.id ===
          ORDER_PICK_STATUSES.STATUS_REMOVE_BACK_TO_INV ? (
            <></>
          ) : (
            <Checkbox
              onClick={onClickBackToInventory}
              checked={isBackToInventoryClicked}
            />
          ),
      }
    })
  }

  return (
    <ExpandableBlock
      className="table-expand"
      headerContent={`REMOVED ORDER (${items.length})`}
      title={``}
      isExpanded={isOpen}
      bodyContent={
        <TableList className="list" rows={getItems()} columns={columns} />
      }
      onToggle={() => setIsOpen(!isOpen)}
    />
  )
}

GroupedOrderRemovedList.propTypes = {
  items: PropTypes.array,

  onChange: PropTypes.func,
}

const mapDispatchToProps = {
  showModal,
  resetModals,
}

export default connect(null, mapDispatchToProps)(GroupedOrderRemovedList)
