import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_CASE_AND_PALLET.FETCH_MANY(payload),
    method: 'GET',
  })

export const inventoryAdjustment = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_CASE_AND_PALLET.INVENTORY_ADJUSTMENT,
    method: 'PUT',
    data: payload,
  })
