import React from 'react'
import StaffTaskAssignmentItem from '../StaffTaskAssignmentItem'
import * as PropTypes from 'prop-types'

const StaffTaskAssignmentList = ({ items }) => {
  return (
    <div className="tasks">
      <span className="tasks__title">Task Details</span>
      <ul className="tasks__list">
        {items.map((item) => (
          <StaffTaskAssignmentItem key={item.id} item={item} />
        ))}
      </ul>
    </div>
  )
}

StaffTaskAssignmentList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default StaffTaskAssignmentList
