import React, { useState } from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

import Modal from 'react-modal'
import Button from '../../Button'
import { connect } from 'react-redux'
import { MODAL_CHOOSE_COPIES } from 'components/common/Modal'
import TextInput from '../../TextInput'
import { REGEX } from 'constants/regex'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '650px',
  },
}

const ModalChooseCopies = ({ isOpen, modal }) => {
  if (!isOpen) {
    return <></>
  }

  const [printQty, setPrintQty] = useState(1)

  const onChange = (name, value) => {
    setPrintQty(!value ? 0 : parseInt(value))
  }

  const handleAccept = () => {
    modal.events.onAccept(printQty)
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal modal--choose-copies">
        <div>
          <h1 className="title">How Many Copies?</h1>
          <TextInput
            onChange={onChange}
            pattern={REGEX.IS_INTEGER}
            value={printQty}
            name="printQty"
          />
        </div>
        <div className="btn-menu">
          <Button onClick={modal.events.onDeny} text="Cancel" />
          <Button text="OK" onClick={handleAccept} />
        </div>
      </div>
    </Modal>
  )
}

ModalChooseCopies.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CHOOSE_COPIES,
  ),
  modal: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_CHOOSE_COPIES,
  ),
})

export default connect(mapStateToProps)(ModalChooseCopies)
