import React from 'react'
import { takeLatest, call, put } from 'redux-saga/effects'
import { fetchMany, fetchOther, update } from 'api/warehouseCase'
import {
  fetchOtherWarehouseCasesListFail,
  fetchOtherWarehouseCasesListSuccess,
  fetchWarehouseCasesListFail,
  fetchWarehouseCasesListSuccess,
  fetchWarehouseCasesListSuccessAppend,
  types,
} from './actions'
import { resetModals, showModal } from '../modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'

function* fetchWarehouseCasesList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)

    yield put(
      showModal({
        name: MODAL_PENDING,
        content: <div className="text">Fetching pallets...</div>,
      }),
    )

    yield put(
      response.data.meta.page === '1'
        ? fetchWarehouseCasesListSuccess(response.data.data.items)
        : fetchWarehouseCasesListSuccessAppend(response.data.data.items),
    )
    yield put(resetModals())
  } catch (error) {
    yield put(fetchWarehouseCasesListFail())
  }
}

function* fetchOtherWarehouseCasesList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOther, payload)

    yield put(fetchOtherWarehouseCasesListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchOtherWarehouseCasesListFail())
  }
}

function* updateWarehouseCase(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

export default function* warehouseCasesSaga() {
  yield takeLatest(
    types.FETCH_WAREHOUSE_CASES_LIST_START,
    fetchWarehouseCasesList,
  )
  yield takeLatest(
    types.FETCH_OTHER_WAREHOUSE_CASES_LIST_START,
    fetchOtherWarehouseCasesList,
  )
  yield takeLatest(types.UPDATE_WAREHOUSE_CASE, updateWarehouseCase)
}
