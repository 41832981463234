import React from 'react'

import './style.scss'

const LinearProgressBar = ({ progress, label }) => {
  const customStyles = {
    width: `${progress}%`,
  }

  return (
    <div
      className={`LinearProgressbar ${
        progress === 0 ? 'LinearProgressbar--empty' : ''
      }`}
    >
      <div className="LinearProgressbar__scale">
        <div className="LinearProgressbar__bar" style={customStyles} />
      </div>
      <div className="LinearProgressbar__text">{`${label}`}</div>
    </div>
  )
}

export default LinearProgressBar
