import React from 'react'
import LinearProgressBar from 'components/common/LinearProgressBar'
import * as PropTypes from 'prop-types'
import { PROCESS_TYPE_LABELS, PROCESS_TYPES } from 'constants/process'
import { TASK_ASSIGNMENT_TYPES, TASK_TYPES } from 'constants/taskAssignment'

const StaffTaskAssignmentItem = ({ item }) => {
  const { entity, process } = item

  const label = TASK_ASSIGNMENT_TYPES.find(
    (type) => type.id === item.taskType.id,
  ).shortName
  const entityName = PROCESS_TYPE_LABELS[process.process_type_id]

  const getRow = () => {
    switch (process.process_type_id) {
      case PROCESS_TYPES.CONTAINER_UNLOAD_PROCESS_TYPE:
        const received = entity.container_products
          .map((item) =>
            item.received > item.quantity ? item.quantity : item.received,
          )
          .reduce((sum, item) => sum + item)

        return (
          <>
            <div className="text-wrapper">
              <span className="label">{label}</span>
              <span className="text text--bold">{entityName}</span>&nbsp;
              <span>{entity.name}</span>
            </div>
            <div className="text-wrapper">
              <span className="text text--bold">Dock</span>&nbsp;
              <span>{entity.dock.name}</span>
            </div>
            <div className="text-wrapper">
              <span className="text text--bold">Case</span>&nbsp;
              <span>{entity.invoice_cases_quantity}</span>
            </div>
            <div className="text-wrapper">
              <span className="text text--bold">Status</span>
              <LinearProgressBar
                label={`${received}/${entity.invoice_cases_quantity}`}
                progress={(received / entity.invoice_cases_quantity) * 100}
              />
            </div>
          </>
        )
      case PROCESS_TYPES.ORDER_PROCESS_TYPE:
        if (item.taskType.id !== TASK_TYPES.PICKING_TYPE) {
          return ''
        }

        const orderCasesQty = entity.order.total_products_quantity

        return (
          <>
            <div className="text-wrapper">
              <span className="label">{label}</span>
              <span className="text text--bold">Order number</span>&nbsp;
              <span>{entity.order.orders_id}</span>
            </div>
            <div className="text-wrapper" />
            <div className="text-wrapper">
              <span className="text text--bold">Case</span>&nbsp;
              <span>{orderCasesQty}</span>
            </div>
            <div className="text-wrapper">
              <span className="text text--bold">Status</span>
              <LinearProgressBar
                label={`${15}/${orderCasesQty}`}
                progress={15}
              />
            </div>
          </>
        )
      case PROCESS_TYPES.RESTOCK_PROCESS_TYPE:
        return (
          <div className="text-wrapper">
            <span className="label">{label}</span>
            <span className="text text--bold">Restock total</span>&nbsp;
            <span>{item.restockTotal}</span>
          </div>
        )
      default:
        return ''
    }
  }

  return <li className="tasks__item">{getRow()}</li>
}

StaffTaskAssignmentItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default StaffTaskAssignmentItem
