import React from 'react'
import 'components/common/Header/style.scss'

const HeaderLogo = () => (
  <div className="main__logo">
    <img
      src={'https://d29j2csl3b3dix.cloudfront.net/_images/logoss.png'}
      className="icon__img"
      alt="Spirit of '76 WMS"
    />
  </div>
)

export default HeaderLogo
