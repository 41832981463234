import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import { TASK_ASSIGNMENT_TYPES, TASK_TYPES } from 'constants/taskAssignment'
import StaffEngagementTab from './StaffEngagementTab'
import { isEmptyObject } from 'helpers'

const TABS = {
  TAB_ALL: 1,
  TAB_AVAILABLE: 2,
  TAB_CONTAINER_UNLOAD: 3,
  TAB_PUTAWAY: 4,
  TAB_PICKING: 5,
  TAB_RESTOCKING: 6,
  TAB_RELOCATING: 7,
  TAB_LOADING: 8,
}

const StaffEngagement = ({
  staff = {},
  onAssign,
  onShowDetailsPopup,
  assignToList,
}) => {
  const [tabs, setTabs] = useState([])
  const [selectedTab, setSelectedTab] = useState({})

  useEffect(() => {
    if (isEmptyObject(staff)) {
      return
    }

    const availableStaff = staff[TASK_TYPES.AVAILABLE_TYPE]
      ? staff[TASK_TYPES.AVAILABLE_TYPE]
      : []
    const containerUnloadStaff = staff[TASK_TYPES.CONTAINER_UNLOAD_TYPE]
      ? staff[TASK_TYPES.CONTAINER_UNLOAD_TYPE]
      : []
    const putawayStaff = staff[TASK_TYPES.PUTAWAY_TYPE]
      ? staff[TASK_TYPES.PUTAWAY_TYPE]
      : []
    const pickingStaff = staff[TASK_TYPES.PICKING_TYPE]
      ? staff[TASK_TYPES.PICKING_TYPE]
      : []
    const restockingStaff = staff[TASK_TYPES.RESTOCKING_TYPE]
      ? staff[TASK_TYPES.RESTOCKING_TYPE]
      : []
    const relocateStaff = staff[TASK_TYPES.RELOCATE_TYPE]
      ? staff[TASK_TYPES.RELOCATE_TYPE]
      : []
    const loadingStaff = staff[TASK_TYPES.LOADING_TYPE]
      ? staff[TASK_TYPES.LOADING_TYPE]
      : []

    const allStaff = [
      ...availableStaff,
      ...containerUnloadStaff,
      ...putawayStaff,
      ...pickingStaff,
      ...restockingStaff,
      ...relocateStaff,
      ...loadingStaff,
    ]

    const dedicatedTabs = [
      {
        id: TABS.TAB_AVAILABLE,
        header: {
          label: `Available (${availableStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.AVAILABLE_TYPE,
            staff: availableStaff,
          },
        },
      },
      {
        id: TABS.TAB_CONTAINER_UNLOAD,
        header: {
          label: `Unloading (${containerUnloadStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.CONTAINER_UNLOAD_TYPE,
            staff: containerUnloadStaff,
          },
        },
      },
      {
        id: TABS.TAB_PUTAWAY,
        header: {
          label: `Putaway (${putawayStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.PUTAWAY_TYPE,
            staff: putawayStaff,
          },
        },
      },
      {
        id: TABS.TAB_PICKING,
        header: {
          label: `Picking (${pickingStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.PICKING_TYPE,
            staff: pickingStaff,
          },
        },
      },
      {
        id: TABS.TAB_RESTOCKING,
        header: {
          label: `Restocking (${restockingStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.RESTOCKING_TYPE,
            staff: restockingStaff,
          },
        },
      },
      {
        id: TABS.TAB_RELOCATING,
        header: {
          label: `Relocate (${relocateStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.RELOCATE_TYPE,
            staff: relocateStaff,
          },
        },
      },
      {
        id: TABS.TAB_LOADING,
        header: {
          label: `Loading (${loadingStaff.length})`,
        },
        body: {
          data: {
            type: TASK_TYPES.LOADING_TYPE,
            staff: loadingStaff,
          },
        },
      },
    ]
    const updatedTabs = [
      {
        id: TABS.TAB_ALL,
        header: {
          label: `All (${allStaff.length})`,
        },
        body: {
          data: {
            staff: dedicatedTabs,
            type: null,
            list: allStaff,
          },
        },
      },
      ...dedicatedTabs,
    ]

    setTabs(updatedTabs)
    setSelectedTab(
      isEmptyObject(selectedTab)
        ? updatedTabs.find((tab) => tab.id === TABS.TAB_ALL)
        : selectedTab,
    )
  }, [staff])

  return (
    <div className="tab">
      <ul className="tab__list">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab__item ${
              selectedTab.id === tab.id ? 'tab__item--active' : ''
            }`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab.id !== TABS.TAB_ALL ? (
              <span className="tab__mark">
                {
                  TASK_ASSIGNMENT_TYPES.find(
                    (item) => item.id === parseInt(tab.body.data.type),
                  ).shortName
                }
              </span>
            ) : (
              ''
            )}
            {tab.header.label}
          </div>
        ))}
      </ul>
      {tabs.map((tab) =>
        tab.id === selectedTab.id ? (
          <StaffEngagementTab
            assignToList={assignToList}
            onShowDetailsPopup={onShowDetailsPopup}
            key={tab.id}
            onAssign={onAssign}
            item={tab}
          />
        ) : (
          ''
        ),
      )}
    </div>
  )
}

StaffEngagement.propTypes = {
  staff: PropTypes.object,

  onAssign: PropTypes.func,
}

export default StaffEngagement
