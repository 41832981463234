export const CONTAINER_STATUS = {
  ON_WATER: 1,
  ON_RAIL: 2,
  ON_TRUCK: 3,
  RECEIVING: 4,
  PREPARE_TO_UNLOAD: 5,
  PENDING: 24,
  ACTIVE: 25,
  IN_PROGRESS: 26,
}
