import React from 'react'
import * as PropTypes from 'prop-types'
import Select, {
  components,
  createFilter,
} from 'react-select/dist/react-select.cjs'
import { ITEMS_LIST_DEFAULT_ITEM_ID } from 'constants/common'
import './style.scss'

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: 14,
    height: 36,
    width: 170,
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  dropdownIndicator: () => ({}),
  menu: (provided) => ({
    ...provided,
    borderRadius: 1.5,
  }),
  option: (provided, state) => {
    const backgroundColor = state.isSelected ? '#ffb601' : '#fff'
    const color = state.isSelected ? '#fff' : '#000'
    const border =
      state.isSelected && state.isFocused
        ? '1px solid #ffb601'
        : state.isSelected || state.isFocused
        ? '1px solid #ffb601'
        : '1px solid #fff'
    return {
      ...provided,
      backgroundColor,
      border,
      color,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: '#ffb601',
        color: '#fff',
        opacity: 0.7,
      },
    }
  },
}

const SelectInput = (props) => {
  const {
    tabIndex,
    name,
    disabled,
    value,
    isSearchable,
    defaultValue,
    placeholder,
    isInvalid,
    items,
    styleOptions,
    matchStartOrAny,
    onChange,
    className,
  } = props

  const onSelect = (item) => onChange(name, item.value)

  const options = defaultValue
    ? [{ value: ITEMS_LIST_DEFAULT_ITEM_ID, label: defaultValue }, ...items]
    : items
  const selectedValue = options.find((item) => item.value === value)

  const fiterOptions = {
    matchFrom: matchStartOrAny ? matchStartOrAny : 'start',
  }

  return (
    <span onClick={(e) => e.stopPropagation()} className={className || ''}>
      <Select
        className={`select ${isInvalid ? 'select--invalid' : ''} ${
          className ? className : ''
        }`}
        classNamePrefix="select"
        options={options}
        isDisabled={disabled}
        value={selectedValue}
        tabIndex={tabIndex}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={onSelect}
        styles={{ ...customStyles, ...styleOptions }}
        filterOption={createFilter(fiterOptions)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <></>
            </components.DropdownIndicator>
          ),
        }}
      />
    </span>
  )
}

SelectInput.propTypes = {
  tabIndex: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string,

  items: PropTypes.array.isRequired,

  styleOptions: PropTypes.object,

  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  matchStartOrAny: PropTypes.string,

  onChange: PropTypes.func,
}

export default SelectInput
