import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalInfo from '../Modal/ModalInfo'
import ModalPending from '../Modal/ModalPending'
import ModalConfirmDefault from '../Modal/ModalConfirmDefault'
import ModalWarning from '../Modal/ModalWarning'
import ModalSuccess from '../Modal/ModalSuccess'
import ModalInvAdjustment from '../Modal/ModalInvAdjustment'
import ModalOrderNotes from '../Modal/ModalOrderNotes'
import ModalProductsList from 'components/common/Modal/ModalProductsList'
import ModalChoosePrinter from 'components/common/Modal/ModalChoosePrinter'
import ModalLanePallet from '../Modal/ModalLanePallet'
import ModalChooseCopies from '../Modal/ModalChooseCopies'

const PageContent = ({ className = '', children }) => (
  <div className={`page-content ${className}`}>
    <ModalInfo />
    <ModalPending />
    <ModalConfirmDefault />
    <ModalWarning />
    <ModalSuccess />
    <ModalInvAdjustment />
    <ModalOrderNotes />
    <ModalProductsList />
    <ModalChoosePrinter />
    <ModalLanePallet />
    <ModalChooseCopies />
    {children}
  </div>
)

PageContent.propTypes = {
  className: PropTypes.string,

  children: PropTypes.any,
}

export default PageContent
