import React from 'react'
import * as PropTypes from 'prop-types'
import ListCell from '../ListCell'

const ListRow = ({ columns, row }) => (
  <div className="list__row">
    {columns.map((column) => (
      <ListCell key={column.value}>{row[column.value]}</ListCell>
    ))}
  </div>
)

ListRow.propTypes = {
  columns: PropTypes.array,

  row: PropTypes.object,
}

export default ListRow
