import { types } from './actions'
import {
  ORDER_PICK_STATUSES,
  TODAY_ORDER_STATUSES,
  SHIPPING_PAGE_ORDER_STATUSES,
} from 'constants/order'

const INIT = {
  items: [],
  today: [],
  nextDay: [],
  removed: [],
  shipping: [],
  shipment: [],
  item: {},
  isLoading: false,
}

export default function orderProcessReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_ONE_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case types.FETCH_ORDER_PROCESS_LIST_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_SHIPMENT_ORDERS_SUCCESS:
      return {
        ...state,
        shipment: payload,
      }
    case types.CLEAR_SHIPMENT_ORDERS:
      return {
        ...state,
        shipment: [],
      }
    case types.FETCH_ORDER_PROCESS_LIST_SUCCESS: {
      const todayOrders = payload
        .filter(
          (item) =>
            TODAY_ORDER_STATUSES.indexOf(item.entity.orderPickStatus.id) > -1,
        )
        .sort((prev, next) => prev.priority - next.priority)

      const ordersNextDay = payload.filter(
        (item) =>
          item.entity.orderPickStatus.id ===
          ORDER_PICK_STATUSES.STATUS_NEXT_DAY,
      )

      const removed = payload.filter(
        (item) =>
          item.entity.orderPickStatus.id ===
            ORDER_PICK_STATUSES.STATUS_REMOVE ||
          item.entity.orderPickStatus.id ===
            ORDER_PICK_STATUSES.STATUS_REMOVE_HOLD ||
          item.entity.orderPickStatus.id ===
            ORDER_PICK_STATUSES.STATUS_REMOVE_BACK_TO_INV,
      )

      const shipping = payload.filter((item) =>
        SHIPPING_PAGE_ORDER_STATUSES.includes(item.entity.orderPickStatus.id),
      )

      return {
        ...state,
        items: payload,
        isLoading: false,
        today: todayOrders,
        nextDay: ordersNextDay,
        shipping: shipping,
        removed: removed,
      }
    }
    case types.FETCH_ORDER_PROCESS_LIST_FAIL:
      return INIT
    case types.UPDATE_ORDER_PROCESS_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case types.SORT_ORDERS: {
      const sortedItems = state[payload.key].sort((prev, next) => {
        let quantityForPrev = 0
        let quantityForNext = 0
        const orderProductsPrev = prev.entity.orderProducts
        const orderProductsNext = prev.entity.orderProducts

        orderProductsPrev.forEach((orderProduct) => {
          quantityForPrev += orderProduct.products_quantity
        })

        orderProductsNext.forEach((orderProduct) => {
          quantityForPrev += orderProduct.products_quantity
        })

        return quantityForPrev - quantityForNext
      })

      return {
        ...state,
        [payload.type]: sortedItems,
      }
    }
    default:
      return state
  }
}
