import { connect } from 'react-redux'
import {
  fetchContainerProcessListStart,
  sortContainerProcessList,
} from 'store/containerProcess/actions'
import { showModal } from 'store/modal/actions'
import ContainerListPage from '../index'
import { FILTERS } from 'constants/filters'
import { CONTAINER_LIST_PAGE_TYPE_UNLOADING } from 'constants/common'
import { ROUTES } from 'constants/routes'

const columns = [
  {
    id: 1,
    label: 'Container',
    value: 'name',
    name: FILTERS.CONTAINER_FILTER,
    isSorting: true,
  },
  {
    id: 2,
    label: 'Vendor',
    value: 'supplier_id',
    name: FILTERS.VENDOR_FILTER,
    isSorting: true,
  },
  {
    id: 3,
    label: 'Status',
    value: 'container_status_id',
    name: FILTERS.CONTAINER_STATUS_RECEIVING_FILTER,
    isSorting: true,
  },
  {
    id: 4,
    label: 'Arrival',
    value: 'arrive_at',
    name: FILTERS.CONTAINER_ARRIVE_DATE_FILTER,
    isSorting: true,
  },
  {
    id: 5,
    label: 'Cases',
    value: 'invoice_cases_quantity',
    name: FILTERS.CONTAINER_CASES_QTY_FILTER,
    isSorting: true,
  },
  {
    id: 6,
    label: 'Pallets',
    value: 'invoice_pallets_quantity',
    name: FILTERS.CONTAINER_PALLETS_QTY_FILTER,
    isSorting: true,
  },
  {
    id: 7,
    label: '',
    value: '',
    name: '',
    isSorting: false,
  },
]

const mapStateToProps = (store) => ({
  items: store.containerProcess.list,
  filterList: [
    FILTERS.CONTAINER_STATUS_UNLOADING_FILTER,
    FILTERS.VENDOR_FILTER,
  ],
  columns: columns,
  detailsLink: ROUTES.CONTAINER_UNLOADING_DETAILS_PAGE_WITH_ID,

  page: {
    type: CONTAINER_LIST_PAGE_TYPE_UNLOADING,
    title: 'Unloading',
    description: 'Unloading List View',
  },
})

const mapDispatchToProps = {
  fetchList: fetchContainerProcessListStart,
  sortList: sortContainerProcessList,
  showModal: showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerListPage)
