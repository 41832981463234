import { connect } from 'react-redux'
import ContainerDetailsStatusSwitcher from './component'
import { clearFilter, fetchFiltersStart } from 'store/filter/actions'
import { updateContainerProcessStart } from 'store/containerProcess/actions'
import { showModal } from 'store/modal/actions'

const mapStateToProps = (store) => ({
  containerStatusList: store.filter.filterParams.containerStatusList,
})

const mapDispatchToProps = {
  fetchFilters: fetchFiltersStart,
  changeItem: updateContainerProcessStart,
  clearFilter: clearFilter,
  showModal: showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerDetailsStatusSwitcher)
