import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import './style.scss'
import LoaderOuterCircle from 'assets/svg/loading-bar.svg'
import { MODAL_PENDING } from 'components/common/Modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '315px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '550px',
  },
}

const ModalPending = ({ modal, isOpen }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal modal--pending">
        <LoaderOuterCircle className="outer-circle" />
        <div className="inner-round">
          <div className="inner-round__curtain" />
        </div>
        {modal.content}
      </div>
    </Modal>
  )
}

ModalPending.propTypes = {
  modal: PropTypes.object,

  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_PENDING,
  ),
  modal: state.modal.openModalsInfo.find((item) => item.name === MODAL_PENDING),
})

export default connect(mapStateToProps, null)(ModalPending)
