import { takeEvery, call, put } from 'redux-saga/effects'
import { types } from './actions'
import { resetModals, showModal } from '../modal/actions'
import { create, fetchMany, updateMany } from 'api/qaHold'
import { wait } from '@hapi/hoek'
import { redirect } from 'helpers'
import { fetchQaHoldListFail, fetchQaHoldListSuccess } from './actions'
import { MODAL_SUCCESS } from 'components/common/Modal'

function* createQaHold(action) {
  const { payload } = action

  try {
    yield call(create, { data: payload.data })
    yield put(
      showModal({ name: MODAL_SUCCESS, content: 'QA Hold Detail Created' }),
    )
    yield wait(2000)
    yield put(resetModals())
    yield redirect(payload.options.redirectUrl)
  } catch (error) {}
}

function* updateManyQaHold(action) {
  const { payload } = action

  try {
    yield call(updateMany, { data: payload.data })
    yield put(showModal({ name: MODAL_SUCCESS, content: 'QA Holds Updated' }))
    yield wait(2000)
    yield put(resetModals())
    yield redirect(payload.options.redirectUrl)
  } catch (error) {}
}

function* fetchQaHoldList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchQaHoldListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchQaHoldListFail())
  }
}

export default function* qaHoldSaga() {
  yield takeEvery(types.CREATE_QA_HOLD, createQaHold)
  yield takeEvery(types.UPDATE_MANY_QA_HOLD, updateManyQaHold)
  yield takeEvery(types.FETCH_QA_HOLD_LIST_START, fetchQaHoldList)
}
