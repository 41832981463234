import React from 'react'
import { connect } from 'react-redux'
import './style.scss'
import Avatar from 'components/features/StaffCard/Avatar'
import StaffTaskAssignmentList from './StaffTaskAssignmentList'
import * as PropTypes from 'prop-types'
import { isEmptyObject } from 'helpers/index'
import CancelIcon from 'assets/svg/cancel.svg'
import { TASK_TYPES } from 'constants/taskAssignment'
import { PROCESS_TYPES } from 'constants/process'

const StaffDetails = ({ staffItem, orderProcess, trianglePosition }) => {
  if (isEmptyObject(staffItem) || isEmptyObject(orderProcess)) {
    return ''
  }

  const taskAssignments = staffItem.taskAssignments
    ? [
        ...staffItem.taskAssignments.filter(
          (item) =>
            [
              TASK_TYPES.LOCATION_PICKING,
              TASK_TYPES.UNLOAD_WRAP_TYPE,
              TASK_TYPES.UNLOAD_PUTAWAY_TYPE,
            ].indexOf(item.taskType.id) === -1,
        ),
        ...[
          {
            id: staffItem.taskAssignments.length + 1,
            taskType: { id: TASK_TYPES.RESTOCKING_TYPE },
            process: { process_type_id: PROCESS_TYPES.RESTOCK_PROCESS_TYPE },
            restockTotal: staffItem.restockTotal,
          },
        ],
      ]
    : []

  return (
    <div className="staff-detail">
      <span className={`triangle triangle--${trianglePosition}`} />
      <div className="desc">
        <Avatar link={staffItem.photo} />
        <div className="desc__info">
          <p className="title">{staffItem.name}</p>
          <div className="row">
            {staffItem.roles.map((role) =>
              role.is_primary ? (
                <span
                  key={`${role.id}--${Math.random()}`}
                  className="badge badge--active"
                >
                  {role.name}
                </span>
              ) : (
                <span key={`${role.id}--${Math.random()}`} className="badge">
                  {role.name}
                </span>
              ),
            )}
          </div>
        </div>
      </div>
      {taskAssignments.length > 0 && (
        <StaffTaskAssignmentList items={taskAssignments} />
      )}
      <CancelIcon className="close" />
    </div>
  )
}

StaffDetails.propTypes = {
  staffItem: PropTypes.object.isRequired,
  orderProcess: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  staffItem: state.modal.modalStaffDetails.staffItem,
  orderProcess: state.modal.modalStaffDetails.orderProcess,
})

export default connect(mapStateToProps)(StaffDetails)
