import React from 'react'

const DragDropTableHeader = ({ columns }) => (
  <div className="drag-drop__table-header">
    {columns.map((column) => (
      <div className="table-cell" key={column.id}>
        {column.label}
      </div>
    ))}
    <div className="table-cell" />
  </div>
)

export default DragDropTableHeader
