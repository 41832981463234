export const PROCESS_TYPES = {
  CONTAINER_UNLOAD_PROCESS_TYPE: 1,
  ORDER_PROCESS_TYPE: 2,
  RESTOCK_PROCESS_TYPE: 3,
}

export const PROCESS_TYPE_LABELS = {
  [PROCESS_TYPES.CONTAINER_UNLOAD_PROCESS_TYPE]: 'Container',
  [PROCESS_TYPES.ORDER_PROCESS_TYPE]: 'Order',
  [PROCESS_TYPES.RESTOCK_PROCESS_TYPE]: 'Restock',
}
