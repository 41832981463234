import React from 'react'
import * as PropTypes from 'prop-types'
import Avatar from '../Avatar'
import Icon from 'components/common/Icon'
import Tooltip from 'components/common/Tooltip'
import '../style.scss'
const CARDS_TO_SHOW_COUNT = 3

const AvatarList = ({ items, onClick = () => false }) => {
  const countOfNonShownItems = items.length - CARDS_TO_SHOW_COUNT

  return (
    <div className="avatar-list" onClick={onClick}>
      {countOfNonShownItems > 0 && (
        <Icon
          className="avatar-list__count"
          key={CARDS_TO_SHOW_COUNT + 1}
        >{`+${countOfNonShownItems}`}</Icon>
      )}
      {items.map((item, index) =>
        index < CARDS_TO_SHOW_COUNT ? (
          <Tooltip
            hoverTarget={<Avatar key={item.id} link={item.photo} />}
            sideText={item.name}
            extraBubbleClassName="for-picker-icon-bubble"
          />
        ) : (
          ''
        ),
      )}
    </div>
  )
}

AvatarList.propTypes = {
  items: PropTypes.array.isRequired,

  onClick: PropTypes.func,
}

export default AvatarList
