import React from 'react'
import * as PropTypes from 'prop-types'
import ListHeader from './ListHeader'
import ListRow from './ListRow'
import './style.scss'

const List = ({ items, columns, placeholder = '', className = '' }) =>
  items.length > 0 ? (
    <div className={`list ${className}`}>
      <ListHeader columns={columns} />
      <div className="list__body">
        {items.map((item) => (
          <ListRow key={item.id} row={item} columns={columns} />
        ))}
      </div>
    </div>
  ) : (
    <div className="list list--empty">
      <div className="text">{placeholder}</div>
    </div>
  )

List.propTypes = {
  items: PropTypes.array,
  columns: PropTypes.array,

  placeholder: PropTypes.string,
  className: PropTypes.string,
}

export default List
