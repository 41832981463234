import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchOne = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_PALLET.FETCH_ONE(payload),
    method: 'GET',
  })

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_PALLET.FETCH_MANY(payload),
    method: 'GET',
  })

export const fetchOther = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_PALLET.FETCH_OTHER(payload),
    method: 'GET',
  })

export const fetchAvailableLocations = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_PALLET.FETCH_AVAILABLE_LOCATION_LIST(payload),
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.WAREHOUSE_PALLET.UPDATE(payload.id),
    method: 'PUT',
    data: payload,
  })
