import React, { useEffect, useState } from 'react'
import { QH_HOLD_TYPE } from 'constants/qaHoldTypes'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { date, dateToFormat, getUniqueId } from 'helpers'
import { createQaHold } from 'store/qaHold/actions'
import { showModal } from 'store/modal/actions'
import Button from 'components/common/Button'
import Calendar from 'components/common/Calendar'
import TextInput from 'components/common/TextInput'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import ProductLotNumber from '../ProductLotNumber'
import { MODAL_PENDING } from 'components/common/Modal'

const DEFAULT_QA_HOLD_DETAILS = {
  requested_date: '',
  customs_number: '',
  productLotNumbers: [],
}

const QAHoldDetailsPage = (props) => {
  const {
    container,
    QAHoldItems,
    setQAHoldItems,
    createQaHold,
    showModal,
    toggleLists,
  } = props

  const [isAbleToSaveQAHold, setIsAbleToSaveQAHold] = useState(false)
  const [QAHoldDetails, setQAHoldDetails] = useState(DEFAULT_QA_HOLD_DETAILS)

  const onChangeQAHoldItems = () => {
    let productLotNumbers = [...QAHoldDetails.productLotNumbers]

    QAHoldItems.forEach((item) => {
      if (!item.isNew) {
        return
      }
      const lotNumbers = !item.lotNumbers.length
        ? [{ id: getUniqueId(), ex_number: '' }]
        : item.lotNumbers

      productLotNumbers = [...productLotNumbers, { ...item, lotNumbers }]
    })

    setQAHoldDetails({ ...QAHoldDetails, productLotNumbers })
  }
  const onChangeQAHoldDetails = () => {
    setIsAbleToSaveQAHold(
      !!QAHoldDetails.requested_date &&
        !!QAHoldDetails.customs_number &&
        QAHoldDetails.productLotNumbers.length > 0,
    )
  }

  useEffect(onChangeQAHoldDetails, [QAHoldDetails])
  useEffect(onChangeQAHoldItems, [QAHoldItems])

  const onCommonQAHoldDetailsChange = (name, value) =>
    setQAHoldDetails({
      ...QAHoldDetails,
      [name]: value,
    })

  const onChangeProductLotNumber = (productLotNumber) =>
    setQAHoldDetails({
      ...QAHoldDetails,
      productLotNumbers: QAHoldDetails.productLotNumbers.map((productLot) =>
        productLot.id === productLotNumber.id ? productLotNumber : productLot,
      ),
    })
  const onDeleteProductLotNumber = (productLotNumber) => {
    const updatedProductLotNumbers = QAHoldDetails.productLotNumbers.filter(
      (productLot) => productLot.id !== productLotNumber.id,
    )

    setQAHoldDetails({
      ...QAHoldDetails,
      productLotNumbers: updatedProductLotNumbers,
    })
  }
  const onCancelQAHold = () => {
    setQAHoldDetails(DEFAULT_QA_HOLD_DETAILS)
    setQAHoldItems([])
  }
  const onSaveQAHold = () => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Saving...</div>,
    })

    QAHoldDetails.productLotNumbers.forEach((productLotNumber) => {
      createQaHold({
        data: {
          qa_hold: {
            product_id: productLotNumber.product.products_id,
            container_product_id: productLotNumber.id,
            quantity_on_hold: productLotNumber.quantity,
            requested_date: QAHoldDetails.requested_date,
            type_id: QH_HOLD_TYPE.CPSC,
            picked_up_qty: 0,
            pick_up_date: null,
            customs_number: QAHoldDetails.customs_number,
            resolution_id: null,
            resolution_date: null,
          },
          qa_hold_lots: productLotNumber.lotNumbers,
        },
      })

      setQAHoldItems([])
      toggleLists()
    })
  }

  return (
    <PageContent className="container-details-page qa-hold">
      <ContentHeader title="QA Hold Details" description="Edit QA Hold Details">
        <div className="btn-group">
          <Button
            type="button"
            onClick={onSaveQAHold}
            disabled={!isAbleToSaveQAHold}
            text="SAVE"
          />
          <Button type="button" onClick={onCancelQAHold} text="CANCEL" />
        </div>
      </ContentHeader>
      <ContentSection>
        <div className="header">
          <div className="header__title">
            Container <span className="text--bold">{container.name}</span>
          </div>
        </div>
        <hr />
        <div className="row container-info">
          <div className="column">
            <div className="row">
              <span className="text--bold">QA Date</span>
              {QAHoldDetails.requested_date
                ? dateToFormat(QAHoldDetails.requested_date, 'MM-DD-YYYY')
                : '-'}
              <Calendar
                name={'requested_date'}
                value={date(QAHoldDetails.requested_date || new Date())}
                onChange={onCommonQAHoldDetailsChange}
              />
            </div>
          </div>
          <div className="column">
            <div className="row">
              <span className="text--bold">Custom Number</span>
              <TextInput
                name="customs_number"
                value={QAHoldDetails.customs_number}
                onChange={onCommonQAHoldDetailsChange}
              />
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection>
        <div className="header">
          <div className="header__title">Product Lot Number</div>
        </div>
        <hr />
        {QAHoldDetails.productLotNumbers.map((productLotNumber) => (
          <ProductLotNumber
            key={productLotNumber.id}
            item={productLotNumber}
            onChange={onChangeProductLotNumber}
            onDelete={onDeleteProductLotNumber}
          />
        ))}
      </ContentSection>
    </PageContent>
  )
}

QAHoldDetailsPage.propTypes = {
  container: PropTypes.object,

  QAHoldItems: PropTypes.array,

  setQAHoldItems: PropTypes.func,
  createQaHold: PropTypes.func,
  showModal: PropTypes.func,
  toggleLists: PropTypes.func,
}

const mapDispatchToProps = {
  createQaHold,
  showModal,
}

export default connect(null, mapDispatchToProps)(QAHoldDetailsPage)
