import { types } from './actions'
import { MODAL_PENDING } from 'components/common/Modal'

const INIT = {
  openModalsInfo: [],
  modalStaffDetails: {
    staffItem: {},
    orderProcess: {},
    isOpen: false,
    isPopup: false,
  },
}

const modalReducer = (state = INIT, action) => {
  const { type, payload } = action

  const showModal = (payload) => {
    const newOpenModalsInfo = state.openModalsInfo.filter(
      (item) => item.name !== payload.name,
    )
    return {
      ...state,
      openModalsInfo: [...newOpenModalsInfo, payload],
    }
  }

  const closeModal = (payload) => {
    const newOpenModalsInfo = state.openModalsInfo.filter(
      (item) => item.name !== payload,
    )

    return {
      ...state,
      openModalsInfo: newOpenModalsInfo,
    }
  }

  switch (type) {
    case types.SHOW_MODAL: {
      return showModal(payload)
    }
    case types.CLOSE_MODAL: {
      return closeModal(payload)
    }
    case types.SHOW_PENDING_MODAL: {
      return showModal({ name: MODAL_PENDING })
    }
    case types.CLOSE_PENDING_MODAL: {
      return closeModal(MODAL_PENDING)
    }
    case types.SHOW_MODAL_STAFF_DETAILS:
      return {
        ...state,
        modalStaffDetails: {
          staffItem: payload.staffItem,
          orderProcess: payload.orderProcess,
          isPopup: payload.isPopup,
          isOpen: !!payload.isPopup,
        },
      }

    case types.RESET_MODALS:
      return { ...INIT }

    case types.SHOW_MODAL_NEW: {
      return showModal(payload)
    }
    case types.CLOSE_MODAL: {
      return closeModal(payload)
    }
    default:
      return state
  }
}

export default modalReducer
