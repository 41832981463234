import request from 'helpers/index'
import { API_ROUTE } from 'constants/apiRoutes'

export const checkUser = (payload) =>
  request({
    url: API_ROUTE.AUTH.CHECK_USER,
    method: 'POST',
    data: payload,
  })

export const login = (payload) =>
  request({
    url: API_ROUTE.AUTH.LOGIN,
    method: 'POST',
    data: payload,
  })

export const logout = (payload) =>
  request({
    url: API_ROUTE.AUTH.LOGOUT,
    method: 'POST',
    headers: { Authorization: `Bearer ${payload}` },
  })

export const getUserData = (payload) =>
  request({
    url: API_ROUTE.AUTH.GET_USER_DATA,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload}`,
    },
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.AUTH.UPDATE,
    method: 'PUT',
    data: payload,
  })
