import React from 'react'
import * as PropTypes from 'prop-types'
import ListCell from '../ListCell'

const ListHeader = ({ columns }) => (
  <div className="list__header">
    {columns.map((column) => (
      <ListCell key={column.id}>{column.label}</ListCell>
    ))}
  </div>
)

ListHeader.propTypes = {
  columns: PropTypes.array,
}

export default ListHeader
