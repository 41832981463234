import React, { useEffect, useState } from 'react'
import query from 'querystringify'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../style.scss'
import Table from 'components/common/Table'
import PageContent from 'components/common/PageContent'
import InventoryManagementFilters from './InventoryManagementFilters'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import PaginationWrapper from 'components/common/PaginationWrapper'
import RecordCountMessage from 'components/common//RecordCountMessage'
import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'
import {
  DEFAULT_URL_PARAMS,
  DEFAULT_URL_PARAMS_VALUES,
} from 'constants/urlDefaultParams'
import { FILTERS, SORT_FILTERS } from 'constants/filters'
import {
  getUrlParams,
  haveDefaultUrlParams,
  redirect,
  removePropFromObject,
} from 'helpers'
import { fetchFiltersStart } from 'store/filter/actions'
import { fetchWarehouseCaseAndPalletListStart } from 'store/warehouseCaseAndPallet/actions'
import { showModal, resetModals } from 'store/modal/actions'
import { fetchReasonListStart } from 'store/reason/actions'
import { PalletWithCount } from 'components/common/PalletIcon'
import Button from 'components/common/Button'
import Toggle from 'components/common/Toggle'
import {
  isAnyStorageLocationType,
  isAnyPickLocationType,
  isSpecialLocationType,
} from 'constants/locationTypes'
import Tooltip from '../../../common/Tooltip'
import {
  MODAL_INVENTORY_ADJUSTMENT,
  MODAL_PENDING,
} from 'components/common/Modal'

export const ALL_LOCATION_PALLETS_LIST_TYPE_NAME = 'lp'
export const ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME = 'lpp'

const ALL_LOCATION_PALLETS_LIST_TYPE_ID = 1
const ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_ID = 2

const PAGE_TYPES = [
  {
    id: ALL_LOCATION_PALLETS_LIST_TYPE_ID,
    columns: [
      {
        id: 1,
        label: 'Model',
        value: 'product_model',
        name: 'product_model',
        isSorting: true,
      },
      {
        id: 2,
        label: 'Manf Model',
        value: 'product_manf_model',
        name: 'product_manf_model',
        isSorting: true,
      },

      {
        id: 3,
        label: 'Product Name',
        value: 'product_name',
        name: 'product_name',
        isSorting: true,
      },
      {
        id: 4,
        label: 'Location',
        value: 'location_title',
        name: 'location_title',
        isSorting: true,
      },
      {
        id: 5,
        label: 'Location Type',
        value: 'location_type',
        name: 'location_type',
        isSorting: true,
      },
      {
        id: 6,
        label: 'Total Cases',
        labelLine2: 'at Location',
        value: 'case_capacity',
        name: 'case_capacity',
      },
      {
        id: 7,
        label: 'Pallet Capacity',
        labelLine2: 'of Location',
        value: 'location_pallet_capacity',
        name: 'location_pallet_capacity',
      },
      {
        id: 8,
        label: 'Case Capacity',
        labelLine2: 'of a Pallet',
        value: 'pallet_case_capacity',
        name: 'pallet_case_capacity',
      },
      {
        id: 9,
        label: '',
        value: 'action',
        name: 'action',
      },
    ],
    value: ALL_LOCATION_PALLETS_LIST_TYPE_NAME,
    label: 'All location pallets',
  },
  {
    id: ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_ID,
    columns: [
      {
        id: 1,
        label: 'Model',
        value: 'product_model',
        name: 'product_model',
        isSorting: true,
      },
      {
        id: 2,
        label: 'Manf Model',
        value: 'product_manf_model',
        name: 'product_manf_model',
        isSorting: true,
      },

      {
        id: 3,
        label: 'Product Name',
        value: 'product_name',
        name: 'product_name',
        isSorting: true,
      },
      {
        id: 4,
        label: 'Location',
        value: 'location_title',
        name: 'location_title',
        isSorting: true,
      },
      {
        id: 5,
        label: 'Location Type',
        value: 'location_type',
        name: 'location_type',
        isSorting: true,
      },
      {
        id: 6,
        label: 'Total Cases',
        labelLine2: 'at Location',
        value: 'case_capacity',
        name: 'case_capacity',
      },
      {
        id: 7,
        label: 'Pallet Capacity',
        labelLine2: 'of Location',
        value: 'location_pallet_capacity',
        name: 'location_pallet_capacity',
      },
      {
        id: 8,
        label: 'Case Capacity',
        labelLine2: 'of a Pallet',
        value: 'pallet_case_capacity',
        name: 'pallet_case_capacity',
      },
      {
        id: 9,
        label: '',
        value: 'action',
        name: 'action',
      },
    ],
    value: ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME,
    label: 'PARTIALS ONLY',
  },
]

const InventoryManagementPage = (props) => {
  const {
    filters = {},
    fetchWarehousePallets,
    fetchReasonList,
    warehouseCases,
    warehousePallets,
    metaData,
    fetchFilters,
    showModal,
    resetModals,
    user,
  } = props

  if (
    !haveDefaultUrlParams(getUrlParams()) ||
    !getUrlParams()[FILTERS.PAGE_TYPE_FILTER]
  ) {
    const correctParams = {
      ...getUrlParams(),
      ...DEFAULT_URL_PARAMS_VALUES,
      [FILTERS.PAGE_TYPE_FILTER]: PAGE_TYPES.find(
        (type) => type.id === ALL_LOCATION_PALLETS_LIST_TYPE_ID,
      ).value,
    }

    redirect(`${window.location.pathname}?${query.stringify(correctParams)}`)

    return ''
  }

  const [sortDirection, setSortDirection] = useState(
    getUrlParams()[DEFAULT_URL_PARAMS.ORDER_BY],
  )

  const [checkedPartialsOnly, setCheckedPartialsOnly] = useState(
    getUrlParams()[FILTERS.PAGE_TYPE_FILTER] ===
      ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME,
  )

  const onMount = () => {
    const params = getUrlParams()
    const pureParams = removePropFromObject('pageType', params)

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })

    fetchFilters([
      FILTERS.PRODUCT_MODEL_FILTER,
      FILTERS.PRODUCT_MANF_MODEL_FILTER,
      FILTERS.PRODUCT_NAME_FILTER,
      FILTERS.LOCATION_TYPE_FILTER,
      FILTERS.PALLET_CAPACITY_FILTER,
      FILTERS.AISLE_FILTER,
      FILTERS.BAY_FILTER,
      FILTERS.LEVEL_FILTER,
    ])

    switch (params[FILTERS.PAGE_TYPE_FILTER]) {
      case ALL_LOCATION_PALLETS_LIST_TYPE_NAME:
        fetchWarehousePallets(
          `?${query.stringify({
            ...removePropFromObject('ppo', pureParams),
            [FILTERS.WAREHOUSE_FILTER]: user.warehouseId,
          })}`,
        )
        break
      case ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME:
        fetchWarehousePallets(
          `?${query.stringify({
            ...pureParams,
            [FILTERS.WAREHOUSE_FILTER]: user.warehouseId,
            ppo: 1,
          })}`,
        )
        break

      default:
        break
    }

    fetchReasonList()
  }

  useEffect(onMount, [])

  const onChangeDirection = () => {
    setSortDirection(
      sortDirection === DIRECTION_ASC ? DIRECTION_DESC : DIRECTION_ASC,
    )
    onMount()
  }
  const onAcceptInvAdjustmentModal = () => {
    setTimeout(() => resetModals(), 50) //setTimeout is not best-practices; used here to delay the closing of modal for visual consistancy with below onMount call
    setTimeout(() => onMount(), 300) // ...used here to delay the onMount so it's not called at the same time the API posts are made.
  }

  const onChangeFilters = (params) => {
    redirect(`${window.location.pathname}?${query.stringify(params)}`)
    onMount()
  }
  const clearFiltersOnClick = () => {
    redirect(`${window.location.pathname}`)
  }
  const selectPartialsOnly = () => {
    setCheckedPartialsOnly(!checkedPartialsOnly)
    if (
      getUrlParams()[FILTERS.PAGE_TYPE_FILTER] ===
      ALL_LOCATION_PALLETS_LIST_TYPE_NAME
    ) {
      redirect(
        `${window.location.pathname}?${query.stringify({
          ...getUrlParams(),
          ...{
            [FILTERS.PAGE_TYPE_FILTER]: ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME,
          },
        })}`,
      )
    } else if (
      getUrlParams()[FILTERS.PAGE_TYPE_FILTER] ===
      ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME
    ) {
      redirect(
        `${window.location.pathname}?${query.stringify({
          ...getUrlParams(),
          ...{
            [FILTERS.PAGE_TYPE_FILTER]: ALL_LOCATION_PALLETS_LIST_TYPE_NAME,
          },
        })}`,
      )
    }
    onMount()
  }

  const isSingleModelPage = () =>
    !!getUrlParams()[FILTERS.PRODUCT_MODEL_FILTER] ||
    !!getUrlParams()[FILTERS.PRODUCT_MANF_MODEL_FILTER] ||
    !!getUrlParams()[FILTERS.PRODUCT_NAME_FILTER]

  const hasFilterSelected = () =>
    !!getUrlParams()[FILTERS.PRODUCT_MODEL_FILTER] ||
    !!getUrlParams()[FILTERS.PRODUCT_MANF_MODEL_FILTER] ||
    !!getUrlParams()[FILTERS.PRODUCT_NAME_FILTER] ||
    !!getUrlParams()[FILTERS.LOCATION_TYPE_FILTER] ||
    !!getUrlParams()[FILTERS.PALLET_CAPACITY_FILTER] ||
    !!getUrlParams()[FILTERS.AISLE_FILTER] ||
    !!getUrlParams()[FILTERS.BAY_FILTER] ||
    !!getUrlParams()[FILTERS.LEVEL_FILTER]

  let totalCasesInWarehouse
  let firstItemTotal
  if (warehousePallets.length) {
    firstItemTotal = warehousePallets[0].item.total_cases_in_warehouse
  }
  const getPageType = (value) => PAGE_TYPES.find((item) => item.value === value)
  const getNormalizedItems = () => {
    const params = getUrlParams()
    if (
      [
        ALL_LOCATION_PALLETS_PARTIAL_LIST_TYPE_NAME,
        ALL_LOCATION_PALLETS_LIST_TYPE_NAME,
      ].indexOf(params[FILTERS.PAGE_TYPE_FILTER]) > -1
    ) {
      return warehousePallets.map(({ item, params }) => {
        const isAnyStorage = isAnyStorageLocationType(item)
        const isAnyPick = isAnyPickLocationType(item)
        const isAnySpecial = isSpecialLocationType(item)
        let action = ''
        let product_model = item.product_model

        const case_capacity_frag = (
          <p style={{ textAlign: 'right' }}> {item.location_case_qty}</p>
        )

        if (item.total_cases_in_warehouse) {
          totalCasesInWarehouse = item.total_cases_in_warehouse
        }

        if (isAnyStorage) {
          action = (
            <div className="pallets-wrapper">
              {item.pallets.map((pallet) => PalletWithCount(pallet))}
            </div>
          )
        }

        if (item.total_cases_in_warehouse && !isSingleModelPage()) {
          product_model = (
            <Tooltip
              hoverTarget={item.product_model}
              hoverMainText={totalCasesInWarehouse}
              sideText="pc"
              extraBubbleClassName="for-total-cases"
            />
          )
        }

        const onEditCaseCapacity = () => {
          showModal({
            name: MODAL_INVENTORY_ADJUSTMENT,
            events: {
              onDeny: resetModals,
              onAccept: onAcceptInvAdjustmentModal,
            },
            options: {
              item: { ...item, warehouseId: user.warehouseId },
            },
          })
        }

        return {
          ...item,
          id: `${item.id}-${item.location_title}`,
          product_name: item.product_name ? item.product_name : '-',
          case_capacity: (
            <>
              <span style={{ display: 'block' }}>{case_capacity_frag}</span>
              { (isAnyStorage || isAnyPick || isAnySpecial) && user.can_update_inventory ? (
                <i className="icon icon-edit" onClick={onEditCaseCapacity} />
              ) : (
                ''
              )}
            </>
          ),
          location_type: (
            <>
              <span
                className="icon--square"
                style={{ backgroundColor: item.location_type_color }}
              />
              <span>{item.location_type}</span>
            </>
          ),
          action: action,
          product_model: product_model,
        }
      })
    } else {
      return warehouseCases.map(({ item }) => {
        const warehouseLocation = item.warehouseLocation

        const locationTitle = warehouseLocation.regular_location
          ? [
              warehouseLocation.regular_location.aisle_id.name,
              warehouseLocation.regular_location.bay_id.name,
              warehouseLocation.regular_location.level,
            ].join('-')
          : warehouseLocation.dock_location.dock_id.name

        return {
          id: `${item.id}${locationTitle}`,
          product_model: item.product.products_model,
          product_name: item.product_description
            ? item.product_description.products_name
            : '-',
          location_title: locationTitle,
          location_type: warehouseLocation.location_type.name,
          case_capacity: item.case_capacity,
          pallets_capacity: item.pallets_capacity,
        }
      })
    }
  }

  const pageTypeValueFromUrl =
    getUrlParams()[FILTERS.PAGE_TYPE_FILTER] ||
    PAGE_TYPES.find((type) => type.id === ALL_LOCATION_PALLETS_LIST_TYPE_ID)
      .value

  const SingleModelTotalCasesDisplay = () => {
    if (isSingleModelPage()) {
      return (
        <>
          <br />
          <br />
          {totalCasesInWarehouse === firstItemTotal && (
            <div className="header__title">
              {totalCasesInWarehouse}{' '}
              <span className="side_text"> total product cases</span>
            </div>
          )}
        </>
      )
    } else {
      return <></>
    }
  }

  const paginationWrapperInstance = (
    <PaginationWrapper
      metaData={metaData}
      fetchFunction={fetchWarehousePallets}
      countPerFetch={parseInt(
        getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM],
      )}
      checkedPartialsOnly={checkedPartialsOnly}
    />
  )

  return (
    <PageContent className="list-page inventory-page">
      <ContentHeader
        title="Inventory management"
        description="Create/Manage Restock Task Here"
      ></ContentHeader>
      <ContentSection>
        <div className="header">
          <div className="header__title">
            <RecordCountMessage
              metaData={metaData}
              unitsText={
                getUrlParams()[FILTERS.PAGE_TYPE_FILTER] ===
                ALL_LOCATION_PALLETS_LIST_TYPE_NAME
                  ? ' pallet locations '
                  : ' locations'
              }
            />

            <SingleModelTotalCasesDisplay />
          </div>
        </div>
        <div className="inv-filter-section">
          <Button
            className="select"
            disabled={!hasFilterSelected()}
            onClick={() => clearFiltersOnClick()}
            text="Clear Filters"
          />
          <Toggle
            text="Partials Only"
            checked={checkedPartialsOnly}
            onChange={selectPartialsOnly}
          />
        </div>

        <InventoryManagementFilters
          onChange={onChangeFilters}
          filters={filters}
        />
        {parseInt(getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM]) > 10
          ? paginationWrapperInstance
          : ''}
        <Table
          rows={getNormalizedItems()}
          columns={getPageType(pageTypeValueFromUrl).columns}
          className="table--pallets"
          sortOptions={{
            sortByKey: getUrlParams()[SORT_FILTERS.SORT_BY_KEY],
            sortDirection: getUrlParams()[DEFAULT_URL_PARAMS.ORDER_BY],
          }}
          events={{
            onSort: onChangeDirection,
          }}
        />
        {paginationWrapperInstance}
      </ContentSection>
    </PageContent>
  )
}

InventoryManagementPage.propTypes = {
  warehouseCases: PropTypes.array,
  warehousePallets: PropTypes.array,

  user: PropTypes.object,
  filters: PropTypes.object,

  fetchWarehousePallets: PropTypes.func,
  fetchWarehouseCases: PropTypes.func,
  fetchFilters: PropTypes.func,
  showModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
  warehouseCases: state.warehouseCase.list,
  warehousePallets: state.warehouseCaseAndPallet.list,
  metaData: state.warehouseCaseAndPallet.metaData,
  filters: {
    [FILTERS.PRODUCT_MODEL_FILTER]: state.filter.filterParams.productModelList,
    [FILTERS.PRODUCT_MANF_MODEL_FILTER]:
      state.filter.filterParams.productManfModelList,
    [FILTERS.PRODUCT_NAME_FILTER]: state.filter.filterParams.productNameList,
    [FILTERS.LOCATION_TYPE_FILTER]: state.filter.filterParams.locationTypeList,
    [FILTERS.PALLET_CAPACITY_FILTER]:
      state.filter.filterParams.palletCapacityList,
    [FILTERS.AISLE_FILTER]: state.filter.filterParams.aisleList,
    [FILTERS.BAY_FILTER]: state.filter.filterParams.bayList,
    [FILTERS.LEVEL_FILTER]: state.filter.filterParams.levelList,
  },
})

const mapDispatchToProps = {
  fetchWarehousePallets: fetchWarehouseCaseAndPalletListStart,
  fetchFilters: fetchFiltersStart,
  fetchReasonList: fetchReasonListStart,
  showModal,
  resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryManagementPage)
