import { takeEvery, call, put, delay } from 'redux-saga/effects'
import {
  types,
  createShipmentSuccess,
  fetchShipmentListSuccess,
  fetchShipmentErrorSuccess,
  markAsPrintedSuccess,
  voidShipmentSuccess,
} from './actions'
import {
  createShipment,
  fetchMany,
  fetchError,
  markAsPrinted,
  voidShipment,
} from 'api/shipment'
import { showModal, resetModals } from '../modal/actions'
import { MODAL_SUCCESS } from 'components/common/Modal'

function* createOrdersShipment(action) {
  const { payload } = action

  try {
    const response = yield call(createShipment, payload)
    yield put(createShipmentSuccess(response))
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
  } catch (error) {}
}

function* voidBol(action) {
  const { payload } = action

  try {
    const response = yield call(voidShipment, payload)
    yield put(voidShipmentSuccess(response))
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
    yield delay(3000)
    yield put(resetModals())
  } catch (error) {}
}

function* fetchShipmentList(action) {
  const { payload } = action

  try {
    const responseWithOrderList = yield call(fetchMany, payload.params)
    yield put(fetchShipmentListSuccess(responseWithOrderList.data.data.items))
  } catch (error) {}
}

function* markBolAsPrinted(action) {
  const { payload } = action

  try {
    const response = yield call(markAsPrinted, payload)
    yield put(markAsPrintedSuccess(response.data.data.message))
    yield put(
      showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
    )
    yield delay(3000)
    yield put(resetModals())
  } catch (error) {}
}

function* fetchShipmentError(action) {
  const { payload } = action

  try {
    const response = yield call(fetchError, payload.params)
    yield put(fetchShipmentErrorSuccess(response.data.data.message))
  } catch (error) {}
}

export default function* shipmentSaga() {
  yield takeEvery(types.CREATE_SHIPMENT_START, createOrdersShipment)
  yield takeEvery(types.MARK_AS_PRINTED_START, markBolAsPrinted)
  yield takeEvery(types.FETCH_SHIPMENT_LIST_START, fetchShipmentList)
  yield takeEvery(types.FETCH_SHIPMENT_ERROR_START, fetchShipmentError)
  yield takeEvery(types.VOID_SHIPMENT_START, voidBol)
}
