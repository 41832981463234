import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import { fetchAppInfoStart, testExceptionStart } from 'store/appInfo/actions'
import { PROJECT } from 'constants/common'
import { buildVersionText } from 'helpers/version'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import Button from 'components/common/Button'

const AboutPage = (props) => {
  const { appInfo, isStale, fetchAppInfo, testException } = props

  const onMount = () => {
    fetchAppInfo(PROJECT)
  }

  useEffect(onMount, [])

  const version = buildVersionText(appInfo)
  const freshness = isStale ? 'out of date!' : 'current.'
  const message = `Your version is ${freshness}`
  const freshnessIcon = isStale ? <FaThumbsDown /> : <FaThumbsUp />
  const contentClassName = isStale
    ? 'about-content-stale'
    : 'about-content-fresh'

  const buttonText = `Update to ${version}`
  const updateButton = isStale ? (
    <Button
      className="update-app"
      onClick={() => window.location.reload()}
      text={buttonText}
    />
  ) : (
    ''
  )

  const testExceptionButton = (
    <Button
      className="test-exception"
      onClick={() => testException()}
      text="Test Exception"
    />
  )

  return (
    <PageContent className="about-page">
      <ContentHeader title="About" description="App Details"></ContentHeader>
      <ContentSection className={contentClassName}>
        <div>App Name: {appInfo.project_name}</div>
        <br />
        <div>Version: {version}</div>
        <br />
        <div>
          <span className="freshness-icon">{freshnessIcon}</span>
          {message}
        </div>
        {updateButton}
        <br />
        <br />
        <br />
        <br />
      </ContentSection>
    </PageContent>
  )
}

AboutPage.propTypes = {}

const mapStateToProps = (state) => ({
  appInfo: state.appInfo.item,
  isStale: state.appInfo.isStale,
})

const mapDispatchToProps = {
  fetchAppInfo: fetchAppInfoStart,
  testException: testExceptionStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage)
