import React, { useState, useEffect } from 'react'
import Form from 'components/common/FormElements/Form'
import { PIN_FIELD, USERNAME_FIELD } from 'constants/fields'
import { REGEX } from 'constants/regex'
import { inputs, stateTypes } from 'constants/form'
import Button from 'components/common/Button'
import { connect } from 'react-redux'
import { checkUserStart, login } from 'store/auth/actions'
import { isEmptyObject } from 'helpers/index'
import Logo from 'assets/svg/logo.svg'

const INITIAL_FIELDS = {
  [USERNAME_FIELD]: '',
  [PIN_FIELD]: '',
}

const LoginForm = (props) => {
  const { checkUser, login, errors, user } = props

  const [data, setData] = useState(INITIAL_FIELDS)
  const [inputErrors, setInputErrors] = useState({})

  useEffect(() => {
    setInputErrors({
      ...inputErrors,
      ...errors,
    })
  }, [errors, checkUser, setInputErrors])

  const onChange = (field, value) => {
    setInputErrors({
      ...inputErrors,
      [field]: null,
    })

    setData({
      ...data,
      [field]: value,
    })
  }

  const checkEnteredUsername = () => {
    if (!data[USERNAME_FIELD]) {
      return
    }

    checkUser({
      data: {
        [USERNAME_FIELD]: data[USERNAME_FIELD],
      },
    })
  }

  const isAllFieldFilled = !!(data[USERNAME_FIELD] && data[PIN_FIELD])

  const onLoginHandle = () => {
    if (!isAllFieldFilled) {
      return setInputErrors({
        [USERNAME_FIELD]: data[USERNAME_FIELD] ? '' : { text: 'required' },
        [PIN_FIELD]: data[PIN_FIELD] ? '' : { text: 'required' },
      })
    }

    if (data[PIN_FIELD] && data[PIN_FIELD].length < 6) {
      return setInputErrors({
        [PIN_FIELD]: { text: 'At least 6 numbers' },
      })
    }

    setInputErrors({})

    login({ data })
  }

  return (
    <div className="login">
      <Logo className="login__logo" />
      <h2 className="login__title">Log in</h2>
      <p className="login__text">Welcome to Warehouse Management System!</p>
      <Form
        fields={[
          {
            type: inputs.TEXT,
            name: USERNAME_FIELD,
            events: {
              onChange,
              onBlur: checkEnteredUsername,
            },
            inputProps: {
              pattern: REGEX.IS_TEXT,
              placeholder: 'USERNAME',
              tabIndex: 1,
            },
            value: data[USERNAME_FIELD],
            state: {
              type: !isEmptyObject(user)
                ? stateTypes.SUCCESS
                : inputErrors[USERNAME_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[USERNAME_FIELD]
                ? inputErrors[USERNAME_FIELD].text
                : '',
            },
          },
          {
            type: inputs.PASSWORD,
            name: PIN_FIELD,
            events: {
              onChange,
            },
            inputProps: {
              pattern: REGEX.IS_PIN,
              placeholder: 'PIN',
              tabIndex: 2,
            },
            value: data[PIN_FIELD],
            state: {
              type: inputErrors[PIN_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[PIN_FIELD]
                ? inputErrors[PIN_FIELD].text
                : '',
            },
          },
        ]}
        className="login__form"
        onSubmit={onLoginHandle}
        submitBtn={<Button tabIndex="3" text="Login" onClick={onLoginHandle} />}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errors: state.auth.errors,
})

const mapDispatchToProps = {
  checkUser: checkUserStart,
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
