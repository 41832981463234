import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { fetchStaffAssignmentStart } from 'store/staff/actions'
import { resetModals, showModalStaffDetails } from 'store/modal/actions'
import User from 'services/User'
import { isEmptyObject, redirect } from 'helpers/index'
import StaffDetailsModal from 'components/features/Order/OrdersPage/StaffDetails/Modal'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import Button from 'components/common/Button'
import ContentSection from 'components/common/PageContent/ContentSection'
import AssignToBlock from 'components/features/Order/OrdersStaffAssignmentPage/AssignToBlock'
import StaffEngagement from 'components/features/Order/OrdersStaffAssignmentPage/StaffEngagement'
import { ROUTES } from 'constants/routes'
import { ROLES } from 'constants/roles'
import { ORDER_PICK_STATUSES } from 'constants/order'
import {
  createManyTaskAssignment,
  deleteManyTaskAssignment,
} from 'store/taskAssignment/actions'
import {
  fetchOneOrderProcessStart,
  updateOrderProcessStart,
} from 'store/orderProcess/actions'
import { connect } from 'react-redux'
import './style.scss'
import { getNormalizedStaffAssignments } from 'store/staff/selectors'

const OrdersStaffAssignmentPage = (props) => {
  const {
    match,
    staff = {},
    orderProcess = {},
    modalStaffDetails,

    fetchStaffAssignmentStart,
    resetModals,
    updateOrdersStart,
    showModalStaffDetails,
    createManyTaskAssignment,
    deleteManyTaskAssignment,
    fetchOneOrderProcessStart,
  } = props

  const [assignToList, setAssignToList] = useState([])
  const matchParams = match.params

  const onMount = () => {
    fetchOneOrderProcessStart(matchParams.id)
    fetchStaffAssignmentStart()
  }
  const onChangeOrderProcess = () => {
    if (!isEmptyObject(orderProcess)) {
      setAssignToList(orderProcess.team.map((item) => new User(item)) || [])
    }
  }

  useEffect(onMount, [])
  useEffect(onChangeOrderProcess, [orderProcess])

  if (isEmptyObject(orderProcess) || isEmptyObject(staff)) {
    return ''
  }

  const onRemoveAssign = (item) =>
    setAssignToList(
      assignToList.filter((assignToItem) => assignToItem.id !== item.id),
    )

  const onAssign = (item) => {
    const existingObject =
      assignToList.find((assignToItem) => assignToItem.id === item.id) || {}

    if (isEmptyObject(existingObject)) {
      setAssignToList([...assignToList, ...[item]])
    }
  }

  const getOrderCasesQty = () =>
    orderProcess.entity.order.total_products_quantity

  const onShowDetailsPopup = (params) =>
    showModalStaffDetails({
      ...params,
      orderProcess,
    })

  const onSaveAssignment = () => {
    orderProcess.tasks.forEach((task) => {
      const oldStaffIds = task.task_assignments
        .map((taskAssignment) => taskAssignment.warehouse_staff_id)
        .sort()
      const newStaffIds = assignToList
        .map((item) => item.warehouseStaffId)
        .sort()

      if (JSON.stringify(newStaffIds) !== JSON.stringify(oldStaffIds)) {
        const staffIdsToCreate = newStaffIds.filter(
          (id) => oldStaffIds.indexOf(id) === -1,
        )
        const staffIdsToDelete = oldStaffIds.filter(
          (id) => newStaffIds.indexOf(id) === -1,
        )

        let assignmentIdsToDelete = []
        staffIdsToDelete.forEach((staffIdToDelete) => {
          task.task_assignments.forEach((assignment) => {
            if (assignment.warehouse_staff_id === staffIdToDelete) {
              assignmentIdsToDelete.push(assignment.id)
            }
          })
        })

        if (staffIdsToCreate.length > 0) {
          createManyTaskAssignment({
            request: {
              data: {
                task_id: task.id,
                staff: {
                  [ROLES.PICKING_WORKER]: staffIdsToCreate,
                },
              },
            },
          })
        }

        if (staffIdsToDelete.length > 0) {
          deleteManyTaskAssignment({
            request: {
              data: {
                task_id: task.id,
                staffIds: staffIdsToDelete,
                taskAssignmentIds: assignmentIdsToDelete,
              },
            },
          })
        }

        if (newStaffIds.length) {
          if (
            orderProcess.entity.orderPickStatus.id !==
            ORDER_PICK_STATUSES.STATUS_PICKING
          ) {
            updateOrdersStart({
              items: [
                {
                  id: orderProcess.id,
                  order_pick_status: ORDER_PICK_STATUSES.STATUS_ASSIGNED,
                },
              ],
            })
          }
        } else {
          updateOrdersStart({
            items: [
              {
                id: orderProcess.id,
                order_pick_status: ORDER_PICK_STATUSES.STATUS_QUEUE,
              },
            ],
          })
        }
      }
    })

    resetModals()
  }

  const onClickCancelButton = () => redirect(ROUTES.ORDERS_ACTIVE_PAGE)
  const onClickSaveButton = () => {
    onSaveAssignment()
    setTimeout(() => {
      onClickCancelButton()
    }, 300)
  }

  return (
    <div className="page-content task-assignment">
      {modalStaffDetails.isOpen && (
        <StaffDetailsModal
          isOpen={modalStaffDetails.isOpen}
          onDeny={resetModals}
        />
      )}
      <ContentHeader
        title="Task assignment"
        description="Assign Workers To A Task"
      >
        <div className="btn-group">
          <Button
            disabled={false}
            type="submit"
            onClick={onClickSaveButton}
            text="Save"
          />
          <Button type="button" onClick={onClickCancelButton} text="Cancel" />
        </div>
      </ContentHeader>
      <ContentSection>
        <ul className="list list--row">
          <li className="list__item">
            <span className="text--bold">Order Number</span>
            {orderProcess.entity.order.orders_id}
          </li>
          <li className="list__item">
            <span className="text--bold">Case</span>
            {getOrderCasesQty()}
          </li>
          <li className="list__item">
            <span className="text--bold">Assign Role</span>Picking Worker
          </li>
        </ul>
      </ContentSection>
      <AssignToBlock onRemove={onRemoveAssign} items={assignToList} />
      <StaffEngagement
        staff={staff}
        assignToList={assignToList}
        onAssign={onAssign}
        onShowDetailsPopup={onShowDetailsPopup}
      />
    </div>
  )
}

OrdersStaffAssignmentPage.propTypes = {
  orderProcess: PropTypes.object,
  modalStaffDetails: PropTypes.object,
  staff: PropTypes.object,

  resetModals: PropTypes.func,
  updateOrdersStart: PropTypes.func,
  showModalStaffDetails: PropTypes.func,
  fetchOneOrderProcessStart: PropTypes.func,
  createManyTaskAssignment: PropTypes.func,
  deleteManyTaskAssignment: PropTypes.func,
  fetchStaffAssignmentStart: PropTypes.func,
}

const mapStateToProps = (state) => ({
  orderProcess: state.orderProcess.item,
  staff: getNormalizedStaffAssignments(state),
  modalStaffDetails: state.modal.modalStaffDetails,
})

const mapDispatchToProps = {
  resetModals,
  updateOrdersStart: updateOrderProcessStart,
  showModalStaffDetails,
  createManyTaskAssignment,
  deleteManyTaskAssignment,
  fetchOneOrderProcessStart,
  fetchStaffAssignmentStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersStaffAssignmentPage)
