import React, { useEffect, useState } from 'react'
import { checkUserStart } from 'store/auth/actions'
import { connect } from 'react-redux'
import Form from 'components/common/FormElements/Form'
import { inputs, stateTypes } from 'constants/form'
import { PIN_FIELD, USERNAME_FIELD } from 'constants/fields'
import { REGEX } from 'constants/regex'
import Button from 'components/common/Button'
import Logo from 'assets/svg/logo.svg'

const INITIAL_FIELDS = {
  [USERNAME_FIELD]: '',
  [PIN_FIELD]: '',
}

const CheckUserForm = (props) => {
  const { user, errors, checkUser } = props

  const [data, setData] = useState(INITIAL_FIELDS)
  const [inputErrors, setInputErrors] = useState({})

  useEffect(() => {
    setInputErrors({
      ...inputErrors,
      ...errors,
    })
  }, [errors, checkUser, setInputErrors])

  const onChange = (name, value) => {
    setInputErrors({
      ...inputErrors,
      [name]: null,
    })

    setData({
      ...data,
      [name]: value,
    })
  }

  const checkUsername = () => {
    if (!data[USERNAME_FIELD]) {
      return
    }

    checkUser({ data: { [USERNAME_FIELD]: data[USERNAME_FIELD] } })
  }

  const checkPassword = () => {
    if (data[PIN_FIELD].length < 6 || !data[USERNAME_FIELD]) {
      return
    }

    checkUser({ data })
  }

  const isAllFieldFilled = !!(data[USERNAME_FIELD] && data[PIN_FIELD])

  const onSubmit = () => {
    if (!isAllFieldFilled) {
      return setInputErrors({
        [USERNAME_FIELD]: data[USERNAME_FIELD] ? '' : { text: 'required' },
        [PIN_FIELD]: data[PIN_FIELD] ? '' : { text: 'required' },
      })
    }

    checkUser({ data, allInfo: true })
  }

  return (
    <div className="login">
      <Logo className="login__logo" />
      <h2 className="login__title">Reset PIN</h2>
      <p className="login__text">Reset Your New PIN Here</p>
      <Form
        fields={[
          {
            type: inputs.TEXT,
            name: USERNAME_FIELD,
            events: {
              onChange,
              onBlur: checkUsername,
            },
            inputProps: {
              pattern: REGEX.IS_TEXT,
              placeholder: 'USERNAME',
              tabIndex: 5,
            },
            value: data[USERNAME_FIELD],
            state: {
              type: user[USERNAME_FIELD]
                ? stateTypes.SUCCESS
                : inputErrors[USERNAME_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[USERNAME_FIELD]
                ? inputErrors[USERNAME_FIELD].text
                : '',
            },
          },
          {
            type: inputs.PASSWORD,
            name: PIN_FIELD,
            events: {
              onChange,
              onBlur: checkPassword,
            },
            inputProps: {
              pattern: REGEX.IS_PIN,
              placeholder: 'PIN',
              tabIndex: 6,
            },
            value: data[PIN_FIELD],
            state: {
              type: user[PIN_FIELD]
                ? stateTypes.SUCCESS
                : inputErrors[PIN_FIELD]
                ? stateTypes.ERROR
                : stateTypes.DEFAULT,
              message: inputErrors[PIN_FIELD]
                ? inputErrors[PIN_FIELD].text
                : '',
            },
          },
        ]}
        className="login__form"
        onSubmit={onSubmit}
        submitBtn={<Button tabIndex="7" text="Next" onClick={onSubmit} />}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errors: state.auth.errors,
})

const mapDispatchToProps = {
  checkUser: checkUserStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckUserForm)
