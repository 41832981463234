export const PAGES = {
  LOCATION: `/locations`,
  CONTAINER: `/container/`,
  ORDER: `/orders/`,
  INVENTORY: `/inventory/`,
}

export const ROUTES = {
  MAIN_PAGE: `/`,
  HOME_PAGE: `/home`,

  LOCATION_LIST_PAGE: `${PAGES.LOCATION}`,
  LOCATION_SINGLE_ADD_PAGE: `${PAGES.LOCATION}/add-single`,
  LOCATION_GROUP_ADD_PAGE: `${PAGES.LOCATION}/add-group`,
  LOCATION_EDIT_DETAILS_PAGE: `${PAGES.LOCATION}/edit-details/`,
  LOCATION_EDIT_DETAILS_PAGE_WITH_ID: `${PAGES.LOCATION}/edit-details/:id`,

  CONTAINER_RECEIVING_LIST_PAGE: `${PAGES.CONTAINER}receiving`,
  CONTAINER_UNLOADING_LIST_PAGE: `${PAGES.CONTAINER}unloading`,
  CONTAINER_RECEIVING_DETAILS_PAGE: `${PAGES.CONTAINER}receiving/details/`,
  CONTAINER_UNLOADING_DETAILS_PAGE: `${PAGES.CONTAINER}unloading/details/`,
  CONTAINER_RECEIVING_DETAILS_PAGE_WITH_ID: `${PAGES.CONTAINER}receiving/:id`,
  CONTAINER_UNLOADING_DETAILS_PAGE_WITH_ID: `${PAGES.CONTAINER}unloading/:id`,
  CONTAINER_QA_HOLD_DETAILS: `${PAGES.CONTAINER}qa-hold-details`,

  ORDERS_ACTIVE_PAGE: `${PAGES.ORDER}active`,
  ORDERS_STAFF_ASSIGNMENT_PAGE_WITH_ID: `${PAGES.ORDER}staff/:id`,
  ORDERS_REMOVED_PAGE: `${PAGES.ORDER}removed`,
  ORDERS_SHIPPING_PAGE: `${PAGES.ORDER}shipping`,
  ORDERS_CREATE_SHIPMENT_PAGE: `${PAGES.ORDER}shipping/create-shipment/:orderIds`,

  INVENTORY_INVENTORY_MANAGEMENT_PAGE: `${PAGES.INVENTORY}inventory-management`,
  INVENTORY_DAMAGE_RESOLUTION_PAGE: `${PAGES.INVENTORY}damage-resolution`,
  INVENTORY_QA_HOLD_RESOLUTION_PAGE: `${PAGES.INVENTORY}qa-hold-resolution`,
  INVENTORY_RETURN_RESOLUTION_PAGE: `${PAGES.INVENTORY}return-resolution`,
  RESTOCK_TASK_DETAIL_PAGE_WITH_ID: `${PAGES.INVENTORY}restock-task-detail/:id`,

  ABOUT_PAGE: `/about`,
  CLEAR_PRINTERS_PAGE: `/clear-printers`,

  LOGIN_PAGE: `/login`,
}
