import React from 'react'
import * as PropTypes from 'prop-types'
import ArrowLeftIcon from 'assets/svg/arrow-left.svg'
import ArrowDownIcon from 'assets/svg/arrow-down.svg'
import './style.scss'

const ExpandableBlock = (props) => {
  const {
    headerContent = '',
    bodyContent = '',
    className = '',
    title = '',

    isExpanded = true,

    onToggle,
  } = props

  return (
    <div className={`expandable-block ${className}`}>
      <div className="expandable-block__header" onClick={onToggle}>
        <div className="title">
          {isExpanded ? <ArrowDownIcon /> : <ArrowLeftIcon />}
          {title}
        </div>
        {headerContent}
      </div>
      {isExpanded && bodyContent}
    </div>
  )
}

ExpandableBlock.propTypes = {
  headerContent: PropTypes.any,
  bodyContent: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,

  isExpanded: PropTypes.bool,

  onToggle: PropTypes.func,
}

export default ExpandableBlock
