import React from 'react'
import * as PropTypes from 'prop-types'
import FormRow from 'components/common/FormElements/FormRow'
import Button from 'components/common/Button'
import './style.scss'

const Form = (props) => {
  const {
    method = 'POST',
    fields,
    onSubmit,
    className,
    submitBtn = <Button>Submit</Button>,
  } = props

  const onSubmitHandle = (e) => {
    e.preventDefault()

    onSubmit()
  }

  return (
    <form
      method={method}
      onSubmit={(e) => onSubmitHandle(e)}
      className={`form-wrapper ${className ? className : ''}`}
    >
      <div className="form-wrapper__section">
        {fields.map((field) => (
          <FormRow field={field} key={field.name} />
        ))}
      </div>
      {submitBtn}
    </form>
  )
}

Form.propTypes = {
  method: PropTypes.string,
  className: PropTypes.string,

  submitBtn: PropTypes.any,

  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string.isRequired,
      className: PropTypes.string,

      events: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
      }),
      inputProps: PropTypes.shape({
        pattern: PropTypes.any,
        placeholder: PropTypes.string,

        tabIndex: PropTypes.number,
        fontSize: PropTypes.number,
      }),

      value: PropTypes.any.isRequired,

      disabled: PropTypes.bool,

      state: PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,

  onSubmit: PropTypes.func.isRequired,
}

export default Form
