import { types } from './actions'

const INIT = {
  item: {},
  newItem: {},
  isLoading: false,
  isStale: false,
}

const appInfoReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_APP_INFO_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_APP_INFO_SUCCESS:
      const item = !state.item.build_id ? payload.data.item : state.item

      const isStale =
        state.item.build_id &&
        (state.isStale || state.item.build_id !== payload.data.item.build_id)

      return {
        ...state,
        item: item,
        newItem: payload.data.item,
        isLoading: false,
        isStale: isStale,
      }
    case types.FETCH_APP_INFO_FAIL:
      return INIT
    default:
      return state
  }
}

export default appInfoReducer
