import { STORE_VERSION } from 'store/rootReducer'

export const initLocalStorage = (state) => {
  if (!!state.version && state.version !== STORE_VERSION) {
    state = {}
    localStorage.clear()
  }

  return state
}
