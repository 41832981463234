export const SNACK_ERROR = {
  variant: 'error',
}

export const SNACK_SUCCESS = {
  variant: 'success',
}

export const SNACK_WARNING = {
  variant: 'warning',
}

export const SNACK_INFO = {
  variant: 'info',
}
