import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.ORDER.FETCH_MANY,
    method: 'GET',
    params: payload,
  })

export const fetchOne = (payload) =>
  request({
    url: API_ROUTE.ORDER.FETCH_ONE(payload),
    method: 'GET',
  })

export const updateLoadStatus = (payload) =>
  request({
    url: API_ROUTE.ORDER.UPDATE_LOAD_STATUS,
    method: 'PUT',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.ORDER.UPDATE,
    method: 'PUT',
    data: payload,
  })

export const deleteOne = (payload) =>
  request({
    url: API_ROUTE.ORDER.DELETE(payload),
    method: 'DELETE',
    data: payload,
  })

export const moveToNextDay = (payload) =>
  request({
    url: API_ROUTE.ORDER.MOVE_TO_NEXT_DAY(payload),
    method: 'PUT',
    data: payload,
  })
