export const ROLES = {
  WAREHOUSE_MANAGER: 1,
  SUPPLY_CHAIN_MANAGER: 2,
  DOCK_LEAD: 3,
  UNLOAD_WORKER: 4,
  PICKING_WORKER: 5,
  RESTOCKER: 6,
  SHIPPING: 7,
}

export const MANAGER_ROLES = [
  ROLES.WAREHOUSE_MANAGER,
  ROLES.SUPPLY_CHAIN_MANAGER,
  ROLES.DOCK_LEAD,
]
export const WORKER_ROLES = [
  ROLES.UNLOAD_WORKER,
  ROLES.PICKING_WORKER,
  ROLES.RESTOCKER,
  ROLES.SHIPPING,
]
