export const inputs = {
  TEXT: 'text',
  PASSWORD: 'password',
}

export const stateTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  DEFAULT: '',
}
