import React from 'react'
import * as PropTypes from 'prop-types'

import SuccessIcon from 'assets/svg/check-icon--secondary.svg'

import 'react-circular-progressbar/dist/styles.css'
import 'assets/components/progress-bar.scss'

import Icon from 'components/common/Icon'
import CheckmarkIcon from 'assets/svg/check-icon--empty.svg'
import { isBrowser } from 'react-device-detect'

const Avatar = (props) => {
  const {
    link,
    loadPercent,
    className,
    isChecked,
    isPrimary,
    onClick,
    onMouseOver,
    onMouseOut,
    onLongPress,
  } = props

  return (
    <Icon
      onMouseOver={isBrowser ? onMouseOver : () => false}
      onMouseOut={isBrowser ? onMouseOut : () => false}
      onLongPress={!isBrowser ? onLongPress : () => false}
      key={link}
      onClick={onClick}
      className={`avatar ${loadPercent === 100 ? 'avatar--success' : ''} ${
        className ? className : ''
      } ${!isPrimary ? 'avatar--secondary' : ''}`.trim()}
      src={link}
    >
      {loadPercent ? (
        <>
          <SuccessIcon className="icon--check" />
        </>
      ) : null}
      {isChecked && <CheckmarkIcon className="checkmark" />}
    </Icon>
  )
}

Avatar.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,

  isChecked: PropTypes.bool,
  isPrimary: PropTypes.bool,

  loadPercent: PropTypes.number,

  onClick: PropTypes.func,
}

export default Avatar
