import React, { Component } from 'react'
import RushIcon from 'assets/svg/rush-shipping.svg'
import query from 'querystringify'
import { connect } from 'react-redux'
import './style.scss'
import DragDropTable from 'components/common/DragDropTable'
import PageContent from 'components/common/PageContent'
import AvatarList from 'components/features/StaffCard/AvatarList'
import Button from 'components/common/Button'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ExpandableBlock from 'components/common/ExpandableBlock'
import User from 'services/User'
import {
  fetchOrderProcessListStart,
  updateOrderProcessStart,
  deleteOrderProcessStart,
  moveOrderToNextDayStart,
} from 'store/orderProcess/actions'
import { isEmptyObject, redirect, dateToFormat } from 'helpers/index'
import { fetchFiltersStart } from 'store/filter/actions'
import { DragDropContext } from 'react-beautiful-dnd'
import { showModal, closeModal } from 'store/modal/actions'
import {
  ALL_ORDERS_STATUSES,
  CARRIERS,
  ORDER_PICK_STATUS_LABELS,
  ORDER_PICK_STATUSES,
  isDeleteable,
  ORDER_PRICE_LEVELS,
} from 'constants/order'
import { MAPPER_CARRIER_LOGO } from 'constants/mappers'
import { FILTERS } from 'constants/filters'
import { ROUTES } from 'constants/routes'
import Switch from 'components/common/Switch'
import Tooltip from 'components/common/Tooltip'
import {
  GreenDot,
  YellowDot,
  YellowHoldDot,
  RedDot,
  BlackDot,
} from 'components/common/StockStatus'
import { MODAL_PENDING } from 'components/common/Modal'
import { MdDelete, MdUpdate } from 'react-icons/md'
import { GrNotes } from 'react-icons/gr'
import { isWarehouseManager } from 'helpers/authorize'

const TABLE_COLUMNS_FOR_TODAY = [
  {
    id: 1,
    key: 'orderId',
    label: 'Order',
  },
  {
    id: 2,
    key: 'carrier',
    label: 'Carrier',
  },
  {
    id: 3,
    key: 'case',
    label: 'Case',
  },
  {
    id: 4,
    key: 'weight',
    label: 'Weight',
  },
  {
    id: 5,
    key: 'customer',
    label: 'Customer',
  },
  {
    id: 6,
    key: 'status',
    label: 'Status',
  },
  {
    id: 7,
    key: 'shippingDate',
    label: 'Shipping Date',
  },
  {
    id: 8,
    key: 'pickers',
    label: 'Picker (s)',
  },
  {
    id: 9,
    key: 'orderNotes',
    label: 'Order Notes',
  },
  {
    id: 9,
    key: 'specialAttention',
    label: 'Special Attention',
  },
  {
    id: 10,
    key: 'removeOrder',
    label: '',
  },
  {
    id: 11,
    key: 'moveOrderToNextDay',
    label: '',
  },
]

class OrdersPage extends Component {
  state = {
    columns: ['today'],
    updatedItems: [],
    filters: {},
    sorting: {},
    dndTablesProps: {
      today: {
        isTableExpanded: true,
        isTableFiltersExpanded: true,
      },
    },
  }

  timeoutId = null

  componentDidMount() {
    this.props.fetchFilters([FILTERS.CARRIER_FILTER])
    this.fetchOrders()
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }

  fetchOrders = () => {
    const { fetchOrders, showModal } = this.props

    const queryParams = query.parse(window.location.search)

    this.setState({
      filters: queryParams[FILTERS.CARRIER_FILTER]
        ? { carriers: [parseInt(queryParams[FILTERS.CARRIER_FILTER])] }
        : {},
    })

    const mappedFilters = queryParams[FILTERS.CARRIER_FILTER]
      ? { 'filters[carriers]': [queryParams[FILTERS.CARRIER_FILTER]] }
      : {}

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Fetching...</div>,
    })

    fetchOrders({
      params: {
        ...mappedFilters,
        'filters[order_statuses]': ALL_ORDERS_STATUSES,
      },
    })
    setTimeout(this.fetchOrdersQuiet, 60000)
  }

  fetchOrdersQuiet = () => {
    const { fetchOrders } = this.props

    const queryParams = query.parse(window.location.search)

    const mappedFilters = queryParams[FILTERS.CARRIER_FILTER]
      ? { 'filters[carriers]': [queryParams[FILTERS.CARRIER_FILTER]] }
      : {}

    fetchOrders({
      params: {
        ...mappedFilters,
        'filters[order_statuses]': ALL_ORDERS_STATUSES,
      },
    })
    this.timeoutId = setTimeout(this.fetchOrdersQuiet, 60000)
  }

  showOrderNotes = (order) => {
    this.props.showModal({
      name: 'modalOrderNotes',
      onClose: () => {
        this.props.closeModal('modalOrderNotes')
      },
      order: order,
    })
  }

  onDelete = (orders_id) =>
    this.props.showModal({
      name: 'confirm-default-new',
      content: (
        <div className="text">Do you want to remove order {orders_id}?</div>
      ),
      events: {
        onDeny: () => {
          this.props.closeModal('confirm-default-new')
        },
        onAccept: () => {
          this.props.closeModal('confirm-default-new')

          this.props.showModal({
            name: 'modalPendingNew',
            content: <div className="text">Deleting...</div>,
          })
          this.props.deleteOrderProcess(orders_id)
        },
      },
    })

  onMove = (orders_id) =>
    this.props.showModal({
      name: 'confirm-default-new',
      content: (
        <div className="text">
          Do you want to move order {orders_id} to tomorrow?
        </div>
      ),
      events: {
        onDeny: () => {
          this.props.closeModal('confirm-default-new')
        },
        onAccept: () => {
          this.props.closeModal('confirm-default-new')

          this.props.showModal({
            name: 'modalPendingNew',
            content: <div className="text">Moving...</div>,
          })
          this.props.moveOrderToNextDay(orders_id)
        },
      },
    })

  normalizeItems = (items) => {
    return items.map((item) => {
      let orderCasesQty = item.entity.order.total_products_quantity
      let orderWeight = item.entity.order.total_products_weight

      const isNewStatus =
        item.entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_NEW
      const status = isNewStatus ? (
        <span className="text--new">{item.entity.orderPickStatus.name}</span>
      ) : item.team.length > 0 ? (
        ORDER_PICK_STATUS_LABELS[item.entity.orderPickStatus.id]
      ) : (
        item.entity.orderPickStatus.name
      )
      const date = item.entity.order.shipping_date
        ? dateToFormat(item.entity.order.shipping_date, 'MM/DD/YYYY')
        : dateToFormat(item.entity.order.pickup_date, 'MM/DD/YYYY')

      const carrier =
        item.entity.carrier !== null ? (
          <Tooltip
            hoverTarget={MAPPER_CARRIER_LOGO[item.entity.carrier.id]}
            sideText={
              item.entity.carrier.other_carrier_name
                ? item.entity.carrier.other_carrier_name
                : item.entity.carrier.name
            }
            extraTargetClassName="carrier-icon-target"
            extraBubbleClassName="for-carrier-logo"
          />
        ) : (
          'Null'
        )

      const onClick = () =>
        item.entity.orderPickStatus.id !== ORDER_PICK_STATUSES.STATUS_NEW
          ? redirect(
              ROUTES.ORDERS_STAFF_ASSIGNMENT_PAGE_WITH_ID.replace(
                ':id',
                item.id,
              ),
            )
          : false

      let stockDot = ''
      switch (item.entity.order.fill_status) {
        case 'green':
          stockDot = <GreenDot />
          break
        case 'yellow':
          stockDot = <YellowDot />
          break
        case 'yellow_hold':
          stockDot = <YellowHoldDot />
          break
        case 'red':
          stockDot = <RedDot />
          break
        case 'black':
          stockDot = <BlackDot />
          break
        default:
          stockDot = <></>
      }

      return {
        id: item.id,
        orderId: (
          <>
            {item.entity.order.rush ? (
              <>
                <RushIcon />
                &nbsp;&nbsp;
              </>
            ) : (
              ''
            )}
            {stockDot}
            {item.entity.order.orders_id}
          </>
        ),
        carrier: carrier,
        case: orderCasesQty,
        weight: orderWeight.toFixed(2),
        status: status,
        fill_status: <YellowDot />,
        customer: item.entity.order.customers_name,
        shippingDate: date,
        notes: item.entity.order.notes,
        pickers: (
          <div onClick={onClick}>
            {!item.team.length ? (
              <span className="text--assign">
                {!isNewStatus ? '+ Assign' : ''}
              </span>
            ) : (
              <AvatarList items={item.team.map((item) => new User(item))} />
            )}
          </div>
        ),
        orderNotes:
          item.entity.order.shipping_notes ||
          item.entity.order.warehouse_notes ? (
            <GrNotes
              className="notes-icon"
              onClick={() => {
                this.showOrderNotes(item.entity.order)
              }}
            />
          ) : (
            ''
          ),
        specialAttention:
          (item.entity.order.tag ? item.entity.order.tag : '') +
          (item.entity.order.tag &&
          item.entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
            ? ', '
            : '') +
          (item.entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
            ? 'Dealer Network Elite'
            : ''),
        removeOrder:
          isDeleteable(item.entity.orderPickStatus.id) &&
          isWarehouseManager(this.props.auth) ? (
            <MdDelete
              className="delete-icon"
              onClick={() => {
                this.onDelete(item.entity.order.orders_id)
              }}
            />
          ) : (
            ''
          ),
        moveOrderToNextDay:
          isDeleteable(item.entity.orderPickStatus.id) &&
          isWarehouseManager(this.props.auth) ? (
            <MdUpdate
              className="delete-icon"
              onClick={() => {
                this.onMove(item.entity.order.orders_id)
              }}
            />
          ) : (
            ''
          ),
        options: {
          expandable: !!item.entity.order.notes,
        },
        expandedContent: (
          <>
            <li className="list__item list__item--title">Notes</li>
            <li className="list__item">{item.entity.order.notes || ''}</li>
          </>
        ),
      }
    })
  }

  onChangeFilter = (name, value) => {
    const { fetchOrders } = this.props
    const { filters } = this.state

    filters[name] = value

    const mappedFiltersToFetch = {}
    Object.keys(filters).forEach((key) => {
      if (key === FILTERS.CARRIER_FILTER && filters[key])
        return (mappedFiltersToFetch['filters[carriers]'] = [filters[key]])
    })

    const mappedFiltersToState = {}
    Object.keys(filters).forEach((key) => {
      if (key === 'carriers' && filters[key])
        return (mappedFiltersToState[FILTERS.CARRIER_FILTER] = [filters[key]])
    })

    if (value === 0) {
      Object.keys(filters).forEach((key) => {
        if (key === 'carriers')
          return delete mappedFiltersToState[FILTERS.CARRIER_FILTER]
      })

      this.setState({
        filters: {
          ...mappedFiltersToState,
        },
      })

      const queryString = query.stringify(mappedFiltersToState, true)

      redirect(
        !isEmptyObject(mappedFiltersToState)
          ? queryString
          : ROUTES.ORDERS_ACTIVE_PAGE,
      )

      return
    }

    const selectedFilter = this.props[name].find((item) => item.value === value)

    let filterValue = null
    if (name === 'carriers') {
      filterValue = [selectedFilter.value]
    }

    const mappedFiltersToSearch = {}

    mappedFiltersToFetch[`filters[${name}]`] = filterValue
    mappedFiltersToState[name] = filterValue
    mappedFiltersToSearch[FILTERS.CARRIER_FILTER] = filterValue

    fetchOrders({
      params: {
        ...mappedFiltersToFetch,
        'filters[order_statuses]': ALL_ORDERS_STATUSES,
      },
    })

    const queryString = query.stringify(mappedFiltersToSearch, true)

    this.setState({
      filters: {
        ...mappedFiltersToState,
      },
    })

    !isEmptyObject(mappedFiltersToState) && redirect(queryString)
  }

  pushUpdatedItem = (pushedItem) => {
    const { updatedItems } = this.state

    const filteredList = updatedItems.filter(
      (item) => !(pushedItem.id === item.id),
    )

    this.setState({
      updatedItems: [...filteredList, pushedItem],
    })
  }

  onDragEnd = (result) => {
    const { columns } = this.state
    const { today } = this.props
    const { source, destination } = result

    if (!destination) {
      return
    }

    const sourceColumn = this.props[columns[source.droppableId - 1]]
    const destinationColumn = this.props[columns[destination.droppableId - 1]]

    const draggedItem = sourceColumn.splice(source.index, 1)
    destinationColumn.splice(destination.index, 0, ...draggedItem)

    const updateInfo = {
      id: result.draggableId,
    }

    if (source.droppableId === destination.droppableId) {
      const updatedItem = today.find(
        (item) => item.id === parseInt(updateInfo.id),
      )

      updateInfo.priority = destination.index + 1
      updateInfo.order_pick_status = updatedItem.entity.orderPickStatus.id
    } else if (columns[destination.droppableId - 1] === 'today') {
      updateInfo.priority = destination.index + 1
      updateInfo.order_pick_status = ORDER_PICK_STATUSES.STATUS_QUEUE
    } else {
      return
    }

    this.pushUpdatedItem(updateInfo)

    this.setState({
      columns: columns,
    })
  }

  updateOrders = () => {
    const { updateOrders, today, showModal } = this.props
    const { updatedItems } = this.state

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Updating...</div>,
    })

    const updatedList = today.map((item, index) => {
      const updatedItem = updatedItems.find(
        (updatedItem) => item.id === updatedItem.id,
      )
      let orderPickStatus = updatedItem
        ? updatedItem.order_pick_status
        : item.entity.orderPickStatus.id

      if (item.entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_NEW) {
        orderPickStatus = ORDER_PICK_STATUSES.STATUS_QUEUE
      }

      if (
        item.entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_NEXT_DAY
      ) {
        orderPickStatus = ORDER_PICK_STATUSES.STATUS_QUEUE
      }

      if (
        item.entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_QUEUE &&
        item.team.length
      ) {
        orderPickStatus = ORDER_PICK_STATUSES.STATUS_ASSIGNED
      }

      return {
        id: item.id,
        priority: index + 1,
        order_pick_status: orderPickStatus,
      }
    })

    updateOrders({ items: updatedList })
  }

  onPrepareOrdersClick = () => {
    this.props.showModal({
      name: MODAL_PENDING,
      content: <div className="text">Fetching all orders...</div>,
    })
    this.props.fetchOrders({
      params: {
        'filters[prepare_all]': 1,
      },
    })
  }

  onNewRemovedOrdersClick = () => {
    this.props.showModal({
      name: MODAL_PENDING,
      content: <div className="text">Fetching New/Removed orders...</div>,
    })
    this.props.fetchOrders({
      params: {
        'filters[prepare_new_removed]': 1,
      },
    })
  }

  toggleTodayTableFiltersExpanded = (e) => {
    this.setState({
      dndTablesProps: {
        ...this.state.dndTablesProps,
        today: {
          ...this.state.dndTablesProps.today,
          isTableFiltersExpanded: !this.state.dndTablesProps.today
            .isTableFiltersExpanded,
        },
      },
    })

    e.stopPropagation()
  }

  onToggleTableExpanded = (type) => {
    this.setState({
      dndTablesProps: {
        ...this.state.dndTablesProps,
        [type]: {
          ...this.state.dndTablesProps[type],
          isTableExpanded: !this.state.dndTablesProps[type].isTableExpanded,
          isTableFiltersExpanded: !this.state.dndTablesProps[type]
            .isTableExpanded,
        },
      },
    })
  }

  render() {
    const { today, carriers } = this.props
    const { filters } = this.state

    const todayFilters = {
      carriers: carriers,
    }

    const generateFilterList = () =>
      Object.keys(todayFilters).map((filter) => (
        <Switch
          key={filter}
          className="switch--multiple switch--secondary"
          name={filter}
          items={todayFilters[filter]}
          defaultValue="All"
          selected={filters[filter] ? filters[filter][0] : 0}
          scalable
          onChange={this.onChangeFilter}
        />
      ))

    const TODAY_COLUMN_ID = 1

    const DRAG_DROP_COLUMNS = [
      {
        id: TODAY_COLUMN_ID,
        title: 'Orders today',
        columns: TABLE_COLUMNS_FOR_TODAY,
        items: today,
        filters: {
          carriers: carriers,
        },
        selectedFilters: filters,
        mapper: this.normalizeItems,
        isExpanded: this.state.dndTablesProps.today.isTableExpanded,
        onToggle: () => this.onToggleTableExpanded('today'),
        headerContent: (
          <>
            {!isEmptyObject(todayFilters) && (
              <div
                className={`filter-btn ${
                  this.state.dndTablesProps.today.isTableFiltersExpanded
                    ? 'filter-btn--active'
                    : ''
                }`}
                onClick={this.toggleTodayTableFiltersExpanded}
              >
                Filter
              </div>
            )}
            <Button
              text="New/Removed Orders"
              onClick={this.onNewRemovedOrdersClick}
            />
            <Button
              disabled={!today.length}
              text="Save"
              onClick={this.updateOrders}
            />
          </>
        ),
        bodyContent: (
          <>
            {this.state.dndTablesProps.today.isTableFiltersExpanded && (
              <div className="filters-list">
                {generateFilterList().map((filter) => filter)}
              </div>
            )}
            <div className="expandable-block__content">
              <DragDropTable
                columns={TABLE_COLUMNS_FOR_TODAY}
                rows={this.normalizeItems(today)}
                droppableId={TODAY_COLUMN_ID}
                key={TODAY_COLUMN_ID}
              />
            </div>
          </>
        ),
      },
    ]

    return (
      <PageContent className="page-content orders-page">
        <ContentHeader
          title="Orders"
          description="Order Prioritizing And Assignment"
        >
          {today.length === 0 ? (
            <Button
              className="btn--secondary"
              text="Prepare Orders"
              onClick={this.onPrepareOrdersClick}
            />
          ) : (
            <Button
              className="btn--secondary"
              text="Refresh"
              onClick={this.fetchOrders}
            />
          )}
        </ContentHeader>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          {DRAG_DROP_COLUMNS.map((item) => (
            <ExpandableBlock
              key={item.id}
              headerContent={item.headerContent}
              bodyContent={item.bodyContent}
              className="table-expand"
              title={`${item.title} (${item.items ? item.items.length : 0})`}
              isExpanded={item.isExpanded}
              onToggle={item.onToggle}
            />
          ))}
        </DragDropContext>
      </PageContent>
    )
  }
}

const mapStateToProps = (state) => ({
  today: state.orderProcess.today,
  carriers: state.filter.filterParams.carrierList,
  auth: state.auth,
})

const mapDispatchToProps = {
  fetchOrders: fetchOrderProcessListStart,
  updateOrders: updateOrderProcessStart,
  fetchFilters: fetchFiltersStart,
  deleteOrderProcess: deleteOrderProcessStart,
  moveOrderToNextDay: moveOrderToNextDayStart,
  showModal: showModal,
  closeModal: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage)
