import React from 'react'
import * as PropTypes from 'prop-types'
import {
  AISLE_FIELD,
  BAY_RANGE_TYPE_FIELD,
  BAY_RANGE_SWITCH_FIELD,
  LEVEL_FIELD,
} from 'constants/formFields'
import { HORIZONTAL } from 'constants/location'
import { splitArrayByRanges } from 'helpers'

const LocationGroupAddLocationItem = ({ item, aisleList, className }) => {
  const aisle = aisleList.find(
    (aisleItem) => aisleItem.value === item[AISLE_FIELD],
  )

  return (
    <div className={`form form--filled ${className}`}>
      <div className="form__main-column">
        <div className="form__row">
          <div className="title">Aisle</div>
          <span>{aisle.label}</span>
        </div>
        <div className="form__row">
          <div className="title">Level</div>
          <div className="text">{item[LEVEL_FIELD]}</div>
        </div>
        <div className="form__row">{item[BAY_RANGE_SWITCH_FIELD]}</div>
      </div>

      <div className="form__main-column">
        <div className="form__row">
          <div className="title">Pallet Capacity</div>
          <div className="text">{item.pallets_capacity}</div>
        </div>
        <div className="form__row form__row--auto-margin">
          <div className="title">Bay Range</div>
          <div className="text">
            {splitArrayByRanges(item[BAY_RANGE_TYPE_FIELD])}
          </div>
        </div>
      </div>
      <div className="form__row form__footer">Location added</div>
    </div>
  )
}

LocationGroupAddLocationItem.propTypes = {
  item: PropTypes.shape({
    aisle_id: PropTypes.number,
    pallets_capacity: PropTypes.number,
    label_orientation: PropTypes.string,
    level: PropTypes.string,
    className: PropTypes.string,

    bay_range_type: PropTypes.array,
  }),
}

LocationGroupAddLocationItem.defaultProps = {
  item: {
    aisle_id: '',
    level: '',
    pallets_capacity: '',
    label_orientation: HORIZONTAL,
    bay_range_type: [],
  },
}

export default LocationGroupAddLocationItem
