import React from 'react'
import * as PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dateToFormat } from 'helpers'
import moment from 'moment'
import './style.scss'
import CalendarIcon from 'assets/svg/calendar.svg'

const Calendar = ({ value, onChange, name }) => {
  const selected = moment(value).format('ddd, MMM Do')
  const selectedIsToday = moment(value).isSame(new Date(), 'day')

  const onChangeDate = (date) =>
    onChange(name, dateToFormat(date, 'YYYY-MM-DD'))

  return (
    <DatePicker
      customInput={
        <div className="datepicker__input">
          <CalendarIcon className="icon-calendar" />
        </div>
      }
      useWeekdaysShort={true}
      selected={value}
      onChange={onChangeDate}
      highlightDates={[new Date()]}
    >
      <div className="react-datepicker__header-day">
        {selectedIsToday ? <span className="text">Today </span> : null}
        {selected}
      </div>
    </DatePicker>
  )
}

Calendar.propTypes = {
  value: PropTypes.instanceOf(Date),

  name: PropTypes.string,

  onChange: PropTypes.func,
}

export default Calendar
