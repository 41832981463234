import { FILTERS, SORT_FILTERS } from 'constants/filters'
import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'

export const DEFAULT_URL_PARAMS = {
  PAGE_PARAM: 'page',
  PER_PAGE_PARAM: 'perPage',
  ORDER_BY: SORT_FILTERS.SORT_DIRECTION,
  SORT_BY: SORT_FILTERS.SORT_BY_KEY,
}

export const DEFAULT_URL_PARAMS_LIST = Object.keys(DEFAULT_URL_PARAMS)

export const DEFAULT_URL_PARAMS_VALUES = {
  [DEFAULT_URL_PARAMS.PAGE_PARAM]: '1',
  [DEFAULT_URL_PARAMS.PER_PAGE_PARAM]: '50',
  [DEFAULT_URL_PARAMS.ORDER_BY]: DIRECTION_ASC,
}

export const DEFAULT_URL_PARAMS_VALUES_CONTAINER_RECEIVING = {
  [DEFAULT_URL_PARAMS.SORT_BY]: FILTERS.CONTAINER_EXPECTED_ARRIVE_DATE_FILTER,
  [DEFAULT_URL_PARAMS.ORDER_BY]: DIRECTION_ASC,
}

export const DEFAULT_URL_PARAMS_VALUES_CONTAINER_UNLOADING = {
  [DEFAULT_URL_PARAMS.SORT_BY]: FILTERS.CONTAINER_ARRIVE_DATE_FILTER,
  [DEFAULT_URL_PARAMS.ORDER_BY]: DIRECTION_DESC,
}
