import request from 'helpers'
import { API_ROUTE } from 'constants/apiRoutes'

export const fetchOne = (payload) =>
  request({
    url: API_ROUTE.LOCATION.FETCH_ONE(payload.id),
    method: 'GET',
  })

export const createLocationLaneDeleteReason = (payload) =>
  request({
    url: API_ROUTE.LOCATION.CREATE_LANE_DELETE_REASON,
    method: 'POST',
    data: payload,
  })

export const fetchMany = (payload) =>
  request({
    url: API_ROUTE.LOCATION.FETCH_MANY(payload),
    method: 'GET',
  })

export const fetchAvailableBaysByAisleAndWarehouse = (payload) =>
  request({
    url: API_ROUTE.LOCATION.FETCH_AVAILABLE_BAYS,
    method: 'GET',
    params: payload,
  })

export const create = (payload) =>
  request({
    url: API_ROUTE.LOCATION.CREATE,
    method: 'POST',
    data: payload,
  })

export const createMany = (payload) =>
  request({
    url: API_ROUTE.LOCATION.CREATE_MANY,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: API_ROUTE.LOCATION.UPDATE(payload.id),
    method: 'PUT',
    data: payload,
  })

export const remove = (payload) =>
  request({
    url: API_ROUTE.LOCATION.REMOVE(payload),
    method: 'DELETE',
  })
