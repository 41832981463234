import { types } from './actions'

const INIT = {
  items: [],
  error: null,
  isShipmentCreated: false,
  isMarkedAsPrinted: false,
}

export default function shipmentReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.CREATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        isShipmentCreated: true,
      }
    case types.MARK_AS_PRINTED_SUCCESS:
      return {
        ...state,
        isMarkedAsPrinted: true,
      }
    case types.FETCH_SHIPMENT_LIST_START:
      return INIT
    case types.FETCH_SHIPMENT_LIST_SUCCESS:
      return {
        ...state,
        items: payload,
      }
    case types.FETCH_SHIPMENT_ERROR_SUCCESS:
      return {
        ...state,
        error: payload,
      }
    default:
      return state
  }
}
