export const REGEX = {
  IS_DIGIT: /^\d+$/,
  IS_INTEGER: /^\d*$/,
  IS_DIGIT_WITH_DOTS: /^\d+(\.\d+)*$/,
  IS_YES_NO: /^(Yes|No)$/,
  IS_LEVEL_FIELD: /^\d+$/,
  IS_DEPTH_FIELD: /^[0-3]$/,
  IS_PALLETS_CAPACITY_FIELD: /(?:\b|-)([1-9]{1,2}[0]?|100)\b/,
  IS_TEXT: /^\w*$/,
  IS_PIN: /^\d{0,6}$/,
}
