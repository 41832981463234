import React from 'react'
import * as PropTypes from 'prop-types'
import SelectInput from 'components/common/SelectInput'
import { DEFAULT_VALUES, FILTERS } from 'constants/filters'
import { getUrlParams, getUrlFilter } from 'helpers'

const InventoryManagementFilters = ({ filters, onChange }) => {
  const onChangeFilter = (name, value) => {
    const oldParams = getUrlParams()
    const newParams = value
      ? { ...oldParams, [name]: value }
      : Object.keys(oldParams)
          .filter((filterName) => filterName !== name)
          .reduce((obj, filterName) => {
            obj[filterName] = oldParams[filterName].toString()

            return obj
          }, {})

    onChange(newParams)
  }

  return (
    <div className="inv-filter-section bottom">
      <SelectInput
        key={FILTERS.PRODUCT_MODEL_FILTER}
        name={FILTERS.PRODUCT_MODEL_FILTER}
        value={getUrlFilter(FILTERS.PRODUCT_MODEL_FILTER)}
        defaultValue={DEFAULT_VALUES[FILTERS.PRODUCT_MODEL_FILTER]}
        items={filters[FILTERS.PRODUCT_MODEL_FILTER]}
        styleOptions={{
          container: (provided) => ({ ...provided, width: 120 }),
        }}
        onChange={onChangeFilter}
      />
      <SelectInput
        key={FILTERS.PRODUCT_MANF_MODEL_FILTER}
        name={FILTERS.PRODUCT_MANF_MODEL_FILTER}
        value={getUrlFilter(FILTERS.PRODUCT_MANF_MODEL_FILTER)}
        defaultValue={DEFAULT_VALUES[FILTERS.PRODUCT_MANF_MODEL_FILTER]}
        items={filters[FILTERS.PRODUCT_MANF_MODEL_FILTER]}
        styleOptions={{
          container: (provided) => ({ ...provided, width: 155 }),
        }}
        onChange={onChangeFilter}
      />
      <SelectInput
        key={FILTERS.PRODUCT_NAME_FILTER}
        name={FILTERS.PRODUCT_NAME_FILTER}
        value={getUrlFilter(FILTERS.PRODUCT_NAME_FILTER)}
        defaultValue={DEFAULT_VALUES[FILTERS.PRODUCT_NAME_FILTER]}
        items={filters[FILTERS.PRODUCT_NAME_FILTER]}
        matchStartOrAny="any"
        styleOptions={{
          container: (provided) => ({ ...provided, width: 190 }),
        }}
        onChange={onChangeFilter}
      />
      <SelectInput
        key={FILTERS.LOCATION_TYPE_FILTER}
        name={FILTERS.LOCATION_TYPE_FILTER}
        value={parseInt(getUrlFilter(FILTERS.LOCATION_TYPE_FILTER))}
        defaultValue={DEFAULT_VALUES[FILTERS.LOCATION_TYPE_FILTER]}
        items={filters[FILTERS.LOCATION_TYPE_FILTER]}
        onChange={onChangeFilter}
      />
      <SelectInput
        key={FILTERS.PALLET_CAPACITY_FILTER}
        name={FILTERS.PALLET_CAPACITY_FILTER}
        value={parseInt(getUrlFilter(FILTERS.PALLET_CAPACITY_FILTER))}
        defaultValue={DEFAULT_VALUES[FILTERS.PALLET_CAPACITY_FILTER]}
        items={filters[FILTERS.PALLET_CAPACITY_FILTER]}
        styleOptions={{
          container: (provided) => ({ ...provided, width: 180 }),
        }}
        onChange={onChangeFilter}
      />

      <SelectInput
        key={FILTERS.AISLE_FILTER}
        name={FILTERS.AISLE_FILTER}
        value={parseInt(getUrlFilter(FILTERS.AISLE_FILTER))}
        defaultValue={DEFAULT_VALUES[FILTERS.AISLE_FILTER]}
        items={filters[FILTERS.AISLE_FILTER]}
        styleOptions={{
          container: (provided) => ({ ...provided, width: 120 }),
        }}
        onChange={onChangeFilter}
      />
      <SelectInput
          key={FILTERS.BAY_FILTER}
          name={FILTERS.BAY_FILTER}
          value={parseInt(getUrlFilter(FILTERS.BAY_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.BAY_FILTER]}
          items={filters[FILTERS.BAY_FILTER]}
          styleOptions={{
          container: (provided) => ({ ...provided, width: 120 }),
           }}
          onChange={onChangeFilter}
        />
        <SelectInput
          key={FILTERS.LEVEL_FILTER}
          name={FILTERS.LEVEL_FILTER}
          value={parseInt(getUrlFilter(FILTERS.LEVEL_FILTER))}
          defaultValue={DEFAULT_VALUES[FILTERS.LEVEL_FILTER]}
          items={filters[FILTERS.LEVEL_FILTER]}
          styleOptions={{
          container: (provided) => ({ ...provided, width: 120 }),
           }}
          onChange={onChangeFilter}
        />
      
      
    </div>
  )
}

InventoryManagementFilters.propTypes = {
  filters: PropTypes.object,

  onChange: PropTypes.func,
}

export default InventoryManagementFilters
