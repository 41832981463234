import { connect } from 'react-redux'
import Component from './component'
import { fetchAvailableBaysStart } from 'store/location/actions'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  fetchAvailableBayList: fetchAvailableBaysStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
