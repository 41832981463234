import { takeEvery, call } from 'redux-saga/effects'
import { create } from 'api/unloadProcess'
import { types } from './actions'

function* createUnloadProcess(action) {
  const { payload } = action

  try {
    yield call(create, payload)
  } catch (error) {}
}

export default function* unloadProcessSaga() {
  yield takeEvery(types.CREATE_UNLOAD_PROCESS, createUnloadProcess)
}
