export const YES_NO_SWITCH_ITEMS = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
]

export const ODD_EVEN_SWITCH_ITEMS = [
  {
    value: 'Both',
    label: 'Both',
  },
  {
    value: 'Odd',
    label: 'Odd',
  },
  {
    value: 'Even',
    label: 'Even',
  },
]
