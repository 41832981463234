import {
  ORDER_PICK_STATUSES,
  SHIPPING_PAGE_ORDER_STATUSES,
} from 'constants/order'

export const selectShippingOrderProcesses = (state) =>
  state.orderProcess.items.filter((item) =>
    SHIPPING_PAGE_ORDER_STATUSES.includes(item.entity.orderPickStatus.id),
  )

export const selectPickOrderItems = (state) =>
  selectShippingOrderProcesses(state).filter(
    (item) =>
      item.entity.orderPickStatus.id ===
        ORDER_PICK_STATUSES.STATUS_PICKING_COMPLETE &&
      item.entity.order.order_pallets.some(
        (pallet) => pallet.pallet_location_shipping || pallet.pallet_location_pickup
      )
  )

export const selectPrepareToShipItems = (state) =>
  selectShippingOrderProcesses(state).filter((item) =>
    [
      ORDER_PICK_STATUSES.STATUS_PREPARE_TO_SHIP,
      ORDER_PICK_STATUSES.STATUS_LOADING,
      ORDER_PICK_STATUSES.STATUS_COMPLETE,
    ].includes(item.entity.orderPickStatus.id),
  )
