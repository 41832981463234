import { takeEvery, call, put, delay} from 'redux-saga/effects'
import { deleteShippingLane } from 'api/shippingLane'
import {
    types,
  } from './actions'
  import { MODAL_SUCCESS } from 'components/common/Modal'
  import { showModal } from '../modal/actions'

function* deleteLane(action) {
    const { payload } = action
    const { request, afterAction } = payload

    try {
      const response = yield call(deleteShippingLane, request)
      yield put(
        showModal({ name: MODAL_SUCCESS, content: response.data.data.message }),
      )
      
      yield delay(3000)
      if (afterAction) {
        afterAction()
      }
    } catch (error) {
    }
  }

export default function* shippingLaneSaga() {
    yield takeEvery(types.DELETE_SHIPPING_LANE, deleteLane)
}
  