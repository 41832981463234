import React from 'react'
import * as Sentry from '@sentry/browser'
import 'assets/app.scss'
import { render } from 'react-dom'
import { configureStore } from 'store'
import { loadState } from 'helpers'
import { Provider } from 'react-redux'
import rootReducer from 'store/rootReducer'
import rootSaga from 'store/rootSaga'
import App from 'components/common/App'
import { initLocalStorage } from 'helpers/initLocalStorage'

//
//

if (window.location.href.indexOf('76fireworks') > -1) {
  Sentry.init({
    dsn:
      'https://b1790440180b4d768fd38aa3737bb2c2@o680325.ingest.sentry.io/5770484',
  })
}

export const store = configureStore(
  rootReducer,
  rootSaga,
  initLocalStorage(loadState()),
)

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root'),
)
