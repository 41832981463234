import { fork } from 'redux-saga/effects'
import authSaga from './auth/saga'
import locationSaga from './location/saga'
import filtersSaga from './filter/saga'
import containerProcessSaga from './containerProcess/saga'
import unloadProcess from './unloadProcess/saga'
import orderProcessSaga from './orderProcess/saga'
import staffSaga from './staff/saga'
import taskAssignmentSaga from './taskAssignment/saga'
import warehousePalletSaga from './warehousePallet/saga'
import restockProcessSaga from './restockProcess/saga'
import warehouseCasesSaga from './warehouseCase/saga'
import warehouseCaseAndPalletSaga from './warehouseCaseAndPallet/saga'
import appInfoSaga from './appInfo/saga'
import logSaga from './log/saga'
import reasonSaga from './reason/saga'
import qaHoldSaga from './qaHold/saga'
import printSaga from 'store/print/saga'
import shipmentSaga from './shipment/saga'
import shippingLaneSaga from './shippingLane/saga'
import pickupLaneSaga from './pickupLane/saga'

export default function* rootSaga() {
  yield fork(authSaga)
  yield fork(locationSaga)
  yield fork(filtersSaga)
  yield fork(containerProcessSaga)
  yield fork(unloadProcess)
  yield fork(orderProcessSaga)
  yield fork(staffSaga)
  yield fork(taskAssignmentSaga)
  yield fork(warehousePalletSaga)
  yield fork(warehouseCasesSaga)
  yield fork(restockProcessSaga)
  yield fork(warehouseCaseAndPalletSaga)
  yield fork(appInfoSaga)
  yield fork(logSaga)
  yield fork(reasonSaga)
  yield fork(qaHoldSaga)
  yield fork(printSaga)
  yield fork(shipmentSaga)
  yield fork(shippingLaneSaga)
  yield fork(pickupLaneSaga)
}
