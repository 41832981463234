import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import query from 'querystringify'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import PageContent from 'components/common/PageContent'
import Table from 'components/common/Table'
import ContainersListTopFilters from './ContainersListTopFilters'
import ContainersSwitchFilters from './ContainersSwitchFilters'
import { dateToFormat, getUrlParams, redirect } from 'helpers'
import { sortArrWithObjects } from 'helpers/sorting'
import HighPriorityIcon from 'assets/svg/high-priority-icon.svg'
import { FILTERS, SORT_FILTERS } from 'constants/filters'
import {
  DEFAULT_URL_PARAMS,
  DEFAULT_URL_PARAMS_VALUES_CONTAINER_RECEIVING,
  DEFAULT_URL_PARAMS_VALUES_CONTAINER_UNLOADING,
} from 'constants/urlDefaultParams'
import { MAPPER_CONTAINERS_LIST_SORTING } from 'constants/mappers'
import {
  CONTAINER_LIST_PAGE_TYPE_UNLOADING,
  CONTAINER_LIST_PAGE_TYPE_RECEIVING,
} from 'constants/common'
import { MODAL_PENDING } from 'components/common/Modal'

const ContainerListPage = (props) => {
  const {
    user,

    items,
    filterList,
    columns,

    detailsLink,
    page: { type, title, description },

    fetchList,
    showModal,
  } = props

  const onMount = () => {
    if (window.location.pathname.includes(CONTAINER_LIST_PAGE_TYPE_RECEIVING)) {
      redirect(
        `${window.location.pathname}?${query.stringify({
          ...getUrlParams(),
          ...{
            ...DEFAULT_URL_PARAMS_VALUES_CONTAINER_RECEIVING,
          },
        })}`,
      )
    }
    if (window.location.pathname.includes(CONTAINER_LIST_PAGE_TYPE_UNLOADING)) {
      redirect(
        `${window.location.pathname}?${query.stringify({
          ...getUrlParams(),
          ...{
            ...DEFAULT_URL_PARAMS_VALUES_CONTAINER_UNLOADING,
          },
        })}`,
      )
    }

    if (!getUrlParams()[FILTERS.WAREHOUSE_FILTER]) {
      redirect(
        `${window.location.pathname}?${query.stringify({
          ...getUrlParams(),
          [FILTERS.WAREHOUSE_FILTER]: user.warehouseId,
        })}`,
      )
    }

    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })
    fetchList(
      query.stringify(
        {
          ...getUrlParams(),
          ...{ [FILTERS.CONTAINER_LIST_TYPE_FILTER]: type },
        },
        true,
      ),
    )
  }

  useEffect(onMount, [])

  const onSort = (key) => {
    redirect(
      `${window.location.pathname}?${query.stringify({
        ...getUrlParams(),
        [SORT_FILTERS.SORT_BY_KEY]: key,
        [SORT_FILTERS.SORT_DIRECTION]: getUrlParams()[
          SORT_FILTERS.SORT_DIRECTION
        ],
      })}`,
    )
  }

  const getNormalizedItems = (items) =>
    sortArrWithObjects(
      items.map((item) => ({
        id: item.id,
        container_priority: item.container_priority ? <HighPriorityIcon /> : '',
        name: item.name,
        container_status_id: item.container_status_name,
        supplier_id: item.supplier_name,
        should_arrive_at: item.should_arrive_at
          ? dateToFormat(item.should_arrive_at, 'MM/DD/YYYY')
          : '-',
        arrive_at: item.arrive_at
          ? dateToFormat(item.arrive_at, 'MM/DD/YYYY')
          : '-',
        invoice_cases_quantity: item.invoice_cases_quantity,
        invoice_pallets_quantity: item.invoice_pallets_quantity,
      })),
      MAPPER_CONTAINERS_LIST_SORTING,
      getUrlParams()[SORT_FILTERS.SORT_BY_KEY],
      getUrlParams()[SORT_FILTERS.SORT_DIRECTION],
    )

  const warehouseId = getUrlParams()[FILTERS.WAREHOUSE_FILTER]
  const customerId = getUrlParams()[FILTERS.CUSTOMER_FILTER]

  const isSelectedWarehouse = !!warehouseId
  const isSelectedCustomer = !!customerId

  return (
    <PageContent className="container-list-page">
      <ContentHeader title={title} description={description}>
        <ContainersListTopFilters page={props.page} pageType={type} />
      </ContentHeader>
      {!isSelectedWarehouse && !isSelectedCustomer ? (
        <ContentSection className="content-section--empty">
          <div className="header">
            <div className="header__title">0 Containers</div>
          </div>
        </ContentSection>
      ) : (
        <ContentSection>
          <div className="header">
            <div className="header__title">
              {items.length} {`Container${items.length !== 1 ? 's' : ''}`}
            </div>
          </div>

          <ContainersSwitchFilters filters={filterList} pageType={type} />

          <Table
            rows={getNormalizedItems(items)}
            columns={columns}
            options={{
              viewable: true,
            }}
            sortOptions={{
              sortByKey: getUrlParams()[SORT_FILTERS.SORT_BY_KEY],
              sortDirection: getUrlParams()[DEFAULT_URL_PARAMS.ORDER_BY],
            }}
            events={{
              onSort,
            }}
            links={{
              view:
                type !== CONTAINER_LIST_PAGE_TYPE_UNLOADING && detailsLink
                  ? `${detailsLink}${query.stringify(
                      { [FILTERS.WAREHOUSE_FILTER]: warehouseId },
                      true,
                    )}`
                  : null,
            }}
          />
        </ContentSection>
      )}
    </PageContent>
  )
}

ContainerListPage.propTypes = {
  items: PropTypes.array,
  filterList: PropTypes.array,
  columns: PropTypes.array,

  detailsLink: PropTypes.string,

  page: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),

  fetchList: PropTypes.func,
  sortList: PropTypes.func,
}

export default ContainerListPage
