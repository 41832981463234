import React from 'react'
import query from 'querystringify'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from 'store/modal/actions'
import { removePropFromObject, getUrlParams } from 'helpers'
import { DEFAULT_URL_PARAMS } from 'constants/urlDefaultParams'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'
import './style.scss'
import styleVariables from '../../../assets/styleVariables.module.scss'
import { MODAL_PENDING } from 'components/common/Modal'

export const DISPLAYED_PAGES_MAX = 7 // Mui Pagination tops out at 7 pages

const paginationTheme = createMuiTheme({
  palette: {
    primary: {
      main: styleVariables.primaryColor,
      dark: styleVariables.thirdColor,
      contrastText: styleVariables.secondaryTextColor,
    },
    secondary: {
      main: styleVariables.secondaryColor,
      contrastText: styleVariables.primaryTextColor,
    },
  },
})

const PaginationWrapper = ({
  checkedPartialsOnly,
  countPerFetch,
  fetchFunction,
  showModal,
  metaData,
}) => {
  const pathname = window.location.pathname
  const currentPage = metaData ? parseInt(metaData.page) : null
  const totalPages = metaData
    ? Math.ceil(
        metaData.numberFound /
          parseInt(getUrlParams()[DEFAULT_URL_PARAMS.PER_PAGE_PARAM]),
      )
    : null
  const ppoCode = !!checkedPartialsOnly ? 1 : 0

  const paginationCallback = (event, value) => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })

    if (pathname.includes('inventory-management')) {
      fetchFunction(
        `?${query.stringify(
          removePropFromObject('pageType', {
            ...query.parse(window.location.search),
            perPage: countPerFetch,
            page: value,
            ppo: ppoCode,
          }),
        )}`,
      )
    }
    if (pathname.includes('locations')) {
      fetchFunction(
        `?${query.stringify(
          removePropFromObject('pageType', {
            ...query.parse(window.location.search),
            perPage: countPerFetch,
            page: value,
          }),
        )}`,
      )
    }
  }

  return (
    <div className="pagination">
      <ThemeProvider theme={paginationTheme}>
        {totalPages > 1 ? (
          <Pagination
            color="primary"
            variant="text"
            count={totalPages}
            page={currentPage}
            onChange={paginationCallback}
            hideNextButton={totalPages > DISPLAYED_PAGES_MAX ? false : true}
            hidePrevButton={totalPages > DISPLAYED_PAGES_MAX ? false : true}
          />
        ) : (
          ''
        )}
      </ThemeProvider>
    </div>
  )
}

PaginationWrapper.propTypes = {
  checkedPartialsOnly: PropTypes.bool,
  countPerFetch: PropTypes.number,
  callback: PropTypes.func,
}

const mapDispatchToProps = {
  showModal,
}

export default connect(null, mapDispatchToProps)(PaginationWrapper)
