import { types } from './actions'
import { takeEvery, call } from 'redux-saga/effects'
import { createInvAdjustmentLog } from 'api/logs'

function* logInvAdjustment(action) {
  const { payload } = action

  try {
    yield call(createInvAdjustmentLog, payload)
  } catch (error) {}
}

export default function* logSaga() {
  yield takeEvery(types.LOG_INVENTORY_ADJUSTMENT, logInvAdjustment)
}
