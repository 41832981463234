import { combineReducers } from 'redux'
import authReducer from './auth/reducer'
import locationReducer from './location/reducer'
import filterReducer from './filter/reducer'
import modalReducer from './modal/reducer'
import containerProcessReducer from './containerProcess/reducer'
import orderProcessReducer from './orderProcess/reducer'
import staffReducer from './staff/reducer'
import warehousePalletReducer from './warehousePallet/reducer'
import warehouseCaseReducer from './warehouseCase/reducer'
import restockProcessReducer from './restockProcess/reducer'
import warehouseCaseAndPalletReducer from './warehouseCaseAndPallet/reducer'
import appInfoReducer from './appInfo/reducer'
import reasonReducer from './reason/reducer'
import qaHoldReducer from './qaHold/reducer'
import printReducer from 'store/print/reducer'
import shipmentReducer from './shipment/reducer'

export const STORE_VERSION = '2'

const rootReducer = combineReducers({
  version: () => STORE_VERSION,
  auth: authReducer,
  location: locationReducer,
  filter: filterReducer,
  modal: modalReducer,
  containerProcess: containerProcessReducer,
  orderProcess: orderProcessReducer,
  staff: staffReducer,
  warehousePallet: warehousePalletReducer,
  warehouseCase: warehouseCaseReducer,
  restockProcess: restockProcessReducer,
  warehouseCaseAndPallet: warehouseCaseAndPalletReducer,
  appInfo: appInfoReducer,
  reason: reasonReducer,
  qaHold: qaHoldReducer,
  print: printReducer,
  shipment: shipmentReducer,
})

export default rootReducer
