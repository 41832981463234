import React from 'react'
import TableList from 'components/common/TableList'
import { dateToFormat } from 'helpers/index'
import Tooltip from 'components/common/Tooltip'
import { MAPPER_CARRIER_LOGO } from 'constants/mappers'
import moment from 'moment'
import ContentSection from 'components/common/PageContent/ContentSection'
import Link from 'components/common/Link'
import './style.scss'

const CurrentShipmentsList = ({ shipments, markAsPrinted, voidBol }) => {
  const getColumns = () => [
    {
      id: 1,
      label: 'Orders ID',
      value: 'ordersId',
    },
    {
      id: 2,
      label: 'Carrier',
      value: 'carrier',
    },
    {
      id: 3,
      label: 'Pickup Date',
      value: 'pickupDate',
    },
    {
      id: 4,
      label: 'Pickup Start',
      value: 'pickupStart',
    },
    {
      id: 5,
      label: 'Created Date',
      value: 'dateCreated',
    },
    {
      id: 5,
      label: 'Date Created',
      value: 'dateCreated',
    },
    {
      id: 6,
      label: 'Printed Date',
      value: 'datePrinted',
    },
    {
      id: 7,
      label: 'Errors',
      value: 'error',
    },
    {
      id: 8,
      label: 'Status',
      value: 'status',
    },
    {
      id: 9,
      label: 'Void',
      value: 'void',
    },
  ]

  const getNormalizedItems = () =>
    shipments.map((item) => {
      const pickupTimes = item.time_of_pickup
        ? item.time_of_pickup.split(' - ')
        : null

      const markBolAsPrinted = () => {
        markAsPrinted(item)
      }

      const voidShipmentBol = () => {
        voidBol(item)
      }

      const isVoid = item.is_void

      return {
        id: item.id,
        carrier: item.carrier ? (
          <Tooltip
            hoverTarget={MAPPER_CARRIER_LOGO[item.carrier.id]}
            sideText={item.carrier.name}
            extraTargetClassName="carrier-icon-target"
            extraBubbleClassName="for-carrier-logo"
          />
        ) : (
          ''
        ),
        ordersId: item.orders_id,
        dateCreated: item.date_bol_created
          ? dateToFormat(item.date_bol_created, 'MM/DD/YYYY')
          : '',
        datePrinted: item.date_bol_created ? (
          item.date_printed ? (
            dateToFormat(item.date_printed, 'MM/DD/YYYY')
          ) : (
            <Link
              aria-label="Row link to mark as printed"
              className={`link ${isVoid ? 'disabled' : ''}`}
              onClick={isVoid ? null : markBolAsPrinted} // Disable onClick when isVoided is true
            >
              Mark As Printed
            </Link>
          )
        ) : (
          ''
        ),
        pickupDate: item.date_of_pickup
          ? dateToFormat(item.date_of_pickup, 'MM/DD/YYYY')
          : '',
        pickupStart: pickupTimes
          ? moment(pickupTimes[0], 'H').format('h:mm A')
          : '',
        error: item.bol_error_message,
        status: item.status
          ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
          : item.bol_batch_ran
          ? 'Failed'
          : 'Pending',
        void: isVoid ? (
          <span className="voided">VOID</span>
        ) : (
          <Link
            aria-label="Row link to void BOL"
            className="link"
            onClick={voidShipmentBol}
          >
            Void
          </Link>
        ),
        isVoid: isVoid,
      }
    })

  return (
    <ContentSection>
      <div className="header">
        <div className="header__title">Current Shipments</div>
      </div>
      <hr />
      <TableList
        className="list"
        rows={getNormalizedItems()}
        columns={getColumns()}
      />
    </ContentSection>
  )
}

export default CurrentShipmentsList
