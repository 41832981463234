import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import TextInput from 'components/common/TextInput'
import { inputs, stateTypes } from 'constants/form'
import SuccessIcon from 'assets/svg/check-icon--third.svg'
import ExclamationIcon from 'assets/svg/exclamation-sign.svg'
import EyeIcon from 'assets/svg/eye.svg'

const FormRow = (props) => {
  const { field } = props

  const FONT_SIZE_SCREEN_XS = 14

  const [fontSize, setFontSize] = useState(field.inputProps.fontSize)
  const [inputType, setInputType] = useState(field.type)
  const [inputState, setInputState] = useState(field.state)

  useEffect(() => {
    setInputState(field.state)
  }, [setInputState, field.state])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (
        window.matchMedia('(max-width: 680px) and (orientation: portrait)')
          .matches
      ) {
        setFontSize(FONT_SIZE_SCREEN_XS)
      } else {
        setFontSize(field.inputProps.fontSize)
      }
    })
  })

  const { type: stateType, message } = field.state

  const showInput = () => {
    switch (field.type) {
      default:
        return (
          <TextInput
            type={inputType}
            placeholder={field.inputProps.placeholder}
            className={`form-wrapper__input ${
              stateType === stateTypes.ERROR ? 'form-wrapper__input--error' : ''
            }`}
            name={field.name}
            pattern={field.inputProps.pattern}
            tabIndex={field.inputProps.tabIndex}
            fontSize={fontSize}
            value={field.value}
            onBlur={field.events.onBlur}
            onChange={field.events.onChange}
            disabled={field.disabled}
            isInvalid={stateType === stateTypes.ERROR}
          />
        )
    }
  }

  const togglePasswordField = () => {
    setInputType(inputType === inputs.PASSWORD ? inputs.TEXT : inputs.PASSWORD)
    setInputState({
      ...inputState,
      type:
        inputState.type === stateTypes.DEFAULT
          ? stateTypes.SUCCESS
          : stateTypes.DEFAULT,
    })
  }

  const showStatus = () => {
    switch (stateType) {
      case stateTypes.ERROR:
        return (
          <div className="form-wrapper__error">
            <ExclamationIcon className="svg" />
            <span className="text">{message}</span>
          </div>
        )
      case stateTypes.SUCCESS:
        return (
          <div className="form-wrapper__error">
            <SuccessIcon className="svg" />
          </div>
        )
      default: {
        if (field.type === inputs.PASSWORD) {
          return (
            <div
              className="form-wrapper__error"
              onClick={() => togglePasswordField()}
            >
              <EyeIcon className="svg--eye" />
            </div>
          )
        }
      }
    }
  }

  return (
    <div
      className={`form-wrapper__row ${field.className ? field.className : ''} ${
        inputState.type ? `form-wrapper__row--${inputState.type}` : ''
      }`}
    >
      {showInput()}
      {showStatus()}
    </div>
  )
}

FormRow.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,

    events: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func,
    }),
    inputProps: PropTypes.shape({
      pattern: PropTypes.any,
      placeholder: PropTypes.string,

      tabIndex: PropTypes.number,
      fontSize: PropTypes.number,
    }),

    value: PropTypes.any.isRequired,

    disabled: PropTypes.bool,

    state: PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default FormRow
