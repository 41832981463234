import React, { Component } from 'react'
import Joi from '@hapi/joi'
import './style.scss'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import ContentSection from 'components/common/PageContent/ContentSection'
import PageContent from 'components/common/PageContent/index'
import NumericInput from 'components/common/NumericInput'
import Button from 'components/common/Button'
import Switch from 'components/common/Switch'
import * as query from 'querystringify'
import * as PropTypes from 'prop-types'
import { YES_NO_SWITCH_ITEMS } from 'constants/switchItems'
import { mapItem, redirect } from 'helpers'
import {
  MAPPER_EDIT_LOCATION_FIELDS,
  MAPPER_LOCATION_INFO,
} from 'constants/mappers'
import { ROUTES } from 'constants/routes'
import {
  AISLE_FIELD,
  BAY_FIELD,
  DOCK_FIELD,
  HEIGHT_FIELD,
  LENGTH_FIELD,
  LEVEL_FIELD,
  LOCATION_TYPE_FIELD,
  PALLETS_CAPACITY_FIELD,
  STORAGE_TYPE_FIELD,
  PRINT_LABEL_FIELD,
  WAREHOUSE_FIELD,
  WIDTH_FIELD,
  WRAP_REQUIRED_FIELD,
} from 'constants/formFields'
import { FILTERS } from 'constants/filters'
import { DEFAULT_WAREHOUSE_ID } from 'constants/common'
import SelectInput from 'components/common/SelectInput'
import { MODAL_PENDING } from 'components/common/Modal'

const DEFAULT_INFO_VALUE = '-'

const DEFAULT_FORM_FIELDS = {
  [WAREHOUSE_FIELD]: undefined,
  [LOCATION_TYPE_FIELD]: undefined,
  [PALLETS_CAPACITY_FIELD]: undefined,
  [PRINT_LABEL_FIELD]: undefined,
  [LENGTH_FIELD]: undefined,
  [WIDTH_FIELD]: undefined,
  [HEIGHT_FIELD]: undefined,
  [WRAP_REQUIRED_FIELD]: 'Yes',
}

const FORM_RULES = {
  [WAREHOUSE_FIELD]: Joi.number().integer(),
  [LOCATION_TYPE_FIELD]: Joi.number().integer(),
  [PALLETS_CAPACITY_FIELD]: Joi.number().integer(),
  [LENGTH_FIELD]: Joi.number().optional(),
  [WIDTH_FIELD]: Joi.number().optional(),
  [HEIGHT_FIELD]: Joi.number().optional(),
  [STORAGE_TYPE_FIELD]: Joi.number(),
  [PRINT_LABEL_FIELD]: Joi.string().valid('v', 'h').optional(),
  [WRAP_REQUIRED_FIELD]: Joi.string().valid('Yes', 'No').required(),
}

export default class LocationEditDetailsPage extends Component {
  static propTypes = {
    item: PropTypes.object,

    warehouseList: PropTypes.array,
    locationTypeList: PropTypes.array,
    aisleList: PropTypes.array,
    bayList: PropTypes.array,
    storageTypeList: PropTypes.array,
    printLabelList: PropTypes.array,

    fetchLocation: PropTypes.func,
    submitForm: PropTypes.func,
    resetModals: PropTypes.func,
    resetLocationStatuses: PropTypes.func,
    resetCurrentLocation: PropTypes.func,
    fetchFilters: PropTypes.func,
    showModal: PropTypes.func,
  }

  static defaultProps = {
    item: {},
  }

  state = {
    form: {
      fields: { ...DEFAULT_FORM_FIELDS },
      rules: { ...FORM_RULES },
      error: {
        fields: [],
      },
    },
    itemInserted: false,
  }

  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.returnToListPage = this.returnToListPage.bind(this)
  }

  componentDidMount() {
    const {
      resetModals,
      fetchFilters,
      resetLocationStatuses,
      match: { params },
      fetchLocation,
    } = this.props

    resetLocationStatuses()
    resetModals()
    fetchFilters([
      FILTERS.WAREHOUSE_FILTER,
      FILTERS.STORAGE_TYPE_FILTER,
      FILTERS.LOCATION_TYPE_FILTER,
      FILTERS.AISLE_FILTER,
      FILTERS.BAY_FILTER,
    ])

    fetchLocation({ id: params.id })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { form } = prevState
    let { warehouseList, item } = nextProps

    const warehouseId =
      parseInt(query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER]) ||
      DEFAULT_WAREHOUSE_ID

    if (warehouseList.length) {
      const warehouse = warehouseList.find((item) => item.value === warehouseId)

      if (warehouse) {
        form.fields[WAREHOUSE_FIELD] = parseInt(warehouse.value)
      }
    }

    if (item.id && !prevState.itemInserted) {
      form.fields = mapItem(item, MAPPER_EDIT_LOCATION_FIELDS)

      form.fields[LOCATION_TYPE_FIELD] = parseInt(
        form.fields[LOCATION_TYPE_FIELD] >= 0
          ? form.fields[LOCATION_TYPE_FIELD]
          : item.location_type.id,
      )

      form.fields[PRINT_LABEL_FIELD] = String(
        form.fields[PRINT_LABEL_FIELD]
          ? form.fields[PRINT_LABEL_FIELD]
          : nextProps.item.label_orientation,
      )
      return {
        form,
        itemInserted: true,
      }
    }
    return prevState
  }

  componentDidUpdate() {
    const {
      isLocationUpdated,
      resetModals,
      resetCurrentLocation,
      resetLocationStatuses,
    } = this.props

    if (isLocationUpdated) {
      setTimeout(() => {
        resetCurrentLocation()
        resetModals()
        resetLocationStatuses()
        this.returnToListPage()
      }, 3000)
    }
  }

  isCommonFieldsFilled = () => !!this.state.form.fields[WRAP_REQUIRED_FIELD]

  returnToListPage = () => {
    this.props.resetCurrentLocation()

    this.setState({
      itemInserted: false,
    })

    redirect(
      `${ROUTES.LOCATION_LIST_PAGE}${query.stringify(
        { [FILTERS.WAREHOUSE_FILTER]: this.state.form.fields[WAREHOUSE_FIELD] },
        true,
      )}`,
    )
  }

  onChangeDropdown = (name, value) => {
    let { form } = this.state

    form.fields[LOCATION_TYPE_FIELD] = value
    this.setState({ form })
  }

  onChangeDropdownPrint = (name, value) => {
    let { form } = this.state

    form.fields[PRINT_LABEL_FIELD] = value
    this.setState({ form })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { item, showModal, submitForm } = this.props
    const { form } = this.state

    if (this.isCommonFieldsFilled() && form.error.fields.length === 0) {
      Joi.validate(form.fields, form.rules, (error) => {
        if (error === null) {
          showModal({
            name: MODAL_PENDING,
            content: <div className="text">Updating...</div>,
          })

          submitForm({
            data: { ...form.fields, ...{ id: item.id } },
            id: item.id,
          })
        }
      })
    }
  }

  onChange = (name, value) => {
    let { form } = this.state

    let Numeric_Fields = [
      form.fields[PALLETS_CAPACITY_FIELD],
      form.fields[LENGTH_FIELD],
      form.fields[WIDTH_FIELD],
      form.fields[HEIGHT_FIELD],
    ]
    if (Numeric_Fields.indexOf(form.fields[name]) >= 0) {
      form.fields[name] = !value ? '' : parseInt(value)
    } else {
      form.fields[name] = !value ? '' : value
    }

    form.error.fields = form.error.fields.filter((value) => value !== name)

    Joi.validate({ [name]: value }, { [name]: form.rules[name] }, (error) => {
      if (error !== null) {
        form.error.fields.push(name)
      }
    })
    this.setState({ form })
  }

  render() {
    const {
      form: { fields },
    } = this.state
    const {
      item,
      warehouseList,
      aisleList,
      bayList,
      locationTypeList,
      storageTypeList,
      printLabelList,
    } = this.props

    const mappedItemInfo = mapItem(item, MAPPER_LOCATION_INFO)

    const warehouseId =
      parseInt(query.parse(window.location.search)[FILTERS.WAREHOUSE_FILTER]) ||
      DEFAULT_WAREHOUSE_ID

    const warehouse = warehouseList.find((item) => item.value === warehouseId)
    const storageType = storageTypeList.find(
      (item) => item.value === mappedItemInfo[STORAGE_TYPE_FIELD],
    )
    const aisle = aisleList.find(
      (item) => item.value === mappedItemInfo[AISLE_FIELD],
    )
    const bay = bayList.find((item) => item.value === mappedItemInfo[BAY_FIELD])

    let locationName = DEFAULT_INFO_VALUE

    if (mappedItemInfo.warehouse_id) {
      if (item.regular_location) {
        locationName = [
          aisle ? aisle.label : '',
          bay ? bay.label : '',
          mappedItemInfo[LEVEL_FIELD],
        ].join('-')
      } else if (item.dock_location) {
        locationName = `Dock ${mappedItemInfo[DOCK_FIELD]}`
      }
    }

    return (
      <PageContent className="edit-details-page">
        <ContentHeader
          title="Edit Location"
          description="Edit location Details"
        />

        <ContentSection>
          <div className="header">
            <div className="header__title">Location {locationName}</div>
          </div>
          <hr />
          <form>
            <div className="form">
              <div className="form__main-column">
                <div className="form__row">
                  <div className="title">Warehouse</div>
                  <div>{warehouse ? warehouse.label : DEFAULT_INFO_VALUE}</div>
                </div>
                <div className="form__row">
                  <div className="title">Aisle</div>
                  <div>{aisle ? aisle.label : DEFAULT_INFO_VALUE}</div>
                </div>
                <div className="form__row">
                  <div className="title">Level</div>
                  <div>
                    {mappedItemInfo[LEVEL_FIELD]
                      ? mappedItemInfo[LEVEL_FIELD]
                      : DEFAULT_INFO_VALUE}
                  </div>
                </div>
                <div className="form__row">
                  <div className="title">Length</div>
                  <div>
                    <div className="form__row--measure">
                      <NumericInput
                        tabIndex="1"
                        name={LENGTH_FIELD}
                        value={fields[LENGTH_FIELD]}
                        placeholder="Enter Length"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="title title--required">Wrapped</div>
                  <Switch
                    tabIndex="5"
                    name={WRAP_REQUIRED_FIELD}
                    items={YES_NO_SWITCH_ITEMS}
                    selected={fields[WRAP_REQUIRED_FIELD]}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div className="form__main-column">
                <div className="form__row">
                  <div className="title">Location Type</div>
                  <SelectInput
                    key={LOCATION_TYPE_FIELD}
                    name={LOCATION_TYPE_FIELD}
                    value={fields[LOCATION_TYPE_FIELD]}
                    items={locationTypeList}
                    styleOptions={{
                      container: (provided) => ({ ...provided, width: 180 }),
                    }}
                    onChange={this.onChangeDropdown}
                  />
                </div>

                <div className="form__row">
                  <div className="title">Bay</div>
                  <div>{bay ? bay.label : DEFAULT_INFO_VALUE}</div>
                </div>

                <div className="form__row">
                  <div className="title">Pallet Capacity</div>
                  <div>
                    <div>
                      <NumericInput
                        tabIndex="0"
                        name={PALLETS_CAPACITY_FIELD}
                        value={fields[PALLETS_CAPACITY_FIELD]}
                        placeholder="Enter Capacity"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row form__row--auto-margin">
                  <div className="title">Width</div>
                  <div>
                    <div className="form__row--measure">
                      <NumericInput
                        tabIndex="2"
                        name={WIDTH_FIELD}
                        value={fields[WIDTH_FIELD]}
                        placeholder="Enter Width"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__row form__row--auto-margin">
                  <div className="title">Print Label</div>
                  <SelectInput
                    tabIndex="5"
                    key={PRINT_LABEL_FIELD}
                    name={PRINT_LABEL_FIELD}
                    value={fields[PRINT_LABEL_FIELD]}
                    items={printLabelList}
                    styleOptions={{
                      container: (provided) => ({ ...provided, width: 180 }),
                    }}
                    onChange={this.onChangeDropdownPrint}
                  />
                </div>
              </div>

              <div className="form__main-column">
                <div className="form__row form__row--auto-margin">
                  <div className="title">Storage Type</div>
                  <div>
                    {storageType ? storageType.label : DEFAULT_INFO_VALUE}
                  </div>
                </div>
                <div className="form__row form__row--measure">
                  <div className="title">Height</div>
                  <NumericInput
                    tabIndex="3"
                    name={HEIGHT_FIELD}
                    value={fields[HEIGHT_FIELD]}
                    placeholder="Enter Height"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form__row" />
              </div>
            </div>
            <hr />
            <div className="footer">
              <Button
                tabIndex="7"
                type="button"
                onClick={this.returnToListPage}
                text="Cancel"
              />
              <Button
                tabIndex="6"
                disabled={!this.isCommonFieldsFilled()}
                type="submit"
                onClick={this.onSubmit}
                text="Save"
              />
            </div>
          </form>
        </ContentSection>
      </PageContent>
    )
  }
}
