export const types = {
  CREATE_RESTOCK_PROCESS: 'CREATE_RESTOCK_PROCESS',
  UPDATE_RESTOCK_PROCESS: 'UPDATE_RESTOCK_PROCESS',

  FETCH_RESTOCK_PROCESS_LIST_START: 'FETCH_RESTOCK_PROCESS_LIST_START',
  FETCH_RESTOCK_PROCESS_LIST_SUCCESS: 'FETCH_RESTOCK_PROCESS_LIST_SUCCESS',
  FETCH_RESTOCK_PROCESS_LIST_FAIL: 'FETCH_RESTOCK_PROCESS_LIST_FAIL',

  FETCH_ONE_RESTOCK_PROCESS_START: 'FETCH_ONE_RESTOCK_PROCESS_START',
  FETCH_ONE_RESTOCK_PROCESS_SUCCESS: 'FETCH_ONE_RESTOCK_PROCESS_SUCCESS',
  FETCH_ONE_RESTOCK_PROCESS_FAIL: 'FETCH_ONE_RESTOCK_PROCESS_FAIL',

  CREATE_RESTOCK_LOCATION: 'CREATE_RESTOCK_LOCATION',
  CREATE_RESTOCK_COMBINE_LOCATION: 'CREATE_RESTOCK_COMBINE_LOCATION',
}

export const createRestockProcess = (payload) => ({
  type: types.CREATE_RESTOCK_PROCESS,
  payload,
})

export const createRestockCombineLocation = (payload) => ({
  type: types.CREATE_RESTOCK_COMBINE_LOCATION,
  payload,
})

export const fetchRestockProcessListStart = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_START,
  payload,
})

export const fetchRestockProcessListSuccess = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_SUCCESS,
  payload,
})

export const fetchRestockProcessListFail = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_FAIL,
  payload,
})

export const fetchOneRestockProcessStart = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_START,
  payload,
})

export const fetchOneRestockProcessSuccess = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_SUCCESS,
  payload,
})

export const fetchOneRestockProcessFail = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_FAIL,
  payload,
})

export const updateRestockProcess = (payload) => ({
  type: types.UPDATE_RESTOCK_PROCESS,
  payload,
})

export const createRestockLocation = (payload) => ({
  type: types.CREATE_RESTOCK_LOCATION,
  payload,
})
