export const RESTOCK_TYPES = {
  TYPE_STAGING: 1,
  TYPE_PICK_SELECT_RESTOCK: 2,
  TYPE_ZONE_PICK: 3,
  TYPE_SMALL_PARCEL: 4,
  TYPE_DAMAGE: 5,
  TYPE_QA_HOLD: 6,
  TYPE_RETURN: 7,
  TYPE_COMBINE: 8,
  TYPE_PICK_TO_PICK: 9,
  TYPE_STORAGE_TO_STORAGE: 10,
  TYPE_BULK_STORAGE_TO_STAGING: 11,
  TYPE_STAGING_TO_STORAGE: 12,
}
