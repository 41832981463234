import { types } from './actions'
import { sortObjectsList } from 'helpers/sorting'

const INIT = {
  item: {},
  list: [],
  isLoading: false,
}

export default function containerProcessReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_CONTAINER_PROCESS_LIST_START:
      return {
        ...state,
        item: {},
        isLoading: true,
      }
    case types.FETCH_CONTAINER_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      }
    case types.RESET_CONTAINER_PROCESS_LIST:
    case types.FETCH_CONTAINER_PROCESS_LIST_FAIL:
      return INIT
    case types.SORT_CONTAINER_PROCESS_LIST:
      const { list } = state
      const { key, direction } = payload

      const sortedList = sortObjectsList(list, key, direction)

      return {
        ...state,
        list: sortedList,
      }
    case types.FETCH_ONE_CONTAINER_PROCESS_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_ONE_CONTAINER_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        item: payload,
      }
    case types.FETCH_ONE_CONTAINER_PROCESS_FAIL:
      return {
        ...state,
        isLoading: false,
        item: {},
      }
    default:
      return state
  }
}
