export const types = {
  CHECK_USER_START: 'CHECK_USER_START',
  CHECK_USER_SUCCESS: 'CHECK_USER_SUCCESS',
  CHECK_USER_FAIL: 'CHECK_USER_FAIL',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT: 'LOGOUT',
  SET_NEW_PIN_CODE_START: 'SET_NEW_PIN_CODE_START',
  SET_NEW_PIN_CODE_SUCCESS: 'SET_NEW_PIN_CODE_SUCCESS',
  SET_NEW_PIN_CODE_FAIL: 'SET_NEW_PIN_CODE_FAIL',
  RESET_USER: 'RESET_USER',
}

export const checkUserStart = (payload) => ({
  type: types.CHECK_USER_START,
  payload,
})

export const checkUserSuccess = (payload) => ({
  type: types.CHECK_USER_SUCCESS,
  payload,
})

export const checkUserFail = (payload) => ({
  type: types.CHECK_USER_FAIL,
  payload,
})

export const logout = (payload) => ({
  type: types.LOGOUT,
  payload,
})

export const login = (payload) => ({
  type: types.LOGIN_START,
  payload,
})

export const loginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
})

export const loginFail = (payload) => ({
  type: types.LOGIN_FAIL,
  payload,
})

export const setNewPinCodeStart = (payload) => ({
  type: types.SET_NEW_PIN_CODE_START,
  payload,
})

export const setNewPinCodeSuccess = (payload) => ({
  type: types.SET_NEW_PIN_CODE_SUCCESS,
  payload,
})

export const setNewPinCodeFail = (payload) => ({
  type: types.SET_NEW_PIN_CODE_FAIL,
  payload,
})

export const resetUser = (payload) => ({
  type: types.RESET_USER,
  payload,
})
