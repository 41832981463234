import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'
import useLongPress from 'helpers/longPress'

const Icon = ({
  src,
  className,
  children,
  style,
  onClick,
  onMouseOver,
  onMouseOut,
  onLongPress,
}) => {
  const longPressEvents = useLongPress(onLongPress, 500)

  return (
    <div
      {...longPressEvents}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={className}
      style={style}
      onClick={onClick}
    >
      {src ? <img src={src} className="icon__img" alt="avatar" /> : null}
      {children}
    </div>
  )
}

Icon.propTypes = {
  children: PropTypes.any,

  className: PropTypes.string,
  src: PropTypes.string,

  style: PropTypes.object,

  onClick: PropTypes.func,
}

export default Icon
