import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Modal from 'react-modal'
import Button from '../../Button'
import { connect } from 'react-redux'
import { MODAL_LANE_PALLET, MODAL_CHOOSE_COPIES } from 'components/common/Modal'
import Tooltip from 'components/common/Tooltip'
import './style.scss'
import { MAPPER_CARRIER_LOGO } from 'constants/mappers'
import TextArea from 'components/common/TextArea'
import { showModal, closeModal } from '../../../../store/modal/actions'
import { openPrintDialog, closePrintDialog } from 'store/print/actions'
import { ORDER_PALLET_LABEL } from 'constants/print'
import Print from 'components/common/Print'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '500px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '650px',
  },
}

const ModalLanePallet = ({
  isOpen,
  laneLocation,
  orderPallet,
  modalEvents,
  showModal,
  closeModal,
  closePrintDialog,
  openPrintDialog,
}) => {
  const [deletionNotes, setDeletionNotes] = useState('')
  const [showDeletionNotes, setShowDeletionNotes] = useState(false)

  const handleDelete = () => {
    if (showDeletionNotes) {
      modalEvents.onAccept(deletionNotes)
    } else {
      setShowDeletionNotes(true)
    }
  }

  const handleNoteChange = (name, value) => {
    setDeletionNotes(value)
  }

  const handleCancel = () => {
    setShowDeletionNotes(false)
    modalEvents.onCancel()
  }

  if (!isOpen) {
    return null
  }

  const printLabel = () => {
    showModal({
      name: MODAL_CHOOSE_COPIES,
      events: {
        onDeny: () => closeModal(MODAL_CHOOSE_COPIES),
        onAccept: (printQty) => {
          closeModal(MODAL_CHOOSE_COPIES)
          openPrintDialog({
            id: orderPallet.id,
            qty: printQty,
            document: ORDER_PALLET_LABEL,
            onPrintDialogClose: () => {
              closePrintDialog()
            },
          })
        },
      },
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
        <div className="modal modal--lane-pallet">
          <p className="title">Order Pallet at {laneLocation.aisle_bay}</p>
          <div>
            <div className="row">
              <span className="sub-title">Date Moved to Lane</span>
              <div className="row-column">{laneLocation.created_date}</div>
            </div>
            <div className="row">
              <span className="sub-title">Order</span>
              <div className="row-column">{orderPallet.orders_id}</div>
            </div>
            <div className="row">
              <span className="sub-title">Customer</span>
              <div className="row-column">{orderPallet.customers_name}</div>
            </div>
            <div className="row">
              <span className="sub-title">Cases on Pallet</span>
              <div className="row-column">
                {orderPallet.order_pallet_case_qty}
              </div>
            </div>
            <div className="row">
              <span className="sub-title">Carrier</span>
              <div className="row-column">
                <Tooltip
                  hoverTarget={MAPPER_CARRIER_LOGO[orderPallet.carrier.id]}
                  sideText={orderPallet.carrier.name}
                  extraTargetClassName="carrier-icon-target"
                  extraBubbleClassName="for-carrier-logo"
                />
              </div>
            </div>
            {showDeletionNotes && (
              <div className="row">
                <span className="sub-title">Reason For Deletion</span>
                <div className="row-column">
                  <TextArea
                    name="deletionNotes"
                    value={deletionNotes}
                    onChange={handleNoteChange}
                    className={'text-area'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="btn-menu">
            <Button text="Print Label" onClick={printLabel} />
            <Button text="Delete" onClick={handleDelete} />
            <Button onClick={handleCancel} text="Cancel" />
          </div>
        </div>
      </Modal>
      <Print />
    </>
  )
}

ModalLanePallet.propTypes = {
  isOpen: PropTypes.bool,
  laneLocation: PropTypes.object,
  orderPallet: PropTypes.object,
}

const mapStateToProps = (state) => {
  const modalInfo = state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_LANE_PALLET,
  )
  return {
    isOpen: !!modalInfo,
    laneLocation: modalInfo ? modalInfo.laneLocation : null,
    orderPallet: modalInfo ? modalInfo.laneLocation.order_pallet : null,
    modalEvents: modalInfo ? modalInfo.events : null,
  }
}

const mapDispatchToProps = {
  showModal,
  closeModal,
  closePrintDialog,
  openPrintDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLanePallet)
