import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import '../style.scss'
import Avatar from 'components/features/StaffCard/Avatar'
import StaffDetailsPopup from 'components/features/Order/OrdersPage/StaffDetails/Popup'
import { TASK_PROGRESS_NAME_MAPPER } from 'constants/mappers'

const PERCENT_HARDCODE = 60

const Card = ({ item, options, events, modalStaffDetails, isChecked }) => {
  const [isStaffDetailsShown, setIsStaffDetailsShown] = useState(false)

  const onAvatarClick = () =>
    options.selectable ? events.onSelect(item) : false
  const showDetails = () => {
    events.onShowDetailsPopup({
      staffItem: item,
      isPopup: false,
    })
    setIsStaffDetailsShown(true)
  }
  const closeDetails = () => setIsStaffDetailsShown(false)
  const onLongPress = () =>
    events.onShowDetailsPopup({
      staffItem: item,
      isPopup: true,
    })

  const assignmentsCount = item.taskAssignments
    ? item.taskAssignments.length
    : 0

  return (
    <div className="card">
      {/* // popup disabled for now bc not useful. Can be improved and reactivated later. (April, 2021) 
      <StaffDetailsPopup
        isOpen={isStaffDetailsShown}
        offsetX={30}
        offsetY={10}
        staffItem={modalStaffDetails.staffItem}
        orderProcess={modalStaffDetails.orderProcess}
      /> */}
      <Avatar
        onMouseOver={showDetails}
        onMouseOut={closeDetails}
        onLongPress={onLongPress}
        isChecked={isChecked}
        isPrimary={item.roles.filter((role) => role.is_primary).length > 0}
        onClick={onAvatarClick}
        link={item.photo}
        loadPercent={PERCENT_HARDCODE}
      />
      <div className="text">{item.fullName}</div>
      {item.taskAssignment ? (
        <div className="text card__load">
          {50}% {TASK_PROGRESS_NAME_MAPPER[item.taskAssignment.taskType.id]}
        </div>
      ) : (
        <></>
      )}
      {assignmentsCount > 0 ? (
        <div className="badge badge--secondary badge--circle">
          {assignmentsCount}
        </div>
      ) : (
        false
      )}
    </div>
  )
}

Card.propTypes = {
  item: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  modalStaffDetails: state.modal.modalStaffDetails,
})

export default connect(mapStateToProps, null)(Card)
