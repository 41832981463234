import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import ExpandableBlock from 'components/common/ExpandableBlock'
import TableList from 'components/common/TableList'
import { date, dateToFormat } from 'helpers/index'
import Calendar from 'components/common/Calendar'
import Checkbox from 'components/common/Checkbox'
import { QA_HOLD_RESOLUTIONS } from 'constants/qaHoldResolutions'

const columns = [
  {
    id: 1,
    label: 'Case',
    value: 'quantity_on_hold',
  },
  {
    id: 2,
    label: 'Model',
    value: 'product_model',
  },
  {
    id: 3,
    label: 'Name',
    value: 'product_name',
  },
  {
    id: 4,
    label: 'Date',
    value: 'resolution_date',
  },
  {
    id: 5,
    label: 'Repurpose',
    value: 'isRepurpose',
  },
  {
    id: 6,
    label: 'Back to Inventory',
    value: 'isReturnToInventory',
  },
]

const GroupedQaHoldItems = ({ onChange, items }) => {
  const [localItems, setLocalItems] = useState([])
  const [notExpandedContainerIds, setNotExpandedContainerIds] = useState([])

  const onToggle = (item) => {
    notExpandedContainerIds.indexOf(item.id) === -1
      ? setNotExpandedContainerIds([...notExpandedContainerIds, item.id])
      : setNotExpandedContainerIds(
          notExpandedContainerIds.filter((id) => id !== item.id),
        )
  }

  const onItemsChange = () => {
    setLocalItems(
      items.map((item) => ({
        id: item.id,
        product_id: item.productInfo.product.products_id,
        container_id: item.container.id,
        container_name: item.container.name,
        product_model: item.productInfo.description.products_name,
        product_name: item.productInfo.product.products_model,
        quantity_on_hold: item.quantity_on_hold,
        resolution_date: item.resolution_date,
        resolution_id: item.resolution_id,
      })),
    )
  }

  useEffect(onItemsChange, [items])

  const getItems = () => {
    const getNormalizedItem = (item) => {
      const changeField = (name, value) => {
        const updatedItems = [...localItems]

        updatedItems.find((updatedItem) => updatedItem.id === item.id)[
          name
        ] = value

        setLocalItems(updatedItems)
        onChange(updatedItems)
      }

      const onCheckRTI = () =>
        changeField(
          'resolution_id',
          item.resolution_id === QA_HOLD_RESOLUTIONS.RETURN_TO_INVENTORY
            ? null
            : QA_HOLD_RESOLUTIONS.RETURN_TO_INVENTORY,
        )

      const onCheckREP = () =>
        changeField(
          'resolution_id',
          item.resolution_id === QA_HOLD_RESOLUTIONS.REPURPOSE
            ? null
            : QA_HOLD_RESOLUTIONS.REPURPOSE,
        )

      return {
        ...item,
        resolution_date: (
          <>
            <span>
              {!item.resolution_date
                ? '-'
                : dateToFormat(item.resolution_date, 'MM-DD-YYYY')}
            </span>
            <span>
              <Calendar
                name="resolution_date"
                value={item.resolution_date ? date(item.resolution_date) : null}
                onChange={changeField}
              />
            </span>
          </>
        ),
        isReturnToInventory: (
          <Checkbox
            onClick={onCheckRTI}
            checked={
              item.resolution_id === QA_HOLD_RESOLUTIONS.RETURN_TO_INVENTORY
            }
          />
        ),
        isRepurpose: (
          <Checkbox
            onClick={onCheckREP}
            checked={item.resolution_id === QA_HOLD_RESOLUTIONS.REPURPOSE}
          />
        ),
      }
    }

    let containerList = []

    localItems.forEach((item) => {
      containerList[item.container_id] = containerList[item.container_id]
        ? {
            id: item.id,
            name: item.container_name,
            items: [
              ...containerList[item.container_id].items,
              getNormalizedItem(item),
            ],
          }
        : {
            id: item.id,
            name: item.container_name,
            items: [getNormalizedItem(item)],
          }
    })

    return containerList
  }

  return (
    <>
      {getItems().map((item) => (
        <ExpandableBlock
          key={item.name}
          className="table-expand"
          headerContent={''}
          title={`Container ${item.name} (${item.items.length})`}
          isExpanded={notExpandedContainerIds.indexOf(item.id) === -1}
          bodyContent={
            <TableList className="list" rows={item.items} columns={columns} />
          }
          onToggle={() => onToggle(item)}
        ></ExpandableBlock>
      ))}
    </>
  )
}

GroupedQaHoldItems.propTypes = {
  items: PropTypes.array,

  onChange: PropTypes.func,
}

export default GroupedQaHoldItems
