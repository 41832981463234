import { takeLatest, call, put } from 'redux-saga/effects'
import {
  fetchStaffAssignmentFail,
  fetchStaffAssignmentSuccess,
  types,
} from 'store/staff/actions'
import { fetchMany } from 'api/staff'

function* fetchStaffAssignment(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchStaffAssignmentSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchStaffAssignmentFail())
  }
}

export default function* staffSaga() {
  yield takeLatest(types.FETCH_STAFF_ASSIGNMENT_START, fetchStaffAssignment)
}
