import React, { useEffect, useState } from 'react'
import query from 'querystringify'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ALL_LOCATION_PALLETS_LIST_TYPE_NAME } from '../InventoryManagementPage'
import { RESTOCK_TYPES } from 'constants/types'
import { ROUTES } from 'constants/routes'
import { DEFAULT_VALUES, FILTERS } from 'constants/filters'
import {
  LOCATION_TYPE,
  isAnyStorageLocationType,
  isAnyPickLocationType,
} from 'constants/locationTypes'
import {
  getUrlFilter,
  getUrlParams,
  isArrayContainsObject,
  isEmptyObject,
  redirect,
  removePropFromObject,
} from 'helpers'
import {
  fetchAvailableLocationListStart,
  fetchOneWarehousePalletStart,
  fetchOtherWarehousePalletListStart,
} from 'store/warehousePallet/actions'
import { fetchFiltersStart } from 'store/filter/actions'
import { createRestockLocation } from 'store/restockProcess/actions'
import { resetModals, showModal } from 'store/modal/actions'
import { createRestockCombineLocation } from 'store/restockProcess/actions'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import SelectInput from 'components/common/SelectInput'
import PageContent from 'components/common/PageContent'
import Checkbox from 'components/common/Checkbox'
import Button from 'components/common/Button'
import Table from 'components/common/Table'
import { CONTAINER_STATUS } from 'constants/containerStatuses'
import { MODAL_SUCCESS, MODAL_WARNING } from 'components/common/Modal'

const COMBINE_PALLETS_TYPE_ID = 1
const RELOCATION_TYPE_ID = 2

const RELOCATION_TYPE_NAME = 'r'
const COMBINE_PALLETS_TYPE_NAME = 'cp'

const PAGE_TYPES = [
  // {
  //   id: COMBINE_PALLETS_TYPE_ID,
  //   columns: [
  //     {
  //       id: 1,
  //       label: 'Model',
  //       value: 'product_model',
  //       name: 'product_model',
  //     },
  //     {
  //       id: 2,
  //       label: 'Location',
  //       value: 'location_title',
  //       name: 'location_title',
  //     },
  //     {
  //       id: 3,
  //       label: 'Case Capacity',
  //       value: 'case_capacity',
  //       name: 'case_capacity',
  //     },
  //     {
  //       id: 4,
  //       label: '',
  //       value: 'checked',
  //       name: 'checked',
  //     },
  //   ],
  //   value: 'cp',
  //   label: 'Combine Pallets',
  // },
  {
    id: RELOCATION_TYPE_ID,
    columns: [
      {
        id: 1,
        label: 'Model',
        value: 'product_model',
        name: 'product_model',
      },
      {
        id: 2,
        label: 'Location',
        value: 'location_title',
        name: 'location_title',
      },
      {
        id: 3,
        label: 'Location Type',
        value: 'location_type',
        name: 'location_type',
      },
      {
        id: 4,
        label: 'Case Capacity',
        value: 'case_capacity',
        name: 'case_capacity',
      },
      {
        id: 5,
        label: 'Pallet Capacity',
        value: 'pallets_capacity',
        name: 'pallets_capacity',
      },
      {
        id: 6,
        label: '',
        value: 'checked',
        name: 'checked',
      },
    ],
    value: 'r',
    label: 'Relocation',
  },
]

const PALLET_FILTERS = [
  {
    id: 1,
    label: '1 Pallet',
    value: 1,
  },
  {
    id: 2,
    label: '3 Pallets',
    value: 3,
  },
  {
    id: 3,
    label: '12 Pallets',
    value: 12,
  },
  {
    id: 4,
    label: '24 Pallets',
    value: 24,
  },
]

const RestockTaskDetailPage = (props) => {
  const {
    match,
    item,

    filters,
    otherItems,
    availableLocations,

    fetchItem,
    fetchFilters,
    fetchOtherItems,
    fetchAvailableLocationList,
    createRestockLocation,
    createRestockCombineLocation,
    showModal,
    resetModals,
  } = props

  const [selectedItems, setSelectedItems] = useState([])
  const [isFilterExpanded, setIsFilterExpanded] = useState(true)
  const matchParams = match.params

  const onMount = () => {
    fetchItem(matchParams.id)
    fetchFilters([FILTERS.AISLE_FILTER, FILTERS.LOCATION_TYPE_FILTER])
  }
  const onChangePageType = (name, value) => {
    if (getUrlParams()[FILTERS.PAGE_TYPE_FILTER] === value) {
      return
    }

    setSelectedItems([])

    redirect(
      `${window.location.pathname}?${query.stringify({
        ...getUrlParams(),
        ...{ [FILTERS.PAGE_TYPE_FILTER]: getPageType(value).value },
      })}`,
    )
  }
  const onCheck = (item) =>
    setSelectedItems(
      !isArrayContainsObject(item, selectedItems)
        ? [...selectedItems, ...[item]]
        : selectedItems.filter((selected) => selected.id !== item.id),
    )
  const onCheckOne = (item) =>
    setSelectedItems(!isArrayContainsObject(item, selectedItems) ? [item] : [])

  const onToggleExpanded = () => setIsFilterExpanded(!isFilterExpanded)
  const onClickBtnCancel = () =>
    redirect(ROUTES.INVENTORY_INVENTORY_MANAGEMENT_PAGE)
  const onClickBtnDone = () => {
    if (
      getPageType(getUrlParams()[FILTERS.PAGE_TYPE_FILTER] || 'cp').id ===
      RELOCATION_TYPE_ID
    ) {
      if (selectedItems.length !== 1) {
        return
      }

      const selectedItem = selectedItems[0]
      let determinedRestockType = ''

      switch (true) {
        case isAnyStorageLocationType(item) &&
          isAnyStorageLocationType(selectedItem):
          determinedRestockType = RESTOCK_TYPES.TYPE_STORAGE_TO_STORAGE
          break
        case isAnyPickLocationType(item) && isAnyPickLocationType(selectedItem):
          determinedRestockType = RESTOCK_TYPES.TYPE_PICK_TO_PICK
          break
        default:
          //TODO: TBD in version 2
          showModal({
            name: MODAL_WARNING,
            content: (
              <div className="modal--info__text">
                Restock type have not determined yet
              </div>
            ),
            events: {
              onClose: resetModals,
            },
          })

          return
      }

      createRestockLocation({
        data: {
          product_id: item.product_id,
          restock_type_id: determinedRestockType,
          warehouse_location_from_id: item.warehouse_location_id,
          warehouse_location_to_id: selectedItem.id,
          status_id: CONTAINER_STATUS.ACTIVE,
          quantity: item.case_capacity_filled,
        },
      })

      redirect(
        `${ROUTES.INVENTORY_INVENTORY_MANAGEMENT_PAGE}?${query.stringify({
          [FILTERS.PAGE_TYPE_FILTER]: ALL_LOCATION_PALLETS_LIST_TYPE_NAME,
          [FILTERS.PRODUCT_MODEL_FILTER]: item.product_model,
        })}`,
      )
    } else {
      const emptySlots = item.case_capacity_full - item.case_capacity_filled
      const filledSlots = selectedItems
        .map((item) => item.case_capacity_filled)
        .reduce((sum, item) => sum + item)

      if (filledSlots <= emptySlots) {
        createRestockCombineLocation({
          data: [
            ...selectedItems.map((selectedItem) => ({
              restock_location: {
                product_id: item.product_id,
                restock_type_id: RESTOCK_TYPES.TYPE_COMBINE,
                warehouse_location_from_id: selectedItem.warehouse_location_id,
                warehouse_location_to_id: item.warehouse_location_id,
                status_id: CONTAINER_STATUS.ACTIVE,
                quantity: selectedItem.case_capacity_filled,
              },
              warehouse_pallet_id: selectedItem.id,
            })),
            {
              restock_location: {
                product_id: item.product_id,
                restock_type_id: RESTOCK_TYPES.TYPE_COMBINE,
                warehouse_location_from_id: item.warehouse_location_id,
                warehouse_location_to_id: item.warehouse_location_id,
                status_id: CONTAINER_STATUS.ACTIVE,
                quantity: item.case_capacity_filled,
              },
              warehouse_pallet_id: item.id,
            },
          ],
        })

        showModal({
          name: MODAL_SUCCESS,
          content: 'Combine Pallets Task Created',
        })

        redirect(
          `${ROUTES.INVENTORY_INVENTORY_MANAGEMENT_PAGE}?${query.stringify({
            [FILTERS.PAGE_TYPE_FILTER]: ALL_LOCATION_PALLETS_LIST_TYPE_NAME,
            [FILTERS.PRODUCT_MODEL_FILTER]: item.product_model,
          })}`,
        )
      } else {
        showModal({
          name: MODAL_WARNING,
          content: (
            <div className="modal--info__text">
              Case Quantity Exceed Threshold
            </div>
          ),
          events: {
            onClose: onCloseModalWarning,
          },
        })
      }
    }
  }
  const onCloseModalWarning = () => {
    resetModals()
    setSelectedItems([])
  }
  const onChangeFilter = (name, value) => {
    const params = !value
      ? removePropFromObject(name, getUrlParams())
      : { ...getUrlParams(), [name]: value }

    redirect(`${window.location.pathname}?${query.stringify(params)}`)
    fetchAvailableLocationList(
      `?${query.stringify({
        ...getUrlParams(),
        product_id: item.warehouseCase.product.products_id,
      })}`,
    )
  }

  useEffect(onMount, [])
  useEffect(() => {
    if (isEmptyObject(item)) {
      return
    }

    // const isStorageOrOversizeStorage =
    //   [
    //     LOCATION_TYPE.OVERSIZED_STORAGE_TYPE_ID,
    //     LOCATION_TYPE.STORAGE_TYPE_ID,
    //   ].indexOf(item.location_type_id) > -1

    // // isNotFull is not clearly determined
    // const isNotFull =
    //   item.case_capacity_filled !== item.case_capacityisRelocationPageType_full // this is undefined

    const paramInUrl = getUrlParams()[FILTERS.PAGE_TYPE_FILTER]

    // this was changing the page type to a particular type on mount; was default Combine
    // onChangePageType(
    //   FILTERS.PAGE_TYPE_FILTER,
    //   paramInUrl
    //     ? paramInUrl
    //     : isStorageOrOversizeStorage && isNotFull
    //     ? COMBINE_PALLETS_TYPE_NAME
    //     : RELOCATION_TYPE_NAME,
    // )
    // now setting to RELOCATION_TYPE_NAME as default bc Combine isn't built out yet.
    onChangePageType(FILTERS.PAGE_TYPE_FILTER, RELOCATION_TYPE_NAME)

    if (!paramInUrl) {
      return
    }

    if (paramInUrl === COMBINE_PALLETS_TYPE_NAME) {
      fetchOtherItems(
        `?${query.stringify({
          main_id: item.id,
          product_id: item.warehouseCase.product.products_id,
        })}`,
      )
    } else if (
      Object.keys(getUrlParams()).includes(FILTERS.AISLE_FILTER) ||
      Object.keys(getUrlParams()).includes(FILTERS.LOCATION_TYPE_FILTER) ||
      Object.keys(getUrlParams()).includes(FILTERS.PALLET_CAPACITY_FILTER)
    ) {
      fetchAvailableLocationList(
        `?${query.stringify({
          ...getUrlParams(),
          product_id: item.warehouseCase.product.products_id,
        })}`,
      )
    }
  }, [item, getUrlParams()['pageType']])

  const getPageType = (value) => PAGE_TYPES.find((item) => item.value === value)
  const getNormalizedItem = (item) => ({
    ...item,
    location_type: (
      <>
        <span
          className="icon--square"
          style={{ backgroundColor: item.location_type_color }}
        />
        <span>{item.location_type}</span>
      </>
    ),
  })
  const getNormalizedPallets = (items) =>
    items.map((item) => {
      const onClickCheckbox = () => onCheck(item)

      return {
        ...getNormalizedItem(item),
        checked: (
          <Checkbox
            onClick={onClickCheckbox}
            checked={
              !!selectedItems.find((selected) => selected.id === item.id)
            }
          />
        ),
      }
    })
  const getNormalizedLocations = (items) =>
    items
      .filter((i) => i.id !== item.warehouse_location_id)
      .map((item) => {
        const onClickCheckbox = () => onCheckOne(item)

        return {
          ...getNormalizedItem(item),
          checked: (
            <Checkbox
              onClick={onClickCheckbox}
              checked={
                !!selectedItems.find((selected) => selected.id === item.id)
              }
            />
          ),
        }
      })

  const pageTypeValueFromUrl =
    getUrlParams()[FILTERS.PAGE_TYPE_FILTER] || PAGE_TYPES[0].value
  const isRelocationPageType =
    getPageType(
      getUrlParams()[FILTERS.PAGE_TYPE_FILTER] || RELOCATION_TYPE_NAME,
    ).id === RELOCATION_TYPE_ID // always true bc of ||, was || 'cp'

  return (
    <PageContent className="restock-task-detail-page">
      <ContentHeader
        title="Restock Task Detail"
        description="Manage And Complete Restock Task"
      >
        <div className="content-header__menu">
          <SelectInput
            name={FILTERS.PAGE_TYPE_FILTER}
            value={pageTypeValueFromUrl}
            items={PAGE_TYPES}
            onChange={onChangePageType}
          />
        </div>
        <div className="btn-group">
          <Button
            disabled={!selectedItems.length}
            type="submit"
            onClick={onClickBtnDone}
            text="Done"
          />
          <Button type="button" onClick={onClickBtnCancel} text="Cancel" />
        </div>
      </ContentHeader>
      {item.id ? (
        <>
          {!isRelocationPageType ? (
            <>
              <div className="content-header">
                <div className="title">PARTIAL PALLET 1</div>
              </div>
              <Table
                rows={[item]}
                columns={getPageType(pageTypeValueFromUrl).columns}
              />
              <div className="content-header">
                <div className="title">
                  OTHER PARTIAL PALLETS ({otherItems.length})
                </div>
              </div>
              <Table
                rows={getNormalizedPallets(otherItems)}
                columns={getPageType(pageTypeValueFromUrl).columns}
              />
            </>
          ) : (
            <>
              <div className="content-header">
                <div className="title">Current location</div>
              </div>
              <Table
                rows={[getNormalizedItem(item)]}
                columns={getPageType(pageTypeValueFromUrl).columns}
              />
              <div className="content-header">
                <div className="title">
                  <span>Available locations ({availableLocations.length})</span>
                </div>
                <div
                  className={`filter-btn ${
                    isFilterExpanded ? 'filter-btn--active' : ''
                  }`}
                  onClick={onToggleExpanded}
                >
                  Filter
                </div>
              </div>
              {isFilterExpanded && (
                <div className="filters-list">
                  <SelectInput
                    key={FILTERS.LOCATION_TYPE_FILTER}
                    name={FILTERS.LOCATION_TYPE_FILTER}
                    value={parseInt(getUrlFilter(FILTERS.LOCATION_TYPE_FILTER))}
                    defaultValue={DEFAULT_VALUES[FILTERS.LOCATION_TYPE_FILTER]}
                    items={filters[FILTERS.LOCATION_TYPE_FILTER]}
                    onChange={onChangeFilter}
                  />
                  <SelectInput
                    key={FILTERS.AISLE_FILTER}
                    name={FILTERS.AISLE_FILTER}
                    value={parseInt(getUrlFilter(FILTERS.AISLE_FILTER))}
                    defaultValue={DEFAULT_VALUES[FILTERS.AISLE_FILTER]}
                    items={filters[FILTERS.AISLE_FILTER]}
                    onChange={onChangeFilter}
                  />
                  <SelectInput
                    key={FILTERS.PALLET_CAPACITY_FILTER}
                    name={FILTERS.PALLET_CAPACITY_FILTER}
                    value={parseInt(
                      getUrlFilter(FILTERS.PALLET_CAPACITY_FILTER),
                    )}
                    defaultValue={
                      DEFAULT_VALUES[FILTERS.PALLET_CAPACITY_FILTER]
                    }
                    items={PALLET_FILTERS}
                    onChange={onChangeFilter}
                  />
                </div>
              )}
              <Table
                rows={getNormalizedLocations(availableLocations)}
                columns={getPageType(pageTypeValueFromUrl).columns}
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </PageContent>
  )
}

RestockTaskDetailPage.propTypes = {
  item: PropTypes.object,

  otherItems: PropTypes.array,
  availableLocations: PropTypes.array,

  showModal: PropTypes.func,
  resetModals: PropTypes.func,
  fetchItem: PropTypes.func,
  fetchOtherItems: PropTypes.func,
  fetchAvailableLocationList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  item: state.warehousePallet.item,
  otherItems: state.warehousePallet.otherList,
  availableLocations: state.warehousePallet.availableLocationList,
  filters: {
    [FILTERS.AISLE_FILTER]: state.filter.filterParams.aisleList,
    [FILTERS.LOCATION_TYPE_FILTER]: state.filter.filterParams.locationTypeList,
  },
})

const mapDispatchToProps = {
  fetchFilters: fetchFiltersStart,
  fetchItem: fetchOneWarehousePalletStart,
  fetchOtherItems: fetchOtherWarehousePalletListStart,
  fetchAvailableLocationList: fetchAvailableLocationListStart,
  createRestockLocation: createRestockLocation,
  createRestockCombineLocation: createRestockCombineLocation,
  showModal,
  resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestockTaskDetailPage)
