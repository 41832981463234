export const types = {
  FETCH_ORDER_PROCESS_LIST_START: 'FETCH_ORDER_PROCESS_LIST_START',
  FETCH_ORDER_PROCESS_LIST_SUCCESS: 'FETCH_ORDER_PROCESS_LIST_SUCCESS',
  FETCH_ORDER_PROCESS_LIST_FAIL: 'FETCH_ORDER_PROCESS_LIST_FAIL',

  FETCH_SHIPMENT_ORDERS_START: 'FETCH_SHIPMENT_ORDERS_START',
  FETCH_SHIPMENT_ORDERS_SUCCESS: 'FETCH_SHIPMENT_ORDERS_SUCCESS',
  CLEAR_SHIPMENT_ORDERS: 'CLEAR_SHIPMENT_ORDERS',

  FETCH_ONE_ORDER_PROCESS_START: 'FETCH_ONE_ORDER_PROCESS_START',
  FETCH_ONE_ORDER_PROCESS_SUCCESS: 'FETCH_ONE_ORDER_PROCESS_SUCCESS',
  FETCH_ONE_ORDER_PROCESS_FAIL: 'FETCH_ONE_ORDER_PROCESS_FAIL',

  UPDATE_ORDER_LOAD_STATUS: 'UPDATE_ORDER_LOAD_STATUS',

  UPDATE_ORDER_PROCESS_START: 'UPDATE_ORDER_PROCESS_START',
  DELETE_ORDER_PROCESS_START: 'DELETE_ORDER_PROCESS_START',
  MOVE_ORDER_TO_NEXT_DAY_START: 'MOVE_ORDER_TO_NEXT_DAY_START',

  SORT_ORDERS: 'SORT_ORDERS',
}

export const updateOrderLoadStatus = (payload) => ({
  type: types.UPDATE_ORDER_LOAD_STATUS,
  payload,
})

export const fetchOrderProcessListStart = (payload) => ({
  type: types.FETCH_ORDER_PROCESS_LIST_START,
  payload,
})

export const fetchOrderProcessListSuccess = (payload) => ({
  type: types.FETCH_ORDER_PROCESS_LIST_SUCCESS,
  payload,
})

export const clearShipmentOrders = (payload) => ({
  type: types.CLEAR_SHIPMENT_ORDERS,
  payload,
})

export const fetchShipmentOrdersStart = (payload) => ({
  type: types.FETCH_SHIPMENT_ORDERS_START,
  payload,
})

export const fetchShipmentOrdersSuccess = (payload) => ({
  type: types.FETCH_SHIPMENT_ORDERS_SUCCESS,
  payload,
})

export const fetchOrderProcessListFail = (payload) => ({
  type: types.FETCH_ORDER_PROCESS_LIST_FAIL,
  payload,
})

export const fetchOneOrderProcessStart = (payload) => ({
  type: types.FETCH_ONE_ORDER_PROCESS_START,
  payload,
})

export const fetchOneOrderProcessSuccess = (payload) => ({
  type: types.FETCH_ONE_ORDER_PROCESS_SUCCESS,
  payload,
})

export const fetchOneOrderProcessFail = (payload) => ({
  type: types.FETCH_ONE_ORDER_PROCESS_FAIL,
  payload,
})

export const updateOrderProcessStart = (payload) => ({
  type: types.UPDATE_ORDER_PROCESS_START,
  payload,
})

export const sortOrders = (payload) => ({
  type: types.SORT_ORDERS,
  payload,
})

export const deleteOrderProcessStart = (payload) => ({
  type: types.DELETE_ORDER_PROCESS_START,
  payload,
})

export const moveOrderToNextDayStart = (payload) => ({
  type: types.MOVE_ORDER_TO_NEXT_DAY_START,
  payload,
})
